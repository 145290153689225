import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";
import Icon from "@components/Icon/Icon";
import Text from "@components/Text";
import HeadRowItem from "@components/Table/components/HeadRowItem";

function HeadRowSort({title, sortValue}) {
    const [searchParams, setSearchParams] = useSearchParams()
    let getCurrentSort = () => {
        if (searchParams.getAll('sort')?.includes(`asc.${sortValue}`)) {
            return true
        } else if (searchParams.getAll('sort')?.includes(`desc.${sortValue}`)) {
            return false
        }
        else {
            return null
        }
    }

    const [sort, setSort] = useState(getCurrentSort())

    useEffect(() => {
        setSort(getCurrentSort())
    }, [searchParams])

    function replaceSortValue(from, to) {
        searchParams.delete('sort', `${from}.${sortValue}`)
        searchParams.append('sort', `${to}.${sortValue}`)
        setSearchParams(searchParams)
    }

    function addNewSortValue() {
        if(!searchParams.get('sort')) {
            searchParams.set('sort', `asc.${sortValue}`)
        } else {
            searchParams.append('sort', `asc.${sortValue}`)
        }
        setSearchParams(searchParams)
    }

    let changeSort = () => {
        if (sort) {
            replaceSortValue('asc', 'desc');
        } else if (sort === false) {
            replaceSortValue('desc', 'asc');
        } else {
            addNewSortValue();
        }
    }

    return (
        <HeadRowItem onClick={() => changeSort()} isAction>
            <Text value={title} font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
            <Icons>
                <IconWrapper iconPosition='top' isActive={sort}>
                    <Icon
                        iconName='krista-arrow-top-v2'
                        iconWidth={24}
                        iconHeight={24}
                    />
                </IconWrapper>
                <IconWrapper iconPosition='bottom' isActive={!sort}>
                    <Icon
                        iconName='krista-arrow-top-v2'
                        iconWidth={24}
                        iconHeight={24}
                    />
                </IconWrapper>
            </Icons>
        </HeadRowItem>
    );
}

const Icons = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
`

const IconWrapper = styled.div`
  color: ${COLORS.LIGHTGRAY};
  opacity: ${({isActive}) => isActive ? 1 : 0.4};
  position: absolute;

  ${({iconPosition}) => {
    if (iconPosition === 'bottom') {
      return `
        top: 6px;
        transform: rotate(180deg);
      `
    }
    if (iconPosition === 'top') {
      return `
        bottom: 6px;
        transform: rotate(0deg);
      `
    }
  }};
`

export default HeadRowSort;