import {COLORS} from "@/constans";
import styled from "styled-components";

function HeadRow({children, onClick, backgroundColor, padding, border, borderRadius, margin, isTopOffset, cursor, zIndex}) {
    return (
        <HeadRowContent
            backgroundColor={backgroundColor}
            padding={padding}
            border={border}
            borderRadius={borderRadius}
            onClick={onClick}
            margin={margin}
            isTopOffset={isTopOffset}
            cursor={cursor}
            zIndex={zIndex}
        >
            {children}
        </HeadRowContent>
    );
}

const HeadRowContent = styled.div`
  align-items: center;
  padding: ${({padding}) => padding ? padding : '0 8px'};
  background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : COLORS.LIGHTGRAY2};
  ${({border}) => border && `border: ${border};`};
  border-radius: ${({borderRadius}) => borderRadius ? borderRadius : '10px'};
  position: sticky;
  overflow: hidden;
  top: ${({isTopOffset}) => isTopOffset ? '71px' : '0'};
  ${({onClick}) => onClick && `cursor: pointer;`};
  ${({margin}) => margin && `margin: ${margin}`};
  z-index: ${({zIndex}) => zIndex ? zIndex : '1'};
`

export default HeadRow;