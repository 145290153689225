import {useEffect} from "react";
import {useSelector} from "react-redux";

import HomeDashboard from "@components/Dashboards/HomeDashboard/HomeDashboard";
import EmptyBox from "@components/EmptyBox/EmptyBox";
import TableNewUsers from "@components/Table/TableDefault/TableNewUsers/TableNewUsers";
import Pagination from "@components/Pagination/Pagination";
import ErrorBoundary from "@components/ErrorBoundary/ErrorBoundary";

import {getNotApprovedClients} from "@actions/clientsAction";

import {useSearchParams} from "react-router-dom";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text/Text";
import FlexLayout from "@components/Layout/FlexLayout";

const HomePage = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const clientsData = useSelector(state => state.registrationRequests.notApprovedClientsState)
    const clientsTotal = useSelector(state => state.registrationRequests.notApprovedClientsState?.data)
    const clients = useSelector(state => state.registrationRequests.notApprovedClientsState?.items)

    const clientsPagination = useSelector(state => state.registrationRequests.notApprovedClientsState?.page)

    useEffect(() => {
        if (!searchParams.get('page') || !searchParams.get('pageSize')) {
            setSearchParams({
                page: '1',
                pageSize: '10'
            })
        } else {
            getNotApprovedClients(searchParams)
        }
    }, [searchParams])

    return (
        <>
            <HomeDashboard/>
            <FlexLayout gap='8px' align='center' margin='0 0 24px 0'>
                <Text
                    font={FONTS.SEMI_BOLD_24}
                    value='Новые пользователи'
                    as='h1'
                    color={COLORS.BLACK}
                />
                <Text
                    as='span'
                    margin='3px 0 0 0'
                    font={FONTS.REGULAR_18}
                    color={COLORS.GRAY}
                    value={
                        <>
                            {clients?.length === 0 && clientsData && "(Нет новых запросов на регистрацию)"}
                            {clients?.length !== 0 && clientsData && `(${clientsTotal} запросов на регистрацию)`}
                        </>
                    }
                />
            </FlexLayout>

            {!clientsData &&
                <EmptyBox
                    title="Загружаем таблицу с клиентами"
                    icon="krista-user"
                />
            }

            {clientsData && clients?.length === 0 &&
                <EmptyBox
                    title=" Вы обработали все запросы новых пользователей"
                    subtitle="Отличная работа, коллега"
                    icon="krista-like"
                />
            }

            {clientsData && clients?.length !== 0 &&
                <ErrorBoundary>
                    <>
                        <TableNewUsers/>
                        <Pagination
                            total={clientsPagination.totalItemCount}
                            totalSuffix="клиентов"
                            active={clientsPagination.currentPageIndex}
                            perPage={clientsPagination.pageSize}
                            startItem={clientsPagination.startItemIndex}
                            endItem={clientsPagination.endItemIndex}
                        />
                    </>
                </ErrorBoundary>
            }
        </>
    );
};

export default HomePage