import Box from "@components/Box";
import React, {useState} from "react";
import TabsSizeLayout from "src/pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/TabsSizeLayout";
import Text from "@components/Text";
import {FONTS} from "@/constans";
import TabsColorsLayout from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/TabsColorsLayout";
import {useParams, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Line from "@components/Line";
import GridLayout from "@components/Layout/GridLayout";
import GroupTextile from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/GroupTextile";

function TaskDetails(props) {

    const {sizes} = props
    const {register, control, watch} = props

    const [searchParams, setSearchParams] = useSearchParams()


    const activeSize = searchParams?.get('activeSize')
    const activeColor = useSelector(state => state.model.modelActiveColor)

    const [isOpen, setIsOpen] = useState(true)
    return (
        <GridLayout gap='20px'>
            <Text value='Детали задания' as='h2' font={FONTS.SEMI_BOLD_20}/>
            <Box>
                <TabsSizeLayout
                    sizes={sizes}
                    register={register}
                />
                <Line margin="-1px -16px 20px -16px"/>

                {sizes?.map((itemSize, itemSizeIndex) => {
                    if (itemSize.modelTaskId == activeSize) {
                        return (
                            <GridLayout gap='20px'>
                                <TabsColorsLayout
                                    itemSize={itemSize}
                                    key={itemSizeIndex}
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                />
                                {isOpen && (
                                    itemSize.colors.map((itemColor, itemColorIndex) => {
                                        if(itemColorIndex === activeColor) {
                                            return(
                                                    <GroupTextile
                                                        watch={watch}
                                                        register={register}
                                                        control={control}
                                                        itemSizeIndex={itemSizeIndex}
                                                        itemColor={itemColor}
                                                    />
                                            )
                                        }

                                    })

                                    )}
                            </GridLayout>
                        );
                    }
                })}
            </Box>
        </GridLayout>
    );
}

export default TaskDetails;