import styled from "styled-components";
import {COLORS} from "@/constans";

function TaskRowBodyItem({children}) {
    return (
        <TaskRowBodyItemContent>
            {children}
        </TaskRowBodyItemContent>
    );
}

export const TaskRowBodyItemContent = styled.div`
  min-height: 68px;
  padding: 4px 0;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    right: -5px;
    width: 1px;
    background-color: ${COLORS.LIGHTGRAY1};
  }
`

export default TaskRowBodyItem;