import {SET_ALL_MANAGER, SET_ALL_CONSTRUCTOR} from "@types/types";

const initState = {
    managersState: [],
    constructorsState: [],
}

const managersReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_ALL_MANAGER :
            return {...state, managersState: action.payload}
        case SET_ALL_CONSTRUCTOR :
            return {...state, constructorsState: action.payload}

        default:
            return state
    }
}
export default managersReducer