import CardOrders from "@components/Cards/CardOrders/CardOrders";
import GridLayout from "@components/Layout/GridLayout";

const OrdersDashboard = () => {
    return (
        <GridLayout gap='20px' template='repeat(3, 1fr)' margin='0 0 24px 0'>
            <CardOrders typeButton='isPreorder'/>
            <CardOrders typeButton='isStock'/>
            <CardOrders typeButton='isTotal'/>
        </GridLayout>
    );
};

export default OrdersDashboard;
