import styled from "styled-components";
import {COLORS} from "@/constans";

function TaskRow({children}) {
    return (
        <TaskRowContent>
            {children}
        </TaskRowContent>
    );
}

const TaskRowContent = styled.div`
  width: 100%;
  padding: 12px 16px;
  background-color: ${COLORS.LIGHTGRAY2};
  border-radius: 10px;
  margin-bottom: 8px;
`

export default TaskRow;