import {COLORS} from "@/constans";
import styled from "styled-components";
import CardStatusClient from "@components/Cards/CardStatus/CardStatusClient/CardStatusClient";
import GridLayout from "@components/Layout/GridLayout";

const ClientsDashboard = ({register, watch}) => {
    const watchHasReserve = watch('hasReserve')
    const watchHasDebt = watch('hasDebt')
    return (
        <GridLayout gap='20px' template='repeat(5, 1fr)' margin='0 0 24px 0' align='stretch'>
            <CardStatusClient
                linkPath=""
                linkTitle=''
                title={`Корзины \n клиентов`}
                quantity="450"
                iconName="krista-user"
                color={COLORS.SALAD}
                leftTotal={{
                    label: "Городов",
                    value: "100",
                    suffix: ""
                }}
                rightTotal={{
                    label: "Заказов",
                    value: "256",
                    suffix: ""
                }}
            />
            <CardStatusClient
                linkPath=""
                title={`Лучшие клиенты \n за период`}
                quantity="20"
                iconName="krista-crown"
                color={COLORS.YELLOW}
                leftTotal={{
                    label: "Единиц",
                    value: "1 280",
                    suffix: ""
                }}
                rightTotal={{
                    label: "На сумму",
                    value: "254 000.00",
                    suffix: "$"
                }}
            />
            <CardStatusClient
                linkPath=""
                title={`Клиенты с \n резервами`}
                quantity="220"
                iconName="krista-box"
                color={COLORS.MAIN_BLUE}
                leftTotal={{
                    label: "Единиц",
                    value: "1 006",
                    suffix: ""
                }}
                rightTotal={{
                    label: "На сумму",
                    value: "14 000.00",
                    suffix: "$"
                }}
                isLinkIcon
                isActive={watchHasReserve}
                register={register}
                watch={watch}
                form={
                    <>
                        <Label>
                            <Checkbox
                                type="checkbox"
                                {...register && {...register('hasReserve')}}
                            />
                        </Label>
                    </>
                }
            />
            <CardStatusClient
                linkPath=""
                title={`Долгов по \n заказам`}
                quantity="220"
                iconName="krista-debt"
                color={COLORS.RED}
                leftTotal={{
                    label: "Единиц",
                    value: "1 006",
                    suffix: ""
                }}
                rightTotal={{
                    label: "На сумму",
                    value: "14 000.00",
                    suffix: ""
                }}
                isLinkIcon
                isActive={watchHasDebt}
                register={register}
                watch={watch}
                form={
                    <>
                        <Label>
                            <Checkbox
                                type="checkbox"
                                {...register && {...register('hasDebt')}}
                            />
                        </Label>
                    </>
                }
            />
            <CardStatusClient
                linkPath=""
                linkTitle="Корзины клиентов"
                title={`Неактивные \n клиенты`}
                quantity="310"
                iconName="krista-inactive-user"
                color={COLORS.LIGHTGRAY}
                leftTotal={{
                    label: "",
                    value: "",
                    suffix: ""
                }}
                rightTotal={{
                    label: "",
                    value: "",
                    suffix: ""
                }}
                isInactiveClients
            />
        </GridLayout>
    );
};

const Label = styled.label`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: block;
  cursor: pointer;
`

const Checkbox = styled.input`
  display: none;
`

export default ClientsDashboard;
