import React from 'react';
import Icon from "@components/Icon/Icon";
import Counter from "@components/Form/Counter";
import Button from "@components/Form/Button";
import BodyRow from "@components/Table/components/BodyRow";
import GridLayout from "@components/Layout/GridLayout";
import BodyRowItem from "@components/Table/components/BodyRowItem";
import Checkbox from "@components/Form/Checkbox";
import Image from "@components/Image/Image";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import FlexLayout from "@components/Layout/FlexLayout";
import Color from "@components/Table/components/Color";

function Row({catalogIcon}) {
    return (
        <BodyRow backgroundColor={COLORS.WHITE}>
            <GridLayout align='center' template='42px 6% 7% 7% 11% auto 9% 6% 6% 8% 8% 54px'>
                <BodyRowItem>
                    <Checkbox/>
                </BodyRowItem>
                <BodyRowItem>
                    <Image src='https://picsum.photos/200/100'/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='10-01 к/р' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='42-44' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <FlexLayout align='center'>
                        <Color backgroundColor='#005CCC'/>
                        <Text value='Блюстоун' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY} margin='0 0 0 8px'/>
                    </FlexLayout>
                </BodyRowItem>
                <BodyRowItem>
                    <FlexLayout align='center'>
                        <Icon
                            iconName={catalogIcon}
                            iconWidth={20}
                            iconHeight={20}
                            iconColor={COLORS.LIGHTGRAY}
                        />
                        <Text value='Предзаказ сериями' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY} margin='0 0 0 8px'/>
                    </FlexLayout>
                </BodyRowItem>
                <BodyRowItem>
                    <Counter value={24}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='48' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='35.00 $' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='1 320.00 $' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='520.00 $' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Button
                        variant='isGray'
                        iconName='krista-delete'
                        isSmall
                    />
                </BodyRowItem>
            </GridLayout>
        </BodyRow>
    );
}

export default Row;