import {COLORS} from "@/constans";
import CardStatusHome from "@components/Cards/CardStatus/CardStatusHome/CardStatusHome";
import GridLayout from "@components/Layout/GridLayout";

const HomeDashboard = () => {
    return (
        <GridLayout
            template='repeat(5, 1fr)'
            gap='20px'
            margin='0 0 32px 0'
            align='stretch'
            data-testid='custom-element'
        >
            <CardStatusHome
                linkPath=""
                linkTitle="Корзины клиентов"
                title={`Корзины \n клиентов`}
                quantity="450"
                iconName="krista-cart"
                color={COLORS.YELLOW}
                leftTotal={{
                    label: "",
                    value: "32 554",
                    suffix: "ед."
                }}
                rightTotal={{
                    label: "",
                    value: "641 589.00",
                    suffix: "$"
                }}
            />
            <CardStatusHome
                linkPath=""
                linkTitle="Перейти к заказам"
                title={`Необработанные \n заказы`}
                quantity="235"
                iconName="krista-cart-add"
                color={COLORS.RED}
                leftTotal={{
                    label: "",
                    value: "23 250 ",
                    suffix: "ед."
                }}
                rightTotal={{
                    label: "",
                    value: "541 589.00",
                    suffix: "$"
                }}
            />
            <CardStatusHome
                linkPath=""
                linkTitle="Перейти к  заказам"
                title={`Заказы в \n работе`}
                quantity="235"
                iconName="krista-scissors"
                color={COLORS.MAIN_BLUE}
                leftTotal={{
                    label: "",
                    value: "23 250",
                    suffix: " ед."
                }}
                rightTotal={{
                    label: "",
                    value: "341 589.00",
                    suffix: "$"
                }}
            />
            <CardStatusHome
                linkPath=""
                linkTitle="Посмотреть склад"
                title="Резервы"
                subtitle="Кол-во клиентов"
                quantity="1 154"
                iconName="krista-warehouse"
                color={COLORS.SALAD}
                leftTotal={{
                    label: "",
                    value: "3 589",
                    suffix: " ед."
                }}
                rightTotal={{
                    label: "",
                    value: "23 589.00",
                    suffix: "$"
                }}
            />
            <CardStatusHome
                linkPath=""
                linkTitle="Посмотреть кассу"
                title="Касса"
                subtitle="Счетов по заказам"
                quantity="160"
                iconName="krista-money"
                color={COLORS.PURPLE}
                leftTotal={{
                    label: "Сумма заказов",
                    value: "964 589.00",
                    suffix: "$"
                }}
                rightTotal={{
                    label: "Сумма долгов",
                    value: "541 589.00",
                    suffix: "$"
                }}
            />
        </GridLayout>
    );
};

export default HomeDashboard;
