import ApiConfig from "@/ApiConfig";
import store from "@store";
import {SET_DESIGN_TASKS, SET_DESIGN_TASKS_STATUS_BOARD} from "@types/types";
import {toast} from "react-toastify";
import ToastContent from "@components/ToastContent/ToastContent";

const path = 'Manufacturing/DesignTask/'

export const getDesignTasks = () => {
    ApiConfig.get(`${path}All`)
        .then((response) => {
            store.dispatch({type: SET_DESIGN_TASKS, payload: response.data})
        })
};
export const getDesignTasksCreate = (data, navigate) => {
    ApiConfig.post(`${path}Create`, data)
        .then((response) => {
            toast.success(<ToastContent title="Успешная операция" response={response.data.message} ok/>);
            navigate("/task-models", {replace: true});
        })
};

export const getDesignTasksStatusBoard = () => {
    ApiConfig.get(`${path}StatusBoard`)
        .then((response) => {
            store.dispatch({type: SET_DESIGN_TASKS_STATUS_BOARD, payload: response.data})
        })
};

export const getDesignTasksAddMaterials = async (articulTaskId, nomenclatureId, isMain, materialPriceId) => {
    return await ApiConfig.patch(`${path}AddMaterials`, {
        articulTaskId: articulTaskId,
        nomenclatureId: nomenclatureId,
        isMain: isMain,
        materialPriceId: materialPriceId
    })
}
export const getDesignTasksUpdateMaterials = async (articulTaskId, nomenclatureId, materialCalculationId, materialPriceId, isMain) => {
    return await ApiConfig.put(`${path}UpdateMaterial`, {
        articulTaskId: articulTaskId,
        nomenclatureId: nomenclatureId,
        materialCalculationId: materialCalculationId,
        materialPriceId: materialPriceId,
        isMain: isMain
    })
}

export const getDesignTasksUpdateMaterialConsumption = async (articulTaskId, nomenclatureId, materialCalculationId, quantity) => {
    return await ApiConfig.patch(`${path}UpdateMaterialConsumption`, {
        articulTaskId: articulTaskId,
        nomenclatureId: nomenclatureId,
        materialCalculationId: materialCalculationId,
        quantity: quantity,
    })
}

export const getDesignTasksDeleteMaterials = async (articulDesignTaskId, nomenclatureId, materialCalculationId) => {
    return await ApiConfig.delete(`${path}deleteMaterial`, {
        data: {
            articulTaskId: articulDesignTaskId,
            nomenclatureId: nomenclatureId,
            materialCalculationId: materialCalculationId
        }
    })
}
