import styled, {css} from "styled-components";

function FooterRowItem({children, padding, bg}) {
    return (
        <FooterRowItemContent padding={padding} bg={bg}>
            {children}
        </FooterRowItemContent>
    );
}

const FooterRowItemContent = styled.div`
  padding: ${({padding}) => padding ? padding : '0 8px'};
  ${({bg}) => bg && css` background-color: ${bg} `};
`

export default FooterRowItem;