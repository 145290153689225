import React from 'react';
import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text";
import Box from "@components/Box";
import FlexLayout from "@components/Layout/FlexLayout";
import Line from "@components/Line";
import {NumericFormat} from "react-number-format";
import RadioButtons from "@components/Form/RadioButtons";
import {getCurrencyScaleLength} from "@util/decimalHelper";

function AvailablePayments(props) {
    const {invoice, register, setValue, watch, isCompleted} = props
    const template = '2fr 1.8fr 1.5fr 1.5fr'

    return (
        <>
            {isCompleted ?
                <Text
                    value={invoice.isCashless ? 'Оплачено безналично' : 'Оплачено наличными'}
                    font={FONTS.REGULAR_12}
                    color={COLORS.LIGHTGRAY}
                />
                :
                <RadioButtons
                    register={register}
                    data={[{id: 0, name: 'Наличными'}, {id: 1, name: 'Безналичная'}]}
                    title="Способ оплаты"
                    name="payMethod"
                    setValue={setValue}
                    selected={watch('payMethod')}
                    label='name'
                />
            }

            {invoice?.totals && (
                <Box background={COLORS.LIGHTGRAY3}>
                    <GridLayout gap='12px'>
                        <GridLayout template={template} align='center'>
                            <FlexLayout gap='4px'>
                                <Text value='Всего единиц:' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                                <Text
                                    value={invoice.totals.totalQuantity}
                                    font={FONTS.REGULAR_14}
                                    color={COLORS.LIGHTGRAY}
                                />
                            </FlexLayout>
                            <div/>
                            <div/>
                            <Text
                                value={`Сумма в ${invoice.currencyCode}`}
                                font={FONTS.REGULAR_14}
                                color={COLORS.LIGHTGRAY}
                            />
                        </GridLayout>
                        <Line/>
                        <GridLayout gap='4px'>
                            <GridLayout template={template} align='center'>
                                <Text value='На сумму без скидки' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                                <div/>
                                <div/>
                                <NumericFormat
                                    value={invoice.totals.totalSumWithoutDiscount}
                                    thousandSeparator=" "
                                    decimalScale={getCurrencyScaleLength(invoice.totals.totalSumWithoutDiscount)}
                                    fixedDecimalScale
                                    displayType="text"
                                    renderText={(value) => (
                                        <Text value={value} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                                    )}
                                    suffix={` ${invoice.totals.currencySymbol}`}
                                />
                            </GridLayout>
                            <GridLayout template={template} align='center'>
                                <Text value='Скидка' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                                <div/>
                                <div/>
                                <NumericFormat
                                    value={invoice.totals.totalDiscount}
                                    thousandSeparator=" "
                                    decimalScale={getCurrencyScaleLength(invoice.totals.totalDiscount)}
                                    fixedDecimalScale
                                    displayType="text"
                                    renderText={(value) => (
                                        <Text value={value} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                                    )}
                                    suffix={` ${invoice.totals.currencySymbol}`}
                                />
                            </GridLayout>
                        </GridLayout>
                        <Line/>
                        <GridLayout template={template} align='center'>
                            <Text value='Итого к оплате' font={FONTS.MEDIUM_14} color={COLORS.DARKGRAY}/>
                            <div/>
                            <div/>
                            <NumericFormat
                                value={invoice.totals.totalSum}
                                thousandSeparator=" "
                                decimalScale={getCurrencyScaleLength(invoice.totals.totalSum)}
                                fixedDecimalScale
                                displayType="text"
                                renderText={(value) => (
                                    <Text value={value} font={FONTS.MEDIUM_14} color={COLORS.DARKGRAY}/>
                                )}
                                suffix={` ${invoice.totals.currencySymbol}`}
                            />
                        </GridLayout>
                    </GridLayout>
                </Box>
            )}
        </>
    );
}

export default AvailablePayments;