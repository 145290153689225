import styled, {css} from "styled-components";
import React from "react";
import Icon from "@components/Icon";
import {COLORS} from "@/constans";

function Image({src, width, height, isEmptyImage}) {
    return (
        <ImageContent width={width} height={height} isEmptyImage={isEmptyImage}>
            {isEmptyImage ?
                <Icon
                    iconName='krista-camera'
                    iconColor={COLORS.LIGHTGRAY}
                    iconWidth={24}
                    iconHeight={24}
                />
                :
                <Img src={src} alt=""/>
            }
        </ImageContent>
    );
}

const ImageContent = styled.div`
  width: ${({width}) => width ? width : '64px'};
  height: ${({height}) => height ? height : '64px'};
  border-radius: 10px;
  overflow: hidden;
  background-color: ${COLORS.LIGHTGRAY2};
  ${({isEmptyImage}) => isEmptyImage && css`
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export default Image;