import BodyRow from "@components/Table/components/BodyRow";
import GridLayout from "@components/Layout/GridLayout";
import BodyRowItem from "@components/Table/components/BodyRowItem";
import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";
import Button from "@components/Form/Button";
import {NumericFormat} from "react-number-format";
import RowDropdown from "@components/Table/TableDefault/TableStoreWarehouse/RowDropdown";
import Table from "@components/Table/components/Wrapper";

function Row({template, toggleArticle, isOpen, article}) {
    return (
        <Table borderRadius='10px' backgroundColor={COLORS.WHITE}>
            <BodyRow backgroundColor={COLORS.WHITE} onClick={toggleArticle}>
                <GridLayout align='center' template={template}>
                    <BodyRowItem>
                        <FlexLayout gap='8px' align='center'>
                            <ImageWrapper>
                                <Img src={article?.photo} alt=""/>
                            </ImageWrapper>
                            <Text value={article?.articulName} font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY}/>
                        </FlexLayout>
                    </BodyRowItem>
                    <BodyRowItem/>
                    <BodyRowItem>
                        <NumericFormat
                            value={article?.totalSum}
                            thousandSeparator=" "
                            decimalScale={2}
                            displayType="text"
                            renderText={(value) => (
                                <Text value={value} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                            )}
                            fixedDecimalScale
                            suffix={` ${article?.currencySymbol}`}
                        />
                    </BodyRowItem>
                    <BodyRowItem>
                        <Text value={article?.linesQuantity} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                    </BodyRowItem>
                    <BodyRowItem>
                        <Text value={article?.quantity} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                    </BodyRowItem>
                    <BodyRowItem>
                        <NumericFormat
                            value={article?.totalSum}
                            thousandSeparator=" "
                            decimalScale={2}
                            displayType="text"
                            renderText={(value) => (
                                <Text value={value} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                            )}
                            fixedDecimalScale
                            suffix={` ${article?.currencySymbol}`}
                        />
                    </BodyRowItem>
                    <BodyRowItem>
                        <Button
                            variant='isChevron'
                            iconName={isOpen ? "krista-chevron-top" : "krista-chevron-bottom"}
                            isSmall
                        />
                    </BodyRowItem>
                </GridLayout>
            </BodyRow>

            {isOpen &&
                <>
                    {article?.sizes.map((size, index) => (
                            <RowDropdown
                                size={size}
                                template={template}
                                key={index}
                            />
                        )
                    )}
                </>
            }
        </Table>
    );
}

const ImageWrapper = styled.div`
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 10px;
`

const Img = styled.img`{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
`

export default Row;