import React from 'react';
import {Link} from "react-router-dom";
import styled from "styled-components";
import hexToRgba from "hex-to-rgba";
import {COLORS, FONTS} from "@/constans";

import Icon from "@components/Icon/Icon";
import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";

function CardStatusLayout(props) {
    const {linkPath, linkTitle, title, subtitle, iconName, color, quantity} = props
    const {isLinkIcon, isActive} = props
    const {form, children} = props
    return (
        <Content color={color} isActive={isActive} linkTitle={linkTitle}>
            {form && form}
            {linkTitle &&
                <LinkPath to={linkPath}>
                    <LinkButton>
                        <Text
                            value={linkTitle}
                            font={FONTS.SEMI_BOLD_12}
                            color='inherit'
                        />
                    </LinkButton>
                </LinkPath>
            }
            <FlexLayout margin='0 0 12px 0'>
                <HeadLeft>
                    <Text
                        value={title}
                        font={FONTS.BOLD_16}
                        color={COLORS.BLACK}
                        whiteSpace='pre-line'
                    />
                    <Text
                        value={subtitle}
                        font={FONTS.REGULAR_16}
                        color={COLORS.BLACK}
                        whiteSpace='pre-line'
                        margin='6px 0 0 0'
                    />
                    <Text
                        value={quantity}
                        font={FONTS.SEMI_BOLD_28}
                        color={COLORS.BLACK}
                        whiteSpace='pre-line'
                        margin='6px 0 0 0'
                    />
                </HeadLeft>
                <FlexLayout>
                    {isLinkIcon &&
                        <IconLink to={linkPath}>
                            <Icon iconName='krista-external-link'/>
                        </IconLink>
                    }
                    <IconWrapper>
                        <Icon iconName={iconName}/>
                    </IconWrapper>
                </FlexLayout>
            </FlexLayout>
            {children}
        </Content>
    );
}

const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const IconLink = styled(Link)`
  width: 36px;
  height: 36px;
  border-radius: 10px;
  background-color: ${hexToRgba(COLORS.LIGHTGRAY, 0.1)};
  color: ${COLORS.LIGHTGRAY};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 14px;
  z-index: 2;
`

const LinkPath = styled(Link)`
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`

const Content = styled.div`
  padding: 20px 24px;
  background-color: ${COLORS.WHITE};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border: ${({isActive, color, linkTitle}) => {
    if (isActive) {
      return `4px solid ${color}`
    }
    if (linkTitle) {
      return '0'
    }
    if (color) {
      return '4px solid transparent'
    }
  }};

  &:hover {
    border: ${({color, linkTitle}) => {
      if (linkTitle) {
        return '0'
      }
      return color && `4px solid ${color}`
    }};
  }

  ${IconWrapper} {
    background-color: ${({color}) => color && hexToRgba(color, 0.1)};
    color: ${({color}) => color && color};
  }

  ${LinkPath} {
    &:hover {
      background-color: ${({color}) => color && hexToRgba(color, 0.5)};
      opacity: 1;
    }
  }
`

const HeadLeft = styled.div`
  flex-grow: 3;
`

const LinkButton = styled.div`
  height: 32px;
  background-color: ${COLORS.WHITE};
  border-radius: 10px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.MAIN};
`

export default CardStatusLayout;