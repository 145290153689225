import React from 'react';
import Icon from "@components/Icon/Icon";
import dayjs from "dayjs";
import {useDispatch} from "react-redux";
import {SET_MODAL_LOGIN_HISTORY} from "@types/types";

import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";
import Text from "@components/Text";
import Line from "@components/Line";
import FlexLayout from "@components/Layout/FlexLayout";
import GridLayout from "@components/Layout/GridLayout";
import hexToRgba from "hex-to-rgba";

function ClientProfilePersonal({clientInfo, itemFiltered}) {
    const dispatch = useDispatch();

    const openModal = () => {
        dispatch({type: SET_MODAL_LOGIN_HISTORY, payload: true})
    }

    const copyLogin = () => {
        clientInfo.user.login && navigator.clipboard.writeText(clientInfo.user.login)
    };

    return (
        <Wrapper>
            <FlexLayout align='center' padding='10px 16px'>
                <Icon
                    iconName='krista-user'
                    iconWidth={24}
                    iconHeight={24}
                    iconColor={COLORS.LIGHTGRAY}
                />
                <Text
                    value='Личные данные'
                    font={FONTS.MEDIUM_14}
                    color={COLORS.DARKGRAY}
                    margin='0 0 0 8px'
                />
            </FlexLayout>
            <Line/>
            <Card>
                <GridLayout gap='8px'>
                    <FlexLayout align='center' spaceBetween>
                        <Text
                            value={itemFiltered.taxPayerType.isJuridical ? 'Организация' : 'Физ Лицо'}
                            font={FONTS.REGULAR_14}
                            color={COLORS.DARKGRAY}
                        />
                        <Text
                            value={itemFiltered.taxPayerType.isJuridical ? itemFiltered.taxPayerType.name + ' ' + itemFiltered.name : itemFiltered.name}
                            font={FONTS.MEDIUM_14}
                            color={COLORS.DARKGRAY}
                            textAlign='right'
                            margin='0 0 0 20px'
                        />
                    </FlexLayout>
                    <FlexLayout align='center' spaceBetween>
                        <Text value='ИНН' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                        <Text
                            value={itemFiltered.inn}
                            font={FONTS.MEDIUM_14}
                            color={COLORS.DARKGRAY}
                            textAlign='right'
                            margin='0 0 0 20px'
                        />
                    </FlexLayout>
                    {itemFiltered.taxPayerType.isJuridical
                        &&
                        <>
                            <FlexLayout align='center' spaceBetween>
                                <Text value='КПП' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                                <Text
                                    value={itemFiltered.governmentRegistration.kpp ? itemFiltered.governmentRegistration.kpp : '-'}
                                    font={FONTS.MEDIUM_14}
                                    color={COLORS.DARKGRAY}
                                    textAlign='right'
                                    margin='0 0 0 20px'
                                />
                            </FlexLayout>
                            <FlexLayout align='center' spaceBetween>
                                <Text value='ОГРН/ОГРНИП' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                                <Text
                                    value={itemFiltered.governmentRegistration.ogrn ? itemFiltered.governmentRegistration.ogrn : '-'}
                                    font={FONTS.MEDIUM_14}
                                    color={COLORS.DARKGRAY}
                                    textAlign='right'
                                    margin='0 0 0 20px'
                                />
                            </FlexLayout>
                            <FlexLayout align='center' spaceBetween>
                                <Text value='БИК' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                                <Text
                                    value={itemFiltered.bankAccount.bik ? itemFiltered.bankAccount.bik : '-'}
                                    font={FONTS.MEDIUM_14}
                                    color={COLORS.DARKGRAY}
                                    textAlign='right'
                                    margin='0 0 0 20px'
                                />
                            </FlexLayout>
                            <FlexLayout align='center' spaceBetween>
                                <Text value='Счет' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                                <Text
                                    value={itemFiltered.bankAccount.account ? itemFiltered.bankAccount.account : '-'}
                                    font={FONTS.MEDIUM_14}
                                    color={COLORS.DARKGRAY}
                                    textAlign='right'
                                    margin='0 0 0 20px'
                                />
                            </FlexLayout>
                            <FlexLayout align='center' spaceBetween>
                                <Text value='Банк' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                                <Text
                                    value={itemFiltered.bankAccount.correspondentAccount ? itemFiltered.bankAccount.correspondentAccount : '-'}
                                    font={FONTS.MEDIUM_14}
                                    color={COLORS.DARKGRAY}
                                    textAlign='right'
                                    margin='0 0 0 20px'
                                />
                            </FlexLayout>
                            <FlexLayout align='center' spaceBetween>
                                <Text value='К/С' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                                <Text
                                    value={itemFiltered.bankAccount.correspondentAccount ? itemFiltered.bankAccount.correspondentAccount : '-'}
                                    font={FONTS.MEDIUM_14}
                                    color={COLORS.DARKGRAY}
                                    textAlign='right'
                                    margin='0 0 0 20px'
                                />
                            </FlexLayout>
                        </>
                    }
                    <FlexLayout align='center' spaceBetween>
                        <Text value='Эл. почта' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                        <Text
                            value={clientInfo.user.email ? clientInfo.user.email : '-'}
                            font={FONTS.MEDIUM_14}
                            color={COLORS.DARKGRAY}
                            textAlign='right'
                            margin='0 0 0 20px'
                        />
                    </FlexLayout>
                    <FlexLayout align='center' spaceBetween>
                        <Text value='Логин' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                        <FlexLayout spaceBetween align='center'>
                            <Text
                                value={clientInfo.user.login ? clientInfo.user.login : '-'}
                                font={FONTS.MEDIUM_14}
                                color={COLORS.DARKGRAY}
                                textAlign='right'
                                margin='0 0 0 20px'
                            />
                            <CopyButton onClick={copyLogin}>
                                <Text value='Скопировать' font={FONTS.MEDIUM_11} color={COLORS.MAIN}/>
                            </CopyButton>
                        </FlexLayout>
                    </FlexLayout>
                    <FlexLayout align='top' spaceBetween>
                        <Text value='Номер телефона' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                        <Text
                            value={
                                clientInfo.user.contacts.map(item => (
                                    <div key={item.id}>
                                        {item.phone ? item.phone : '-'}
                                    </div>
                                ))
                            }
                            font={FONTS.MEDIUM_14}
                            color={COLORS.DARKGRAY}
                            textAlign='right'
                            margin='0 0 0 20px'
                        />
                    </FlexLayout>
                    <FlexLayout align='center' spaceBetween>
                        <Text value='Зарегистрирован' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                        <Text
                            value={clientInfo.user.createDate ? dayjs(clientInfo.user.createDate).format('DD.MM.YYYY') : '-'}
                            font={FONTS.MEDIUM_14}
                            color={COLORS.DARKGRAY}
                            textAlign='right'
                            margin='0 0 0 20px'
                        />
                    </FlexLayout>
                    <FlexLayout align='center' spaceBetween>
                        <Text value='Был в сети' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                        <div onClick={openModal}>
                            <Text
                                value={clientInfo.user.lastSignIn ? dayjs(clientInfo.user.lastSignIn).format('DD.MM.YYYY, HH:mm') : '-'}
                                font={FONTS.MEDIUM_14}
                                color={COLORS.MAIN}
                                textAlign='right'
                                margin='0 0 0 20px'
                                cursor='pointer'
                                userSelect='none'
                            />
                        </div>
                    </FlexLayout>
                </GridLayout>
            </Card>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  background-color: ${COLORS.LIGHTGRAY3};
  border-radius: 6px;
`

const Card = styled.div`
  padding: 16px;
  overflow: auto;
  height: 418px;
`

const CopyButton = styled.div`
  padding: 2px 8px;
  background-color: ${hexToRgba(COLORS.MAIN, 0.1)};
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  margin-left: 8px;

  &:hover {
    background-color: ${hexToRgba(COLORS.MAIN, 0.2)};
  }
`

export default ClientProfilePersonal;