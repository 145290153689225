import dayjs from "dayjs";

export const defaultSelectParamsValue = (searchParams, url, arr, id) => {
    const params = searchParams.get(url)
    if (!params) return []
    return arr.filter(item => params.includes(String(id ? item[id] : item.id)));
}

export const defaultCalendarSelectParamsValue = (searchParams, dateName, format) => {
    if (!searchParams.get(dateName)) {
        return null
    }
    return dayjs(searchParams.get(dateName), format).toDate();
}

export const setOrDeleteSearchParamsArr = (searchParams, value, url, id) => {
    if (value.length > 0) {
        return searchParams.set(url, value.map(item => id ? item[id] : item.id).join(','))
    }
    return searchParams.delete(url)
}

export const setOrDeleteSearchParams = (searchParams, value, url) => {
    value ? searchParams.set(url, value) : searchParams.delete(url)
}

export const setCalendarSearchParams = (searchParams, value, url, format) => {
    if(value) {
        if(value.getFullYear() < 2000) return;
        return searchParams.set(url, dayjs(value).format(format))
    }
    return searchParams.delete(url)
}