import {getSidebar} from "@actions/sidebarAction";
import {useEffect} from "react";
import {getAllManagersShortData} from "@actions/employeeAction";
import {getAllCities} from "@actions/cityAction";
import styled from "styled-components";
import Header from "@components/Header/Header";
import Sidebar from "@components/Sidebars/Sidebar/Sidebar";
import RouterApp from "@/RouterApp";

const AppLayout = () => {
    useEffect(() => {
        getSidebar()
        getAllManagersShortData()
        getAllCities()
        // getAllCatalogs()
    }, [])

    return (
        <Wrapper>
            <Header/>
            <Side>
                <Sidebar/>
                <Content>
                    <RouterApp/>
                </Content>
            </Side>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Side = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  flex-grow: 3;
`

const Content = styled.main`
  background-color: #FAFAFB;
  padding: 32px 40px;
  flex-grow: 3;
  display: flex;
  flex-direction: column;
  @media (max-width: 640px) {
    padding: 20px 16px;
  }
`


export default AppLayout;
