import React from 'react';
import styled, {css} from "styled-components";
import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text";

const Switch = ({array, selected, title}) => {
    return (
        <div aria-label={`Поле ${title}`}>
            {
                title && (
                    <Text value={title} font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY} margin='0 0 6px 0'/>
                )
            }

            <Switcher>
                <GridLayout gap='4px' template={`repeat(${array?.length}, 1fr)`}>
                    {array.map(item => (
                        <SwitcherItem isActive={item.id === selected} key={item.id}>
                            <Text
                                value={item.render}
                                font={FONTS.REGULAR_14}
                                color='inherit'
                            />
                        </SwitcherItem>
                    ))}
                </GridLayout>
            </Switcher>
        </div>

    );
};
const Switcher = styled.div`
  background: #FFFFFF;
  border: 1px solid ${COLORS.LIGHTGRAY1};
  border-radius: 10px;
  padding: 4px;
`
const SwitcherItem = styled.div`
  position: relative;
  background-color: ${COLORS.LIGHTGRAY3};
  border-radius: 8px;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  user-select: none;
  color: ${COLORS.GRAY};
  text-align: center;
  overflow: hidden;

  &:hover {
    background-color: ${COLORS.LIGHTGRAY1};
  }

  & {
    a {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a.active {
      color: ${COLORS.WHITE};
      background-color: ${COLORS.MAIN};
    }
  }


  ${({isActive}) => isActive && css`
    background-color: ${COLORS.MAIN};
    color: ${COLORS.WHITE};

    &:hover {
      background-color: ${COLORS.MAIN};
    }
  `}
`

export default Switch;