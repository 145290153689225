import Icon from "@components/Icon/Icon";
import { useNavigate } from "react-router-dom";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";
import styled from "styled-components";
import Button from "@components/Form/Button";

const EmptyBox = ({title, subtitle, icon, iconColor, isRefresh = true}) => {

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    return (
        <Content>
            <IconWrapper>
                <Icon iconName={icon} iconWidth={48} iconHeight={48} iconColor={iconColor}/>
            </IconWrapper>
            <Text value={title} font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY}/>
            <Text value={subtitle} font={FONTS.REGULAR_16} color={COLORS.LIGHTGRAY} margin='0 0 20px 0'/>
            {isRefresh &&
                <Button
                    buttonText='Обновить'
                    iconName='krista-cycle'
                    variant='isMain'
                    onClick={refreshPage}
                    isSmall
                />
            }
        </Content>
    );
};

const Content = styled.div`
  background-color: ${COLORS.LIGHTGRAY2};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 3;
  padding: 20px 0;
`
const IconWrapper = styled.div`
  color: ${COLORS.SALAD};
  margin-bottom: 16px;
`

export default EmptyBox;
