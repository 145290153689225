import React from 'react';
import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";

function BadgeStatus({backgroundColor, value}) {
    return (
        <BadgeStatusContent backgroundColor={backgroundColor} value={value}>
            <Text value={value} font={FONTS.REGULAR_14} color={COLORS.WHITE}/>
        </BadgeStatusContent>
    );
}

const BadgeStatusContent = styled.div`
  padding: 2px 10px;
  border-radius: 16px;
  text-align: center;
  display: inline-flex;

  background-color: ${({backgroundColor}) => backgroundColor && backgroundColor};
`

export default BadgeStatus;