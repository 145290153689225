import {useSearchParams} from "react-router-dom";
import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";
import PaginationComponent from "react-js-pagination";
import Icon from "@components/Icon/Icon";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";

const Pagination = ({total, totalSuffix, active, perPage, startItem, endItem}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const setPage = (value) => {
        searchParams.set('page', value)
        setSearchParams(searchParams)
    }

    const setPageSize = (value) => {
        searchParams.set('pageSize', value)
        setSearchParams(searchParams)
    }

    const valueChecker = () => {
        if(startItem && endItem && total) {
            return `${startItem}-${endItem} из ${total} ${totalSuffix}`
        }
        return ''
    }

    return (
        <Side>
            <div>
                <Text
                    as='span'
                    value={`${startItem}-${endItem} из ${total} ${totalSuffix}`}
                    font={FONTS.REGULAR_14}
                    color={COLORS.BLACK}
                />
            </div>
            <FlexLayout align='center'>
                <PaginationContent>
                    <PaginationComponent
                        itemClass="listLi"
                        linkClass="listLink"
                        innerClass="list"
                        activeClass="active"
                        activeLinkClass="active"
                        itemClassPrev="controlStep"
                        itemClassNext="controlStep"
                        itemClassFirst="controlRange"
                        itemClassLast="controlRange"
                        prevPageText={<Icon iconName="krista-chevron-left" iconWidth={24} iconHeight={24}/>}
                        nextPageText={<Icon iconName="krista-chevron-right" iconWidth={24} iconHeight={24}/>}
                        pageRangeDisplayed={5}
                        activePage={active}
                        totalItemsCount={total ? total : 0}
                        onChange={(value) => setPage(value)}
                        itemsCountPerPage={perPage}
                        disabledClass="disabled"
                    />
                </PaginationContent>
                <SelectWrapper>
                    <Select
                        value={+perPage}
                        onChange={
                            (e) => setPageSize(+e.target.value)
                        }
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </Select>
                    <SelectIcon>
                        <Icon iconName="krista-arrows" iconWidth={24} iconHeight={24}/>
                    </SelectIcon>
                </SelectWrapper>

            </FlexLayout>
        </Side>
    );
};

const PaginationContent = styled.div`
  .list {
    display: flex;
    align-items: center;
    margin: 0;
  }
  .listLi{
    list-style: none;
    margin: 0 4px;
  }
  .listLink{
    font: ${FONTS.REGULAR_14};
    color: ${COLORS.LIGHTGRAY};
    text-decoration: none;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active{
      color: ${COLORS.MAIN};
    }
    &:hover{
      color: ${COLORS.MAIN};
    }
  }
  .controlStep, .controlRange{
    border: 1px solid ${COLORS.LIGHTGRAY1};
    border-radius: 8px;
    &:hover{
      border-color: ${COLORS.MAIN};
    }
  }
  .disabled{
    opacity: 0.2;
    &:hover{
      border-color: ${COLORS.LIGHTGRAY1};
      .listLink{
        color: ${COLORS.LIGHTGRAY};
      }
    }
    .listLink{
      cursor: not-allowed;
    }
  }
`

const Side = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${COLORS.WHITE};
  border-radius: 10px;
  padding: 16px;
  margin-top: 14px;
  bottom: 0;
  position: sticky;
  z-index: 2;
`

const SelectWrapper = styled.label`
  position: relative;
  cursor: pointer;
  &:after{
    top: 0;
    right: 24px;
    bottom: 0;
    width: 1px;
    background-color: ${COLORS.LIGHTGRAY1};
    content: '';
    position: absolute;
  }
`

const SelectIcon = styled.div`
    position: absolute;
    top: 4px;
    right: 0;
    bottom: 4px;
    color: ${COLORS.LIGHTGRAY};
    pointer-events: none;
`

const Select = styled.select`
  height: 34px;
  width: 70px;
  border: 1px solid ${COLORS.LIGHTGRAY1};
  border-radius: 8px;
  margin-left: 14px;
  padding-right: 24px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #92929D;
  background-color: ${COLORS.WHITE};
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
`

export default Pagination;
