import {useState} from "react";
import dayjs from "dayjs";
import {useDispatch} from "react-redux";
import {
    SET_CURRENT_CUSTOMERS,
    SET_MODAL_CONFIRM_ACTION, SET_MODAL_CONFIRM_NEW_CLIENT,
    SET_MODAL_CURRENT_CUSTOMERS,
    SET_NOT_APPROVED_CLIENT_BY_ID
} from "@types/types";
import PropTypes from "prop-types";
import {COLORS, FONTS} from "@/constans";

import {getNotApprovedClient, getNotApprovedClients, getRemoveClient} from "@actions/clientsAction";
import {getTaxPayerType} from "@actions/taxPayerTypeAction";
import {getDiscount} from "@actions/discountAction";
import {getMargins} from "@actions/marginsAction";
import {getCurrentCustomers} from "@actions/currentCustomersAction";
import {useSearchParams} from "react-router-dom";

import OpenModalCurrentCustomers from "@components/Buttons/OpenModalCurrentCustomers/OpenModalCurrentCustomers";
import Button from "@components/Form/Button";
import BodyRow from "@components/Table/components/BodyRow";
import GridLayout from "@components/Layout/GridLayout";
import BodyRowItem from "@components/Table/components/BodyRowItem";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";


const Row = ({item}) => {
    const [searchParams] = useSearchParams()
    const dispatch = useDispatch()

    const [isDisabledButtonConfirmModal, setIsDisabledButtonConfirmModal] = useState(false)
    const [isDisabledButtonDeleteModal, setIsDisabledButtonDeleteModal] = useState(false)
    const [isDisabledButtonCurrentCustomersModal, setIsDisabledButtonCurrentCustomersModal] = useState(false)

    const openModalCurrentCustomers = () => {
        setIsDisabledButtonCurrentCustomersModal(true)
        getCurrentCustomers(item.cityName)
            .then((response) => {
                dispatch({type: SET_CURRENT_CUSTOMERS, payload: response.data})
                dispatch({type: SET_MODAL_CURRENT_CUSTOMERS, payload: true})
                setIsDisabledButtonCurrentCustomersModal(false)
            })
            .catch(() => {
                setIsDisabledButtonCurrentCustomersModal(false)
            })
    }

    const openModalDeleteNewUser = async () => {
        setIsDisabledButtonDeleteModal(true)

        const removeClient = (response) => {
            getRemoveClient(response.data)
                .then(() => (
                    getNotApprovedClients(searchParams)
                ))
        }

        getNotApprovedClient(item.id)
            .then((response) => {
                dispatch({type: SET_NOT_APPROVED_CLIENT_BY_ID, payload: item})
                dispatch({
                    type: SET_MODAL_CONFIRM_ACTION, payload: {
                        isShow: true,
                        title: 'Подтвердите удаление',
                        desc: `Вы уверены, что хотите удалить запрос на регистрацию нового клиента <b>${response.data.fullName}</b> из <b>${response.data.cityName} ?</b>`,
                        accept: () => removeClient(response)
                    }
                })
                setIsDisabledButtonDeleteModal(false)
            })
            .catch(() => {
                getNotApprovedClients(searchParams)
                setIsDisabledButtonDeleteModal(false)
            })
    }

    const openModalConfirmNewUser = async () => {
        if (!isDisabledButtonConfirmModal) {
            setIsDisabledButtonConfirmModal(true)
            await getTaxPayerType()
            await getDiscount()
            await getMargins()
            getNotApprovedClient(item.id)
                .then((response) => {
                    dispatch({type: SET_NOT_APPROVED_CLIENT_BY_ID, payload: response.data})
                    dispatch({type: SET_MODAL_CONFIRM_NEW_CLIENT, payload: true})
                    setIsDisabledButtonConfirmModal(false)
                })
                .catch(() => {
                    getNotApprovedClients(searchParams)
                    setIsDisabledButtonConfirmModal(false)
                })
        }
    }

    return (
        <BodyRow backgroundColor={COLORS.WHITE}>
            <GridLayout align='center' template='20% 20% 18% 15% 15% 100px'>
                <BodyRowItem>
                    <Text value={item.fullName} font={FONTS.MEDIUM_16} color={COLORS.BLACK}/>
                </BodyRowItem>
                <BodyRowItem>
                    <div>
                        <Text
                            value={item.phone ? item.phone : 'Отсутствует'}
                            font={FONTS.REGULAR_16}
                            color={COLORS.BLACK}
                        />
                        <Text
                            value={item.email ? item.email : 'Отсутствует'}
                            font={FONTS.REGULAR_16}
                            color={COLORS.GRAY}
                        />
                    </div>
                </BodyRowItem>
                <BodyRowItem>
                    <FlexLayout align='center'>
                        <Text
                            value={item.cityName ? item.cityName : 'Отсутствует'}
                            font={FONTS.REGULAR_16}
                            color={COLORS.BLACK}
                        />
                        {
                            item.activeClientsCount > 0 &&
                            <OpenModalCurrentCustomers
                                onClick={openModalCurrentCustomers}
                                isDisabled={isDisabledButtonCurrentCustomersModal}
                                item={item}
                            />
                        }
                    </FlexLayout>
                </BodyRowItem>
                <BodyRowItem>
                    <div>
                        <Text
                            value={dayjs(item.createDate).format('DD.MM.YYYY')}
                            font={FONTS.REGULAR_16}
                            color={COLORS.BLACK}
                        />
                        <Text
                            value={dayjs(item.createDate).format('HH:mm')}
                            font={FONTS.REGULAR_16}
                            color={COLORS.GRAY}
                        />
                    </div>
                </BodyRowItem>
                <BodyRowItem>
                    <Text
                        value={item.employeeId ? item.employeeName : 'Нет менеджера'}
                        font={FONTS.REGULAR_16}
                        color={COLORS.BLACK}
                    />
                </BodyRowItem>
                <BodyRowItem>
                    <GridLayout gap='8px' template='auto auto'>
                        <Button
                            variant='isSalad'
                            iconName={`${isDisabledButtonConfirmModal ? 'krista-loading' : 'krista-check'}`}
                            ariaLabel='Подтвердить нового пользователя'
                            isSmall
                            onClick={openModalConfirmNewUser}
                        />
                        <Button
                            variant='isDangerBorder'
                            iconName={`${isDisabledButtonDeleteModal ? 'krista-loading' : 'krista-delete'}`}
                            ariaLabel='Удалить нового пользователя'
                            isSmall
                            onClick={openModalDeleteNewUser}
                        />
                    </GridLayout>
                </BodyRowItem>
            </GridLayout>
        </BodyRow>
    );
};

Row.defaultProps = {
    id: "",
    fullName: "",
    phone: "",
    email: "",
    cityId: 0,
    cityName: "",
    address: "",
    mallName: "",
    activeClientsCount: "",
    createDate: "",
    employeeId: 0,
    employeeName: ""
}
Row.propTypes = {
    id: PropTypes.string,
    fullName: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    cityId: PropTypes.number,
    cityName: PropTypes.string,
    address: PropTypes.string,
    mallName: PropTypes.string,
    activeClientsCount: PropTypes.string,
    createDate: PropTypes.string,
    employeeId: PropTypes.number,
    employeeName: PropTypes.string
}

export default Row;
