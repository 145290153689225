import {Link} from "react-router-dom";
import ModelDevelopment from "@components/Tasks/ModelDevelopment";
import {useEffect} from "react";
import {getDesignTasks, getDesignTasksStatusBoard} from "@actions/designTaskAction";
import ModelDevelopmentDashboard
    from "@components/Dashboards/ModelDevelopmentDashboard";
import {useSelector} from "react-redux";
import Button from "@components/Form/Button";
import Text from "@components/Text/Text";
import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";

const ModelDevelopmentPage = () => {
    const dashboardData = useSelector(state => state.designTasks.designTasksStatusBoardState)

    useEffect(() => {
        getDesignTasks()
        getDesignTasksStatusBoard()
    }, [])

    return (
        <>
            <GridLayout spaceBetween template='auto auto'>
                <Text
                    font={FONTS.SEMI_BOLD_24}
                    value='Разработка новых моделей'
                    margin='0 0 24px 0'
                    color={COLORS.BLACK}
                    as='h1'
                />
                <Button
                    variant='isMain'
                    buttonText='Создать задание'
                    ariaLabel='Создать задание'
                    iconName='krista-plus-circle'
                    to="create-model"
                    as={Link}
                    isSmall
                    isBorder
                />
            </GridLayout>
            {/*//TODO: тут будут фильтры*/}
            {/*<NavFiltersModelDevelopment/>*/}
            <ModelDevelopmentDashboard dashboardData={dashboardData}/>
            <ModelDevelopment dashboardData={dashboardData}/>
        </>
    );
};

export default ModelDevelopmentPage;