import {FONTS} from "@/constans";
import Text from "@components/Text/Text";

const ErrorPage = () => {
    return (
        <div data-testid='error-page'>
            <Text font={FONTS.SEMI_BOLD_24} value='Такой страницы нет =('/>
        </div>
    );
};

export default ErrorPage;
