import Box from "@components/Box";
import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text";
import {NumericFormat} from "react-number-format";
import Line from "@components/Line";

const TableTotals = ({documents, documentsType, documentsTotals, currencySymbol}) => {

    const tableTemplate = '1fr 90px 90px 1fr'
    const tableHead = [documentsType, 'Документов', 'Кол-во ед.', 'На сумму']

    return (
            <Box background={COLORS.LIGHTGRAY3} >
                <GridLayout template={tableTemplate} gap="16px">
                    {tableHead.map(item => (
                        <Text
                            font={FONTS.REGULAR_14}
                            color={COLORS.LIGHTGRAY}
                            value={item}
                            key={item}
                        />
                    ))}
                </GridLayout>
                <Line margin="12px 0"/>
                {documents?.map(item => (
                    <GridLayout template={tableTemplate} gap="16px" key={item.documentName}>
                        <Text
                            font={FONTS.REGULAR_14}
                            color={COLORS.DARKGRAY}
                            value={item.documentName}
                        />
                        <Text
                            font={FONTS.REGULAR_14}
                            color={COLORS.DARKGRAY}
                            value={item.totalDocumentsCount}
                        />
                        <Text
                            font={FONTS.REGULAR_14}
                            color={COLORS.DARKGRAY}
                            value={item.totalQuantity}
                        />
                        <NumericFormat
                            value={item?.totalSum}
                            thousandSeparator=","
                            decimalScale={2}
                            displayType="text"
                            renderText={(value) => (
                                <Text
                                    value={`${value} ${currencySymbol}`}
                                    font={FONTS.REGULAR_14}
                                    color={COLORS.DARKGRAY}
                                />
                            )}
                            fixedDecimalScale
                        />
                    </GridLayout>
                ))}
                <Line margin="12px 0"/>
                <GridLayout template={tableTemplate} gap="16px">
                    <Text
                        font={FONTS.MEDIUM_14}
                        color={COLORS.DARKGRAY}
                        value='Итого'
                    />
                    <Text
                        font={FONTS.MEDIUM_14}
                        color={COLORS.DARKGRAY}
                        value={documentsTotals?.totalDocumentsCount}
                    />
                    <Text
                        font={FONTS.MEDIUM_14}
                        color={COLORS.DARKGRAY}
                        value={documentsTotals?.totalQuantity}
                    />
                    <NumericFormat
                        value={documentsTotals?.totalSum}
                        thousandSeparator=","
                        decimalScale={2}
                        displayType="text"
                        renderText={(value) => {
                            if(!value) {
                                return null
                            }
                            return (
                                <Text
                                    value={`${value} ${currencySymbol}`}
                                    font={FONTS.MEDIUM_14}
                                    color={COLORS.DARKGRAY}
                                />
                            )
                        }}
                        fixedDecimalScale
                    />
                </GridLayout>
            </Box>
    );
};

export default TableTotals;