import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getAuthorization} from "../../store/actions/authorizationAction";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";
import * as yup from "yup";

import Input from "@components/Form/Inputs/Input";
import Button from "@components/Form/Button";
import Icon from "@components/Icon/Icon";
import Text from "@components/Text/Text";
import GridLayout from "@components/Layout/GridLayout";

const LoginPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [isDisabled, setIsDisabled] = useState(false)
    const [error, setError] = useState('')

    const schema = yup.object({
        login: yup.string().required("Обязательное поле"),
        password: yup.string().required('Обязательное поле'),
    });

    const {register, getValues, setValue, handleSubmit, formState: {errors, isValid}} = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            login: 'test_operator',
            password: '112233'
        }
    });

    const authorizationHandle = async (data) => {
        setIsDisabled(true)
        getAuthorization(dispatch, data, setError, setIsDisabled, navigate)
    }

    useEffect(() => {
        if (error.length !== 0) {
            setTimeout(() => {
                setError("")
            }, 3000)
        }
    }, [error]);

    return (
        <Form>
            <Text
                as='legend'
                value='Вход'
                font={FONTS.BOLD_56}
                color={COLORS.DARKGRAY}
                margin='0 auto 32px auto'
                textAlign='center'
            />
            <Text
                font={FONTS.REGULAR_16}
                color={COLORS.GRAY}
                margin='0 auto 48px auto'
                textAlign='center'
                value='Войдите в систему используя ваши данные для авторизации.'
            />
            <Content>
                <GridLayout gap='20px'>
                    <Input
                        label="Логин"
                        name="login"
                        register={register}
                        errors={errors?.login?.message}
                        getValues={getValues}
                        setValue={setValue}
                    />
                    <Input
                        label="Пароль"
                        name="password"
                        type="password"
                        register={register}
                        errors={errors?.password?.message}
                        getValues={getValues}
                        setValue={setValue}
                    />
                    {error &&
                        <Message>
                            <Icon iconName="krista-error"/>
                            <Text
                                value={error}
                                color={COLORS.RED}
                                font={FONTS.REGULAR_12}
                                margin='0 0 0 8px'
                            />
                        </Message>
                    }
                    <Button
                        variant={!isValid || isDisabled ? 'isDisabled' : 'isMain'}
                        buttonText="Войти"
                        ariaLabel="Войти"
                        isDisabled={!isValid || isDisabled}
                        isResponsive
                        onClick={handleSubmit(authorizationHandle)}
                    />
                </GridLayout>
            </Content>
        </Form>
    );
};

const Form = styled.form`
  padding: 68px 0;
  @media (max-width: 640px) {
    padding: 10px 0;
  }
  //[class*="form_controlBox"] {
  //  margin-bottom: 20px;
  //}
  //[class*="form_controlButton"] {
  //  margin-top: 40px;
  //}
  //TODO: delete
`

const Content = styled.div`
  max-width: 480px;
  margin: 0 auto;
  background: ${COLORS.WHITE};
  border-radius: 20px;
  padding: 50px;
  @media (max-width: 640px) {
    padding: 20px;
  }
`

const Message = styled.div`
  padding: 16px 20px;
  background: rgba(252, 90, 90, 0.1);
  color: ${COLORS.RED};
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`

export default LoginPage;
