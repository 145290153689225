import styled from 'styled-components'
import {COLORS} from "@/constans";

const Box = (props) => {
    return (
        <BoxContent {...props} onClick={props.onClick}>
            {props.children}
        </BoxContent>
    )
}

const BoxContent = styled.div`
  background-color: ${({background}) => background ? background : COLORS.WHITE};
  padding: ${({padding}) => padding ? padding : '20px 16px'};
  margin: ${({margin}) => margin ? margin : '0'};
  border-radius: ${({borderRadius}) => borderRadius ? borderRadius : '14px'};
  width: ${({width}) => width && width};
  cursor: ${({cursor}) => cursor && cursor};
  height: ${({height}) => height && height};
  
  ${({border}) => border && `border: 1px solid ${border}`};
  ${({position}) => position && `position: ${position}`};
  ${({otherStyle}) => otherStyle && otherStyle};
`

export default Box;


