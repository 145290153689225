import styled from "styled-components";

const FlexLayout = (props) => {
    const {children, ariaLabel} = props
    const {template, gap, align, margin, padding} = props
    const {alignSelf, spaceBetween, justifyContent} = props
    const {height} = props
    return (
        <Content
            template={template}
            gap={gap}
            align={align}
            spaceBetween={spaceBetween}
            aria-label={ariaLabel}
            margin={margin}
            padding={padding}
            alignSelf={alignSelf}
            justifyContent={justifyContent}
            height={height}
        >
            {children}
        </Content>
    );
};

const Content = styled.div`
  display: flex;
  ${({gap}) => gap && `gap: ${gap}`};
  ${({margin}) => margin && `margin: ${margin}`};
  ${({padding}) => padding && `padding: ${padding}`};
  ${({height}) => height && `height: ${height}`};
  ${({alignSelf}) => alignSelf && `align-self: ${alignSelf}`};
  ${({align}) => align ? `align-items: ${align}` : 'align-items: flex-start'};
  ${({spaceBetween}) => spaceBetween && `justify-content: space-between;`}
  ${({justifyContent}) => justifyContent && `justify-content: ${justifyContent};`}
`

export default FlexLayout