import {Routes, Route} from "react-router-dom";
import {useSelector} from "react-redux";
import HomePage from '@pages/HomePage/HomePage'
import ClientsPage from "@pages/ClientsPage/ClientsPage";
import LoginPage from "@pages/LoginPage/LoginPage";
import ErrorPage from "@pages/ErrorPage/ErrorPage";
import IconsPage from "@pages/IconsPage/IconsPage";
import OrdersPage from "@pages/OrdersPage/OrdersPage"
import OrdersByModelPage from "@pages/OrdersPage/OrdersByModelPage/OrdersByModelPage";
import OrdersLayout from "@pages/OrdersPage/OrdersLayout/OrdersLayout";
import OrderLayout from "@pages/OrderPage/OrderLayout/OrderLayout";
import OrderPage from "@pages/OrderPage/OrderPage";
import OrderChangesPage from "@pages/OrderPage/OrderChangesPage/OrderChangesPage";
import ModelDevelopmentPage from "@pages/ProductionPages/ModelDevelopmentPage";
import OperatorPage from "@pages/ProductionPages/ModelDevelopmentPage/OperatorPage";
import ConstructorPage from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage";
import ChiefPage from "@pages/ProductionPages/ModelDevelopmentPage/ChiefPage";
import TechnologistPage from "@pages/ProductionPages/ModelDevelopmentPage/TechnologistPage";
import ModelsPage from "@pages/ListsPages/Models";
import SalesPage from "@pages/StorePages/SalesPage";
import StoreWarehousePage from "@pages/StorePages/StoreWarehousePage";
import AcceptanceDocumentsPage from "@pages/StorePages/AcceptanceDocumentsPage";
import SalesHistoryPage from "@pages/StorePages/SalesHistoryPage";
import WriteOffDocumentsPage from "@pages/StorePages/WriteOffDocumentsPage";
import AuditDocumentsPage from "@pages/StorePages/AuditDocumentsPage";
import MovementHistory from "@pages/StorePages/MovementHistory";
import MovementDocumentsPage from "@pages/StorePages/MovementDocumentsPage";

const RouterApp = () => {
    const token = useSelector(state => state.user.userState.token)

    return (
        <Routes>
            {token ?
                <>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/client" element={<ClientsPage/>}/>
                    <Route element={<OrdersLayout/>}>
                        <Route path="/orders" element={<OrdersPage/>}/>
                        <Route path="/orders/models" element={<OrdersByModelPage/>}/>
                    </Route>
                    <Route element={<OrderLayout/>}>
                        <Route path="/orders/order" element={<OrderPage/>}/>
                        <Route path="/orders/order/changes" element={<OrderChangesPage/>}/>
                    </Route>
                    <Route path="/task-models/" element={<ModelDevelopmentPage/>}/>
                    <Route path="/task-models/create-model" element={<OperatorPage/>}/>
                    <Route path="/task-models/:id" element={<ConstructorPage/>}/>
                    <Route path="/task-models/chief-model" element={<ChiefPage/>}/>
                    <Route path="/task-models/technologist-model" element={<TechnologistPage/>}/>
                    <Route path="/lists/models" element={<ModelsPage/>}/>
                    <Route path="/store/sales" element={<SalesPage/>}/>
                    <Route path="/store/sales-history" element={<SalesHistoryPage/>}/>
                    <Route path="/store/warehouse" element={<StoreWarehousePage/>}/>
                    <Route path="/store/acceptance-documents"  element={<AcceptanceDocumentsPage/>}/>
                    <Route path="/store/write-off-documents"  element={<WriteOffDocumentsPage/>}/>
                    <Route path="/store/movement-documents"  element={<MovementDocumentsPage/>}/>
                    <Route path="/store/audit-documents"  element={<AuditDocumentsPage/>}/>
                    <Route path="/store/movement-history"  element={<MovementHistory/>}/>

                    <Route path="*" element={<ErrorPage/>}/>
                    <Route path="/icons" element={<IconsPage/>}/>
                </>
                :
                <Route path="*" element={<LoginPage/>}/>
            }
        </Routes>
    );
};

export default RouterApp;