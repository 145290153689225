import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text";

function ModelDevelopmentDashboard({dashboardData}) {
    return (
        <GridLayout template='repeat(9,1fr)' gap='8px' margin='0 0 8px 0' align='stretch'>
            {dashboardData?.map(item => (
                <Status
                    bgColor={item.hex}
                    key={item.statuses + item.title}
                    aria-label={item.title}
                >
                    <Text
                        value={item.title}
                        font={FONTS.SEMI_BOLD_14}
                        color={COLORS.WHITE}
                        margin='0 0 8px 0'
                    />
                    {item.descriptions.map(itemDescription => (
                        <Text
                            value={`• ${itemDescription}`}
                            font={FONTS.REGULAR_14}
                            color={COLORS.WHITE}
                            key={itemDescription}
                            ariaLabel={itemDescription}
                        />
                    ))}
                </Status>
            ))}
        </GridLayout>
    );
}

const Status = styled.div`
  color: ${COLORS.WHITE};
  padding: 8px 8px 10px 8px;
  background: ${({bgColor}) => bgColor ? bgColor : COLORS.PURPLE};
  border-radius: 10px;
`

export default ModelDevelopmentDashboard;