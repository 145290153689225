import ApiConfig from "@/ApiConfigStore";

export const getInvoice = async(invoiceId) => {
    return await ApiConfig.get(`documents/invoice/${invoiceId}`)
};

export const getAcceptedPayment = async(invoiceId) => {
    return await ApiConfig.get(`documents/invoice/${invoiceId}/acceptedPayment`)
};

export const createInvoiceProduct = async (data) => {
    return await ApiConfig.post(`documents/invoice/create/product/add`, data)
};

export const addInvoiceProduct = async (data) => {
    return await ApiConfig.post(`documents/invoice/product/add`, data)
};

export const cancelInvoiceProduct = async (data) => {
    return await ApiConfig.post(`documents/invoice/product/add/cancel`, data)
};

export const paymentInvoice = async (data) => {
    return await ApiConfig.post(`documents/invoice/pay`, data)
};

export const changeEmployee = (invoiceId, employeeId) =>{
    ApiConfig.post(`documents/invoice/${invoiceId}/setSeller/${employeeId}`)
}

export const setClient = async (data) =>{
    return await ApiConfig.post(`documents/invoice/setClient`, data)
}

export const changeDiscount = async (data) => {
    return await ApiConfig.post(`documents/invoice/setDiscount`, data)
}

export const changeDiscountByDiscountCard = async (data) => {
    return await ApiConfig.post(`documents/invoice/setDiscountByDiscountCard`, data)
}

export const postAcceptedPayment = async (data) =>{
    return await ApiConfig.post(`documents/invoice/acceptedPayment/set`, data)
}

export const deleteInvoiceProduct = async (invoiceId, productId) => {
    return await ApiConfig.delete(`documents/invoice/${invoiceId}/product/${productId}/delete`).then(()=> {
        getInvoice(invoiceId)
    })
};

export const deleteInvoice = async (invoiceId) => {
    await ApiConfig.delete(`documents/invoice/${invoiceId}/delete`)
};