import {SET_CURRENT_CUSTOMERS} from "@types/types";

const initState = {
    currentClientsState: null,
}

const currentCustomersReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_CURRENT_CUSTOMERS :
            return {...state, currentClientsState: action.payload}
        default:
            return state
    }
}

export default currentCustomersReducer