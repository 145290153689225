import OrdersHeader from "@components/Orders/OrdersHeader/OrdersHeader";
import OrdersDashboard from "@components/Dashboards/OrdersDashboard/OrdersDashboard";
import NavFiltersOrders from "@components/NavFilters/NavFiltersOrders/NavFiltersOrders";
import {Outlet} from "react-router-dom";

function OrdersLayout() {
    return (
        <>
            <OrdersHeader/>
            <OrdersDashboard/>
            <NavFiltersOrders/>
            <Outlet />
        </>
    );
}

export default OrdersLayout;