import Icon from "@components/Icon/Icon";
import ClientInfoColumn
    from "@components/Table/TableDefault/TableClient/RowDropdown/components/ClientInfoColumn/ClientInfoColumn";
import FlexLayout from "@components/Layout/FlexLayout";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";
import Line from "@components/Line";
import styled from "styled-components";
import GridLayout from "@components/Layout/GridLayout";

function ClientProfileStore({clientInfo}) {
    return (
        <ClientInfoColumn>
            <FlexLayout align='center' padding='10px 16px'>
                <Icon
                    iconName='krista-shop'
                    iconWidth={24}
                    iconHeight={24}
                    iconColor={COLORS.LIGHTGRAY}
                />
                <Text
                    value='Информация по магазинам'
                    font={FONTS.MEDIUM_14}
                    color={COLORS.DARKGRAY}
                    margin='0 0 0 8px'
                />
            </FlexLayout>
            <Line/>
            <Card>
                {clientInfo.addresses.map((item, index) => (
                    <CardWrapper key={item.id}>
                        <Text
                            value={`Магазин ${index + 1}`}
                            font={FONTS.MEDIUM_14}
                            color={COLORS.LIGHTGRAY}
                            margin='0 0 16px 0'
                        />
                        <GridLayout gap='8px'>
                            <FlexLayout align='center' spaceBetween>
                                <Text
                                    value='Город'
                                    font={FONTS.REGULAR_14}
                                    color={COLORS.DARKGRAY}
                                />
                                <Text
                                    value={item.city.name ? item.city.name : '-'}
                                    font={FONTS.MEDIUM_14}
                                    color={COLORS.DARKGRAY}
                                    margin='0 0 0 20px'
                                    textAlign='right'
                                />
                            </FlexLayout>
                            <FlexLayout align='center' spaceBetween>
                                <Text
                                    value='Адрес'
                                    font={FONTS.REGULAR_14}
                                    color={COLORS.DARKGRAY}
                                />
                                <Text
                                    value={item.text ? item.text : '-'}
                                    font={FONTS.MEDIUM_14}
                                    color={COLORS.DARKGRAY}
                                    margin='0 0 0 20px'
                                    textAlign='right'
                                />
                            </FlexLayout>
                            <FlexLayout align='center' spaceBetween>
                                <Text
                                    value='Торговый центр'
                                    font={FONTS.REGULAR_14}
                                    color={COLORS.DARKGRAY}
                                />
                                <Text
                                    value={item.mallName ? item.mallName : '-'}
                                    font={FONTS.MEDIUM_14}
                                    color={COLORS.DARKGRAY}
                                    margin='0 0 0 20px'
                                    textAlign='right'
                                />
                            </FlexLayout>
                            <FlexLayout align='center' spaceBetween>
                                <Text
                                    value='Название магазина'
                                    font={FONTS.REGULAR_14}
                                    color={COLORS.DARKGRAY}
                                />
                                <Text
                                    value={item.shopName ? item.shopName : '-'}
                                    font={FONTS.MEDIUM_14}
                                    color={COLORS.DARKGRAY}
                                    margin='0 0 0 20px'
                                    textAlign='right'
                                />
                            </FlexLayout>
                        </GridLayout>
                    </CardWrapper>
                ))}
            </Card>
        </ClientInfoColumn>
    );
}

const Card = styled.div`
  padding: 16px;
  overflow: auto;
  height: 418px;
`

const CardWrapper = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.LIGHTGRAY1};
    margin-bottom: 16px;
    padding-bottom: 16px;
  }
`

export default ClientProfileStore;