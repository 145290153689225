import IconCard from "@pages/IconsPage/IconCard/IconCard";
import GridLayout from "@components/Layout/GridLayout";

function IconsPage() {
    const iconsArray = [
        "krista-add-user",
        "krista-remove-user",
        "krista-user",
        "krista-edit-user",
        "krista-diamond",
        "krista-message",
        "krista-plus",
        "krista-plus-v2",
        "krista-plus-circle",
        "krista-cart",
        "krista-file",
        "krista-home",
        "krista-wallet",
        "krista-warehouse",
        "krista-warehouse-add",
        "krista-warehouse-delete",
        "krista-cart-add",
        "krista-money",
        "krista-dollar",
        "krista-arrow-top",
        "krista-arrow-top-v2",
        "krista-arrow-bottom",
        "krista-arrow-left-right",
        "krista-children",
        "krista-arrows",
        "krista-delete",
        "krista-check",
        "krista-scissors",
        "krista-error",
        "krista-success",
        "krista-close",
        "krista-close-v2",
        "krista-map-marker",
        "krista-like",
        "krista-square-check",
        "krista-square-uncheck",
        "krista-around-uncheck",
        "krista-around-check",
        "krista-pen",
        "krista-call",
        "krista-lock",
        "krista-mail",
        "krista-shop",
        "krista-at",
        "krista-nav",
        "krista-time",
        "krista-world",
        "krista-chevron-bottom",
        "krista-chevron-top",
        "krista-chevron-double-bottom",
        "krista-chevron-double-top",
        "krista-time-cycle",
        "krista-cycle",
        "krista-cycle-v2",
        "krista-external-link",
        "krista-search",
        "krista-cart-v2",
        "krista-crown",
        "krista-box",
        "krista-debt",
        "krista-inactive-user",
        "krista-delete-user",
        "krista-minus",
        "krista-people",
        "krista-document",
        "krista-download-file",
        "krista-share",
        "krista-comment",
        "krista-calendar",
        "krista-copy",
        "krista-more",
        "krista-star",
        "krista-cancel",
        "krista-info",
        "krista-drop-indicator",
        "krista-empty-photo",
        "krista-photo",
        "krista-upload",
        "krista-file-v2",
        "krista-close-circle",
        "krista-confirm",
        "krista-loading",
        "krista-camera",
        "krista-manager",
        "krista-client",
        "krista-percent",
    ]
    return (
        <GridLayout template='repeat(8,1fr)' gap='20px' align='stretch'>
            {
                iconsArray.map(item=> (
                    <IconCard item={item}></IconCard>
                ))
            }
        </GridLayout>
    );
}

export default IconsPage;