import Checkbox from "@components/Form/Checkbox";
import {useForm} from "react-hook-form";

function ClientCatalog({item}) {
    const {register} = useForm({
        mode: 'onChange'
    })

    return (
        <Checkbox
            title={item.name}
            value={item.id}
            register={register}
            name={item.name}
        />
    );
}

export default ClientCatalog;