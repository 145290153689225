import * as yup from "yup";

export const schema = yup.object().shape({
    productBarcode: yup.string()
        .test('value must be 16 numbers', 'Штрих код должен быть 16 символов', (value) => {
            return !value || value.length === 16;
        }),
    discountCardCode: yup.string().nullable()
        .test('value must be 16 numbers', 'Скидочная карта должна быть 16 символов', (value) => {
            return !value || value.length === 16;
        }),
    employee: yup.object().required('Обязательное поле').nullable().shape({
        employeeId: yup.number(),
        employeeName: yup.string(),
    }),
    discountPercent: yup.string().nullable()
        .test('value must be less than 100', 'Скидка должна быть меньше 100', (value) => {
            return !value || value < 100;
        }),
})