const FONT_FAMILY = 'Roboto, sans-serif';


export const COLORS = {
    WHITE: '#FFFFFF',
    BLACK: '#171725',
    GRAY: '#696974',
    DARKGRAY: '#44444F',
    LIGHTGRAY: '#8A8A99',
    LIGHTGRAY1: '#E2E2EA',
    LIGHTGRAY2: '#F1F1F5',
    LIGHTGRAY3: '#FAFAFB',
    MAIN: '#0073FF',
    MAIN_BLUE: '#50B5FF',
    DARKBLUE: '#005CCC',
    SALAD: '#3DD598',
    DARK_SALAD: '#31AA7A',
    RED: '#FC5A5A',
    DARKRED: '#CA4848',
    YELLOW: '#FAB314',
    STATUS_GREEN: '#82C43C',
    DARK_STATUS_GREEN: '#70a834',
    PURPLE: '#8F73E5',
}

export const FONTS = {
    BOLD_56: `700 56px/66px ${FONT_FAMILY}`,
    BOLD_16: `700 16px/19px ${FONT_FAMILY}`,

    SEMI_BOLD_28: `600 28px/33px ${FONT_FAMILY}`,
    SEMI_BOLD_24: `600 24px/28px ${FONT_FAMILY}`,
    SEMI_BOLD_20: `600 20px/23px ${FONT_FAMILY}`,
    SEMI_BOLD_16: `600 16px/20px ${FONT_FAMILY}`,
    SEMI_BOLD_14: `600 14px/16px ${FONT_FAMILY}`,
    SEMI_BOLD_12: `600 12px/14px ${FONT_FAMILY}`,

    MEDIUM_24: `500 24px/28px ${FONT_FAMILY}`,
    MEDIUM_20: `500 20px/23px ${FONT_FAMILY}`,
    MEDIUM_18: `500 18px/21px ${FONT_FAMILY}`,
    MEDIUM_16: `500 16px/19px ${FONT_FAMILY}`,
    MEDIUM_14: `500 14px/16px ${FONT_FAMILY}`,
    MEDIUM_12: `500 12px/14px ${FONT_FAMILY}`,
    MEDIUM_11: `500 11px/13px ${FONT_FAMILY}`,

    REGULAR_36: `400 36px/42px ${FONT_FAMILY}`,
    REGULAR_24: `400 24px/28px ${FONT_FAMILY}`,
    REGULAR_20: `400 20px/23px ${FONT_FAMILY}`,
    REGULAR_18: `400 18px/21px ${FONT_FAMILY}`,
    REGULAR_16: `400 16px/19px ${FONT_FAMILY}`,
    REGULAR_14: `400 14px/16px ${FONT_FAMILY}`,
    REGULAR_12: `400 12px/14px ${FONT_FAMILY}`,
    REGULAR_11: `400 11px/13px ${FONT_FAMILY}`,
    REGULAR_10: `400 10px/12px ${FONT_FAMILY}`,
}
