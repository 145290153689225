import FooterRow from "@components/Table/components/FooterRow/FooterRow";
import GridLayout from "@components/Layout/GridLayout";
import FooterRowItem from "@components/Table/components/FooterRowItem";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";
import React from "react";
import FlexLayout from "@components/Layout/FlexLayout";
import {NumericFormat} from "react-number-format";

function Footer({template, totals}) {
    return (
        <FooterRow padding='0' border='0' backgroundColor={COLORS.WHITE}>
            <GridLayout template={template} align='center'>
                <FooterRowItem padding="0 8px 0 16px">
                    <FlexLayout gap='4px' align='center'>
                        <Text value='Всего товаров:' font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY}/>
                        <Text value={totals?.totalProducts} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                    </FlexLayout>
                </FooterRowItem>
                <FooterRowItem/>
                <FooterRowItem/>
                <FooterRowItem/>
                <FooterRowItem padding="24px 8px" bg="#EEF8FF">
                    <Text value={totals?.expectedTotalQuantity} font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                </FooterRowItem>
                <FooterRowItem padding="24px 8px" bg="#EEF8FF">
                    <NumericFormat
                        value={totals?.expectedTotalSum}
                        thousandSeparator=" "
                        decimalScale={2}
                        displayType="text"
                        renderText={(value) => (
                            <Text value={value} font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                        )}
                        fixedDecimalScale
                        suffix={`${totals?.curencySymbol}`}
                    />
                </FooterRowItem>
                <FooterRowItem padding="24px 8px" bg="#FFF8E8">
                    <Text value={totals?.actualTotalQuantity} font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                </FooterRowItem>
                <FooterRowItem padding="24px 8px" bg="#FFF8E8">
                    <NumericFormat
                        value={totals?.actualTotalSum}
                        thousandSeparator=" "
                        decimalScale={2}
                        displayType="text"
                        renderText={(value) => (
                            <Text value={value} font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                        )}
                        fixedDecimalScale
                        suffix={totals?.curencySymbol}
                    />
                </FooterRowItem>
                <FooterRowItem padding="24px 8px" bg="#FFEFEF">
                    <Text value={totals?.totalQuantityDiff} font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                </FooterRowItem>
                <FooterRowItem padding="24px 8px" bg="#FFEFEF">
                    <NumericFormat
                        value={totals?.totalSumDiff}
                        thousandSeparator=" "
                        decimalScale={2}
                        displayType="text"
                        renderText={(value) => (
                            <Text value={`${value} ${totals?.curencySymbol}`} font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                        )}
                        fixedDecimalScale
                    />
                </FooterRowItem>

            </GridLayout>
        </FooterRow>
    );
}

export default Footer;