import Label from "@components/Form/Label/Label";
import Tooltip from "rc-tooltip";
import Icon from "@components/Icon/Icon";
import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";
import ControlBox from "@components/Form/Inputs/components/ControlBox";
import Text from "@components/Text";
import ControlError from "@components/Form/Inputs/components/ControlError";

function InputLayout({children, errors, label, icon, iconPosition, symbol, ariaLabel, isSmall, isBorder = true}) {
    return (
        <ControlBox
            ariaLabel={label ? `Поле ${label}` : `Поле ${ariaLabel}`}
            isInvalid={errors}
            isSmall={isSmall}
            isBorder={isBorder}
            iconPosition={iconPosition}
            icon={icon}
        >
            {label && <Label label={label}/>}
            <ControlInner>
                {icon &&
                    //TODO: тултип применяется не везде, что с ним сделать?
                    <Tooltip placement="bottom" overlay={
                        <div>
                            <div>Последние артикулы</div>
                            <div>310-11</div>
                            <div>310-10</div>
                            <div>310-12</div>
                        </div>
                    }>
                        <ControlIcon iconPosition={iconPosition}>
                            <Icon
                                iconName={icon}
                                iconWidth={24}
                                iconHeight={24}
                            />
                        </ControlIcon>
                    </Tooltip>
                }
                {symbol &&
                    <Symbol>
                        <Text value={symbol} font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
                    </Symbol>
                }
                {children}
            </ControlInner>
            {errors &&
                <ControlError>
                    <Text
                        ariaLabel={label ? `Ошибка в поле ${label}` : `Ошибка в поле ${ariaLabel}`}
                        value={errors} font={FONTS.REGULAR_12}
                        color={COLORS.RED}
                    />
                </ControlError>
            }
        </ControlBox>
    );
}

const ControlIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: ${COLORS.LIGHTGRAY};
  ${({iconPosition}) => {
    if (iconPosition === 'right') {
      return 'right: 8px'
    } else {
      return 'left: 8px'
    }
  }}
`

const ControlInner = styled.div`
  position: relative;
  display: block;
`

const Symbol = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  pointer-events: none;
`


export default InputLayout;