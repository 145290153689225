import CardOrderInfo from "@components/Cards/CardOrder/CardOrderInfo/CardOrderInfo";
import CardOrderProfileInfo from "@components/Cards/CardOrder/CardOrderProfileInfo/CardOrderProfileInfo";
import CardOrderComment from "@components/Cards/CardOrder/CardOrderComment/CardOrderComment";
import GridLayout from "@components/Layout/GridLayout";

const OrderDashboard = () => {
    return (
        <GridLayout template='5fr 4fr 3fr' gap='20px' margin='0 0 24px 0'>
            <CardOrderInfo/>
            <CardOrderProfileInfo/>
            <CardOrderComment/>
        </GridLayout>
    );
};

export default OrderDashboard;
