import ApiConfig from "@/ApiConfig";
import {SET_ALL_MANAGER, SET_ALL_CONSTRUCTOR} from "@types/types";
import store from '@store'

export const getAllManagersShortData = async () => {
    const response = await ApiConfig.get(`Employees/Employee/Manager/Lookup`)
    store.dispatch({type: SET_ALL_MANAGER, payload: response.data})
};
export const getAllConstructor = async () => {
    const response = await ApiConfig.get(`Employees/Employee/Constructor/Lookup`)
    store.dispatch({type: SET_ALL_CONSTRUCTOR, payload: response.data})
};