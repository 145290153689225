import GridLayout from "@components/Layout/GridLayout";
import SpecificationItem
    from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/Specification/SpecificationItem";
import SpecificationItemTotal
    from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/Specification/SpecificationItemTotal";
import Box from "@components/Box";
import {useFieldArray, useForm} from "react-hook-form";
import Button from "@components/Form/Button";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import Text from "@components/Text/Text";
import {FONTS} from "@/constans";

function Specification() {
    const schema = () => {
        return yup.object().shape({
            specification: yup.array().of(
                yup.object().shape({
                    furniture: yup.string().required('Ошибка ввода'),
                    patterns: yup.number().required('Ошибка ввода').min(1, 'Минимум 1 шт.').max(999).typeError('Должно быть числом'),
                    details: yup.number().required('Ошибка ввода').min(1, 'Минимум 1 шт.').max(999).typeError('Должно быть числом'),
                })
            ),
            specificationTotal: yup.object().shape({
                patternsTotal: 0,
                detailsTotal: 0,
            })
        })
    }

    const {control, register, watch, setValue, handleSubmit, formState: {errors}} = useForm({
        resolver: yupResolver(schema()),
        defaultValues: {
            specification: [{
                furniture: "",
                patterns: 0,
                details: 0
            }],
            specificationTotal: {
                patternsTotal: 0,
                detailsTotal: 0,
            }
        }
    })

    const {fields, append, remove} = useFieldArray({control, name: 'specification'})


    const submitForm = (data) => {
        console.log(data, 'data')
    }

    return (
        <form>
            <Box>
                <Text value='Спецификация' as='h3' font={FONTS.SEMI_BOLD_16} margin='0 0 20px 0'/>
                <GridLayout gap='16px'>
                    {fields.map((item, index) => (
                        <SpecificationItem
                            key={item.id}
                            labels={index === 0 && {
                                selectFurnitureLabel: 'Фурнитура',
                                inputPatternsLabel: 'Лекала',
                                inputDetailsLabel: 'Деталей',
                                inputMaterialLabel: 'Материал',
                            }}
                            remove={remove}
                            index={index}
                            fields={fields}
                            register={register}
                            watch={watch}
                            setValue={setValue}
                            control={control}
                            errors={errors}
                        />
                    ))}
                    {fields.length > 0 &&
                        <SpecificationItemTotal watch={watch} register={register} setValue={setValue}/>}
                    <GridLayout template='auto 166px' spaceBetween>
                        <Button
                            buttonText='Добавить деталь'
                            ariaLabel='Добавить деталь'
                            iconName='krista-plus'
                            isSmall
                            onClick={() => {
                                append({
                                    furniture: '',
                                    patterns: '0',
                                    details: '0',
                                    material: null
                                })
                            }}
                        />
                        <Button
                            variant='isMain'
                            buttonText='Сохранить'
                            ariaLabel='Сохранить'
                            isSmall
                            onClick={handleSubmit(submitForm)}
                        />
                    </GridLayout>
                </GridLayout>
            </Box>
        </form>
    );
}

export default Specification;