import {SET_ALL_CATALOG} from "@types/types";

const initState = {
    catalogsState: [],
}

const catalogReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_ALL_CATALOG :
            return {...state, catalogsState: action.payload}
        default:
            return state
    }
}
export default catalogReducer