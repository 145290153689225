import Icon from "@components/Icon/Icon";
import {NumericFormat} from "react-number-format";
import Button from "@components/Form/Button";
import React from "react";
import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";
import GridLayout from "@components/Layout/GridLayout";
import FlexLayout from "@components/Layout/FlexLayout";
import Wrapper from "@components/Table/TableDefault/TableClient/RowDropdown/components/Wrapper/Wrapper";
import ClientHeader from "@components/Table/TableDefault/TableClient/RowDropdown/components/ClientHeader/ClientHeader";

function ClientBalance({clientInfo}) {
    return (
        <Wrapper>
            <ClientHeader backgroundColor={COLORS.SALAD}>
                <FlexLayout align='center' spaceBetween>
                    <FlexLayout align='center'>
                        <Icon
                            iconName='krista-wallet'
                            iconWidth={24}
                            iconHeight={24}
                        />
                        <Text
                            value='Баланс'
                            font={FONTS.BOLD_16}
                            color='inherit'
                            margin='0 0 0 4px'
                        />
                    </FlexLayout>
                    <Text
                        value={
                            <NumericFormat
                                value={clientInfo?.balance}
                                displayType="text"
                                suffix=" $"
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        }
                        font={FONTS.REGULAR_16}
                        color='inherit'
                    />
                </FlexLayout>
            </ClientHeader>
            <GridLayout gap='16px' padding='0 16px' margin='16px 0 0 0'>
                <GridLayout gap='4px'>
                    <Text value='Тип цен' font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
                    <FlexLayout align='center' spaceBetween>
                        <Text value='Стоимость' font={FONTS.REGULAR_14} color={COLORS.BLACK}/>
                        <Text value={`${clientInfo?.margin} $`} font={FONTS.MEDIUM_14} color={COLORS.BLACK}/>
                    </FlexLayout>
                    <FlexLayout align='center' spaceBetween>
                        <Text value='Скидка' font={FONTS.REGULAR_14} color={COLORS.BLACK}/>
                        <Text value={`${clientInfo?.discount} %`} font={FONTS.MEDIUM_14} color={COLORS.BLACK}/>
                    </FlexLayout>
                </GridLayout>
                <GridLayout gap='4px'>
                    <Text value='Информация по клиенту' font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
                    <FlexLayout align='center' spaceBetween>
                        <Text value='Оформлено заказов' font={FONTS.REGULAR_14} color={COLORS.BLACK}/>
                        <Text value={clientInfo?.orderTotal.totalCount} font={FONTS.MEDIUM_14} color={COLORS.BLACK}/>
                    </FlexLayout>
                    <FlexLayout align='center' spaceBetween>
                        <Text value='Заказанно единиц' font={FONTS.REGULAR_14} color={COLORS.BLACK}/>
                        <Text value={clientInfo?.orderTotal.totalItemsCount} font={FONTS.MEDIUM_14} color={COLORS.BLACK}/>
                    </FlexLayout>
                    <FlexLayout align='center' spaceBetween>
                        <Text value='Сумма заказов' font={FONTS.REGULAR_14} color={COLORS.BLACK}/>
                        <Text
                            value={
                                <NumericFormat
                                    value={clientInfo?.orderTotal.totalSum}
                                    displayType="text"
                                    suffix=" $"
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                />
                            }
                            font={FONTS.MEDIUM_14}
                            color={COLORS.BLACK}
                        />
                    </FlexLayout>
                </GridLayout>
                <GridLayout gap='4px'>
                    <Text value='Текущие заказы' font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
                    <FlexLayout align='center' spaceBetween>
                        <Text value='Сумма за заявку' font={FONTS.REGULAR_14} color={COLORS.BLACK}/>
                        <Text value={'$'} font={FONTS.MEDIUM_14} color={COLORS.BLACK}/>
                    </FlexLayout>
                    <FlexLayout align='center' spaceBetween>
                        <Text value='В производстве' font={FONTS.REGULAR_14} color={COLORS.BLACK}/>
                        <Text value={'$'} font={FONTS.MEDIUM_14} color={COLORS.BLACK}/>
                    </FlexLayout>
                    <FlexLayout align='center' spaceBetween>
                        <Text value='Сумма предоплаты' font={FONTS.REGULAR_14} color={COLORS.BLACK}/>
                        <Text value={'$'} font={FONTS.MEDIUM_14} color={COLORS.BLACK}/>
                    </FlexLayout>
                    <FlexLayout align='center' spaceBetween>
                        <Text value='В резервах' font={FONTS.REGULAR_14} color={COLORS.BLACK}/>
                        <Text value={'$'} font={FONTS.MEDIUM_14} color={COLORS.BLACK}/>
                    </FlexLayout>
                    <FlexLayout align='center' spaceBetween>
                        <Text value='К оплате' font={FONTS.REGULAR_14} color={COLORS.BLACK}/>
                        <Text value={'$'} font={FONTS.MEDIUM_14} color={COLORS.BLACK}/>
                    </FlexLayout>
                </GridLayout>
            </GridLayout>
            <GridLayout gap='6px' margin='34px 16px 0'>
                <Button
                    variant='isMainBorder'
                    buttonText='Корзины'
                    ariaLabel='Корзины'
                    isSmall
                />
                <Button
                    variant='isMainBorder'
                    buttonText='Заказы'
                    ariaLabel='Заказы'
                    isSmall
                />
                <Button
                    variant='isMainBorder'
                    buttonText='Счета'
                    ariaLabel='Счета'
                    isSmall
                />
                <Button
                    variant='isMainBorder'
                    buttonText='Резервы'
                    ariaLabel='Резервы'
                    isSmall
                />
                <Button
                    variant='isMainBorder'
                    buttonText='Отправки'
                    ariaLabel='Отправки'
                    isSmall
                />
            </GridLayout>
        </Wrapper>
    );
}

export default ClientBalance;