import Table from "@components/Table/components/Wrapper";
import GridLayout from "@components/Layout/GridLayout";
import HeadRow from "@components/Table/components/HeadRow";
import HeadRowItem from "@components/Table/components/HeadRowItem";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import HeadRowSort from "@components/Table/components/HeadRowSort";
import Row from "@components/Table/TableDefault/TableStoreWarehouse/Row";
import {useState} from "react";
import Button from "@components/Form/Button";
import {useSelector} from "react-redux";

function TableStoreWarehouse() {
    const articles = useSelector(state => state.warehouse.warehouseProductsState?.items)

    const template = '2fr 2fr 1fr 1fr 1fr 2fr 56px'

    const [isOpenArticles, setIsOpenArticles] = useState(Array(articles?.length).fill(false));
    const [isOpenAll, setIsOpenAll] = useState(false)

    const toggleAll = () => {
        setIsOpenAll(!isOpenAll)
        setIsOpenArticles(Array(articles?.length).fill(isOpenAll));
    };

    const toggleArticle = (index) => {
        const newArticles = [...isOpenArticles];
        newArticles[index] = !newArticles[index];
        setIsOpenArticles(newArticles);
    };

    return (
        <Table>
            <GridLayout gap='10px'>
                <HeadRow>
                    <GridLayout template={template} align='stretch'>
                        <HeadRowItem>
                            <Text value='Артикул' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Цвет' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowSort title='Цена' sortValue='price'/>
                        <HeadRowSort title='Скидка' sortValue='linesQuantity'/>
                        <HeadRowSort title='Единиц' sortValue='quantity'/>
                        <HeadRowSort title='На сумму' sortValue='totalSum'/>
                        <HeadRowItem>
                            <Button
                                variant='isGray'
                                isSmall
                                iconName={isOpenAll ? 'krista-chevron-double-bottom' : 'krista-chevron-double-top'}
                                onClick={toggleAll}
                            />
                        </HeadRowItem>
                    </GridLayout>
                </HeadRow>
                {articles?.map((article, index) => (
                    <Row
                        key={article.articulId}
                        article={article}
                        template={template}
                        isOpen={isOpenArticles[index]}
                        toggleArticle={() => toggleArticle(index)}
                    />
                ))}
            </GridLayout>
        </Table>
    );
}

export default TableStoreWarehouse;