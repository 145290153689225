import {COLORS} from "@/constans";
import styled, {css} from "styled-components";

function BodyRow(props) {
    const {children, onClick, isActive, ariaLabel} = props
    const {backgroundColor, backgroundColorHover, border, borderTop, borderRadius, padding} = props
    return (
        <BodyRowContent
            isActive={isActive}
            onClick={onClick}
            backgroundColor={backgroundColor}
            backgroundColorHover={backgroundColorHover}
            border={border}
            borderTop={borderTop}
            borderRadius={borderRadius}
            padding={padding}
            aria-label={ariaLabel}
        >
            {children}
        </BodyRowContent>
    );
}

const BodyRowContent = styled.div`
  align-items: center;
  overflow: hidden;
  background-color: ${COLORS.WHITE};
  padding: ${({padding}) => padding ? padding : '16px 8px'};
  ${({margin}) => margin && `margin: ${margin}`}
  cursor: ${({onClick}) => onClick ? 'pointer' : 'default'};
  background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
  ${({border}) => border && `border: ${border};`};
  ${({borderTop}) => borderTop && css`
    border-top: 1px solid ${COLORS.LIGHTGRAY1};
  `};
  border-radius: ${({borderRadius}) => borderRadius ? borderRadius : '10px'};

  ${({isActive}) => isActive && 'border-radius: 10px 10px 0 0;'}
  &:hover, &:focus, &:active {
    background: ${({backgroundColorHover}) => backgroundColorHover ? backgroundColorHover : `rgba(196, 219, 255, 0.15);`}
  }
    
  &:global {
    &.sortable-chosen {
      background: rgba(196, 219, 255, 0.15);
    }
  }
`

export default BodyRow;