import {COLORS} from "@/constans";
import {useSelector} from "react-redux";
import ClientCatalog from "@components/Table/TableDefault/TableClient/RowDropdown/ClientCatalogs/ClientCatalog/ClientCatalog";
import Wrapper from "@components/Table/TableDefault/TableClient/RowDropdown/components/Wrapper/Wrapper";
import ClientHeader from "@components/Table/TableDefault/TableClient/RowDropdown/components/ClientHeader/ClientHeader";
import Text from "@components/Text";
import GridLayout from "@components/Layout/GridLayout";

function ClientCatalogs() {
    const catalogsState = useSelector(state => state.catalogs.catalogsState)

    return (
        <Wrapper>
            <GridLayout gap='16px'>
                <ClientHeader backgroundColor={COLORS.PURPLE}>
                    <Text value='Доступные каталоги' color='inherit' font='inherit'/>
                </ClientHeader>
                {catalogsState.length > 0 &&
                    <GridLayout gap='8px'>
                        {catalogsState.map(item => <ClientCatalog item={item} key={item.id}/>)}
                    </GridLayout>
                }
            </GridLayout>
        </Wrapper>
    );
}

export default ClientCatalogs;