import React, {useEffect, useState} from 'react';
import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";
import Button from "@components/Form/Button";
import {
    getAcceptance,
    getAcceptanceCreate,
    getAcceptanceDelete, getAcceptanceFinish, getAcceptanceProductDelete,
    getAcceptanceProductScan, getAcceptanceProductScanCancel
} from "@actions/acceptanceDocumentsAction";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import TableAcceptance from "@components/Table/TableDefault/TableAcceptance";
import {toast} from "react-toastify";
import ToastContent from "@components/ToastContent";
import EmptyBox from "@components/EmptyBox/EmptyBox";
import {SET_MODAL_CONFIRM_ACTION} from "@types/types";
import {useDispatch} from "react-redux";
import LastScan from "@pages/StorePages/components/LastScan";
import ProductBarcode from "@pages/StorePages/components/ProductBarcode";

const AcceptanceDocumentsPage = () => {
    const dispatch = useDispatch()

    const [acceptance, setAcceptance] = useState(null)
    const [isAddProduct, setIsAddProduct] = useState(true)
    const [lastProduct, setLastProduct] = useState(null)

    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate();

    const {register, handleSubmit, formState: {errors}, watch} = useForm({
        resolver: yupResolver(yup.object().shape({
            productBarcode: yup.string().required('Обязательное поле')
        })),
        defaultValues: {productBarcode: ""}
    })

    const acceptanceDelete = () => {
        dispatch({
            type: SET_MODAL_CONFIRM_ACTION, payload: {
                isShow: true,
                title: 'Вы уверены, что хотите удалить все ?',
                desc: '',
                accept: () => {
                    getAcceptanceDelete(searchParams.get('acceptanceDocumentId'))
                        .then(() => [
                            navigate('/store/warehouse?page=1&pageSize=25'),
                            toast.success(<ToastContent title="Документ принятие на склад, удален" ok/>),
                            dispatch({
                                type: SET_MODAL_CONFIRM_ACTION, payload: {
                                    isShow: false,
                                    title: '',
                                    desc: '',
                                    accept: null,
                                    icon: ''
                                }
                            })
                        ])
                },
                icon: 'krista-delete'
            }
        })

    }

    const acceptanceFinish = () => {
        getAcceptanceFinish(searchParams.get('acceptanceDocumentId'))
            .then(() => [
                navigate('/store/warehouse?page=1&pageSize=25'),
                toast.success(<ToastContent title="Документ принятие на склад, добавлен" ok/>)
            ])
    }

    const submit = (data) => {
        if (isAddProduct) {
            getAcceptanceProductScan(
                {
                    ...data,
                    acceptanceDocumentId: searchParams.get('acceptanceDocumentId')
                }
            ).then(({data}) => [
                setLastProduct(data),
                getAcceptance(searchParams.get('acceptanceDocumentId'))
                    .then(({data}) => setAcceptance(data))
            ])
        } else {
            getAcceptanceProductScanCancel(
                {
                    ...data,
                    acceptanceDocumentId: searchParams.get('acceptanceDocumentId')
                }
            ).then(({data}) => [
                setLastProduct(data),
                getAcceptance(searchParams.get('acceptanceDocumentId'))
                    .then(({data}) => setAcceptance(data))
            ])
        }

    }

    useEffect(() => {
        if (watch('productBarcode').length === 16) {
            handleSubmit(submit)()
        }
    }, [watch('productBarcode')]);

    useEffect(() => {
        if (!searchParams.get('acceptanceDocumentId')) {
            getAcceptanceCreate().then(({data}) => setSearchParams(data))
        } else {
            getAcceptance(searchParams.get('acceptanceDocumentId')).then(({data}) => setAcceptance(data))
        }
    }, [searchParams])

    return (
        <GridLayout gap='24px'>
            <FlexLayout gap='4px'>
                <Text value='Магазин' font={FONTS.REGULAR_24} color={COLORS.BLACK}/>
                <Text value='/' font={FONTS.REGULAR_24} color={COLORS.LIGHTGRAY}/>
                <Text value='Склад наличия' font={FONTS.REGULAR_24} color={COLORS.BLACK}/>
                <Text value='/' font={FONTS.REGULAR_24} color={COLORS.LIGHTGRAY}/>
                {acceptance &&
                    <Text
                        value={`${acceptance?.documentTypeName} №${acceptance?.serialNumber}`}
                        font={FONTS.MEDIUM_24}
                        color={COLORS.BLACK}
                    />
                }
            </FlexLayout>
            {acceptance?.isCompleted && (
                <FlexLayout gap='8px' align='center'>
                    <Text
                        value={`Номер документа: ${acceptance.serialNumber}`}
                        font={FONTS.MEDIUM_18}
                    />
                    <Text
                        value={acceptance.statusName}
                        color={COLORS.STATUS_GREEN}
                        font={FONTS.REGULAR_24}
                    />
                </FlexLayout>
            )}
            {!acceptance?.isCompleted &&
                <GridLayout gap='20px' template='1fr 1fr'>
                    <div>
                        <ProductBarcode
                            register={register}
                            errors={errors}
                            onClick={handleSubmit(submit)}
                            isAddProduct={isAddProduct}
                            setIsAddProduct={setIsAddProduct}
                        />
                    </div>
                    {lastProduct && <div><LastScan item={lastProduct}/></div>}
                </GridLayout>
            }
            {!acceptance?.products?.length ?
                <EmptyBox icon='krista-box' title='Нет товаров' isRefresh={false}/>
                :
                <TableAcceptance acceptance={acceptance} setAcceptance={setAcceptance}/>

            }
            {!acceptance?.isComleted
                &&
                <GridLayout template='14% 30%' spaceBetween>
                    <Button
                        variant='isDangerBorder'
                        buttonText='Удалить все'
                        onClick={acceptanceDelete}
                    />
                    <Button
                        isDisabled={!acceptance?.products?.length}
                        variant={`${!acceptance?.products?.length ? 'isDisabled' : 'isSuccess'}`}
                        buttonText='Принять на склад'
                        onClick={acceptanceFinish}
                    />
                </GridLayout>
            }
        </GridLayout>
    );
};

export default AcceptanceDocumentsPage;