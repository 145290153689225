import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";
import Icon from "@components/Icon/Icon";
import Text from "@components/Text";

const ToastContent = ({title, response, responseURL, ok, error}) => {
    return (
        <Content>
            <IconWrapper>
                <Icon
                    iconName={`${ok ? 'krista-success' : ''}${error ? 'krista-error' : ''}`}
                    iconWidth={32}
                    iconHeight={32}
                    iconColor={ok && COLORS.STATUS_GREEN || error && COLORS.RED}
                />
            </IconWrapper>
            <Text
                font={FONTS.REGULAR_16}
                textAlign='center'
                value={title}
                color={COLORS.BLACK}
                margin='0 0 4px 0'
            />
            <Text
                font={FONTS.SEMI_BOLD_16}
                textAlign='center'
                value={response}
                color={COLORS.BLACK}
            />
            <Text
                font={FONTS.SEMI_BOLD_16}
                textAlign='center'
                value={responseURL}
                color={COLORS.BLACK}
            />
            <CloseWrapper>
                <Icon iconName="krista-close" iconWidth={16} iconHeight={16}/>
            </CloseWrapper>
        </Content>
    );
};

const Content = styled.div`
  text-align: center;
`

const CloseWrapper = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${COLORS.LIGHTGRAY};
`

const IconWrapper = styled.div`
  text-align: center;
  margin: 0 auto 10px auto;
  width: 32px;
  height: 32px;
`

export default ToastContent;
