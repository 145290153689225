import React from "react";
import {Controller} from "react-hook-form";
import InputLayout from "@components/Form/Inputs/InputLayout";
import styled from "styled-components";
import {COLORS} from "@/constans";
import DateTimePicker from "react-datetime-picker";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import Icon from "@components/Icon";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

const Calendar = (props) => {
    const {control, name, errors, label, setValue} = props
    const {icon, placeholder} = props
    const {isSmall, isBorder, isRange, isTimeFormat} = props

    const clearIcon = () => {
        return (
            <CalendarClear onClick={(e) => {
                if (setValue) {
                    if (isRange) {
                        setValue(name, '')
                    } else {
                        setValue(name, '')
                    }
                    e.stopPropagation()
                }
            }}>
                <CalendarClearIcon>
                    <Icon
                        iconName='krista-close-v2'
                        iconWidth={24}
                        iconHeight={24}
                    />
                </CalendarClearIcon>
            </CalendarClear>
        )
    }

    const calendarOptions = (value, onChange) => {
        return {
            disableClock: true,
            className: "custom-calendar",
            onChange: onChange,
            value: value,
            locale: "ru-RU",
            minDetail: "decade",
            format: isTimeFormat ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy",
            dayPlaceholder: 'дд',
            monthPlaceholder: 'мм',
            yearPlaceholder: 'гггг',
            hourPlaceholder: 'чч',
            minutePlaceholder: 'мм',
            nextLabel: <Icon
                iconName='krista-chevron-bottom'
                iconClass='react-calendar-button react-calendar-button-next'
                iconWidth={24}
                iconHeight={24}
            />,
            prevLabel: <Icon
                iconName='krista-chevron-bottom'
                iconClass='react-calendar-button react-calendar-button-prev'
                iconWidth={24}
                iconHeight={24}
            />,
            next2Label: null,
            prev2Label: null,
            calendarIcon: <Icon
                iconName={icon ? icon : 'krista-calendar'}
                iconWidth={24}
                iconHeight={24}
                iconColor={COLORS.LIGHTGRAY}
            />,
            clearIcon: clearIcon(),
            isCalendarOpen: false,
        }
    }

    const isShowCalendar = (value) => {
        if (!value) {
            return true
        }
        if (value[0] === null || value[1] === null) {
            return true
        }
    }

    return (
        <Controller
            control={control}
            name={name}
            render={({field: {value, onChange}}) => {
                return (
                    <CalendarContent placeholder={placeholder} isShowCalendar={isShowCalendar(value)}>
                        <InputLayout
                            isSmall={isSmall}
                            isBorder={isBorder}
                            label={label}
                            errors={errors}
                        >
                            {isRange ? (
                                    <DateRangePicker
                                        {...calendarOptions(value, onChange)}
                                    />
                                ) :
                                <DateTimePicker
                                    {...calendarOptions(value, onChange)}
                                />
                            }
                        </InputLayout>
                    </CalendarContent>
                )
            }}
        />
    )
}

const CalendarClear = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 34px;
  cursor: pointer;
  background-color: ${COLORS.LIGHTGRAY2};
  width: 20px;
  height: 20px;
  border-radius: 50%;
`

const CalendarClearIcon = styled.div`
  position: absolute;
  color: ${COLORS.LIGHTGRAY};
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`

const CalendarContent = styled.div`
  position: relative;

  .react-datetime-picker {
    position: relative;
    cursor: pointer;
  }

  .react-datetime-picker--closed {
    &::before {
      position: absolute;
      content: "${({placeholder}) => placeholder}";
      inset: 0;
      background-color: #fff;
      z-index: 4;
      display: ${({isShowCalendar}) => isShowCalendar ? 'flex': 'none'};
      align-items: center;
      color: ${COLORS.LIGHTGRAY};
      padding: 0 16px;
      border-radius: 10px;
    }

    &:hover::before {
      display: none;
    }
  }

  .react-daterange-picker {
    position: relative;
    cursor: pointer;
  }

  .react-daterange-picker--closed {
    &::before {
      position: absolute;
      content: "${({placeholder}) => placeholder}";
      inset: 0;
      background-color: #fff;
      z-index: 4;
      display: ${({isShowCalendar}) => isShowCalendar ? 'flex': 'none'};
      align-items: center;
      color: ${COLORS.LIGHTGRAY};
      padding: 0 16px;
      border-radius: 10px;
    }

    &:hover::before {
      display: none;
    }
  }

  .custom-calendar {
    padding: 0 8px 0 16px;

    .react-datetime-picker {
      &__wrapper {
        height: 100%;
        border: 0;
      }
      
      &__calendar {
        z-index: 5;
      }

      &__calendar-button {
        padding: 0;
      }

      &__inputGroup__input {
        &:invalid {
          color: ${COLORS.BLACK};
          background: transparent;
        }

        &:focus-visible {
          outline: 0;
        }
      }

      &__inputGroup {
        padding: 0;
      }
    }


    .react-daterange-picker {
      &__wrapper {
        height: 100%;
        border: 0;
      }

      &__calendar {
        z-index: 5;
      }

      &__calendar-button {
        padding: 0;
        position: absolute;
        right: 8px;
      }

      &__inputGroup {
        flex-grow: 0;
      }

      &__inputGroup__input {
        &:invalid {
          color: ${COLORS.BLACK};
          background: transparent;
        }

        &:focus-visible {
          outline: 0;
        }
      }

      &__inputGroup {
        padding: 0;
      }
    }
  }
`

export default Calendar