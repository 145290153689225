import React from 'react';
import TabSize from "@pages/ProductionPages/ModelDevelopmentPage/components/TabSize";
import PropTypes from "prop-types";

const TabsSizeLayout = (props) => {

    const {sizes} = props
    const {register} = props

    const newSizes = sizes?.map(item => ({
        id: item.modelTaskId,
        value: '',
        subSizes: item?.sizeTasks?.map(subItem => ({
            id: subItem.size.sizeId,
            value: subItem.size.value,
            isTopPriority: subItem.isTopPriority
        }))
    }))
    console.log(sizes)
    return (
        <TabSize
            sizes={newSizes}
            register={register}
        />
    );
};

export default TabsSizeLayout;