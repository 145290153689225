import Icon from "@components/Icon/Icon";
import useOutside from "@hooks/useOutside";
import clsx from "clsx";
import s from './selects-custom.module.scss'
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text/Text";
import styled from "styled-components";
import sDefault from "@components/Form/SelectsCustom/selects-custom.module.scss";
import {getDesignTasksAddMaterials, getDesignTasksUpdateMaterials} from "@actions/designTaskAction";
import {getMaterialColors} from "@actions/materialsAction";
import {useEffect, useState} from "react";

const SelectTextileColor = (props) => {

    const {itemTextile, itemColor, indexTextile} = props
    const {isConstructor = false, isDisabled} = props
    const {updateTextile, clearErrors, errorMessage} = props
    const {ref, isShow, setIsShow} = useOutside(false)

    const {materialId, supplierId, taskId} = props
    const {photo, name, priceId, price} = props

    const [colors, setColors] = useState([])

    const openDropdown = () => {
        if (!isDisabled) {
            setIsShow(!isShow)
            getMaterialColors(materialId, supplierId).then(({data}) => {
                setColors(data)
            })
        }
    }
    const setColor = (item) => {
        if (isConstructor) {
            updateTextile(indexTextile, {
                ...itemTextile,
                material: {...itemTextile.material, ...item}
            })
            if(itemTextile.hasOwnProperty('isNew')) {
                getDesignTasksAddMaterials(taskId, itemColor?.nomenclatureId, itemTextile.isMain, item?.price?.colorMaterialPriceId).then(({data}) => {
                    updateTextile(indexTextile, data)
                })

            } else {
                getDesignTasksUpdateMaterials(taskId, itemColor?.nomenclatureId,itemTextile?.materialCalculationId, item.price.colorMaterialPriceId, itemTextile.isMain)
            }

        } else {
            updateTextile(indexTextile, {...itemTextile, colorSelected: item})
        }
        // clearErrors()
    }

    return (
        <div className={clsx(s.selectWrapper, s.selectTextileColor,)} ref={ref}>
            <div className={clsx(
                s.select,
                isShow && s.selectActive,
                errorMessage && sDefault.selectError
            )}
                 onClick={openDropdown} aria-label='Выбор цвета ткани'>
                <div className={s.selectImageWrapper}>
                    {!itemTextile?.colorSelected?.materialPhoto ?
                        <Icon
                            iconName="krista-empty-photo"
                            iconWidth={24}
                            iconHeight={24}
                        />
                        :
                        <img
                            src={process.env.REACT_APP_IMAGE_PREFIX + itemTextile?.colorSelected?.materialPhoto + '?width=40&height=40&format=jpg&quality=80'}
                            alt="" className={s.selectImage}/>
                    }
                </div>
                <div>
                    <div
                        className={s.selectValue}>{name ? name : 'Цвет'}
                    </div>
                </div>
                <div className={s.selectDecor}>
                    <Icon iconName="krista-arrow-bottom"/>
                </div>
            </div>
            <ErrorWrapper>
                <Text value={errorMessage} font={FONTS.REGULAR_12} color={COLORS.RED} margin='2px 0 0 0'/>
            </ErrorWrapper>
            {isShow &&
                <div className={clsx(s.dropdown)}>
                    <div className={s.dropdownSearch} aria-label='Поисковик цвета ткани'>
                        <label className={s.dropdownSearchWrapperInput}>
                            <Icon iconClass={s.dropdownSearchIcon} iconName="krista-search"/>
                            <input className={s.dropdownSearchInput} type="text" placeholder="Поиск цвета"
                                   aria-label='Поиск цвета'/>
                        </label>
                    </div>
                    {colors?.map(itemTextileColor => (
                        <div
                            className={s.dropdownItem}
                            onClick={() => setColor(itemTextileColor)}
                            key={itemTextileColor.id}
                            aria-label={`Цвет ткани ${itemTextileColor.color.name}`}
                        >
                            <div className={s.dropdownGridColorItem}>
                                {!itemTextileColor.materialPhoto ?
                                    <Icon
                                        iconName="krista-empty-photo"
                                        iconWidth={24}
                                        iconHeight={24}
                                    />
                                    :
                                    <img
                                        src={process.env.REACT_APP_IMAGE_PREFIX + itemTextileColor.materialPhoto + '?width=40&height=40&format=jpg&quality=80'}
                                        alt="" className={s.dropdownImage}
                                    />
                                }

                            </div>
                            <div className={s.dropdownInfoColor}>
                                <div>
                                    <div className={s.dropdownInfoValue}
                                         aria-label='Цвет ткани'>{itemTextileColor.color.name}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};

const ErrorWrapper = styled.div`
  position: absolute;
  bottom: -16px;
  left: 0;
`


export default SelectTextileColor;