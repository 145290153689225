import OrderHeader from "@components/Order/OrderHeader/OrderHeader";
import OrderDashboard from "@components/Dashboards/OrderDashboard/OrderDashboard";
import OrderNav from "@components/Order/OrderNav/OrderNav";
import {Outlet} from "react-router-dom";
import OrderInfoPanel from "@components/Order/OrderPanelInfo/OrderPanelInfo";

function OrderLayout() {
    return (
        <>
            <OrderHeader statusTitle='Необработан'/>
            <OrderDashboard/>
            <OrderInfoPanel/>
            <OrderNav/>
            <Outlet/>
        </>
    );
}

export default OrderLayout;