import React from 'react';
import Icon from "@components/Icon/Icon";
import {COLORS, FONTS} from "@/constans";
import {css} from "styled-components";
import styled from "styled-components";

function SelectInput({isSmall, selectedValues, setIsShow, isShow, clearInput, placeholder}) {
    return (
        <MultiSelectContainer isSmall={isSmall} onClick={setIsShow}>
            <MultiSelectContainerHidden>
                <MultiSelectInput
                    placeholder={placeholder}
                    type="text"
                    value={selectedValues}
                    readOnly
                />
                {selectedValues.length > 0 &&
                    <MultiSelectClear onClick={clearInput}>
                        <MultiSelectClearIcon>
                            <Icon
                                iconName='krista-close-v2'
                                iconWidth={24}
                                iconHeight={24}
                                iconColor={COLORS.LIGHTGRAY}
                            />
                        </MultiSelectClearIcon>
                    </MultiSelectClear>
                }
                <MultiSelectDropdownIcon>
                    <Icon
                        iconName='krista-arrow-bottom'
                        iconWidth={24}
                        iconHeight={24}
                        onClick={() => {
                            setIsShow(!isShow)
                        }}
                        iconColor={COLORS.LIGHTGRAY}
                    />
                </MultiSelectDropdownIcon>
            </MultiSelectContainerHidden>
            <MultiSelectCount>
                {selectedValues.length > 0 &&
                    <>
                        ({selectedValues.split(',').length})
                    </>
                }
            </MultiSelectCount>
        </MultiSelectContainer>
    );
}

const MultiSelectContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    background-color: ${COLORS.WHITE};
    border-radius: 10px;
  ${({isSmall}) => isSmall && css`
    min-height: 38px;
    border: 0;
  `}
`

const MultiSelectContainerHidden = styled.div`
  display: flex;
  width: 100%;
`

const MultiSelectInput = styled.input`
  border: 0;
  width: 100%;
  min-height: 38px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  outline: none;
  font: ${FONTS.REGULAR_14};
  cursor: default;
  padding: 0 90px 0 20px;
  border-radius: 10px;
`

const MultiSelectDropdownIcon = styled.div`
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`

const MultiSelectClear = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 34px;
  cursor: pointer;
  background-color: ${COLORS.LIGHTGRAY2};
  width: 20px;
  height: 20px;
  border-radius: 50%;
`

const MultiSelectClearIcon = styled.div`
  position: absolute;
  color: ${COLORS.LIGHTGRAY};
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`

const MultiSelectCount = styled.span`
  position: absolute;
  background-color: transparent;
  right: 58px;
  top: 50%;
  transform: translateY(-55%);
  padding-left: 4px;
`

const a = styled.div`
  .controlMultiSelectSearch {
    color: ${COLORS.LIGHTGRAY};
    margin: 0 8px;
  }
`

export default SelectInput;