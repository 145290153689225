import {SET_STORE_ALL_CLIENT} from "@types/types";

const initState = {
    clientState: [],
}

const storeClientsReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_STORE_ALL_CLIENT :
            return {...state, clientsState: action.payload}

        default:
            return state
    }
}

export default storeClientsReducer