import GridLayout from "@components/Layout/GridLayout";
import Select from "@components/Form/Selects/Select";
import {useForm} from "react-hook-form";
import Button from "@components/Form/Button";
import InputNumeric from "@components/Form/Inputs/InputNumeric";
import Input from "@components/Form/Inputs/Input";
import {useEffect, useMemo} from "react";
import dayjs from "dayjs";
import {useNavigate, useSearchParams} from "react-router-dom";
import SelectParams from "@components/Form/Selects/SelectParams";

function NavFiltersStoreWarehouse() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    const sizeLine = [{id: 1, name: 'test value'}, {id: 2, name: 'test value2'}]
    const colors = [{id: 1, name: 'test value'}, {id: 2, name: 'test value2'}]

    const sizesLineDefaultValue = useMemo(() => {
        const sizeLineIds = searchParams.get('sizeLineIds')
        if (!sizeLineIds) return []
        return sizeLine.filter(item => sizeLineIds.includes(String(item.id)));
    }, [sizeLine])

    const colorDefaultValue = useMemo(() => {
        const colorIds = searchParams.get('colorIds')
        if (!colorIds) return []
        return colors.filter(item => colorIds.includes(String(item.id)));
    }, [colors])

    const {control, setValue, reset, register, watch} = useForm({
        defaultValues: {
            article: searchParams.get('articulName'),
            sizeLineIds: sizesLineDefaultValue,
            colorIds: colorDefaultValue,
            series: '',
            priceFrom: searchParams.get('priceFrom'),
            priceTo: searchParams.get('priceTo'),
        }
    })

    const watchArticle = watch('articulName');
    const watchSize = watch('sizeLineIds');
    const watchColor = watch('colorIds')
    const watchSeries = watch('series')
    const watchPriceFrom = watch('priceFrom')
    const watchPriceTo = watch('priceTo')

    useEffect(() => {
        watchArticle ? searchParams.set('articulName', watchArticle) : searchParams.delete('articulName')
        watchPriceFrom ? searchParams.set('priceFrom', watchPriceFrom) : searchParams.delete('priceFrom')
        watchPriceTo ? searchParams.set('priceTo', watchPriceTo) : searchParams.delete('priceTo')
        watchSeries ? searchParams.set('series', watchSeries) : searchParams.delete('series')
        watchSize.length > 0 ? searchParams.set('sizeLineIds', watchSize.map(item => item.id).join(',')) : searchParams.delete('sizeLineIds')
        watchColor.length > 0 ? searchParams.set('colorIds', watchColor.map(item => item.id).join(',')) : searchParams.delete('colorIds')

        setSearchParams(searchParams)
    }, [watchArticle, watchSize, watchPriceTo, watchPriceFrom, watchSeries, watchColor])

    const clearFilters = () => {
        navigate('/store/warehouse?page=1&pageSize=25')
        reset({
            articulName: '',
            sizeLineIds: [],
            colorIds: [],
            series: '',
            priceFrom: '',
            priceTo: '',
        })
    }

    return (
        <GridLayout gap='20px'>
            <Input
                register={register}
                isDebounce
                name='articulName'
                isBorder={false}
                icon='krista-search'
                placeholder='Артикул'
            />
            <SelectParams
                register={register}
                control={control}
                name='sizeLineIds'
                values={sizeLine}
                setValue={setValue}
                isBorder={false}
                placeholderText='Размер'
                url="sizeIds"
                chosenValues={watch('sizeLineIds')}
                placeholder='Выбрать размер'
                placeholderInner='Поиск по размерам'
                isMulti
                getOptionLabel='name'
                getOptionValue='id'
                watch={watch}
                hasNotDropdownIndicator
            />
            <SelectParams
                register={register}
                control={control}
                name='colorIds'
                values={colors}
                setValue={setValue}
                isBorder={false}
                placeholderText='Цвет'
                url="colorIds"
                chosenValues={watch('colorIds')}
                placeholder='Выбрать цвет'
                placeholderInner='Поиск по цветам'
                isMulti
                getOptionLabel='name'
                getOptionValue='id'
                watch={watch}
                hasNotDropdownIndicator
            />
        </GridLayout>
    );
}

export default NavFiltersStoreWarehouse;