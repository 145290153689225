import {useEffect} from "react";
import {useSelector} from "react-redux";
import {yupResolver} from "@hookform/resolvers/yup";
import {useFieldArray, useForm} from "react-hook-form";
import BasicInformationOnTheModel
    from "@pages/ProductionPages/ModelDevelopmentPage/OperatorPage/components/BasicInformationOnTheModel";
import TaskDetails from "@pages/ProductionPages/ModelDevelopmentPage/OperatorPage/components/TaskDetails";
import {defaultValues, validateSchema} from "@pages/ProductionPages/ModelDevelopmentPage/OperatorPage/schema";
import Box from "@components/Box";
import Text from "@components/Text/Text";
import GridLayout from "@components/Layout/GridLayout";
import {FONTS} from "@/constans";
import {getSizes} from "@actions/sizeAction";

const OperatorPage = () => {
    const modelSizeAll = useSelector(state => state.model.modelAllSizeState)

    const {register, handleSubmit, control, watch, setValue, getValues, clearErrors, formState: {errors}} = useForm({
        mode: "onChange",
        resolver: yupResolver(validateSchema),
        defaultValues: defaultValues,
    });
    console.log(watch())
    const {fields: sizes, replace} = useFieldArray({control, name: "models"});

    useEffect(() => {
        if (modelSizeAll.length > 0) {
            replace(modelSizeAll.map(item => (
                {
                    size: item.size,
                    remark: item.remark,
                    colors: item.colors,
                }
            )))
        }
    }, [modelSizeAll])
    useEffect(() => {
        getSizes()
    }, []) //TODO УДАЛИТЬ!

    return (
        <>
            <Text value="Разработка модели" font={FONTS.SEMI_BOLD_24} as="h1" margin="0 0 24px 0"/>
            <form>
                <GridLayout template="413px 1fr" gap="20px">
                    <Box>
                        <BasicInformationOnTheModel
                            register={register}
                            control={control}
                            getValues={getValues}
                            setValue={setValue}
                            errors={errors}
                            watch={watch}
                        />
                    </Box>

                    <TaskDetails
                        sizes={sizes}
                        getValues={getValues}
                        register={register}
                        errors={errors}
                        control={control}
                        clearErrors={clearErrors}
                        handleSubmit={handleSubmit}
                    />

                </GridLayout>

            </form>
        </>
    );
};

export default OperatorPage;