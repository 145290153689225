import GridLayout from "@components/Layout/GridLayout";
import Box from "@components/Box";
import ConfirmIndicator from "@components/ConfirmIndicator";
import {FONTS} from "@/constans";
import Text from "@components/Text/Text";

function Documents() {
    return (
        <Box>
            <GridLayout gap='16px'>
                <GridLayout gap='4px'>
                    <Text value='Документы' font={FONTS.SEMI_BOLD_16} as='h3'/>
                    <Text value='Проверьте наличие и содержание файлов' font={FONTS.REGULAR_14} as='h4' isGray/>
                </GridLayout>
                <GridLayout gap='8px'>
                    <ConfirmIndicator text='Графис' confirm/>
                    <ConfirmIndicator text='Калькуляции' confirm/>
                    <ConfirmIndicator text='Раскладка' confirm/>
                    <ConfirmIndicator text='Табель мер' confirm/>
                    <ConfirmIndicator text='Технологическое описание' confirm/>
                    <ConfirmIndicator text='Спецификация' confirm/>
                </GridLayout>
            </GridLayout>
        </Box>
    );
}

export default Documents;