import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";

import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";

function ClientProfileNav({activeNav, setActiveNav, clientInfo}) {
    return (
        <FlexLayout padding='0 12px'>
            <NavInner>
                {clientInfo.taxPayers.map((item, index) => (
                    <NavItem
                        key={item.id}
                        className={`${activeNav === item.id ? 'active' : ''}`}
                        isActive={activeNav === item.id}
                        onClick={() => setActiveNav(item.id)}
                    >
                        <Text
                            value={`Лицо ${index + 1}`}
                            font={FONTS.MEDIUM_12}
                            color={activeNav ? COLORS.BLACK : COLORS.GRAY}
                            margin='0 0 2px 0'
                        />
                        <Text
                            value={<NavSubtitle>{item.name}</NavSubtitle>}
                            font={FONTS.REGULAR_12}
                            color={activeNav ? COLORS.MAIN : COLORS.LIGHTGRAY}
                        />
                    </NavItem>
                ))}
            </NavInner>
        </FlexLayout>
    );
}

const NavInner = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${COLORS.LIGHTGRAY1};
  padding: 0 16px;
`

const NavItem = styled.div`
  width: 140px;
  height: 60px;
  padding: 16px;
  cursor: pointer;

  ${({isActive}) => isActive && `
    background: linear-gradient(0deg, rgba(0, 115, 255, 0.1) 0%, rgba(0, 115, 255, 0) 100%);
    border-bottom: 2px solid ${COLORS.MAIN};
  `}
`

const NavSubtitle = styled.div`
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default ClientProfileNav;