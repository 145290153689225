import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";
import Icon, {Content as IconContent} from "@components/Icon/Icon";
import Text from "@components/Text/Text";

function ConfirmIndicator({confirm, text}) {
    return (
        <Content confirm={confirm}>
            <GridLayout template='auto auto' spaceBetween align='center'>
                <Text
                    value={text}
                    color={COLORS.MAIN}
                    font={FONTS.SEMI_BOLD_14}
                    otherStyle={`
                      text-decoration-line: underline;
                      cursor: pointer;
                      user-select: none;
                    `}
                />
                <Icon
                    iconName='krista-confirm'
                    iconWidth={24}
                    iconHeight={24}

                />
            </GridLayout>
        </Content>
    );
}

const Content = styled.div`
  width: 100%;
  background: ${COLORS.WHITE};
  border: 1px solid rgba(0, 115, 255, 0.5);
  border-radius: 10px;
  padding: 12px 16px 12px 24px;
  height: 48px;

  ${IconContent} {
    color: ${({confirm}) => confirm ? `${COLORS.SALAD}` : `${COLORS.LIGHTGRAY1}`}
  }
`

export default ConfirmIndicator;