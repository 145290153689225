import React, {useState} from "react";
import RowDropdown from "@components/Table/TableDefault/TableClient/RowDropdown/RowDropdown";
import {getClient} from "@actions/clientsAction";
import {NumericFormat} from "react-number-format";
import {COLORS, FONTS} from "@/constans";
import BodyRow from "@components/Table/components/BodyRow";
import GridLayout from "@components/Layout/GridLayout";
import Checkbox from "@components/Form/Checkbox";
import BodyRowOnline from "@components/Table/components/BodyRowOnline";
import Text from "@components/Text";
import BodyRowItem from "@components/Table/components/BodyRowItem/BodyRowItem";
import Button from "@components/Form/Button";
import BodyRowAnimateIndicator
    from "@components/Table/components/BodyRowAnimateIndicator/BodyRowAnimateIndicator";

const Row = ({item}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [clientInfo, setClientInfo] = useState(null)

    const openClientDetail = () => {
        if (!isOpen) {
            setIsLoading(true)
            getClient(item.id)
                .then((response) => {
                    setClientInfo(response.data)
                    setIsLoading(false)
                    setIsOpen(true)
                })
                .catch(() => {
                    setIsLoading(false)
                })
        } else {
            setIsOpen(false)
        }
    }

    return (
        <GridLayout position='relative'>
            <BodyRowAnimateIndicator isLoading={isLoading}/>
            <BodyRow onClick={openClientDetail} isActive={isOpen} backgroundColor={COLORS.WHITE}>
                <GridLayout template='40px 24px auto 9% 11% 15% 13% 8% 84px' align='center'>
                    <BodyRowItem>
                        <Checkbox/>
                    </BodyRowItem>
                    <BodyRowItem>
                        <BodyRowOnline isActive={item.isOnline}/>
                    </BodyRowItem>
                    <BodyRowItem>
                            <Text value={item.fullName} font={FONTS.BOLD_16} color={COLORS.DARKGRAY}/>
                    </BodyRowItem>
                    <BodyRowItem>
                        <Text
                            value={`${item.cartItemsCount} единиц`}
                            font={FONTS.REGULAR_16}
                            color={COLORS.MAIN}
                        />
                        <Text
                            value={
                                <NumericFormat
                                    value={item.cartTotalSum}
                                    displayType="text"
                                    suffix=" $"
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                />
                            }
                            font={FONTS.REGULAR_16}
                            color={COLORS.LIGHTGRAY}
                        />
                    </BodyRowItem>
                    <BodyRowItem>
                        <Text
                            value={
                                <>
                                    {item.newOrderTotal.totalCount} заказ
                                    ({item.newOrderTotal.numbers.map(item =>
                                    <Text
                                        value={item}
                                        color={COLORS.MAIN}
                                        font='inherit'
                                    />
                                )})
                                </>
                            }
                            font={FONTS.REGULAR_16}
                            color={COLORS.DARKGRAY}
                        />
                        <Text
                            value={
                                <>
                                    {item.newOrderTotal.totalItemsCount} ед
                                    <span style={{padding: '0 4px'}}>=</span>
                                    <NumericFormat
                                        value={item.newOrderTotal.totalSum}
                                        displayType="text"
                                        suffix=" $"
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </>
                            }
                            font={FONTS.REGULAR_16}
                            color={COLORS.LIGHTGRAY}
                        />
                    </BodyRowItem>
                    <BodyRowItem>
                        <Text
                            value={
                                <>
                                    {item.processedOrderTotal.totalCount} заказа
                                    ({item.processedOrderTotal.numbers.map(item =>
                                    <Text
                                        value={item}
                                        color={COLORS.MAIN}
                                        font='inherit'
                                    />
                                )})
                                </>
                            }
                            font={FONTS.REGULAR_16}
                            color={COLORS.DARKGRAY}
                        />
                        <Text
                            value={
                                <>
                                    {item.processedOrderTotal.totalItemsCount} ед
                                    <span style={{padding: '0 4px'}}>=</span>
                                    <NumericFormat
                                        value={item.processedOrderTotal.totalSum}
                                        displayType="text"
                                        suffix=" $"
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </>
                            }
                            font={FONTS.REGULAR_16}
                            color={COLORS.LIGHTGRAY}
                        />
                    </BodyRowItem>
                    <BodyRowItem>
                        <Text
                            value={
                                <>
                                    {item.reserveTotal.totalCount} заказа
                                    ({item.reserveTotal.numbers.map(item =>
                                    <Text
                                        value={item}
                                        color={COLORS.MAIN}
                                        font='inherit'
                                    />
                                )})
                                </>
                            }
                            font={FONTS.REGULAR_16}
                            color={COLORS.DARKGRAY}
                        />
                        <Text
                            value={
                                <>
                                    {item.reserveTotal.totalItemsCount} ед
                                    <span style={{padding: '0 4px'}}>=</span>
                                    <NumericFormat
                                        value={item.reserveTotal.totalSum}
                                        displayType="text"
                                        suffix=" $"
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </>
                            }
                            font={FONTS.REGULAR_16}
                            color={COLORS.LIGHTGRAY}
                        />
                    </BodyRowItem>
                    <BodyRowItem>
                        <Text
                            value={
                                <NumericFormat
                                    value={item.debtTotal}
                                    displayType="text"
                                    suffix=" $"
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                />
                            }
                            font={FONTS.REGULAR_16}
                            color={COLORS.MAIN}
                        />
                    </BodyRowItem>
                    <BodyRowItem>
                        <Button
                            variant='isChevron'
                            iconName={isOpen ? "krista-chevron-top" : "krista-chevron-bottom"}
                            isSmall
                            onClick={() => setIsOpen(!isOpen)}
                        />
                    </BodyRowItem>
                </GridLayout>
            </BodyRow>
            {isOpen && <RowDropdown clientInfo={clientInfo}/>}
        </GridLayout>
    );
};

export default Row;
