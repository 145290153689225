import styled from "styled-components";
import {COLORS} from "@/constans";

function TaskRowHead({children}) {
    return (
        <TaskRowHeadContent>
            {children}
        </TaskRowHeadContent>
    );
}

const TaskRowHeadContent = styled.div`
  width: 100%;
  padding: 10px 16px;
  background: ${COLORS.LIGHTGRAY3};
  border: 1px solid ${COLORS.LIGHTGRAY1};
  border-radius: 10px;
`

export default TaskRowHead;