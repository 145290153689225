import {SET_WAREHOUSE_PRODUCTS} from "@types/types"

const initState = {
    warehouseProductsState: null
}

const warehouseProductsReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_WAREHOUSE_PRODUCTS :
            return {...state, warehouseProductsState: action.payload}
        default:
            return state
    }
}

export default warehouseProductsReducer