import BodyRow from "@components/Table/components/BodyRow";
import GridLayout from "@components/Layout/GridLayout";
import BodyRowItem from "@components/Table/components/BodyRowItem";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";
import Color from "@components/Table/components/Color";
import Button from "@components/Form/Button";
import {NumericFormat} from "react-number-format";
import {getAcceptedPayment, deleteInvoiceProduct, getInvoice} from "@actions/invoiceAction";
import Image from "@components/Image/Image";
import {SET_MODAL_CONFIRM_ACTION} from "@types/types";
import {useDispatch} from "react-redux";
import {getCurrencyScaleLength} from "@util/decimalHelper";

function Row({template, product, invoice, setInvoice, setAcceptedPayment, isCompleted}) {
    const dispatch = useDispatch()

    const deleteProduct = () => {
        dispatch({
            type: SET_MODAL_CONFIRM_ACTION,
            payload: {
                isShow: true,
                title: 'Вы уверены что хотите удалить товар?',
                desc: `
                    <b>Артикул:</b> ${product.articulName} <br>
                    <b>Размер:</b> ${product.sizeLineName} <br>
                    <b>Цвет:</b> ${product.colorName} <br>
                    <b>Количество:</b> ${product.quantity}
                `,
                accept: () => {
                    deleteInvoiceProduct(invoice?.invoiceId, product?.productId).then(() => {
                        getInvoice(invoice?.invoiceId).then(({data}) => {
                            setInvoice(data)
                            dispatch({
                                type: SET_MODAL_CONFIRM_ACTION, payload: {
                                    title: '',
                                    desc: ``,
                                    isShow: false,
                                    icon: ''
                                }
                            })
                        })
                        getAcceptedPayment(invoice?.invoiceId).then(({data}) => {
                            setAcceptedPayment(data)
                        })
                    })
                },
                icon: 'krista-delete'
            }
        })
    }

    return product && (
        <BodyRow backgroundColor={COLORS.WHITE}>
            <GridLayout align='center' template={template}>
                <BodyRowItem>
                    <FlexLayout gap='8px' align='center'>
                        <Image src={product.photo} width='36px' height='36px'/>
                        <Text value={product.articulName} font={FONTS.SEMI_BOLD_14} color={COLORS.DARKGRAY}/>
                    </FlexLayout>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value={product.sizeLineName} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <FlexLayout gap='8px'>
                        <Color backgroundColor={product.hex}/>
                        <Text value={product.colorName} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                    </FlexLayout>
                </BodyRowItem>
                <BodyRowItem>
                    <NumericFormat
                        value={product.originalPrice}
                        thousandSeparator=" "
                        decimalScale={getCurrencyScaleLength(product?.originalPrice)}
                        fixedDecimalScale
                        displayType="text"
                        renderText={(value) => (
                            <Text value={value} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                        )}
                        suffix={` ${product.currencySymbol}`}
                    />
                </BodyRowItem>
                <BodyRowItem>
                    <NumericFormat
                        value={product.discountPercent ? product.discountPercent : ''}
                        thousandSeparator=" "
                        decimalScale={getCurrencyScaleLength(product?.discountPercent)}
                        fixedDecimalScale
                        displayType="text"
                        renderText={(value) => (
                            <Text
                                value={`-${value}`}
                                font={FONTS.REGULAR_14}
                                color={product.discountPercent ? COLORS.RED : COLORS.DARKGRAY}
                            />
                        )}
                        suffix={`%`}
                    />
                </BodyRowItem>
                <BodyRowItem>
                    <NumericFormat
                        value={product.discountPercent ? product.price : '-'}
                        thousandSeparator=" "
                        decimalScale={getCurrencyScaleLength(product.price)}
                        fixedDecimalScale
                        displayType="text"
                        renderText={(value) => (
                            <Text value={value} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                        )}
                        suffix={` ${product.currencySymbol}`}
                    />
                </BodyRowItem>
                <BodyRowItem>
                    <Text value={product?.quantity} font={FONTS.SEMI_BOLD_14} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    {product.discountPercent &&
                        <NumericFormat
                            value={product.originalTotalSum}
                            thousandSeparator=" "
                            decimalScale={getCurrencyScaleLength(product.originalTotalSum)}
                            fixedDecimalScale
                            displayType="text"
                            renderText={(value) => (
                                <Text
                                    value={value}
                                    font={FONTS.REGULAR_12}
                                    color={COLORS.LIGHTGRAY}
                                    textDecoration='line-through'
                                />
                            )}
                            suffix={` ${product.currencySymbol}`}
                        />
                    }
                    <NumericFormat
                        value={product.totalSum}
                        thousandSeparator=" "
                        decimalScale={getCurrencyScaleLength(product.totalSum)}
                        fixedDecimalScale
                        displayType="text"
                        renderText={(value) => (
                            <Text value={value} font={FONTS.SEMI_BOLD_14} color={COLORS.DARKGRAY}/>
                        )}
                        suffix={` ${product.currencySymbol}`}
                    />
                </BodyRowItem>
                <BodyRowItem>
                    {!isCompleted &&
                        <Button
                            variant='isDangerBorder'
                            isSmall
                            iconName='krista-delete'
                            onClick={deleteProduct}
                        />
                    }
                </BodyRowItem>
            </GridLayout>
        </BodyRow>
    );
}

export default Row;