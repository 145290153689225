import {SET_SIZE} from "@types/types"

const initState = {
    sizeState: null
}

const sizeReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_SIZE :
            return {...state, sizeState: action.payload}
        default:
            return state
    }
}

export default sizeReducer