import ApiConfig from "@/ApiConfig";
import store from '@store'
import {SET_COLLECTION, SET_MODAL_CREATE_COLLECTION} from "@types/types";
import {toast} from "react-toastify";
import ToastContent from "@components/ToastContent";

export const getCollection = async () => {
    const {data} = await ApiConfig.get('Model/Collection/Lookup')
    store.dispatch({type: SET_COLLECTION, payload: data})
};

export const getCreateCollection = async (data) => {
    ApiConfig.post(`Model/Collection/Create`, data).then((response) => {
        toast.success(<ToastContent title="Успешная операция" response={response.data.message} ok/>);
        getCollection()
        store.dispatch({type: SET_MODAL_CREATE_COLLECTION, payload: false})
    })
}