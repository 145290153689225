import styled from "styled-components";
import {COLORS} from "@/constans";
import {InputContentStyles} from "@components/Form/Inputs/components/InputContentStyles";
import {SelectContentStyles} from "@components/Form/Selects/components/SelectContentStyles";

const ControlBox = ({children, isInvalid, ariaLabel, isSmall, iconPosition, icon, isBorder, isDisabled}) => {
    return (
        <ControlBoxContent
            isInvalid={isInvalid}
            aria-label={ariaLabel}
            isSmall={isSmall}
            iconPosition={iconPosition}
            icon={icon}
            isBorder={isBorder}
            isDisabled={isDisabled}
        >
            {children}
        </ControlBoxContent>
    );
};

const ControlBoxContent = styled.div`
  position: relative;
  
  ${({isDisabled}) => isDisabled && 'cursor: not-allowed'};
  
  //Select
  .controlSelect {
    ${({isInvalid, isSmall, isBorder, isDisabled}) => SelectContentStyles(isInvalid, isSmall, isBorder, isDisabled)}
  }
  
  //Input
  .defaultInput, .controlInputDebounce, .inputGenerateValue, .custom-calendar, .inputNumeric {
    ${({isInvalid, isSmall, iconPosition, icon}) => InputContentStyles(isInvalid, isSmall, iconPosition, icon)}
  }

  .controlPhoneInput {
    border-radius: 10px;
    background: ${COLORS.WHITE};
    border: 1px solid ${COLORS.LIGHTGRAY1};
    position: relative;

    ${({isInvalid}) => isInvalid && `border: 1px solid ${COLORS.RED};`}
    .controlPhone {
      width: 100%;
      border: 0;
      outline: none;
      height: 48px;
      padding: 0 16px 0 98px;
      border-radius: 10px;
      font-weight: 400;
      font-size: 14px;
    }

    .controlFlagButton {
      border-radius: 10px 0 0 10px;
      border-right: 1px solid ${COLORS.LIGHTGRAY1};
      background-color: #fff;
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      width: 82px;
      cursor: pointer;

      .selected-flag {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 14px;
      }

      .flag {
        //TODO: пофиксить флаги, не отображаются
        background-image: url("../public/flagsSprite.svg");
        background-repeat: no-repeat;
        width: 34px;
        height: 24px;
        position: relative;

        &.ru {
          background-position: 0 0;
        }

        &.kz {
          background-position: -34px 0;
        }

        &.kg {
          background-position: -68px 0;
        }

        &.uz {
          background-position: -102px 0;
        }


      }

      .arrow {
        position: absolute;
        right: -18px;
        top: calc(50% - 3px);
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid ${COLORS.LIGHTGRAY};
      }
    }

    .controlPhoneDropdown {
      list-style: none;
      background-color: ${COLORS.WHITE};
      top: calc(100% + 4px);
      border: 1px solid ${COLORS.LIGHTGRAY1};
      border-radius: 10px;
      left: 0;
      position: absolute;
      margin: 0;
      padding: 4px 0;
      z-index: 2;

      .country {
        padding: 6px 10px;
        display: flex;
        align-items: center;

        &:hover {
          background-color: ${COLORS.LIGHTGRAY2};
        }

        &.highlight {
          background-color: ${COLORS.LIGHTGRAY1};
        }
      }

      .flag {

      }

      .country-name {
        font-size: 14px;
        margin: 0 8px;
      }

      .dial-code {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
`

export default ControlBox
