import React, {useEffect} from 'react';
import InputDebounce from "@components/Form/Inputs/InputDebounce";
import {getMaterials} from "@actions/materialsAction";
import {useForm} from "react-hook-form";
import sDefault from "@components/Form/SelectsCustom/selects-custom.module.scss";

const SearchPanel = (props) => {
    const {isShow, isTextile} = props

    const {control, setValue, watch} = useForm()

    const watchSupplierName = watch("supplierName")
    const watchMaterialName = watch("materialName")

    useEffect(() => {
        if (!isShow) {
            setValue('supplierName', '')
            setValue('materialName', '')
        }
    }, [isShow])

    useEffect(() => {
        if (watchSupplierName?.length > 0 || watchMaterialName?.length > 0) {
            getMaterials(watch(), isTextile)
        } else {
            getMaterials({}, isTextile)
        }
    }, [watchSupplierName, watchMaterialName])

    return (
        <div className={sDefault.dropdownSearch}>
            <InputDebounce
                icon="krista-search"
                minLength={1}
                debounceTimeout={600}
                name="supplierName"
                control={control}
                placeholder="Имя поставщика" isSmall
                ariaLabel="Имя поставщика"
            />
            <InputDebounce
                icon="krista-search"
                minLength={1}
                debounceTimeout={600}
                name="materialName"
                control={control}
                placeholder="Название ткани" isSmall/>
        </div>
    );
};

export default SearchPanel;