import {COLORS, FONTS} from "@/constans";
import hexToRgba from "hex-to-rgba";
import styled from "styled-components";

import Icon from "@components/Icon/Icon";
import Box from "@components/Box";
import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";
import GridLayout from "@components/Layout/GridLayout";

function CardOrderInfo() {
    return (
        <Box>
            <GridLayout gap='12px'>
                <FlexLayout align='center' spaceBetween>
                    <div>
                        <Text
                            value="Информация по заказу"
                            font={FONTS.SEMI_BOLD_16}
                            color={COLORS.BLACK}
                            margin='0 0 2px 0'
                        />
                        <Text
                            value="Оформлен: Клиентом"
                            font={FONTS.REGULAR_14}
                            color={COLORS.LIGHTGRAY}
                        />
                    </div>
                    <IconWrapper>
                        <Icon
                            iconName='krista-cart'
                            iconWidth={24}
                            iconHeight={24}
                        />
                    </IconWrapper>
                </FlexLayout>
                <GridLayout gap='2px'>
                    <FlexLayout>
                        <Text
                            value="Упаковка:"
                            font={FONTS.MEDIUM_14}
                            color={COLORS.DARKGRAY}
                        />
                        <Text
                            value="Стандартная"
                            font={FONTS.REGULAR_14}
                            color={COLORS.DARKGRAY}
                            margin='0 0 0 4px'
                        />
                    </FlexLayout>
                    <FlexLayout>
                        <Text
                            value="Каталоги:"
                            font={FONTS.MEDIUM_14}
                            color={COLORS.DARKGRAY}
                        />
                        <Text
                            value="Предзаказ сериями, Предзаказ не сериями"
                            font={FONTS.REGULAR_14}
                            color={COLORS.DARKGRAY}
                            margin='0 0 0 4px'
                        />
                    </FlexLayout>
                </GridLayout>
                <div>
                    <GridLayout gap='12px'>
                        <GridLayout template='1fr 1fr 1fr 1fr' gap='20px'>
                            <div>
                                <Text value="128" font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                                <Text value="Серий" font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY}/>
                            </div>
                            <div>
                                <Text value="4 000.00 $" font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                                <Text value="Сумма заказа" font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY}/>
                            </div>
                            <div>
                                <Text value="0" font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                                <Text value="Отправлено ед." font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY}/>
                            </div>
                            <div>
                                <Text value="0.00 $" font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                                <Text value="Внесено по заказу" font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY}/>
                            </div>
                        </GridLayout>
                        <GridLayout template='1fr 1fr 1fr 1fr' gap='20px'>
                            <div>
                                <Text value="256" font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                                <Text value="Единиц" font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY}/>
                            </div>
                            <div>
                                <FlexLayout align='center'>
                                    <Text value="1 500.00 $" font={FONTS.REGULAR_16} color={COLORS.RED}/>
                                    <Text value="40%" font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY} margin='0 0 0 4px'/>
                                </FlexLayout>
                                <Text value="Сумма предоплат" font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY}/>
                            </div>
                            <div>
                                <Text value="256" font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                                <Text value="Долг единиц" font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY}/>
                            </div>
                            <div>
                                <Text value="4000.00 $" font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                                <Text value="Долг по заказу" font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY}/>
                            </div>
                        </GridLayout>
                    </GridLayout>
                </div>
            </GridLayout>

        </Box>
    );
}

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background: ${hexToRgba(COLORS.YELLOW, 0.1)};
  border-radius: 10px;
  color: ${COLORS.YELLOW};
`

export default CardOrderInfo;