import {
    SET_NOT_APPROVED_CLIENTS,
    SET_NOT_APPROVED_CLIENT_BY_ID,
} from "@types/types";

const initState = {
    notApprovedClientsState: null,
    notApprovedClientByIdState: null,
}

const registrationRequestReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_NOT_APPROVED_CLIENTS :
            return {...state, notApprovedClientsState: action.payload}
        case SET_NOT_APPROVED_CLIENT_BY_ID :
            return {...state, notApprovedClientByIdState: action.payload}
        default:
            return state
    }
}

export default registrationRequestReducer