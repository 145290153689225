import styled from "styled-components";
import PropTypes from "prop-types";

const GridLayout = (props) => {
    return (
        <Content {...props} aria-label={props.ariaLabel}>
            {props.children}
        </Content>
    )
};

const Content = styled.div`
  display: grid;
  ${({template}) => template && `grid-template-columns: ${template}`};
  ${({templateRows}) => templateRows && `grid-template-rows: ${templateRows}`};
  ${({gap}) => gap && `gap: ${gap}`};
  ${({margin}) => margin && `margin: ${margin}`};
  ${({padding}) => padding && `padding: ${padding}`};
  ${({align}) => align ? `align-items: ${align}` : 'align-items: flex-start'};
  ${({spaceBetween}) => spaceBetween && `justify-content: space-between;`}
  ${({zIndex}) => zIndex && `z-index: ${zIndex}`};
  ${({height}) => height && `height ${height}`};
  ${({position}) => position && `position ${position}`};
`

GridLayout.propTypes = {
    template: PropTypes.string,
    templateRows: PropTypes.string,
    gap: PropTypes.string,
    margin: PropTypes.string,
    padding: PropTypes.string,
    align: PropTypes.string,
    spaceBetween: PropTypes.bool,
    zIndex: PropTypes.string,
    height: PropTypes.string,
    position: PropTypes.string,
};

export default GridLayout