import React, {useEffect} from 'react';
import Input from "@components/Form/Inputs/Input";
import {useForm} from "react-hook-form";
import {useNavigate, useSearchParams} from "react-router-dom";
import Button from "@components/Form/Button";
import InputNumeric from "@components/Form/Inputs/InputNumeric";
import SelectParams from "@components/Form/Selects/SelectParams";
import {
    defaultCalendarSelectParamsValue,
    defaultSelectParamsValue, setCalendarSearchParams,
    setOrDeleteSearchParams,
    setOrDeleteSearchParamsArr
} from "@util/defaultSelectParamsValue";
import Calendar from "@components/Form/Inputs/Calendar";
import dayjs from "dayjs";

function NavFiltersSalesHistory({employees}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    const sizeLine = [{id: 1, name: 'Первый'}, {id: 2, name: 'Второй'}]
    const colors = [{id: 1, name: 'Первый'}, {id: 2, name: 'Второй'}]
    const clients = [{id: 1, name: 'Первый'}, {id: 2, name: 'Второй'}]

    const {control, setValue, reset, register, watch} = useForm({
        defaultValues: {
            executionDateFrom: defaultCalendarSelectParamsValue(searchParams, 'executionDateFrom'),
            executionDateTo: defaultCalendarSelectParamsValue(searchParams, 'executionDateTo'),
            serialNumber: searchParams.get('serialNumber'),
            totalSumFrom: searchParams.get('totalSumFrom'),
            totalSumTo: searchParams.get('totalSumTo'),
            articulName: searchParams.get('articulName'),
            sizeLineIds: defaultSelectParamsValue(searchParams, 'sizeLineIds', sizeLine),
            colorIds: defaultSelectParamsValue(searchParams, 'colorIds', colors),
            employeeIds: defaultSelectParamsValue(searchParams, 'employeeIds', employees, 'employeeId'),
            clientIds: defaultSelectParamsValue(searchParams, 'clientIds', clients,),
        }
    })

    const watchExecutionDateFrom = watch('executionDateFrom')
    const watchExecutionDateTo = watch('executionDateTo')
    const watchSerialNumber = watch('serialNumber');
    const watchTotalSumFrom = watch('totalSumFrom');
    const watchSumTo = watch('totalSumTo');
    const watchArticle = watch('articulName');
    const watchSize = watch('sizeLineIds');
    const watchColor = watch('colorIds');
    const watchEmployee = watch('employeeIds');
    const watchClients = watch('clientIds');

    useEffect(() => {
        setOrDeleteSearchParams(searchParams, watchSerialNumber, 'serialNumber')
        setOrDeleteSearchParams(searchParams, watchTotalSumFrom, 'totalSumFrom')
        setOrDeleteSearchParams(searchParams, watchSumTo, 'totalSumTo')
        setOrDeleteSearchParams(searchParams, watchArticle, 'articulName')

        setCalendarSearchParams(searchParams, watchExecutionDateFrom, 'executionDateFrom')
        setCalendarSearchParams(searchParams, watchExecutionDateTo, 'executionDateTo')

        setOrDeleteSearchParamsArr(searchParams, watchSize, 'sizeLineIds')
        setOrDeleteSearchParamsArr(searchParams, watchColor, 'colorIds')
        setOrDeleteSearchParamsArr(searchParams, watchEmployee, 'employeeIds', 'employeeId')
        setOrDeleteSearchParamsArr(searchParams, watchClients, 'clientIds')

        setSearchParams(searchParams)
    }, [
        watchSerialNumber, watchTotalSumFrom, watchSumTo, watchArticle, watchSize, watchColor, watchEmployee,
        watchClients, watchExecutionDateFrom, watchExecutionDateTo
    ])

    const clearFilters = () => {
        navigate('/store/sales-history?page=1&pageSize=25')
        reset({
            serialNumber: '',
            totalSumFrom: '',
            totalSumTo: '',
            employeeIds: [],
            clientIds: [],
            articulName: '',
            sizeLineIds: [],
            colorIds: [],
            executionDateFrom: '',
            executionDateTo: '',
        })
    }

    return (
        <>
            <InputNumeric
                isDebounce
                control={control}
                name='serialNumber'
                isSmall
                isBorder={false}
                icon='krista-search'
                placeholder='№ продажи'
            />
            <Input
                register={register}
                name='articulName'
                isDebounce
                isSmall
                isBorder={false}
                icon='krista-search'
                placeholder='Артикул'
            />
            <Calendar
                control={control}
                name='executionDateFrom'
                setValue={setValue}
                isSmall
                isBorder={false}
                isTimeFormat
                isDebounce
                placeholder='Дата и время, от'
            />
            <InputNumeric
                isDebounce
                control={control}
                name='totalSumFrom'
                isSmall
                isBorder={false}
                placeholder='На сумму, от'
            />
            <SelectParams
                register={register}
                control={control}
                values={employees}
                setValue={setValue}
                isSmall
                isBorder={false}
                isMulti
                hasNotDropdownIndicator

                name='employeeIds'
                url="employeeIds"
                chosenValues={watch('employeeIds')}
                placeholderText='Менеджер'
                placeholder='Менеджер'
                placeholderInner='Поиск'
                getOptionLabel='employeeName'
                getOptionValue='employeeId'
                watch={watch}
            />
            <SelectParams
                register={register}
                control={control}
                values={sizeLine}
                setValue={setValue}
                isSmall
                isBorder={false}
                isMulti
                hasNotDropdownIndicator

                name='sizeLineIds'
                chosenValues={watch('sizeLineIds')}
                url="sizeIds"
                placeholderText='Размер'
                placeholder='Размер'
                placeholderInner='Поиск'
                getOptionLabel='name'
                getOptionValue='id'
                watch={watch}
            />
            <Calendar
                control={control}
                name='executionDateTo'
                setValue={setValue}
                isSmall
                isBorder={false}
                isTimeFormat
                isDebounce
                placeholder='На сумму, до'
            />
            <InputNumeric
                isDebounce
                control={control}
                name='totalSumTo'
                isSmall
                isBorder={false}
                placeholder='На сумму, до'
            />
            <SelectParams
                register={register}
                control={control}
                values={sizeLine}
                setValue={setValue}
                isSmall
                isMulti
                watch={watch}
                hasNotDropdownIndicator
                isBorder={false}

                name='clientIds'
                url="clientIds"
                chosenValues={watch('clientIds')}
                placeholderText='Клиент'
                placeholder='Клиент'
                placeholderInner='Поиск'
                getOptionLabel='name'
                getOptionValue='id'
            />
            <SelectParams
                register={register}
                control={control}
                values={sizeLine}
                setValue={setValue}
                watch={watch}
                isSmall
                isBorder={false}
                hasNotDropdownIndicator

                name='colorIds'
                url="colorIds"
                chosenValues={watch('colorIds')}
                placeholderText='Цвет'
                placeholder='Цвет'
                placeholderInner='Поиск'
                isMulti
                getOptionLabel='name'
                getOptionValue='id'
            />
            <div/>
            <Button
                onClick={clearFilters}
                variant='isMainBorder'
                buttonText='Сбросить все'
                isSmall
            />
        </>
    );
}

export default NavFiltersSalesHistory;