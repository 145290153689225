import {useSelector} from "react-redux";

import Row from "@components/Table/TableDefault/TableClient/Row/Row";
import HeadRowSort from "@components/Table/components/HeadRowSort/HeadRowSort";

import HeadRow from "@components/Table/components/HeadRow";
import GridLayout from "@components/Layout/GridLayout";
import Table from "@components/Table/components/Wrapper";
import HeadRowItem from "@components/Table/components/HeadRowItem/HeadRowItem";
import Checkbox from "@components/Form/Checkbox";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";

function TableClient() {
    const clients = useSelector(state => state.clients.clientsState?.items)

    return (
        <Table overflow='visible'>
            <GridLayout gap='10px' position='relative'>
                <HeadRow isTopOffset>
                    <GridLayout template='40px 24px auto 9% 11% 15% 13% 8% 84px' align='center'>
                        <HeadRowItem isAction>
                            <Checkbox/>
                        </HeadRowItem>
                        <HeadRowItem/>
                        {/*//TODO: из-за того что у имени есть before, checkbox занимает много места*/}
                        <HeadRowSort title='Имя' sortValue='fullname'/>
                        <HeadRowSort title='Корзина' sortValue='cartItemsCount'/>
                        <HeadRowSort title='Необработанное' sortValue='unprocessedOrderItemsCount'/>
                        <HeadRowSort title='Заказы в работе' sortValue='processedOrderItemsCount'/>
                        <HeadRowSort title='Резервы' sortValue='reservesItemsCount'/>
                        <HeadRowSort title='Долг' sortValue='debtAmount'/>
                        <HeadRowItem>
                            <Text value='Действие' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                    </GridLayout>
                </HeadRow>

                {clients.map(item => <Row id={item.id} item={item} key={item.id}/>)}
            </GridLayout>
        </Table>
    );
}



export default TableClient;