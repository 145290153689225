import React from "react";
import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";
import Icon from "@components/Icon/Icon";
import Button from "@components/Form/Button";
import ClientHeader from "@components/Table/TableDefault/TableClient/RowDropdown/components/ClientHeader/ClientHeader";
import GridLayout from "@components/Layout/GridLayout";
import Wrapper from "@components/Table/TableDefault/TableClient/RowDropdown/components/Wrapper/Wrapper";
import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";

function ClientLinks() {
    return (
        <Wrapper>
            <ClientHeader backgroundColor={COLORS.LIGHTGRAY2}>
                <FlexLayout align='center' spaceBetween>
                    <Text
                        value='Страница'
                        font={FONTS.SEMI_BOLD_16}
                        color={COLORS.GRAY}
                    />
                    <Text
                        value='Действие'
                        font={FONTS.SEMI_BOLD_16}
                        color={COLORS.GRAY}
                    />
                </FlexLayout>
            </ClientHeader>
            <GridLayout padding='8px 12px'>
                <Item>
                    <GridLayout template='136px auto' align='center' padding='8px 0'>
                        <FlexLayout align='center'>
                            <Icon
                                iconName='krista-home'
                                iconWidth={24}
                                iconHeight={24}
                                iconColor={COLORS.LIGHTGRAY}
                            />
                            <Text
                                value='Главная'
                                font={FONTS.REGULAR_14}
                                color={COLORS.BLACK}
                                margin='0 0 0 8px'
                            />
                        </FlexLayout>
                        <GridLayout template='auto auto' gap='16px' margin='0 0 0 auto'>
                            <Button
                                iconName='krista-copy'
                                isMinimal
                                variant='isMainV3'
                            />
                            <Button
                                iconName='krista-external-link'
                                isMinimal
                                variant='isMainV3'
                            />
                        </GridLayout>
                    </GridLayout>
                </Item>
                <Item>
                    <GridLayout template='136px auto' align='center' padding='8px 0'>
                        <FlexLayout align='center'>
                            <Icon
                                iconName='krista-cart'
                                iconWidth={24}
                                iconHeight={24}
                                iconColor={COLORS.LIGHTGRAY}
                            />
                            <Text
                                value='Каталог'
                                font={FONTS.REGULAR_14}
                                color={COLORS.BLACK}
                                margin='0 0 0 8px'
                            />
                        </FlexLayout>
                        <GridLayout template='auto auto' gap='16px' margin='0 0 0 auto'>
                            <Button
                                iconName='krista-copy'
                                isMinimal
                                variant='isMainV3'
                            />
                            <Button
                                iconName='krista-external-link'
                                isMinimal
                                variant='isMainV3'
                            />
                        </GridLayout>
                    </GridLayout>
                </Item>
                <Item>
                    <GridLayout template='136px auto' align='center' padding='8px 0'>
                        <FlexLayout align='center'>
                            <Icon
                                iconName='krista-user'
                                iconWidth={24}
                                iconHeight={24}
                                iconColor={COLORS.LIGHTGRAY}
                            />
                            <Text
                                value='Профиль'
                                font={FONTS.REGULAR_14}
                                color={COLORS.BLACK}
                                margin='0 0 0 8px'
                            />
                        </FlexLayout>
                        <GridLayout template='auto auto' gap='16px' margin='0 0 0 auto'>
                            <Button
                                iconName='krista-copy'
                                isMinimal
                                variant='isMainV3'
                            />
                            <Button
                                iconName='krista-external-link'
                                isMinimal
                                variant='isMainV3'
                            />
                        </GridLayout>
                    </GridLayout>
                </Item>
            </GridLayout>
            <GridLayout padding='0 12px'>
                <Button
                    variant='isDangerBorder'
                    buttonText='Отключить ссылки'
                    ariaLabel='Отключить ссылки'
                    isResponsive
                    isSmall
                />
            </GridLayout>
        </Wrapper>
    );
}

const IconWrapper = styled.div`
  cursor: pointer;
  user-select: none;
`

const Item = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.LIGHTGRAY1};
  }
`

export default ClientLinks;