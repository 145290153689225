import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";

function Label({name, label, margin, color}) {
    return (
        <Content htmlFor={name} margin={margin} color={color}>{label}</Content>
    );
}

const Content = styled.label`
  ${({margin}) => margin ? `margin: ${margin}` : 'margin: 0 0 6px 0'};
  ${({color}) => color ? `color: ${color}` : `color: ${COLORS.LIGHTGRAY}`};
  display: block;
  font: ${FONTS.REGULAR_12};
`

export default Label;