import {SET_SIDEBAR} from "@types/types"

const initState = {
    sidebarState: null,
}

const sidebarReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_SIDEBAR :
            return {...state, sidebarState: action.payload}
        default:
            return state
    }
}

export default sidebarReducer