import ApiConfig from "@/ApiConfigStore";
import store from "@store";
import {SET_WAREHOUSE_PRODUCTS} from "@types/types";

export const getWarehouseProducts = (searchParams) => {
    ApiConfig.get(`warehouse/products?${searchParams}`)
        .then(({data}) => {
            store.dispatch({type: SET_WAREHOUSE_PRODUCTS, payload: data})
        })
};

export const getWarehouse = async (searchParams) => {
    return await ApiConfig.get(`warehouse/documents?${searchParams}`)
};
