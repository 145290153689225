import GridLayout from "@components/Layout/GridLayout";
import TechnologistItem from "@pages/ProductionPages/ModelDevelopmentPage/TechnologistPage/components/TechnologistItem";
import {useFieldArray, useForm} from "react-hook-form";
import FileMaterial from "@pages/ProductionPages/ModelDevelopmentPage/components/FileMaterial";
import Button from "@components/Form/Button";

function TechnologistPage() {
    const {register, control, watch, setValue} = useForm({
        name: 'technologist',
        defaultValues: {
            tailoringBrigade: {
                shop: '1',
                cuttingTable: '2'
            },
            sewingBrigade: {
                shop: '1',
                seamstress: '2'
            },
            graphis: '',
            layouts: [
                {name: ''},
                {name: ''},
            ],
            sizeInWork: {
                sizeId: 0,
                prioritySizeId: 1
            }
        }
    })

    const {fields, update} = useFieldArray({
        control,
        name: 'layouts'
    })

    const sizeInWorkData = [
        {
            id: 1,
            value: "38"
        },
        {
            id: 2,
            value: "40"
        }
    ]

    const sizePrioritySelected = watch('sizeInWork.prioritySizeId')
    return (
        <GridLayout template='412px 1fr' gap='20px' align='flex-start'>
            <FileMaterial
                readOnly
                sizeInWorkData={sizeInWorkData}
                sizePrioritySelected={sizePrioritySelected}
                watch={watch}
                register={register}
                setValue={setValue}
                fields={fields}
                update={update}
                isPriortySizeEditable
            />
            <GridLayout gap='20px'>
                <GridLayout gap='20px' template='1fr 1fr'>
                    <TechnologistItem
                        title='Бригада покроя'
                        control={control}
                        register={register}
                        firstSelectName='tailoringBrigade.shop'
                        firstSelectLabel='Цех'
                        secondSelectName='tailoringBrigade.cuttingTable'
                        secondSelectLabel='Кроильный стол'
                        firstSelectValues={[]}
                        secondSelectValues={[]}
                    />
                    <TechnologistItem
                        title='Бригада покроя'
                        control={control}
                        register={register}
                        firstSelectName='sewingBrigade.shop'
                        firstSelectLabel='Цех'
                        secondSelectName='sewingBrigade.seamstress'
                        secondSelectLabel='Швея'
                        firstSelectValues={[]}
                        secondSelectValues={[]}
                    />
                </GridLayout>
                <Button
                    variant='isMain'
                    buttonText='Подтвердить'
                    ariaLabel='Подтвердить'
                    isResponsive
                />
            </GridLayout>
        </GridLayout>
    );
}

export default TechnologistPage;