import React from 'react';
import {COLORS, FONTS} from "@/constans";
import hexToRgba from "hex-to-rgba";
import styled from "styled-components";

import Icon from "@components/Icon/Icon";
import Box from "@components/Box";
import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";
import Button from "@components/Form/Button";

function CardOrderProfileInfo() {
    return (
        <Box>
            <FlexLayout spaceBetween align='center' margin='0 0 6px 0'>
                <div>
                    <Text
                        value='Стержиченко Лариса'
                        font={FONTS.SEMI_BOLD_16}
                        color={COLORS.MAIN}
                        margin='0 0 2px 0'
                    />
                    <FlexLayout>
                        <Text
                            value='г. Санкт-Петербург •'
                            font={FONTS.SEMI_BOLD_14}
                            color={COLORS.LIGHTGRAY}
                        />
                        <Text
                            value='ул. Емельяненко 210'
                            font={FONTS.REGULAR_14}
                            color={COLORS.LIGHTGRAY}
                            margin='0 0 0 4px'
                        />
                    </FlexLayout>
                </div>
                <IconWrapper>
                    <Icon
                        iconName='krista-user'
                        iconWidth={24}
                        iconHeight={24}
                    />
                </IconWrapper>
            </FlexLayout>
            <List>
                <FlexLayout>
                    <Text
                        value='Магазин:'
                        font={FONTS.MEDIUM_14}
                        color={COLORS.DARKGRAY}
                    />
                    <Text
                        value='Top Bridal'
                        font={FONTS.REGULAR_14}
                        color={COLORS.DARKGRAY}
                        margin='0 0 0 4px'
                    />
                </FlexLayout>
                <FlexLayout>
                    <Text
                        value='Контакты:'
                        font={FONTS.MEDIUM_14}
                        color={COLORS.DARKGRAY}
                    />
                    <Text
                        value='+7 (495) 725-55-66, strij.larisa@topbridal.com'
                        font={FONTS.REGULAR_14}
                        color={COLORS.DARKGRAY}
                        margin='0 0 0 4px'
                    />
                </FlexLayout>
                <FlexLayout>
                    <Text
                        value='Менеджер:'
                        font={FONTS.MEDIUM_14}
                        color={COLORS.DARKGRAY}
                    />
                    <Text
                        value='Алина'
                        font={FONTS.REGULAR_14}
                        color={COLORS.DARKGRAY}
                        margin='0 0 0 4px'
                    />
                </FlexLayout>
            </List>
            <FlexLayout margin='0 0 12px 0'>
                <Text
                    value='Долг по кассе:'
                    font={FONTS.REGULAR_16}
                    color={COLORS.DARKGRAY}
                />
                <Text
                    value='12 070.50 $'
                    font={FONTS.MEDIUM_16}
                    color={COLORS.MAIN}
                    margin='0 0 0 4px'
                />
            </FlexLayout>
            <Button
                variant='isSuccess'
                buttonText={`Баланс: 10.50 $`}
                isResponsive
            />
        </Box>
    );
}

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: ${hexToRgba(COLORS.LIGHTGRAY, 0.1)};
  border-radius: 10px;
  color: ${COLORS.LIGHTGRAY};
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 4px 0;
`

export default CardOrderProfileInfo;