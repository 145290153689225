import {SET_MATERIALS} from "@types/types";


const initState = {
    materialsState: null
}

const materialsReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_MATERIALS :
            return {...state, materialsState: action.payload}
        default:
            return state
    }
}

export default materialsReducer