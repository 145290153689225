import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text";
import Box from "@components/Box";
import Line from "@components/Line";
import {NumericFormat} from "react-number-format";
import React, {useState} from "react";
import AcceptedPaymentsItem from "@pages/StorePages/SalesPage/components/AcceptedPayments/AcceptedPaymentsItem";
import {getCurrencyScaleLength} from "@util/decimalHelper";

function AcceptedPayments({acceptedPayment, setAcceptedPayment, invoice, isCompleted}) {
    const [isOpen, setIsOpen] = useState(false);

    const template = '2fr 1.8fr 1.5fr 1.5fr'
    
    let acceptedTotals = acceptedPayment?.acceptedPaymentsTotals

    return (
        <>
            {!isCompleted && acceptedPayment?.availablePaymentsToAccept.find(item => item.isMainCurrency) && (
                <AcceptedPaymentsItem
                    isMainCurrency
                    item={acceptedPayment?.availablePaymentsToAccept.find(item => item.isMainCurrency)}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    setAcceptedPayment={setAcceptedPayment}
                    paymentsToAccept={acceptedPayment?.availablePaymentsToAccept}
                />
            )}

            {!isCompleted && acceptedPayment?.availablePaymentsToAccept
                ?.filter((filterItem) => !filterItem.isMainCurrency)
                .map((item, index) => {
                return (
                    <AcceptedPaymentsItem
                        invoice={invoice}
                        key={item.currencyId + 'availablePaymentsToAccept'}
                        item={item}
                        index={index}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        setAcceptedPayment={setAcceptedPayment}
                        paymentsToAccept={acceptedPayment?.availablePaymentsToAccept}
                    />
                )
            })}

            {acceptedPayment?.acceptedPaymentsTotals && (
                <>
                    <Box background={COLORS.LIGHTGRAY3}>
                        <GridLayout gap='12px'>
                            <GridLayout gap='12px' template={template}>
                                <Text value='Итоги прихода' font={FONTS.MEDIUM_14} color={COLORS.LIGHTGRAY}/>
                                <Text value='В валюте' font={FONTS.MEDIUM_14} color={COLORS.LIGHTGRAY}/>
                                <Text value='Курс' font={FONTS.MEDIUM_14} color={COLORS.LIGHTGRAY}/>
                                <Text value={`В ${invoice?.currencyCode}`} font={FONTS.MEDIUM_14} color={COLORS.LIGHTGRAY}/>
                            </GridLayout>

                            <Line/>

                            {acceptedTotals.acceptedPayments?.map(item => {
                                return (
                                    <React.Fragment key={item.currencyId}>
                                        <GridLayout gap='8px'>
                                            <GridLayout
                                                gap='12px'
                                                template={template}
                                                key={item.currencyId + 'acceptedPaymentsTotals'}
                                            >
                                                <Text
                                                    value={`Приход в ${item.currencyCode}`}
                                                    font={FONTS.REGULAR_14}
                                                    color={COLORS.DARKGRAY}
                                                />
                                                <NumericFormat
                                                    value={item.isMainCurrency ? '-' : item.paymentSum}
                                                    thousandSeparator=" "
                                                    decimalScale={getCurrencyScaleLength(item.paymentSum)}
                                                    fixedDecimalScale
                                                    displayType="text"
                                                    renderText={(value) => (
                                                        <Text
                                                            value={value}
                                                            font={FONTS.REGULAR_14}
                                                            color={COLORS.DARKGRAY}
                                                        />
                                                    )}
                                                    suffix={` ${item.currencyCode}`}
                                                />
                                                <NumericFormat
                                                    value={item.isMainCurrency ? '-' : item.exchangeRate}
                                                    thousandSeparator=" "
                                                    decimalScale={getCurrencyScaleLength(item.exchangeRate)}
                                                    fixedDecimalScale
                                                    displayType="text"
                                                    renderText={(value) => (
                                                        <Text
                                                            value={value}
                                                            font={FONTS.REGULAR_14}
                                                            color={COLORS.DARKGRAY}
                                                        />
                                                    )}
                                                    suffix={` ${invoice?.currencySymbol}`}
                                                />
                                                <NumericFormat
                                                    value={item.exchangedSum}
                                                    thousandSeparator=" "
                                                    decimalScale={getCurrencyScaleLength(item.exchangedSum)}
                                                    fixedDecimalScale
                                                    displayType="text"
                                                    renderText={(value) => (
                                                        <Text
                                                            value={value}
                                                            font={FONTS.REGULAR_14}
                                                            color={COLORS.DARKGRAY}
                                                        />
                                                    )}
                                                    suffix={` ${invoice.currencySymbol}`}
                                                />
                                            </GridLayout>
                                        </GridLayout>

                                        <Line/>
                                    </React.Fragment>
                                )
                            })}

                            <GridLayout gap='12px' template={template}>
                                <Text value='Итого приход' font={FONTS.MEDIUM_14} color={COLORS.DARKGRAY}/>
                                <div/>
                                <div/>
                                <NumericFormat
                                    value={acceptedTotals.totalSumAccepted}
                                    thousandSeparator=" "
                                    decimalScale={getCurrencyScaleLength(acceptedTotals.totalSumAccepted)}
                                    fixedDecimalScale
                                    displayType="text"
                                    renderText={(value) => (
                                        <Text value={value} font={FONTS.MEDIUM_14} color={COLORS.DARKGRAY}/>
                                    )}
                                    suffix={` ${invoice?.currencySymbol}`}
                                />
                            </GridLayout>
                        </GridLayout>
                    </Box>

                    <Box background={COLORS.LIGHTGRAY3} padding='12px 16px'>
                        <GridLayout gap='12px' template={template}>
                            <Text value='Сдача' font={FONTS.SEMI_BOLD_14} color={COLORS.DARKGRAY}/>
                            <div/>
                            <div/>
                            <NumericFormat
                                value={acceptedTotals.change > 0 ? acceptedTotals.change : 0.00}
                                decimalScale={acceptedTotals.change > 0 ? getCurrencyScaleLength(acceptedTotals.change) : 2}
                                fixedDecimalScale
                                thousandSeparator=" "
                                displayType="text"
                                renderText={(value) => (
                                    <Text value={value} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                                )}
                                suffix={` ${invoice?.currencySymbol}`}
                            />
                        </GridLayout>
                    </Box>
                </>
            )}
        </>
    )
}

export default AcceptedPayments;