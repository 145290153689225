import ApiConfig from "@/ApiConfigStore";

const path = 'documents/movement/'

export const documentsCreate = async () => {
    return await ApiConfig.post(`${path}create/toMainWarehouse`)
};

export const documentsScan = async (data) => {
    return await ApiConfig.post(`${path}product/scan`, data)
};

export const documentsScanCancel = async (data) => {
    return await ApiConfig.post(`${path}product/scan/cancel`, data)
};

export const getDocuments = async (documentId) => {
    return await ApiConfig.get(`${path}${documentId}`)
};

export const documentsDelete = async (documentId) => {
    return await ApiConfig.delete(`${path}${documentId}/delete`)
};

export const documentsFinish = async (documentId) => {
    return await ApiConfig.post(`${path}${documentId}/finish`)
};

export const documentsProductDelete = async (documentId, productId) => {
    return await ApiConfig.delete(`${path}${documentId}/product/delete/${productId}`)
};


