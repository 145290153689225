import React from 'react';
import StoreCard from "@pages/StorePages/components/StoreCard";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";
import dayjs from "dayjs";
import {NumericFormat} from "react-number-format";

function StoreHeadCompleted({invoice}) {
    return (
        <GridLayout gap='20px' template='repeat(4, 1fr)'>
            <StoreCard
                title='Дата и время'
                subtitle={
                    <Text
                        value={dayjs(invoice?.executionDate).format('DD.MM.YYYY, hh:mm')}
                        font={FONTS.REGULAR_14}
                        color={COLORS.BLACK}
                    />
                }
                iconName='krista-calendar'
                color={COLORS.STATUS_GREEN}
            />
            <StoreCard
                title='Менеджер'
                subtitle={
                    <Text
                        value={invoice?.employeeName}
                        font={FONTS.REGULAR_14}
                        color={COLORS.BLACK}
                    />
                }
                iconName='krista-manager'
                color={COLORS.MAIN_BLUE}
            />
            <StoreCard
                title='Клиент'
                subtitle={
                    <Text
                        value={invoice?.clientFullName ? invoice?.clientFullName : '-'}
                        font={FONTS.REGULAR_14}
                        color={invoice?.clientFullName ? COLORS.MAIN : COLORS.BLACK}
                    />
                }
                iconName='krista-client'
                color={COLORS.PURPLE}
            />
            <StoreCard
                title='Скидка'
                subtitle={
                    <NumericFormat
                        value={invoice?.discountPercent}
                        decimalScale={2}
                        fixedDecimalScale
                        displayType="text"
                        renderText={(value) => (
                            <Text
                                value={invoice?.discountPercent ? `- ${value}` : '-'}
                                font={FONTS.REGULAR_14}
                                color={invoice?.discountPercent ? COLORS.RED : COLORS.BLACK}
                            />
                        )
                        }
                        suffix={`%`}
                    />
                }
                iconName='krista-percent'
                color={COLORS.RED}
            />
        </GridLayout>
    );
}

export default StoreHeadCompleted;