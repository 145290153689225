import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";

import Select from "@components/Form/Selects/Select";
import Calendar from "@components/Form/Inputs/Calendar";
import Checkbox from "@components/Form/Checkbox";
import InputDebounce from "@components/Form/Inputs/InputDebounce";
import {hasCart} from "@components/NavFilters/NavFiltersClients/data";

import Button from "@components/Form/Button";
import SelectParams from "@components/Form/Selects/SelectParams";
import GridLayout from "@components/Layout/GridLayout";
import styled from "styled-components";

function NavFiltersForms({register, control, reset, setValue, watch}) {
    const navigate = useNavigate()

    const citiesState = useSelector(state => state.cities.citiesState)
    const catalogsState = useSelector(state => state.catalogs.catalogsState)

    const [searchParams] = useSearchParams()

    const citiesSort = useMemo(() => {
        const cityIds = searchParams.get('cityIds')?.split(',')
        if (!cityIds) return

        return citiesState.sort((a, b) => {
            const byChosenAsc = cityIds.includes(String(b.id)) - cityIds.includes(String(a.id))
            return byChosenAsc || a.name.localeCompare(b.name)
        })
    }, [citiesState, searchParams])

    const clearFilter = () => {
        navigate('/client?sort=asc.fullname&page=1&pageSize=25')
        reset({
            fullname: '',
            phone: '',
            cities: [],
            isOnline: '',
            hasCart: null,
            catalogTypes: [],
            hasReserve: '',
            hasDebt: '',
            registrationDates: '',
            onlineDates: '',
        })
    }

    return (
        <GridLayout template='repeat(4, 1fr)' gap='20px' margin='0 0 14px 0' zIndex='3'>
            <InputDebounce
                minLength={2}
                debounceTimeout={300}
                control={control}
                name='fullname'
                isSmall
                isBorder={false}
                icon='krista-search'
                placeholder='Искать по именам'
            />
            <InputDebounce
                minLength={2}
                debounceTimeout={300}
                control={control}
                name='phone'
                isSmall
                isBorder={false}
                icon='krista-search'
                placeholder='Искать по телефонам'
            />
            <SelectParams
                register={register}
                setValue={setValue}
                name="cities"
                url="cityIds"
                control={control}
                values={!searchParams.get('cityIds') ? citiesState : citiesSort}
                chosenValues={watch('cities')}
                placeholder='Выбрать город'
                placeholderInner='Поиск по городам'
                isSmall
                isMulti
                getOptionLabel='name'
                getOptionValue='id'
                getOptionKey='id'
                watch={watch}
                hasNotDropdownIndicator
            />
            <Calendar
                name="registrationDates"
                control={control}
                setValue={setValue}
                placeholder='Зарегистрирован в период'
                isSmall
                isBorder={false}
                isRange
            />
            <Calendar
                name="onlineDates"
                control={control}
                setValue={setValue}
                placeholder='Был в сети в период'
                isSmall
                isBorder={false}
                isRange
            />
            <Select
                setValue={setValue}
                name="hasCart"
                register={register}
                control={control}
                isSmall
                values={hasCart}
                placeholderText='Статус корзины'
                getOptionLabel='value'
                getOptionValue='key'
                getOptionObjectKey='key'
            />
            {/*//TODO: Пофиксить когда придут каталоги с бэка*/}
            <Checkbox
                register={register}
                name="isOnline"
                title="Пользователи в сети"
                hasBorder
            />
            {/*//TODO: Здесь будут новые пользователи*/}
            <Button
                buttonText='Сбросить все'
                variant='isMainBorder'
                ariaLabel='Сбросить все'
                isSmall
                onClick={clearFilter}
            />
        </GridLayout>
    );
}

const EmptyItem = styled.div`
  grid-column: 1 span;
`
export default NavFiltersForms;