import {useSearchParams} from "react-router-dom";
import useOutside from "@hooks/useOutside";
import SelectLayout from "@components/Form/Selects/SelectLayout";
import SelectInput from "@components/Form/Selects/SelectInput";
import styled from "styled-components";
import FlexLayout from "@components/Layout/FlexLayout";
import Icon from "@components/Icon";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";
import ControlBox from "@components/Form/Inputs/components/ControlBox";

const SelectParams = (props) => {
    const {errors, name, control, values, chosenValues, setValue, register, watch} = props
    const {url, placeholder, placeholderInner, label} = props
    const {isSmall, isMulti, isBorder} = props
    const {getOptionValue, getOptionLabel, getOptionObjectKey} = props

    let [searchParams, setSearchParams] = useSearchParams()
    const {ref, isShow, setIsShow} = useOutside(false)

    const clearInput = () => {
        searchParams.delete(url)
        setSearchParams(searchParams)
        setValue(name, [])
    }

    const formatOptionLabel = (option) => {
        return (
            <FlexLayout align='center'>
                <Uncheck>
                    <Icon
                        iconName='krista-square-uncheck'
                        iconWidth='24px'
                        iconHeight='24px'
                        iconColor={COLORS.DARKGRAY}
                    />
                </Uncheck>
                <Check>
                    <Icon
                        iconName='krista-square-check'
                        iconWidth='24px'
                        iconHeight='24px'
                        iconColor={COLORS.SALAD}
                    />
                </Check>

                <Text value={getOptionLabel ? option[getOptionLabel] : option.name} font={FONTS.REGULAR_14} color={COLORS.BLACK} margin='0 0 0 4px'/>
            </FlexLayout>
        );
    }

    return (
        <ControlBox isInvalid={errors} isBorder={isBorder} isSmall={isSmall}>
            <ControlSelectMulti ref={ref} isInvalid={errors}>
                <SelectInput
                    isSmall={isSmall}
                    selectedValues={chosenValues.length > 0 ? chosenValues?.map(item => item[getOptionLabel]).join(", ") : []}
                    setIsShow={() => setIsShow(!isShow)}
                    isShow={isShow}
                    clearInput={clearInput}
                    placeholder={placeholder}
                />
                {isShow &&
                    <SelectInner>
                        <SelectLayout
                            formatOptionLabel={formatOptionLabel}
                            placeholder={placeholderInner}
                            values={values}
                            name={name}
                            register={register}
                            control={control}
                            label={label}
                            isMulti={isMulti}
                            customIndicator='krista-search'
                            hasClearIndicator={false}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            controlShouldRenderValue={false}
                            isClearable={false}
                            hasDropdownIndicator={false}
                            setValue={setValue}
                            getOptionLabel={getOptionLabel}
                            getOptionValue={getOptionValue}
                            getOptionObjectKey={getOptionObjectKey}
                            isSmall
                            menuIsOpen
                        />
                    </SelectInner>
                }
            </ControlSelectMulti>
            {errors && <Errors aria-label={`Ошибка в поле ${label}`}>{errors}</Errors>}
        </ControlBox>
    );
}

const Errors = styled.div`
  color: ${COLORS.RED};
  position: absolute;
  top: calc(100% + 2px);
  font: ${FONTS.REGULAR_12};
`

const Uncheck = styled.div`
  display: block
`

const Check = styled.div`
  display: none
`

const SelectInner = styled.div`
  position: absolute;
  top: 48px;
  width: 100%;
  z-index: 3;

  .controlSelectPrefix__ {
    &option--is-selected {
      background-color: ${COLORS.WHITE};
      color: ${COLORS.WHITE};

      ${Check} {
        display: block;
      }

      ${Uncheck} {
        display: none;
      }

      &:hover {
        background-color: ${COLORS.LIGHTGRAY2};
        color: ${COLORS.LIGHTGRAY2};
      }
    }
  }
`

const ControlSelectMulti = styled.div`
  .controlSelect {
    position: absolute;
    top: calc(100% + 0);
    left: 0;
    right: 0;
    z-index: 3;

    [class$="-control"] {
      border-radius: 10px 10px 0 0;
      border: 1px solid ${COLORS.LIGHTGRAY};
      background-color: ${COLORS.WHITE};
    }

    .controlSelectPrefix__ {
      &value-container {
        padding: 0 14px;
      }

      &option--is-focused {
        background: none;
      }

      //is selected
      &option--is-selected {
        background-color: ${COLORS.WHITE};
        color: ${COLORS.WHITE};

        &:hover {
          background-color: ${COLORS.LIGHTGRAY2};
          color: ${COLORS.WHITE};
        }
      }

      &menu {
        border: 1px solid ${COLORS.LIGHTGRAY};
        margin-top: 0;
        border-radius: 0 0 10px 10px;
        border-top: 0;
        font: ${FONTS.REGULAR_14};
        color: ${COLORS.DARKGRAY};
        box-shadow: none;
      }
    }
  }

  //hover option
  .customOption {
    &:hover {
      background-color: ${COLORS.LIGHTGRAY2};
      color: ${COLORS.BLACK};
      cursor: pointer;
    }
  }
`


export default SelectParams;