import styled from "styled-components";

function Table({children, position, margin, padding, border, borderRadius, borderTop, backgroundColor, overflow, ariaLabel}) {
    return (
        <TableContent
            position={position}
            margin={margin}
            padding={padding}
            border={border}
            borderTop={borderTop}
            backgroundColor={backgroundColor}
            borderRadius={borderRadius}
            overflow={overflow}
            aria-label={ariaLabel}
        >
            {children}
        </TableContent>
    );
}

const TableContent = styled.div`
  position: ${({position}) => position ? position : 'relative'};
  margin : ${({margin}) => margin && margin};
  padding : ${({padding}) => padding && padding};
  border : ${({border}) => border && border};
  border-radius : ${({borderRadius}) => borderRadius && borderRadius};
  overflow: ${({overflow}) => overflow ? overflow : 'hidden'};
  border-top : ${({borderTop}) => borderTop && borderTop};
  background-color: ${({backgroundColor}) => backgroundColor && backgroundColor};
  border-radius : ${({backgroundColor}) => backgroundColor && backgroundColor};
`

export default Table;