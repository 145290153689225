import React from 'react';
import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";

import Icon from "@components/Icon/Icon";
import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";
import Box from "@components/Box";
import hexToRgba from "hex-to-rgba";

function CardOrderComment() {
    return (
        <Box>
            <FlexLayout align='center' spaceBetween margin='0 0 12px 0'>
                <Text
                    value='Комментарий к заказу'
                    font={FONTS.SEMI_BOLD_16}
                    color={COLORS.BLACK}
                />
                <IconWrapper>
                    <Icon
                        iconName='krista-comment'
                        iconHeight={24}
                        iconWidth={24}
                    />
                </IconWrapper>
            </FlexLayout>
            <Inner>
                <Text
                    value='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aut consequuntur eligendi in incidunt ipsa labore maxime, natus nulla qui ratione tempora totam unde ut voluptas. Facere itaque nemo possimus provident ratione. A, impedit laborum mollitia neque odit officia omnis placeat, possimus quis quisquam repellat rerum velit vero. Ab accusantium atque ea eaque id nam pariatur quidem? Accusamus animi mollitia placeat sed. Aspernatur ipsam modi quas soluta ullam! Aperiam asperiores eum ipsa mollitia, qui quibusdam sit! Deserunt harum libero molestiae nemo quidem rerum, tenetur. Accusamus alias animi consequatur dicta ducimus, enim harum illo laborum libero numquam, perferendis quasi quod ratione repudiandae rerum tempora unde vitae? Adipisci aliquam consectetur corporis cumque delectus dolores doloribus laudantium maiores maxime pariatur quae rerum ullam, velit veritatis voluptates! Animi, cum cumque ea eligendi est iusto minima obcaecati possimus sapiente sed similique, tempore!'
                    font={FONTS.REGULAR_14}
                    color={COLORS.DARKGRAY}
                />
            </Inner>
        </Box>
    );
}

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: ${hexToRgba(COLORS.LIGHTGRAY, 0.1)};
  background: rgba(${COLORS.LIGHTGRAY}, 0.1);
  border-radius: 10px;
  color: ${COLORS.LIGHTGRAY};
`

const Inner = styled.div`
  max-height: 130px;
  overflow: auto;
`

export default CardOrderComment;