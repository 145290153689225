import OrderChangesHistory from "@components/Order/OrderChangesHistory/OrderChangesHistory";
import Button from "@components/Form/Button";
import GridLayout from "@components/Layout/GridLayout";

function OrderChangesPage() {
    return (
        <>
            <div>
                <Button
                    variant='isMainBorder'
                    buttonText='Исходный заказ'
                    iconName='krista-document'
                    ariaLabel='Исходный заказ'
                    isSmall
                />
            </div>
            <GridLayout gap='24px' margin='24px 0 0 0'>
                <OrderChangesHistory
                    title='Добавлена модель'
                />
                <OrderChangesHistory
                    title='Изменено количество'
                />
                <OrderChangesHistory
                    title='Удалена модель'
                />
            </GridLayout>
        </>
    );
}

export default OrderChangesPage;