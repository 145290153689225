import ApiConfig from "@/ApiConfig";
import store from '@store'
import {toast} from "react-toastify";
import {SET_CURRENT_ARTICLE} from "@types/types";
import ToastContent from "@components/ToastContent/ToastContent";

export const getArticleList = async (constructorId) => {
    return await ApiConfig.get(`Manufacturing/DesignTask/Articul/Priorities/${constructorId}`)
    // store.dispatch({type: SET_CONSTRUCTOR_ARTICLUS, payload: response.data})
};

export const getArticleDesignTaskDetail = async (id) => {
    return await ApiConfig.get(`Manufacturing/DesignTask/Articul/${id}`)
}

export const postArticleDesignTaskDetail = async (id) => {
    const response = await ApiConfig.post(`Manufacturing/DesignTask/Move/${id}`, id)
    toast.success(<ToastContent title={`Успешно`} response={response.data.message} ok/>);
};
