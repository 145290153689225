import {SET_DISCOUNT} from "@types/types"

const initState = {
    discountState: null
}

const discountReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_DISCOUNT :
            return {...state, discountState: action.payload}
        default:
            return state
    }
}

export default discountReducer