import {
    SET_MODAL_CURRENT_CUSTOMERS,
    SET_MODAL_CONFIRM_NEW_CLIENT,
    SET_MODAL_CONFIRM_ACTION,
    SET_MODAL_CREATE_CITY,
    SET_MODAL_CREATE_COUNTRY,
    SET_MODAL_LOGIN_HISTORY, SET_MODAL_CREATE_ADDITIONAL_TAX_PAYER,
    SET_MODAL_RESET,
    SET_MODAL_FABRIC_DETAILS, SET_MODAL_CREATE_SIZES, SET_MODAL_CREATE_COLOR,
    SET_MODAL_CREATE_COLLECTION,
} from "@types/types";

const initState = {
    modalCurrentCustomersState: false,
    modalConfirmNewUsersState: false,
    modalConfirmActionState: {
        isShow: false,
        title: "",
        desc: "",
        accept: null,
        icon: ""
    },
    modalCreateCityState: false,
    modalCreateCountryState: false,
    modalLoginHistoryState: false,
    modalCreateAdditionalTaxPayerState: false,
    modalFabricDetailsState: false,
    modalCreateSizeState: false,
    modalCreateColorState: false,
    modalCreateCollectionState: false,
}

const modalsReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_MODAL_CURRENT_CUSTOMERS :
            return {...state, modalCurrentCustomersState: action.payload}
        case SET_MODAL_CONFIRM_NEW_CLIENT :
            return {...state, modalConfirmNewUsersState: action.payload}
        case SET_MODAL_CONFIRM_ACTION :
            return {...state, modalConfirmActionState: action.payload}
        case SET_MODAL_CREATE_CITY :
            return {...state, modalCreateCityState: action.payload}
        case SET_MODAL_CREATE_COUNTRY :
            return {...state, modalCreateCountryState: action.payload}
        case SET_MODAL_LOGIN_HISTORY :
            return {...state, modalLoginHistoryState: action.payload}
        case SET_MODAL_CREATE_ADDITIONAL_TAX_PAYER :
            return {...state, modalCreateAdditionalTaxPayerState: action.payload}
        case SET_MODAL_FABRIC_DETAILS :
            return {...state, modalFabricDetailsState: action.payload}
        case SET_MODAL_CREATE_SIZES :
            return {...state, modalCreateSizeState: action.payload}
        case SET_MODAL_CREATE_COLOR :
            return {...state, modalCreateColorState: action.payload}
        case SET_MODAL_CREATE_COLLECTION :
            return {...state, modalCreateCollectionState: action.payload}

        case SET_MODAL_RESET :
            return {...initState}

        default:
            return state
    }
}
export default modalsReducer