import Box from "@components/Box";
import Text from "@components/Text/Text";
import GridLayout from "@components/Layout/GridLayout";
import Select from "@components/Form/Selects/Select";
import {FONTS} from "@/constans";

//TODO: подумать над названием файла
function TechnologistItem(props) {
    const {
        title,
        control,
        firstSelectName,
        firstSelectLabel,
        secondSelectName,
        secondSelectLabel,
        firstSelectValues,
        secondSelectValues
    } = props
    return (
        <Box>
            <Text value={title} margin='0 0 12px 0' as='h3' font={FONTS.SEMI_BOLD_16}/>
            <GridLayout gap='16px' template='1fr 1fr'>
                <GridLayout gap='6px'>
                    <Select
                        label={firstSelectLabel}
                        control={control}
                        name={firstSelectName}
                        values={firstSelectValues}
                        //TODO: поменять пропсы будут реальные данные
                        // getOptionLabel='value'
                        // getOptionValue='key'
                        // getOptionObjectKey='key'
                    />
                </GridLayout>
                <GridLayout gap='6px'>
                    <Select
                        label={secondSelectLabel}
                        control={control}
                        name={secondSelectName}
                        values={secondSelectValues}
                        //TODO: поменять пропсы когда будут реальные данные
                        // getOptionLabel='value'
                        // getOptionValue='key'
                        // getOptionObjectKey='key'
                    />
                </GridLayout>
            </GridLayout>
        </Box>
    );
}

export default TechnologistItem;