import GridLayout from "@components/Layout/GridLayout";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {getAcceptedPayment, postAcceptedPayment} from "@actions/invoiceAction";
import {useSearchParams} from "react-router-dom";
import InputNumeric from "@components/Form/Inputs/InputNumeric";
import Button from "@components/Form/Button";
import {getCurrencyScaleLength} from "@util/decimalHelper";

const AcceptedPaymentsItem = ({isOpen, item, setIsOpen, index, setAcceptedPayment, isMainCurrency, invoice}) => {
    const [searchParams] = useSearchParams()

    const defaultValues = () => {
        return {
            exchangeRate: item?.exchangeRate ? item.exchangeRate : 0,
            paymentSum: item?.paymentSum ? item.paymentSum : 0,
        }
    }
    const {control, watch, formState: {isDirty}} = useForm({
        defaultValues: defaultValues({
            exchangeRate: item?.exchangeRate ? item.exchangeRate : 0,
            paymentSum: item?.paymentSum ? item.paymentSum : 0,
        })
    })

    const hasLabel = index === 0

    useEffect(() => {
        if(isDirty) {
            postAcceptedPayment({
                invoiceId: searchParams.get('invoiceId'),
                currencyId: item?.currencyId,
                exchangeRate: watch('exchangeRate') !== '' ? watch('exchangeRate') : 0,
                paymentSum: watch('paymentSum') !== '' ? watch('paymentSum') : 0,
            }).then(()=> {
                getAcceptedPayment(searchParams.get('invoiceId')).then(({data}) => {
                    setAcceptedPayment(data)
                })
            })
        }
    }, [watch('paymentSum'), watch('exchangeRate')]);

    if (isMainCurrency) {
        return (
            <GridLayout template='1fr 48px' gap='16px' align='end'>
                <InputNumeric
                    decimalScale={4}
                    control={control}
                    name='paymentSum'
                    label='Получено в валюте'
                    symbol={item?.currencyCode}
                    isDebounce
                />

                <Button
                    variant='isChevronV2'
                    iconName={isOpen ? "krista-chevron-top" : "krista-chevron-bottom"}
                    onClick={() => setIsOpen(!isOpen)}
                />
            </GridLayout>
        )
    }

    return (
        <div style={{display: `${isOpen ? 'block' : 'none'}`}}>
            <GridLayout gap='16px' template='1fr 1fr 1fr'>
                <InputNumeric
                    decimalScale={4}
                    control={control}
                    name='paymentSum'
                    label={hasLabel && 'Получено в валюте'}
                    symbol={item?.currencyCode}
                    isSmall
                    isDebounce
                />
                <InputNumeric
                    decimalScale={4}
                    control={control}
                    name='exchangeRate'
                    label={hasLabel && 'Курс валюты'}
                    isSmall
                    isDebounce
                />
                <InputNumeric
                    decimalScale={getCurrencyScaleLength(item?.exchangedSum)}
                    fixedDecimalScale
                    value={item?.exchangedSum}
                    label={hasLabel && `Получено в ${invoice?.currencyCode}`}
                    symbol={invoice?.currencyCode}
                    disabled
                    isSmall
                />
            </GridLayout>
        </div>
    )
}

export default AcceptedPaymentsItem;