import BodyRow from "@components/Table/components/BodyRow";
import GridLayout from "@components/Layout/GridLayout";
import BodyRowItem from "@components/Table/components/BodyRowItem";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";
import Button from "@components/Form/Button";
import {NumericFormat} from "react-number-format";

import dayjs from "dayjs";

function Row({item, template}) {

    return (
        <BodyRow backgroundColor={COLORS.WHITE}>
            <GridLayout align='center' template={template}>
                <BodyRowItem>
                    <Text value={item?.serialNumber} font={FONTS.SEMI_BOLD_14} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value={item.executionDate ? dayjs(item.executionDate).format('DD MM YYYY HH:mm') : ''} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value={item?.executedByEmployeeName} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value={item?.clientName} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value={item?.quantity} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <NumericFormat
                        value={item?.totalSum}
                        thousandSeparator=" "
                        decimalScale={2}
                        displayType="text"
                        renderText={(value) => (
                            <Text value={value} font={FONTS.SEMI_BOLD_14} color={COLORS.DARKGRAY}/>
                        )}
                        fixedDecimalScale
                        suffix={` ${item?.currencySymbol}`}
                    />
                </BodyRowItem>
                <BodyRowItem>
                    <FlexLayout gap='16px' align='center'>
                        <Button variant='isMainV3' buttonText='Открыть' isMinimal/>

                        <Button variant='isDangerV3' buttonText='Возврат' isMinimal/>
                    </FlexLayout>
                </BodyRowItem>
            </GridLayout>
        </BodyRow>
    );
}

export default Row;