import React from 'react';
import Button from "@components/Form/Button";
import FlexLayout from "@components/Layout/FlexLayout";


function OrderPanelActions() {
    return (
        <FlexLayout spaceBetween margin='0 0 24px 0'>
            <div>
                <Button
                    variant='isMain'
                    iconName='krista-debt'
                    buttonText='Выставить счет'
                    ariaLabel='Выставить счет'
                    isSmall
                />
            </div>
            <FlexLayout gap='16px'>
                <Button
                    variant='isWhite'
                    iconName='krista-plus-circle'
                    buttonText='Добавить модель'
                    ariaLabel='Добавить модель'
                    isSmall
                />
                <Button
                    variant='isDangerV2'
                    iconName='krista-delete'
                    isSmall
                />
            </FlexLayout>
        </FlexLayout>
    );
}

export default OrderPanelActions;