import {SET_TAX_PAYER_DATA} from "@types/types"

const initState = {
    taxPayerDataState: null
}

const taxPayerDataReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_TAX_PAYER_DATA :
            return {...state, taxPayerDataState: action.payload}
        default:
            return state
    }
}

export default taxPayerDataReducer