import GridLayout from "@components/Layout/GridLayout";
import HeadRow from "@components/Table/components/HeadRow";
import HeadRowItem from "@components/Table/components/HeadRowItem";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import Table from "@components/Table/components/Wrapper";
import Row from "@components/Table/TableDefault/TableSales/Row";
import Footer from "@components/Table/TableDefault/TableSales/Footer";

function TableSales({invoice, setInvoice, setLastScanProduct, setAcceptedPayment, isCompleted}) {
    const template = () => {
        if(isCompleted) {
            return '1.4fr 1.2fr 1.8fr 1.8fr 1.5fr 1.8fr 1fr 1.4fr'
        }
        return '1.4fr 1.2fr 1.8fr 1.8fr 1.5fr 1.8fr 1fr 1.8fr 1fr'
    }

    return (
        <Table overflow='visible'>
            <GridLayout gap='10px' position='relative'>
                <HeadRow>
                    <GridLayout template={template()} align='center'>
                        <HeadRowItem>
                            <Text value='Артикул' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Размер' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Цвет' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Цена' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Скидка' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Цена со скидкой' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Единиц' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Итого' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        {!isCompleted && (
                            <HeadRowItem/>
                        )}
                    </GridLayout>
                </HeadRow>
                {invoice?.products.map((product) => (
                    <Row
                        template={template()}
                        product={product}
                        invoice={invoice}
                        setInvoice={setInvoice}
                        key={product?.productId}
                        setLastScanProduct={setLastScanProduct}
                        setAcceptedPayment={setAcceptedPayment}
                        isCompleted={isCompleted}
                    />
                ))}
                <Footer template={template()} totals={invoice?.totals}/>
            </GridLayout>
        </Table>
    );
}

export default TableSales;