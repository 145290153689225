import {SET_MODAL_CONFIRM_NEW_CLIENT} from "@types/types";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getTaxPayerType} from "@actions/taxPayerTypeAction";
import {getDiscount} from "@actions/discountAction";
import {getMargins} from "@actions/marginsAction";
import {COLORS, FONTS} from "@/constans";
import useOutside from "@hooks/useOutside";
import styled from "styled-components";

import Icon from "@components/Icon/Icon";
import Button from "@components/Form/Button";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";
import {getAuthorizationTemp} from "@actions/authorizationAction";

const Header = () => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.userState.token)
    const {ref, isShow, setIsShow} = useOutside(false)

    const openCreateClientModal = async () => {
        await getTaxPayerType()
        await getDiscount()
        await getMargins()
        dispatch({type: SET_MODAL_CONFIRM_NEW_CLIENT, payload: true})
    }

    const tempAuth = ({data}) => {
        //TODO УДАЛИТЬ !!!! после продакшена
        getAuthorizationTemp({data, dispatch})
    }
    return (
        <Content>
            <LogoWrapper>
                <LogoLink to="/">
                    <IconWrapper>
                        <Icon iconName="krista-diamond" iconHeight={32} iconWidth={28}/>
                    </IconWrapper>
                    <Text
                        value='Krista'
                        font={FONTS.SEMI_BOLD_20}
                        color={COLORS.DARKGRAY}
                        as='span'
                    />
                    <Text
                        value='Pro'
                        font={FONTS.REGULAR_20}
                        color={COLORS.DARKGRAY}
                        as='span'
                        margin='0 0 0 4px'
                    />
                </LogoLink>
            </LogoWrapper>
            {token &&
                <>
                    <NavLink>
                        {/*//TODO: сделать active когда придут данные?*/}
                        <LinkContent href="/" className='active'>
                            <Text
                                value='Unique'
                                font={FONTS.SEMI_BOLD_14}
                                color='inherit'
                                href="/"
                            />
                        </LinkContent>
                        <LinkContent href="/client">
                            <Text
                                value='Market'
                                font={FONTS.SEMI_BOLD_14}
                                color='inherit'
                                href="/"
                            />
                        </LinkContent>
                        <div style={{marginRight: 40}} onClick={() => tempAuth({data: {login: 'test_manager', password: '112233'}})}>
                            <Text
                                value='Manager'
                                font={FONTS.SEMI_BOLD_14}
                                color='inherit'
                            />
                        </div>
                        <div onClick={() => tempAuth({data: {login: 'test_operator', password: '112233'}})}>
                            <Text
                                value='Operator'
                                font={FONTS.SEMI_BOLD_14}
                                color='inherit'
                            />
                        </div>

                    </NavLink>
                    <FlexLayout align='center'>
                        <Action>
                            <Icon
                                iconName="krista-add-user"
                                iconWidth={24}
                                iconHeight={24}
                            />
                        </Action>
                        <Action>
                            <Icon
                                iconName="krista-message"
                                iconWidth={24}
                                iconHeight={24}
                            />
                            <ActionAmount>
                                <Text
                                    as='span'
                                    value='14'
                                    font={FONTS.REGULAR_14}
                                    color={COLORS.WHITE}
                                />
                            </ActionAmount>
                        </Action>

                        <ButtonWrapper>
                            <Button
                                buttonText='Создать'
                                iconName='krista-plus'
                                variant='isMain'
                                ariaLabel='Создать'
                                isSmall
                                onClick={() => setIsShow(!isShow)}
                            />
                            {isShow &&
                                <Dropdown ref={ref}>
                                    <DropdownItem onClick={openCreateClientModal}>
                                        <Text value='Клиента' font={FONTS.REGULAR_14} color={COLORS.GRAY}/>
                                    </DropdownItem>
                                    <DropdownItem onClick={openCreateClientModal}>
                                        <Text value='Заказ' font={FONTS.REGULAR_14} color={COLORS.GRAY}/>
                                    </DropdownItem>
                                    <DropdownItem onClick={openCreateClientModal}>
                                        <Text value='Счёт' font={FONTS.REGULAR_14} color={COLORS.GRAY}/>
                                    </DropdownItem>
                                </Dropdown>
                            }
                        </ButtonWrapper>
                    </FlexLayout>
                </>
            }
        </Content>
    );
};

const Content = styled.header`
  background-color: ${COLORS.WHITE};
  border-bottom: 1px solid ${COLORS.LIGHTGRAY1};
  padding: 0 40px 0 24px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  min-height: 71px;
`

const LogoWrapper = styled.div`
  width: 226px;
  margin-right: 40px;
`

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`

const IconWrapper = styled.div`
  margin-right: 14px;
  color: ${COLORS.MAIN};
`

const NavLink = styled.nav`
  display: flex;
  align-items: center;
  margin-right: auto;
`

const LinkContent = styled.a`
  color: ${COLORS.BLACK};
  text-decoration: none;
  margin-right: 40px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    position: absolute;
    bottom: -1px;
    right: 0;
    left: 0;
    height: 3px;
    width: 100%;
    content: '';
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &.active {
    color: ${COLORS.MAIN};

    &:after {
      background-color: ${COLORS.MAIN};
    }
  }

  &:hover {
    color: ${COLORS.MAIN};
  }
`

const Action = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: ${COLORS.LIGHTGRAY};
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;

  &:hover {
    color: ${COLORS.MAIN};
    background-color: ${COLORS.LIGHTGRAY3};
  }
`

const ActionAmount = styled.div`
  border-radius: 50%;
  position: absolute;
  background-color: ${COLORS.RED};
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonWrapper = styled.div`
  position: relative;
  margin-left: 15px;
`

const Dropdown = styled.div`
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.LIGHTGRAY1};
  box-shadow: 0 5px 15px rgba(68, 68, 79, 0.1);
  border-radius: 8px;
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  left: 0;
  padding: 8px 0;
`

const DropdownItem = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.LIGHTGRAY2};
  }
`

export default Header;