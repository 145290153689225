import clsx from "clsx";
import Icon from "@components/Icon/Icon";
import Label from "@components/Form/Label/Label";
import sDefault from '../selects-custom.module.scss'
import Text from "@components/Text/Text";
import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";
import SearchPanel from "@components/Form/SelectsCustom/components/SearchPanel";

const SelectTextile = (props) => {
    const {label, errorMessage} = props

    const {isTextile} = props
    const {name, subName} = props
    const {openDropdown, setItem} = props
    const {items} = props
    const {ref, isShow, isLoaded} = props






    // const setTextileItem = (item) => {
    //
    //         update(index, item)
    //         setIsShow(false)
    //     // clearErrors()
    // }

    return (
        <div className={sDefault.selectWrapper} ref={ref} >
            {label && <Label label={label}/>}

            <div
                className={clsx(sDefault.select, isShow && sDefault.selectActive, errorMessage && sDefault.selectError)}
                onClick={openDropdown}
                aria-label='Выбор ткани'
            >

                <div>
                    <div className={sDefault.selectValue}>{name}</div>
                    <div className={sDefault.selectSubValue}>{subName}</div>
                </div>
                <div className={clsx(sDefault.selectInfoDetail, isLoaded && sDefault.selectLoading)}>
                    <Icon iconName={isLoaded ? 'krista-loading' : 'krista-info'}/>
                </div>
                <div className={sDefault.selectDecor}>
                    <Icon iconName="krista-arrow-bottom"/>
                </div>
            </div>
            <ErrorWrapper>
                <Text value={errorMessage} font={FONTS.REGULAR_12} color={COLORS.RED} margin='2px 0 0 0'/>
            </ErrorWrapper>
            {isShow &&
                <div
                    className={sDefault.dropdown}
                    aria-label='Поисковик ткани'
                >
                    >
                    <SearchPanel isShow={isShow} isTextile={isTextile}/>
                    <div>
                        {items?.length < 1 && 'По вашему запросу нету данных'}
                        {items?.map(itemMaterial => (
                            <div
                                className={sDefault.dropdownItem}
                                onClick={() => setItem(itemMaterial)}
                                aria-label={`Ткань ${itemMaterial.name}`}
                                key={itemMaterial.id}
                            >
                                <div className={sDefault.dropdownInfoColor}>
                                    <div>
                                        <div className={sDefault.dropdownInfoValue}>{itemMaterial.name}</div>
                                        <div className={sDefault.dropdownInfoSubValue}>{itemMaterial.supplierName}</div>
                                    </div>
                                </div>
                                <div className={sDefault.dropdownInfoIcon}>
                                    <Icon iconName="krista-info"/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    );
};

const ErrorWrapper = styled.div`
  position: absolute;
  bottom: -16px;
  left: 0;
`

export default SelectTextile;