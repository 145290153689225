import styled, {css} from "styled-components";
import {COLORS} from "@/constans";

function BodyRowItem({children, ariaLabel, borderTop, padding, isCentered, align, bg, isFillRow}) {
    return (
        <BodyRowItemContent
            bg={bg}
            aria-label={ariaLabel}
            borderTop={borderTop}
            padding={padding}
            isCentered={isCentered}
            isFillRow={isFillRow}
        >
            {children}
        </BodyRowItemContent>
    );
}

const BodyRowItemContent = styled.div`
  padding: ${({padding}) => padding ? padding : '0 8px'};
  ${({border}) => border && `border: ${border}`};
  ${({isCentered}) => isCentered && css`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `};
  border-top: 1px solid ${({borderTop}) => borderTop ? COLORS.LIGHTGRAY1 : 'transparent'};
  ${({bg}) => bg && css` background-color: ${bg} `};
  ${({isFillRow}) => isFillRow && css`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 20px 8px;
  `}
`

export default BodyRowItem;