import GridLayout from "@components/Layout/GridLayout";
import HeadRow from "@components/Table/components/HeadRow";
import HeadRowItem from "@components/Table/components/HeadRowItem";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import HeadRowSort from "@components/Table/components/HeadRowSort";
import Table from "@components/Table/components/Wrapper";
import Row from "@components/Table/TableDefault/TableSalesHistory/Row";
import {useSelector} from "react-redux";

function TableSalesHistory({salesHistory}) {
    const template = '1.5fr 2fr 1.5fr 1.5fr 1fr 1.2fr 150px'
    return (
        <Table overflow='visible'>
            <GridLayout gap='10px' position='relative'>
                <HeadRow>
                    <GridLayout template={template} align='center'>
                        <HeadRowSort title='№ продажи' sortValue='serialNumber'/>
                        <HeadRowSort title='Дата и время' sortValue='createDate'/>
                        <HeadRowSort title='Менеджер' sortValue='employeeName'/>
                        <HeadRowSort title='Клиент' sortValue='clientName'/>
                        <HeadRowSort title='Кол-во единиц' sortValue='quantity'/>
                        <HeadRowSort title='Итог' sortValue='totalSum'/>
                        <HeadRowItem>
                            <Text value='Действие' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                    </GridLayout>
                </HeadRow>
                {salesHistory?.items.map((item) => (
                    <Row
                        template={template}
                        item={item}
                        key={item?.invoiceDocumentId}
                    />
                ))}
            </GridLayout>
        </Table>
    );
}

export default TableSalesHistory;