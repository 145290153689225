import ApiConfig from "src/ApiConfig";

const path = 'Manufacturing/Material/'

export const getMaterials = async (params, isTextile) => {

    const {supplierName, materialName} = params

    return await ApiConfig.get(`${path}BySupplier/Lookup`, {
        params: {
            supplierName: supplierName ? supplierName : null,
            materialName: materialName ? materialName : null,
            page: 1,
            pageSize: 100,
            materialType: isTextile ? 'textile' : 'accessories'
        }
    })
};

export const getMaterialColors = async (materialId, supplierId) => {
    return await ApiConfig.get(`${path}Colors/${materialId}/${supplierId}`)
}

