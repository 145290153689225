import styled from "styled-components";
import {TaskRowBodyItemContent} from "@components/Tasks/components/TaskRowBodyItem/TaskRowBodyItem";

function TaskRowBodyItems({children, ariaLabel}) {
    return (
        <TaskRowBodyItemsContent aria-label={ariaLabel}>
            {children}
        </TaskRowBodyItemsContent>
    );
}

export const TaskRowBodyItemsContent = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 9px;
  
  ${TaskRowBodyItemContent} {
    &:last-child:before {
      content: none;
    }
  }
  
  &:first-child {
    ${TaskRowBodyItemContent} {
      padding-top: 8px;
    }
  }
  
  &:last-child {
    ${TaskRowBodyItemContent} {
      padding-bottom: 8px;
    }
  }
`

export default TaskRowBodyItems;