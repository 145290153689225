import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import FlexLayout from "@components/Layout/FlexLayout";
import Button from "@components/Form/Button";
import NavFiltersStoreWarehouse from "@components/NavFilters/NavFiltersStockWarehouse";
import TableStoreWarehouse from "@components/Table/TableDefault/TableStoreWarehouse";
import {getWarehouseProducts} from "@actions/warehouseAction";
import {useEffect} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Pagination from "@components/Pagination";
import Box from "@components/Box";
import Icon from "@components/Icon";
import {NumericFormat} from "react-number-format";
import {getCurrencyScaleLength} from "@util/decimalHelper";

function StoreWarehousePage() {
    const warehousePagination = useSelector(state => state.warehouse.warehouseProductsState?.page)
    const warehouse = useSelector(state => state.warehouse)
    const totals = useSelector(state => state.warehouse.warehouseProductsState?.totals)

    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (!searchParams.get('page') || !searchParams.get('pageSize')) {
            setSearchParams({
                page: '1',
                pageSize: '25',
            })
        } else {
            getWarehouseProducts(searchParams)
        }
    }, [searchParams])

    return (
        <>
            <GridLayout gap='24px'>
                    <FlexLayout gap='4px'>
                        <Text value='Магазин' font={FONTS.REGULAR_24} color={COLORS.BLACK}/>
                        <Text value='/' font={FONTS.REGULAR_24} color={COLORS.LIGHTGRAY}/>
                        <Text value='Склад наличия' font={FONTS.MEDIUM_24} color={COLORS.BLACK}/>
                    </FlexLayout>

                <GridLayout template='repeat(4, 1fr)' align='center' gap='8px'>
                    <Button
                        to="/store/acceptance-documents"
                        variant='isMain'
                        iconName='krista-warehouse-add'
                        buttonText='Добавить на склад'
                        as={Link}
                    />
                    <Button
                        to="/store/movement-documents"
                        variant='isMain'
                        iconName='krista-warehouse-delete'
                        buttonText='Списание со склада'
                        as={Link}
                    />
                    <Button
                        to="/store/audit-documents"
                        variant='isMain'
                        iconName='krista-cycle-v2'
                        buttonText='Начать ревизию'
                        as={Link}
                    />
                    <Button
                        to="/store/movement-history"
                        variant='isMain'
                        iconName='krista-arrow-left-right'
                        buttonText='История перемещений'
                        as={Link}
                    />
                </GridLayout>

                <GridLayout template='repeat(3, 1fr)' gap='20px' align='stretch'>
                    <Box padding='20px'>
                        <GridLayout gap='16px'>
                            <GridLayout gap='8px'>
                                <FlexLayout gap='8px' align='center'>
                                    <Icon
                                        iconName='krista-warehouse'
                                        iconWidth={16}
                                        iconHeight={16}
                                        iconColor={COLORS.LIGHTGRAY}
                                    />
                                    <Text
                                        value='Всего'
                                        font={FONTS.SEMI_BOLD_16}
                                        color={COLORS.BLACK}
                                    />
                                </FlexLayout>

                                <FlexLayout spaceBetween>
                                    <Text
                                        value='Артикулов'
                                        font={FONTS.REGULAR_14}
                                        color={COLORS.BLACK}
                                    />

                                    <Text
                                        value={totals?.totalArticuls}
                                        font={FONTS.REGULAR_14}
                                        color={COLORS.BLACK}
                                    />
                                </FlexLayout>

                                <FlexLayout spaceBetween>
                                    <Text
                                        value='Единиц'
                                        font={FONTS.REGULAR_14}
                                        color={COLORS.BLACK}
                                    />

                                    <Text
                                        value={totals?.totalQuantity}
                                        font={FONTS.REGULAR_14}
                                        color={COLORS.BLACK}
                                    />
                                </FlexLayout>
                            </GridLayout>
                            <GridLayout gap='8px'>
                                <FlexLayout gap='8px' align='center'>
                                    <Icon
                                        iconName='krista-lock'
                                        iconWidth={16}
                                        iconHeight={16}
                                        iconColor={COLORS.LIGHTGRAY}
                                    />
                                    <Text
                                        value='Забронировано'
                                        font={FONTS.SEMI_BOLD_16}
                                        color={COLORS.BLACK}
                                    />
                                </FlexLayout>

                                <FlexLayout spaceBetween>
                                    <Text
                                        value='Единиц'
                                        font={FONTS.REGULAR_14}
                                        color={COLORS.BLACK}
                                    />

                                    <Text
                                        value={'???'}
                                        font={FONTS.REGULAR_14}
                                        color={COLORS.MAIN}
                                    />
                                </FlexLayout>
                            </GridLayout>
                        </GridLayout>
                    </Box>

                    <Box padding='20px'>
                        <GridLayout gap='16px'>
                            <GridLayout gap='8px'>
                                <FlexLayout gap='8px' align='center'>
                                    <Icon
                                        iconName='krista-money'
                                        iconWidth={16}
                                        iconHeight={16}
                                        iconColor={COLORS.LIGHTGRAY}
                                    />
                                    <Text
                                        value='В наличии'
                                        font={FONTS.SEMI_BOLD_16}
                                        color={COLORS.BLACK}
                                    />
                                </FlexLayout>

                                <FlexLayout spaceBetween>
                                    <Text
                                        value='На сумму'
                                        font={FONTS.REGULAR_14}
                                        color={COLORS.BLACK}
                                    />

                                    <NumericFormat
                                        value={totals?.totalSum}
                                        thousandSeparator=" "
                                        decimalScale={getCurrencyScaleLength(0)}
                                        fixedDecimalScale
                                        displayType="text"
                                        renderText={(value) => (
                                            <Text value={value} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                                        )}
                                        suffix={` ${totals?.currencySymbol}`}
                                    />
                                </FlexLayout>
                            </GridLayout>
                        </GridLayout>
                    </Box>

                    <NavFiltersStoreWarehouse/>
                </GridLayout>


                <TableStoreWarehouse/>
            </GridLayout>
            {
                warehousePagination &&
                <Pagination
                    total={warehousePagination?.totalItemCount}
                    totalSuffix='артикулов'
                    active={warehousePagination?.currentPageIndex}
                    perPage={warehousePagination?.pageSize}
                    startItem={warehousePagination?.startItemIndex}
                    endItem={warehousePagination?.endItemIndex}
                />
            }

        </>
    );
}

export default StoreWarehousePage;