import {COLORS} from "@/constans/index";
import hexToRgba from "hex-to-rgba";

export const COLORS_BUTTON = {
    isMain: {
        color: COLORS.WHITE,
        colorHover: COLORS.WHITE,
        bgColor: COLORS.MAIN,
        bgColorHover: COLORS.DARKBLUE,
        borderColor: COLORS.MAIN,
        borderColorHover: COLORS.DARKBLUE,
    },
    isMainV2: {
        color: COLORS.MAIN,
        colorHover: COLORS.MAIN,
        bgColor: hexToRgba(COLORS.MAIN, '0.1'),
        bgColorHover: hexToRgba(COLORS.MAIN, '0.2'),
        borderColor: 'transparent',
        borderColorHover: 'transparent',
    },
    isMainV3: {
        color: COLORS.MAIN,
        colorHover: COLORS.MAIN,
        bgColor: 'transparent',
        bgColorHover: 'transparent',
        borderColor: 'transparent',
        borderColorHover: 'transparent',
    },
    isMainBorder: {
        color: COLORS.MAIN,
        bgColor: 'transparent',
        borderColor: COLORS.MAIN,
        colorHover: COLORS.WHITE,
        bgColorHover: COLORS.MAIN,
        borderColorHover: COLORS.MAIN,
    },
    isSuccess: {
        color: COLORS.WHITE,
        colorHover: COLORS.WHITE,
        bgColor: COLORS.STATUS_GREEN,
        bgColorHover: COLORS.DARK_STATUS_GREEN,
        borderColor: COLORS.STATUS_GREEN,
        borderColorHover: COLORS.DARK_STATUS_GREEN,
    },
    isSuccessBorder: {
        color: COLORS.STATUS_GREEN,
        bgColor: 'transparent',
        borderColor: COLORS.STATUS_GREEN,
        colorHover: COLORS.WHITE,
        bgColorHover: COLORS.STATUS_GREEN,
        borderColorHover: COLORS.STATUS_GREEN,
    },
    isSuccessV2: {
        color: COLORS.STATUS_GREEN,
        colorHover: COLORS.STATUS_GREEN,
        bgColor: hexToRgba(COLORS.STATUS_GREEN, '0.1'),
        bgColorHover: hexToRgba(COLORS.STATUS_GREEN, '0.2'),
        borderColor: 'transparent',
        borderColorHover: 'transparent',
    },
    isWarning: {
        color: COLORS.WHITE,
        colorHover: COLORS.WHITE,
        bgColor: COLORS.YELLOW,
        bgColorHover: COLORS.DARK_YELLOW,
        //TODO: нужны DARK_YELLOW
        borderColor: COLORS.YELLOW,
        borderColorHover: COLORS.DARK_YELLOW,
        //TODO: нужны DARK_YELLOW
    },
    isWarningV2: {
        color: COLORS.YELLOW,
        colorHover: COLORS.YELLOW,
        bgColor: hexToRgba(COLORS.YELLOW, '0.1'),
        bgColorHover: hexToRgba(COLORS.YELLOW, '0.2'),
        borderColor: 'transparent',
        borderColorHover: 'transparent',
    },
    isWarningBorder: {
        color: COLORS.YELLOW,
        bgColor: 'transparent',
        borderColor: COLORS.YELLOW,
        colorHover: COLORS.WHITE,
        bgColorHover: COLORS.YELLOW,
        borderColorHover: COLORS.YELLOW,
    },
    isDanger: {
        color: COLORS.WHITE,
        colorHover: COLORS.WHITE,
        bgColor: COLORS.RED,
        bgColorHover: COLORS.DARKRED,
        borderColor: COLORS.RED,
        borderColorHover: COLORS.DARKRED,
    },
    isDangerBorder: {
        color: COLORS.RED,
        colorHover: COLORS.WHITE,
        bgColor: 'transparent',
        borderColor: COLORS.RED,
        bgColorHover: COLORS.RED,
        borderColorHover: COLORS.RED,
    },
    isDangerV2: {
        color: COLORS.RED,
        colorHover: COLORS.RED,
        bgColor: hexToRgba(COLORS.RED, '0.1'),
        bgColorHover: hexToRgba(COLORS.RED, '0.2'),
        borderColor: 'transparent',
        borderColorHover: 'transparent',
    },
    isDangerV3: {
        color: COLORS.RED,
        colorHover: COLORS.RED,
        bgColor: 'transparent',
        bgColorHover: 'transparent',
        borderColor: 'transparent',
        borderColorHover: 'transparent',
    },
    isSalad: {
        color: COLORS.WHITE,
        colorHover: COLORS.WHITE,
        bgColor: COLORS.SALAD,
        bgColorHover: COLORS.DARK_SALAD,
        borderColor: COLORS.SALAD,
        borderColorHover: COLORS.DARK_SALAD,
    },
    isSaladBorder: {
        color: COLORS.SALAD,
        bgColor: 'transparent',
        borderColor: COLORS.SALAD,
        colorHover: COLORS.WHITE,
        bgColorHover: COLORS.SALAD,
        borderColorHover: COLORS.SALAD,
    },
    isGray: {
        color: COLORS.LIGHTGRAY,
        colorHover: COLORS.LIGHTGRAY,
        bgColor: COLORS.WHITE,
        bgColorHover: COLORS.LIGHTGRAY2,
        borderColor: COLORS.LIGHTGRAY1,
        borderColorHover: COLORS.LIGHTGRAY1,
    },
    isGrayV2: {
        color: COLORS.GRAY,
        colorHover: COLORS.GRAY,
        bgColor: 'transparent',
        bgColorHover: 'transparent',
        borderColor: COLORS.LIGHTGRAY1,
        borderColorHover: COLORS.GRAY,
    },
    isWhite: {
        color: COLORS.MAIN,
        colorHover: COLORS.WHITE,
        bgColor: COLORS.WHITE,
        bgColorHover: COLORS.MAIN,
        borderColor: COLORS.WHITE,
        borderColorHover: COLORS.MAIN,
    },
    isGrayBorder: {
        color: COLORS.LIGHTGRAY,
        colorHover: COLORS.WHITE,
        bgColor: 'transparent',
        bgColorHover: COLORS.LIGHTGRAY,
        borderColor: COLORS.LIGHTGRAY1,
        borderColorHover: COLORS.LIGHTGRAY,
    },
    isChevron: {
        color: COLORS.MAIN,
        colorHover: COLORS.WHITE,
        bgColor: 'transparent',
        bgColorHover: COLORS.MAIN,
        borderColor: hexToRgba(COLORS.MAIN, '0.15'),
        borderColorHover: COLORS.MAIN,
    },
    isChevronV2: {
        color: COLORS.LIGHTGRAY,
        colorHover: COLORS.LIGHTGRAY,
        bgColor: COLORS.LIGHTGRAY3,
        bgColorHover: COLORS.LIGHTGRAY2,
        borderColor: COLORS.LIGHTGRAY1,
        borderColorHover: COLORS.LIGHTGRAY1,
    },
    isDefault: {
        color: COLORS.DARKGRAY,
        colorHover: COLORS.DARKGRAY,
        bgColor: COLORS.LIGHTGRAY2,
        bgColorHover: COLORS.LIGHTGRAY2,
        borderColor: COLORS.LIGHTGRAY2,
        borderColorHover: COLORS.LIGHTGRAY2,
    },
    isDisabled: {
        color: COLORS.LIGHTGRAY,
        colorHover: COLORS.LIGHTGRAY,
        bgColor: COLORS.LIGHTGRAY2,
        bgColorHover: COLORS.LIGHTGRAY2,
        borderColor: COLORS.LIGHTGRAY2,
        borderColorHover: COLORS.LIGHTGRAY2,
    }
}