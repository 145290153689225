import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";

function ClientHeader({children, backgroundColor}) {
    return (
        <ClientHeaderContent backgroundColor={backgroundColor}>
            {children}
        </ClientHeaderContent>
    );
}

const ClientHeaderContent = styled.div`
  background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : COLORS.SALAD};
  border-radius: 6px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  font: ${FONTS.SEMI_BOLD_16};
  color:${COLORS.WHITE};
`

export default ClientHeader;