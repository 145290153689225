export const SET_USER_DETAIL = 'SET_USER_DETAIL'

export const SET_NOT_APPROVED_CLIENTS = 'SET_NOT_APPROVED_CLIENTS'
export const SET_NOT_APPROVED_CLIENT_BY_ID = 'SET_NOT_APPROVED_CLIENT_BY_ID'

export const SET_CLIENTS = 'SET_CLIENTS'

export const SET_ALL_MANAGER = 'SET_ALL_MANAGER'
export const SET_ALL_CONSTRUCTOR = 'SET_ALL_CONSTRUCTOR'
export const SET_ALL_CITY = 'SET_ALL_CITY'
export const SET_ALL_COUNTRY = 'SET_ALL_COUNTRY'
export const SET_ALL_CATALOG = 'SET_ALL_CATALOG'

export const SET_MODAL_CURRENT_CUSTOMERS = 'SET_MODAL_CURRENT_CUSTOMERS'
export const SET_MODAL_CONFIRM_NEW_CLIENT = 'SET_MODAL_CONFIRM_NEW_CLIENT'
export const SET_MODAL_CONFIRM_ACTION = 'SET_MODAL_CONFIRM_ACTION'
export const SET_MODAL_CREATE_CITY = 'SET_MODAL_CREATE_CITY'
export const SET_MODAL_CREATE_COUNTRY = 'SET_MODAL_CREATE_COUNTRY'
export const SET_MODAL_LOGIN_HISTORY = 'SET_MODAL_LOGIN_HISTORY'
export const SET_MODAL_CREATE_ADDITIONAL_TAX_PAYER = 'SET_MODAL_CREATE_ADDITIONAL_TAX_PAYER'
export const SET_MODAL_RESET = 'SET_MODAL_RESET'
export const SET_MODAL_FABRIC_DETAILS = 'SET_MODAL_FABRIC_DETAILS'
export const SET_MODAL_CREATE_SIZES = 'SET_MODAL_CREATE_SIZES'
export const SET_MODAL_CREATE_COLOR = 'SET_MODAL_CREATE_COLOR'
export const SET_MODAL_CREATE_COLLECTION = 'SET_MODAL_CREATE_COLLECTION'


export const SET_NEW_CITY = 'SET_NEW_CITY'
export const SET_NEW_COUNTRY_NAME = 'SET_NEW_COUNTRY_NAME'
export const SET_NEW_CITY_ID = 'SET_NEW_CITY_ID'
export const SET_NEW_COUNTRY = 'SET_NEW_COUNTRY'

export const SET_TAX_PAYER_DATA = 'SET_TAX_PAYER_DATA'

export const SET_SIDEBAR = 'SET_SIDEBAR'

export const SET_TAX_PAYER_TYPE = 'SET_TAX_PAYER_TYPE'

export const SET_DISCOUNT = 'SET_DISCOUNT'

export const SET_MARGINS = 'SET_MARGINS'

export const SET_CURRENT_CUSTOMERS = 'SET_CURRENT_CUSTOMERS'

export const SET_COLLECTION = 'SET_COLLECTION'

export const SET_MODEL_ACTIVE_SIZE = 'SET_MODEL_ACTIVE_SIZE'
export const SET_MODEL_ACTIVE_COLOR = 'SET_MODEL_ACTIVE_COLOR'
export const SET_MODEL_ALL_SIZE = 'SET_MODEL_ALL_SIZE'
export const SET_MODEL_COLOR = 'SET_MODEL_COLOR'


export const SET_DESIGN_TASKS = 'SET_DESIGN_TASKS'
export const SET_DESIGN_TASKS_STATUS_BOARD = 'SET_DESIGN_TASKS_STATUS_BOARD'

export const SET_SIZE = 'SET_SIZE'
export const SET_COLOR = 'SET_COLOR'

export const SET_MATERIALS = 'SET_MATERIALS'

export const SET_CURRENT_ARTICLE = 'SET_CURRENT_ARTICUL'

export const SET_WAREHOUSE_PRODUCTS = 'SET_WAREHOUSE_PRODUCTS'

export const SET_STORE_ALL_MANAGER = 'SET_STORE_ALL_MANAGER'
export const SET_STORE_ALL_CLIENT = 'SET_STORE_ALL_CLIENT'
