import styled from "styled-components";
import {COLORS} from "@/constans";
import {isWhite} from "@util/isWhite";

function Color({backgroundColor}) {
    return (
        <ColorContent backgroundColor={backgroundColor}/>
    );
}

const ColorContent = styled.div`
  background-color: ${({backgroundColor}) => backgroundColor && backgroundColor};
  border: 1px solid ${({backgroundColor}) => isWhite(backgroundColor) ? COLORS.LIGHTGRAY2 : 'transparent'};
  border-radius: 8px;
  height: 20px;
  width: 20px;
`

export default Color;