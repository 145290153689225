import React from 'react';
import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import BadgeStatus from "@components/Badges/BadgeStatus";

const BreadCrumbs = ({documentTypeName, serialNumber, statusHexColor, statusName}) => {
    return (
        <>
            <FlexLayout gap='4px' align='center' margin="0 0 16px 0">
                <Text value='Магазин' font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
                <Text value='/' font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY1}/>
                <Text value='Склад наличия' font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
            </FlexLayout>
            <FlexLayout gap='16px' align='center'>
                <Text
                    value={`${documentTypeName} №${serialNumber} `}
                    font={FONTS.MEDIUM_24}
                    color={COLORS.BLACK}
                />
                <BadgeStatus backgroundColor={statusHexColor} value={statusName}/>
            </FlexLayout>
        </>
    );
};

export default BreadCrumbs;