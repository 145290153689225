import {SET_MODEL_ACTIVE_COLOR} from "@types/types";
import {useDispatch, useSelector} from "react-redux";
import {isWhite} from "@util/isWhite";
import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";

const ItemColor = ({item, index, children}) => {

    const dispatch = useDispatch()

    const activeColor = useSelector(state => state.model.modelActiveColor)

    const switchColorTab = () => {
        dispatch({type: SET_MODEL_ACTIVE_COLOR, payload: index})
    }

    return (
        <Item
            activeColor={activeColor === index}
            aria-label={item.name}
            onClick={switchColorTab}
        >
            <Color
                isActive={isWhite(item.hex)}
                style={{backgroundColor: item.hex}}
            />
            <Row>
                <Value>{item.name}</Value>
                {item.isMain && <Label>{item.isMain && 'Базовый'}</Label>}
            </Row>
            {children}
        </Item>
    );
};

const Item = styled.div`
  display: flex;
  align-items: center;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.LIGHTGRAY1};
  border-radius: 10px;
  padding: 3px 8px 3px 16px;
  min-height: 38px;
  user-select: none;
  cursor: pointer;
  ${({activeColor}) => activeColor && `
    background-color: ${COLORS.LIGHTGRAY3};
    border: 1px solid ${COLORS.LIGHTGRAY};
  `}
`

const Color = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  ${({isActive}) => isActive && `
        border: 1px solid ${COLORS.LIGHTGRAY1};
  `}
`

const Row = styled.div`
  margin-right: 10px;
`

const Value = styled.div`
  font: ${FONTS.REGULAR_14};
  color: ${COLORS.GRAY};
`

const Label = styled.div`
  font: ${FONTS.REGULAR_12};
  color: ${COLORS.MAIN};
`


export default ItemColor;


