import React from 'react';
import {COLORS, FONTS} from "@/constans";
import Row from "@components/Table/TableDefault/TableOrders/Row/Row";
import Table from "@components/Table/components/Wrapper";
import GridLayout from "@components/Layout/GridLayout";
import Checkbox from "@components/Form/Checkbox";
import HeadRow from "@components/Table/components/HeadRow";
import HeadRowItem from "@components/Table/components/HeadRowItem";
import Text from "@components/Text";

function TableOrders() {
    const array = [1,2,3,4,5,6,7,8,9,10]

    return (
        <Table>
            <GridLayout gap='10px'>
                <HeadRow>
                    <GridLayout template='24px 8% 20px auto 12% 12% 4% 4% 9% 9% 9% 50px' align='center'>
                        <Checkbox/>
                        <HeadRowItem>
                            <Text value='№, Дата' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem/>
                        <HeadRowItem>
                            <Text value='Клиент' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Каталог' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Статус' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Серий' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Ед.' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Сумма' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Предоплата' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Внесено, Остаток' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem/>
                    </GridLayout>
                </HeadRow>
                <GridLayout gap='10px'>
                    {array.map(item => <Row
                        isOnline
                        isSuccess
                        catalogIcon='krista-scissors'
                        key={item}/>
                    )}
                </GridLayout>
            </GridLayout>
        </Table>
    );
}

export default TableOrders;