import PropTypes from 'prop-types';
import spritePath from '@/sprite.svg'
import styled from "styled-components";

const Icon = ({iconClass, iconName, iconWidth, iconHeight, iconColor, transform}) => {
    return (
        <Content
            className={`${iconClass}`.trim()} width={iconWidth} height={iconHeight}
            iconColor={iconColor} transform={transform}
        >
            <use xlinkHref={`${spritePath}#${iconName}`}/>
        </Content>
    );
};

export const Content = styled.svg`
  display: flex;
  pointer-events: none;
  color: ${({iconColor}) => iconColor ? iconColor : 'inherit'};
  ${({transform}) => transform && `transform: ${transform}`};
`


Icon.defaultProps = {
    iconClass: '',
    iconName: '',
    iconWidth: 24,
    iconHeight: 24,
};

Icon.propTypes = {
    iconClass:  PropTypes.string,
    iconName:  PropTypes.string,
    iconWidth: PropTypes.number,
    iconHeight: PropTypes.number,
};

export default Icon;