import PropTypes from "prop-types";
import InputLayout from "@components/Form/Inputs/InputLayout";
import {debounce} from "lodash";

const Input = (props) => {
    const {name, errors, register, required} = props
    const {placeholder, icon, iconPosition, label, type, symbol, value} = props
    const {isSmall, isBorder, disabled, isDebounce, whenChanged} = props

    const onChangeType = (e) => {
        if(whenChanged) {
            whenChanged(e)
        }
        register().onChange(e)
    }

    const isDebounceOption = () => {
        if (register) {
            if(isDebounce){
                return {onChange: debounce((e) => onChangeType(e), 300)}
            }
            if(whenChanged && !isDebounce) {
                return {onChange: (e) => onChangeType(e)}
            }
        }
    }

    return (
        <InputLayout
            symbol={symbol}
            label={label}
            errors={errors}
            icon={icon}
            iconPosition={iconPosition}
            isInvalid={errors}
            isSmall={isSmall}
            isBorder={isBorder}
        >
            <input
                {...register && {...register(`${name}`, {required: required, valueAsNumber: type === 'number'})}}
                {...isDebounceOption()}
                autoComplete="off"
                type={type}
                placeholder={placeholder}
                name={name}
                id={name}
                aria-label={label}
                disabled={disabled}
                value={value}
                className='defaultInput'
            />
        </InputLayout>
    )
}

Input.defaultProps = {
    label: '',
    icon: '',
    placeholder: '',
    type: 'text',
    name: '',
    required: false,
};

Input.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
};
export default Input;
