import React from 'react';
import {NavLink} from "react-router-dom";
import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";
import Text from "@components/Text";

function OrderNav() {
    return (
        <Nav>
            <NavItem to="/orders/order" end>
                <Text value='Заказ' font='inherit' color='inherit'/>
            </NavItem>
            <NavItem to="/orders/order/changes" end>
                <Text value='Изменения' font='inherit' color='inherit'/>
            </NavItem>
        </Nav>
    );
}

const Nav = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;
  border-bottom: 1px solid ${COLORS.LIGHTGRAY1};
  padding: 0 20px;
  margin-bottom: 24px;
`

const NavItem = styled(NavLink)`
  position: relative;
  padding-bottom: 14px;
  text-decoration: none;
  font: ${FONTS.REGULAR_14};
  color: ${COLORS.LIGHTGRAY};
    &.active {
      color: ${COLORS.BLACK};
      font: ${FONTS.MEDIUM_14};
      &:after {
        background-color: ${COLORS.MAIN};
      }
    }
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 50%;

    transform: translateX(-50%);
    height: 2px;
    width: 100%;
  }
`

export default OrderNav;