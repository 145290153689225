import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";
import FlexLayout from "@components/Layout/FlexLayout";
import Icon from "@components/Icon/Icon";
import Text from "@components/Text";

const UploadFile = ({onFileChange, isSmall}) => {
    return (
        <Content>
            <FlexLayout align='center' alignSelf='end' spaceBetween>
                <Icon
                    iconName='krista-upload'
                    iconClass=''
                    iconWidth={24}
                    iconHeight={24}
                />
            </FlexLayout>
            <Input type="file" onChange={onFileChange}/>
            {!isSmall &&
                <Text
                    value='Загрузить графис размера'
                    font={FONTS.SEMI_BOLD_14}
                    color={COLORS.WHITE}
                    margin='0 0 0 8px'
                />
            }
        </Content>
    );
}

const Content = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.MAIN};
  color: ${COLORS.WHITE};
  padding: 12px 0;
  border-radius: 10px;
  cursor: pointer;
  align-self: end;
`

const Input = styled.input`
  display: none;
`

export default UploadFile;