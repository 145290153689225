import {useSelector} from "react-redux";
import Row from "@components/Table/TableDefault/TableNewUsers/Row/Row";
import Table from "@components/Table/components/Wrapper";
import HeadRow from "@components/Table/components/HeadRow";
import GridLayout from "@components/Layout/GridLayout";
import HeadRowItem from "@components/Table/components/HeadRowItem";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";

const TableNewUsers = () => {
    const clients = useSelector(state => state.registrationRequests.notApprovedClientsState?.items)
    return (
        <Table overflow='visible'>
            <GridLayout gap='10px' position='relative'>
                <HeadRow isTopOffset>
                    <GridLayout template='20% 20% 18% 15% 15% 100px' align='center'>
                        <HeadRowItem>
                            <Text value='Имя' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Контакты' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Город и ТЦ' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Регистрация' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Менеджер' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Действие' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                    </GridLayout>
                </HeadRow>
                <GridLayout gap='10px'>
                    {clients.map(item => <Row item={item} key={item.id}/>)}
                </GridLayout>
            </GridLayout>
        </Table>
    );
};

export default TableNewUsers;