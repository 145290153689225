import {SET_USER_DETAIL} from "@types/types";
import {getLocalStorage} from "../../util/getLocalStorage";

const initState = {
    userState: {
        name: getLocalStorage('adminName'),
        id: +getLocalStorage('adminId'),
        rolaName: getLocalStorage('adminRoleName'),
        token: getLocalStorage('adminToken'),
        refreshToken: getLocalStorage('adminRefreshToken'),
    },
}

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_USER_DETAIL :
            return {...state, userState: action.payload}
        default:
            return state
    }
}
export default userReducer