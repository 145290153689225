import BodyRow from "@components/Table/components/BodyRow";
import GridLayout from "@components/Layout/GridLayout";
import BodyRowItem from "@components/Table/components/BodyRowItem";
import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";
import Color from "@components/Table/components/Color";
import Button from "@components/Form/Button";
import {NumericFormat} from "react-number-format";
import Image from "@components/Image/Image";
import {Link} from "react-router-dom";
import dayjs from "dayjs";

function Row({template, document}) {

    return (
        <BodyRow backgroundColor={COLORS.WHITE}>
            <GridLayout align='center' template={template}>
                <BodyRowItem>
                    <Text value={document.serialNumber} font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value={document.documentTypeName} font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text
                        value={document.directionName}
                        font={FONTS.SEMI_BOLD_14}
                        color={document.direction === "Out" ? COLORS.RED : COLORS.STATUS_GREEN}
                    />
                </BodyRowItem>
                <BodyRowItem>
                    <Text value={document?.executedByEmployeeName} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                        {document?.executionDate &&
                            <Text
                                value={dayjs(document?.executionDate).format('DD.MM.YYYY, HH:MM')}
                                font={FONTS.REGULAR_16}
                                color={COLORS.DARKGRAY}
                            />
                        }
                </BodyRowItem>
                <BodyRowItem>
                    <div style={{display: 'flex'}}>
                        <Text
                            value={document?.statusName}
                            font={FONTS.REGULAR_16}
                            color={COLORS.WHITE}
                            otherStyle={`
                                padding: 2px 10px;
                                background-color: ${document.statusHexColor};
                                border-radius: 20px;
                            `}
                        />
                    </div>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value={document?.quantity} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <NumericFormat
                        value={document.totalSum}
                        thousandSeparator=" "
                        decimalScale={2}
                        displayType="text"
                        renderText={(value) => <Text value={value} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>}
                        fixedDecimalScale
                        suffix={` ${document.currencySymbol}`}
                    />
                </BodyRowItem>

                <BodyRowItem>
                    <Button variant='isMainV3' buttonText='Открыть' isMinimal to={`/store/${document.uri}`} as={Link}/>
                </BodyRowItem>
            </GridLayout>
        </BodyRow>
    );
}

export default Row;