import React, {useState} from 'react';
import {COLORS, FONTS} from "@/constans";

import Icon from "@components/Icon/Icon";
import TableSecondaryOrdersModel from "@components/Table/TableDefault/TableSecondaryOrdersModel/TableSecondaryOrdersModel";
import Table from "@components/Table/components/Wrapper";
import hexToRgba from "hex-to-rgba";
import Checkbox from "@components/Form/Checkbox";
import HeadRowItem from "@components/Table/components/HeadRowItem";
import HeadRow from "@components/Table/components/HeadRow";
import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";
import Color from "@components/Table/components/Color";

function RowChild({catalogIcon}) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Table backgroundColor={hexToRgba(COLORS.YELLOW, 0.1)}>
            <HeadRow onClick={() => setIsOpen(!isOpen)} backgroundColor='transparent' margin='0'>
                <GridLayout template='40px 80px 8% 10% 10% 17% 6% 6% 6% 7% auto 4%' align='center'>
                    <HeadRowItem>
                        <Checkbox/>
                    </HeadRowItem>
                    <HeadRowItem></HeadRowItem>
                    <HeadRowItem>
                        <Text value='10-01 к/р' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                    </HeadRowItem>
                    <HeadRowItem>
                        <Text value='36-38-40' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                    </HeadRowItem>
                    <HeadRowItem>
                        <FlexLayout align='center'>
                            <Color backgroundColor='#50B5FF'/>
                            <Text
                                value='Блюстоун'
                                font={FONTS.MEDIUM_16}
                                color={COLORS.DARKGRAY}
                                margin='0 0 0 8px'
                            />
                        </FlexLayout>
                    </HeadRowItem>
                    <HeadRowItem>
                        <FlexLayout align='center'>
                            <Icon
                                iconName={catalogIcon}
                                iconWidth={20}
                                iconHeight={20}
                                iconColor={COLORS.LIGHTGRAY}
                            />
                            <Text
                                value='Предзаказ сериями'
                                font={FONTS.REGULAR_16}
                                color={COLORS.DARKGRAY}
                                margin='0 0 0 8px'
                            />
                        </FlexLayout>
                    </HeadRowItem>
                    <HeadRowItem>
                        <Text value='24' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                    </HeadRowItem>
                    <HeadRowItem>
                        <Text value='72' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                    </HeadRowItem>
                    <HeadRowItem>
                        <Text value='96' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                    </HeadRowItem>
                    <HeadRowItem>
                        <Text value='35.00 $' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                    </HeadRowItem>
                    <HeadRowItem>
                        <Text value='1 320.00 $' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                    </HeadRowItem>
                    <HeadRowItem>
                        <GridLayout gap='1px' template='auto auto' align='center' margin='0 0 0 auto'>
                            <Icon
                                iconName='krista-people'
                                iconWidth={24}
                                iconHeight={24}
                                iconColor={COLORS.LIGHTGRAY}
                            />
                            <Icon
                                iconName='krista-arrow-top-v2'
                                iconWidth={12}
                                iconHeight={24}
                                iconColor={hexToRgba(COLORS.LIGHTGRAY, 0.4)}
                                transform={isOpen ? 'rotate(0)' : 'rotate(180deg)'}
                            />
                        </GridLayout>
                    </HeadRowItem>
                </GridLayout>

            </HeadRow>
            {isOpen &&
                <Table margin='6px 16px 16px 16px' border={`1px solid ${COLORS.LIGHTGRAY1}`} borderRadius='10px 10px 0 0'>
                    <TableSecondaryOrdersModel/>
                </Table>
            }
        </Table>
    );
}

export default RowChild;