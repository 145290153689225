import ApiConfig from "@/ApiConfigStore";

const path = 'documents/audit/'

export const getAuditCreate = async () => {
    return await ApiConfig.post(`${path}create`)
};

export const getAuditProductScan = async (data) => {
    return await ApiConfig.post(`${path}product/scan`, data)
};

export const getAuditProductScanCancel = async (data) => {
    return await ApiConfig.post(`${path}product/scan/cancel`, data)
};

export const getAudit = async (auditDocumentId) => {
    return await ApiConfig.get(`${path}${auditDocumentId}`)
};

export const getAuditDelete = async (auditDocumentId) => {
    return await ApiConfig.delete(`${path}${auditDocumentId}/delete`)
};

export const getAuditFinish = async (auditDocumentId) => {
    return await ApiConfig.post(`${path}${auditDocumentId}/finish`)
};

export const getAuditProductDelete = async (auditDocumentId, productId) => {
    return await ApiConfig.delete(`${path}${auditDocumentId}/product/delete/${productId}`)
};


