import PropTypes from "prop-types";
import CardStatusLayout from "@components/Cards/CardStatus/CardStatusLayout/CardStatusLayout";
import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";

const CardStatusHome = (props) => {
    const {linkPath, linkTitle, title, subtitle, quantity, iconName, color} = props
    const {leftTotal, rightTotal} = props
    return (
        <CardStatusLayout
            linkTitle={linkTitle}
            linkPath={linkPath}
            title={title}
            subtitle={subtitle}
            quantity={quantity}
            iconName={iconName}
            color={color}
        >
            <FlexLayout gap='20px' align='center' margin='auto 0 0 0'>
                <div>
                    <Text
                        value={`${leftTotal.value} ${leftTotal.suffix}`}
                        font={FONTS.REGULAR_18}
                        color={COLORS.BLACK}
                    />
                    <Text
                        value={leftTotal.label}
                        font={FONTS.REGULAR_12}
                        color={COLORS.GRAY}
                        margin='0 0 4px 0'
                    />
                </div>
                <div>
                    <Text
                        value={`${rightTotal.value} ${rightTotal.suffix}`}
                        font={FONTS.REGULAR_18}
                        color={COLORS.BLACK}
                    />
                    <Text
                        value={rightTotal.label}
                        font={FONTS.REGULAR_12}
                        color={COLORS.GRAY}
                        margin='0 0 4px 0'
                    />
                </div>
            </FlexLayout>
        </CardStatusLayout>
    );
};

CardStatusHome.defaultProps = {
    linkPath: '',
    linkTitle: '',
    title: '',
    subtitle: '',
    quantity: '',
    iconName: '',
    leftTotal: {
        value: '',
        label: '',
        suffix: ''
    },
    rightTotal: {
        value: '',
        label: '',
        suffix: ''
    },
};

CardStatusHome.propTypes = {
    linkPath: PropTypes.string,
    linkTitle: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    quantity: PropTypes.string,
    iconName: PropTypes.string,
    leftTotal: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        suffix: PropTypes.string,
    }),
    rightTotal: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        suffix: PropTypes.string,
    }),
};
export default CardStatusHome;
