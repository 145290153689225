import SelectLayout from "@components/Form/Selects/SelectLayout";

const Select = (params) => {
    const {errors, label, name, control, setValue} = params
    const {values, isSmall, closeMenuOnSelect, placeholderText, isBorder, hasClearIndicator} = params
    const {getOptionLabel, getOptionValue, getOptionObjectKey, whenChanged, onClear, isDisabled} = params
    return (
        <SelectLayout
            values={values}
            name={name}
            control={control}
            whenChanged={whenChanged}
            onClear={onClear}
            errors={errors}
            label={label}
            setValue={setValue}
            isSmall={isSmall}
            isBorder={isBorder}
            closeMenuOnSelect={closeMenuOnSelect}
            placeholder={placeholderText}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            getOptionObjectKey={getOptionObjectKey}
            hasClearIndicator={hasClearIndicator}
            isDisabled={isDisabled}
        />
    );
};

export default Select;