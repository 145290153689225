import {useState} from "react";
import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";

import TableOrderChanges from "@components/Table/TableDefault/TableOrderChanges/TableOrderChanges";
import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";
import Button from "@components/Form/Button";

function OrderChangesHistory({title}) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
            <Wrapper isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                <GridLayout template='16% 13% 13% 13% 13% auto' gap='40px' align='center'>
                    <Text value={title} font={FONTS.SEMI_BOLD_16} color={COLORS.BLACK}/>
                    <FlexLayout gap='4px'>
                        <Text value='Менеджер:' font={FONTS.REGULAR_16} color={COLORS.LIGHTGRAY}/>
                        <Text value='Ажара' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                    </FlexLayout>
                    <FlexLayout gap='4px'>
                        <Text value='Добавлено:' font={FONTS.REGULAR_16} color={COLORS.LIGHTGRAY}/>
                        <Text value='16 серий' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                    </FlexLayout>
                    <FlexLayout gap='4px'>
                        <Text value='Было:' font={FONTS.REGULAR_16} color={COLORS.LIGHTGRAY}/>
                        <Text value='0 серий' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                    </FlexLayout>
                    <FlexLayout gap='4px'>
                        <Text value='Стало:' font={FONTS.REGULAR_16} color={COLORS.LIGHTGRAY}/>
                        <Text value='16 серии' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                    </FlexLayout>
                    <FlexLayout align='center' margin='0 0 0 auto'>
                        <Text
                            value='23.06.2022, 18:30'
                            font={FONTS.REGULAR_16}
                            color={COLORS.LIGHTGRAY}
                            margin='0 24px 0 0'
                        />
                        <Button
                            isSmall
                            iconName={isOpen ? 'krista-chevron-top' : 'krista-chevron-bottom'}
                            variant='isWhite'
                        />
                    </FlexLayout>
                </GridLayout>
            </Wrapper>
            {isOpen && <TableOrderChanges/>}
        </div>
    );
}

const Wrapper = styled.div`
  background: transparent;
  border: 1px solid ${COLORS.LIGHTGRAY1};
  border-radius: 14px;
  padding: 15px;
  cursor: pointer;
  ${({isOpen}) => isOpen && `
    border-radius: 14px 14px 0 0;
    border-bottom: 0;
  `}
`

export default OrderChangesHistory;