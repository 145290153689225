import ApiConfig from "@/ApiConfig";
import {SET_ALL_CITY, SET_MODAL_CREATE_CITY, SET_NEW_CITY_ID} from "@types/types";
import {toast} from "react-toastify";
import ToastContent from "@components/ToastContent/ToastContent";
import store from '@store'

export const getAllCities = (cityId) => {
    ApiConfig.get(`Common/City`).then((response) => {
        const cityData = response.data
        //TODO: Города должны быть с пагинацией
        response.data.push({name: 'Выбрать другой город', id: -1})
        store.dispatch({type: SET_ALL_CITY, payload: cityData})
    })
};
export const getCreateCity = (data, setIsButtonDisabled) => {
    ApiConfig.post(`Common/City`, data).then((response) => {
        toast.success(<ToastContent title="Успешная операция" response={response.data.message} ok/>);
        getAllCities()
        store.dispatch({type: SET_NEW_CITY_ID, payload: response.data.value})
        store.dispatch({type: SET_MODAL_CREATE_CITY, payload: false})
        setIsButtonDisabled(false)
    }).catch(() => {
        setIsButtonDisabled(false)
    })
};