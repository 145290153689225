import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text/Text";
import {FONTS} from "@/constans";
import Box from "@components/Box";
import UploadFileLayouts from "@components/Upload/UploadFileLayouts/UploadFileLayouts";

function Graphis({readOnly, watch, setValue}) {
    const fieldName = "graphis"
    const watchValue = watch(fieldName)
    return (
        <Box>
            <GridLayout gap='16px'>
                <Text value='Графис' as='h3' font={FONTS.SEMI_BOLD_16}/>
                <UploadFileLayouts
                    watch={watch}
                    setValue={setValue}
                    readOnly={readOnly}
                    fieldName={fieldName}
                    watchValue={watchValue}
                />
            </GridLayout>
        </Box>
    );
}

export default Graphis;