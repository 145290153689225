import {SET_COLLECTION} from "@types/types";

const initState = {
   collectionState: [],
}

const collectionReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_COLLECTION :
            return {...state, collectionState: action.payload}
        default:
            return state
    }
}
export default collectionReducer