import React from "react";
import {COLORS, FONTS} from "@/constans";
import Icon from "@components/Icon/Icon";
import BodyRow from "@components/Table/components/BodyRow";
import GridLayout from "@components/Layout/GridLayout";
import BodyRowItem from "@components/Table/components/BodyRowItem";
import Image from "@components/Image/Image";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";
import Color from "@components/Table/components/Color";

function Row({catalogIcon}) {
    return (
        <BodyRow backgroundColor={COLORS.WHITE}>
            <GridLayout template='100px 10% 11% 12% auto 8% 8% 10% 10% 36px' align='center'>
                <BodyRowItem>
                    <Image src='https://picsum.photos/200/100'/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='10-01 к/р' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='42-44' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <FlexLayout align='center'>
                        <Color backgroundColor='#005CCC'/>
                        <Text value='Блюстоун' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY} margin='0 0 0 8px'/>
                    </FlexLayout>
                </BodyRowItem>
                <BodyRowItem>
                    <FlexLayout align='center'>
                        <Icon
                            iconName={catalogIcon}
                            iconWidth={20}
                            iconHeight={20}
                            iconColor={COLORS.LIGHTGRAY}
                        />
                        <Text value='Предзаказ сериями' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY} margin='0 0 0 8px'/>
                    </FlexLayout>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='48' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='35.00 $' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='1 320.00 $' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='520.00 $' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
            </GridLayout>
        </BodyRow>
    );
}

export default Row;