import {
    SET_MODEL_COLOR,
    SET_MODEL_ALL_SIZE,
    SET_MODEL_ACTIVE_SIZE,
    SET_MODEL_ACTIVE_COLOR,
    SET_CURRENT_ARTICLE
} from "@types/types";

const initState = {
    modelActiveSize: '417cdb08-1e3a-46b4-8153-c43218f93064',
    modelActiveColor: 0,
    modelAllSizeState: [],
    modelColorState: null,
    modelArticleTaskState: null
}

const articulDesignTaskReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_MODEL_ACTIVE_SIZE :
            return {...state, modelActiveSize: action.payload}
        case SET_MODEL_ACTIVE_COLOR :
            return {...state, modelActiveColor: action.payload}
        case SET_MODEL_ALL_SIZE :
            return {...state, modelAllSizeState: action.payload}
        case SET_MODEL_COLOR :
            return {...state, modelColorState: action.payload}
        case SET_CURRENT_ARTICLE:
            return {...state, modelArticleTaskState: action.payload}
        default:
            return state
    }
}
export default articulDesignTaskReducer