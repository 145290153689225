import {SET_DESIGN_TASKS, SET_DESIGN_TASKS_STATUS_BOARD} from "@types/types";
import {getDesignTasksStatusBoard} from "@actions/designTaskAction";

const initState = {
    designTasksState: null,
    designTasksStatusBoardState: null
}

const designTaskReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_DESIGN_TASKS :
            return {...state, designTasksState: action.payload}
        case SET_DESIGN_TASKS_STATUS_BOARD :
            return {...state, designTasksStatusBoardState: action.payload}

        default:
            return state
    }
}

export default designTaskReducer