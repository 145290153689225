import Row from "@components/Table/TableDefault/TableAllModelConstructor/Row/Row";
import {ReactSortable} from "react-sortablejs";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import HeadRow from "@components/Table/components/HeadRow";
import HeadRowItem from "@components/Table/components/HeadRowItem";
import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import Table from "@components/Table/components/Wrapper";

function TableAllModelConstructor({isLoadTable, articulName, getValues, setValue, watch, sizesCount, colorsCount}) {
    const sizes = useSelector(state => state.model.modelAllSizeState)
    const articulTaskPriorities = watch('articulTaskPriorities')
    const filteredArticul = articulTaskPriorities.filter(item => !item.hasOwnProperty('isNew'))
    const newArticulIndex = watch('articulTaskPriorities')?.findIndex(item => item.hasOwnProperty('isNew')) + 1

    const newArray = [...filteredArticul, {
        articulName: articulName, isNew: true, sizeLinesCount: sizesCount, colorsCount: colorsCount,
    }]

    useEffect(() => {
        if (articulTaskPriorities && articulName) {
            setValue('articulTaskPriorities', newArray)
        }
        if (articulTaskPriorities && articulName === '') {
            setValue('articulTaskPriorities', filteredArticul)
        }
    }, [articulName, sizes])

    useEffect(() => {
        setValue('priority', newArticulIndex)
    }, [newArticulIndex])

    return (
        <Table ariaLabel='Список артикулов' overflow='hidden' border={`1px solid ${COLORS.LIGHTGRAY1}`} borderRadius='10px'>
            {getValues('articulTaskPriorities').length > 0 &&
                <HeadRow borderRadius='0' zIndex='0'>
                    <GridLayout gap='8px' template='25% 25% 25% 25%' align='center'>
                        <HeadRowItem>
                            <Text value='Приоритет' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Артикул' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Рядов' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Цветов' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                    </GridLayout>
                </HeadRow>
            }
            <ReactSortable
                tag={"div"}
                list={articulTaskPriorities}
                setList={(items) => {
                    setValue('articulTaskPriorities', items)
                }}
                group="groupName"
                animation={200}
                delay={1}
            >
                {articulTaskPriorities?.map((item, index) => (
                    <Row item={item} index={index} key={item.id + index} ariaLabel='Артикул'/>
                ))}
            </ReactSortable>
        </Table>
    );
}

export default TableAllModelConstructor;