import Box from "@components/Box";
import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text/Text";
import {FONTS} from "@/constans";
import UploadFileLayouts from "@components/Upload/UploadFileLayouts/UploadFileLayouts";

function Layouts({update, fields, readOnly, watch}) {
    return (
        <Box>
            <GridLayout gap='16px'>
                <Text value='Раскладки' as='h3' font={FONTS.SEMI_BOLD_16}/>
                {fields.map((item, index) => (
                    <UploadFileLayouts
                        item={item}
                        index={index}
                        update={update}
                        fields={fields}
                        readOnly={readOnly}
                        isMultiple
                        watch={watch}
                    />
                ))}
            </GridLayout>
        </Box>
    );
}

export default Layouts;