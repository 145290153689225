import * as yup from "yup";

export const validateSchema = yup.object().shape({
    collectionId: yup.number().nullable().required('Обязательное поле'),
    articulName: yup.string().required('Обязательное поле'),
    constructorId: yup.number().nullable().required('Обязательное поле'),
    models: yup.array().of(
        yup.object().shape({
            priority: yup.number(),
            colors: yup.array().of(
                yup.object().shape({
                    id: yup.number(),
                    textileFields: yup.array().of(
                        yup.object().shape({
                            colors: yup.array()
                                .test('textile required', 'Выберите ткань', (value) => value !== null)
                                .nullable()
                                .of(yup.object().shape({
                                    id: yup.string().required('Выберите другую ткань')
                                })),
                            colorSelected: yup.object()
                                .nullable()
                                .shape({
                                    id: yup.number()
                                        .required('Выберите другой цвет')
                                        .nullable()
                                        .test('textile required', 'Выберите цвет', (value) => value !== null)
                                    ,
                                    price: yup.object()
                                        .test('price required', 'Ошибка в цене', (value) => value !== null)
                                        .nullable()
                                        .shape({
                                            price: yup.number()
                                        })
                                })
                        })
                    ).min(1, 'Не выбрана основная ткань'),
                    accessoriesFields: yup.array().of(
                        yup.object().shape({
                            colors: yup.array()
                                .test('accessory required', 'Выберите фурнитуру', (value) => value !== null)
                                .nullable()
                                .of(yup.object().shape({
                                    id: yup.string().required('Выберите другую фурнутиру')
                                })),
                            colorSelected: yup.object()
                                .nullable()
                                .shape({
                                    id: yup.number()
                                        .required('Выберите другой цвет')
                                        .nullable()
                                        .test('textile required', 'Выберите цвет', (value) => value !== null)
                                    ,
                                    price: yup.object()
                                        .test('price required', 'Ошибка в цене', (value) => value !== null)
                                        .nullable()
                                        .shape({
                                            price: yup.number()
                                        })
                                })
                        })
                    ),
                })
            ).min(1, 'Цвет не выбран в'),
            remark: yup.string().max(511, 'не более 511 символов')
        })
    )
})

export const defaultValues = {
    collectionId: null,
    articulName: '',
    constructorId: null,
    articulTaskPriorities: [],
    // models: [{
    //     size: {
    //         id: 32,
    //         value: "44 - 50",
    //         isLine: true,
    //         subSizes: [
    //             {id: 36, value: "44", isTopPriority: false},
    //             {id: 37, value: "46", isTopPriority: false},
    //             {id: 38, value: "48", isTopPriority: false},
    //             {id: 39, value: "50", isTopPriority: true}
    //         ]
    //     },
    //     colors: [
    //         {
    //             accessoriesFields: [],
    //             textileFields: [
    //                 {
    //                     colorSelected: {
    //                         color: {id: null, name: ""},
    //                         id: null,
    //                         materialPhoto: "",
    //                         price: {
    //                             currencyCode: "",
    //                             id: null,
    //                             price: "",
    //                         }
    //                     },
    //                     colors: null,
    //                     id: null,
    //                     name: "",
    //                     supplierId: null,
    //                     supplierName: "",
    //                 }
    //             ],
    //             hex: "#FFFFFF",
    //             id: 31,
    //             isMain: true,
    //             name: "Абрикос №89",
    //         }
    //     ]
    // }]
}