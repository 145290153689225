import Box from "@components/Box";
import GridLayout from "@components/Layout/GridLayout";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";
import Icon from "@components/Icon";
import styled from "styled-components";
import hexToRgba from "hex-to-rgba";

function StoreCard({title, subtitle, iconName, color}) {
    return (
        <Box padding='20px'>
            <FlexLayout spaceBetween align='center'>
                <GridLayout gap='1px'>
                    <Text value={title} font={FONTS.SEMI_BOLD_14} color={COLORS.BLACK}/>
                    {subtitle}
                </GridLayout>
                <IconContent color={color}>
                    <Icon
                        iconName={iconName}
                        iconColor={color ? color : COLORS.LIGHTGRAY}
                        iconWidth={24}
                        iconHeight={24}
                    />
                </IconContent>
            </FlexLayout>
        </Box>
    );
}

const IconContent = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 10px;
  background-color: ${({color}) => color ? hexToRgba(color, 0.1) : COLORS.LIGHTGRAY2};
  display: flex;
  justify-content: center;
  align-items: center;
`

export default StoreCard;