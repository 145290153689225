import FooterRow from "@components/Table/components/FooterRow/FooterRow";
import GridLayout from "@components/Layout/GridLayout";
import FooterRowItem from "@components/Table/components/FooterRowItem";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";
import React from "react";
import FlexLayout from "@components/Layout/FlexLayout";
import {NumericFormat} from "react-number-format";

function Footer({template, totals}) {
    return (
        <FooterRow padding='24px 8px' border='0' backgroundColor={COLORS.WHITE}>
            <GridLayout template={template} align='center'>
                <FooterRowItem>
                    <FlexLayout gap='4px' align='center'>
                        <Text value='Всего товаров:' font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY}/>
                        <Text value={totals?.totalProducts} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                    </FlexLayout>
                </FooterRowItem>
                <FooterRowItem/>
                <FooterRowItem/>
                <FooterRowItem/>
                <FooterRowItem>
                    <Text value={totals?.totalQuantity} font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                </FooterRowItem>
                <FooterRowItem>
                    <NumericFormat
                        value={totals?.totalSum}
                        thousandSeparator=" "
                        decimalScale={2}
                        displayType="text"
                        renderText={(value) => <Text value={value} font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>}
                        fixedDecimalScale
                        suffix={` ${totals?.curencySymbol}`}
                    />
                </FooterRowItem>
                <FooterRowItem/>
            </GridLayout>
        </FooterRow>
    );
}

export default Footer;