import {SET_TAX_PAYER_TYPE} from "@types/types"

const initState = {
    taxPayerTypeState: null
}

const taxPayerTypeReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_TAX_PAYER_TYPE :
            return {...state, taxPayerTypeState: action.payload}
        default:
            return state
    }
}

export default taxPayerTypeReducer