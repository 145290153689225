import {NumericFormat} from "react-number-format";
import {Controller} from 'react-hook-form'
import InputLayout from "@components/Form/Inputs/InputLayout";
import {debounce} from "lodash";
import React from "react";

const InputNumeric = (props) => {
    const {name, control, errors} = props
    const {placeholder, icon, iconPosition, label, symbol} = props
    const {isSmall, disabled, isDebounce, isBorder} = props
    const {decimalScale, decimalSeparator, thousandSeparator, fixedDecimalScale} = props
    const {onChange, whenChanged, value} = props

    const onChangeType = (newField, e) => {
        if(whenChanged) {
            whenChanged(e)
        }
        newField.onChange(e)
    }

    const numericFormatProps = () => {
        return {
            valueAsNumber: true,
            type: 'input',
            icon: icon,
            disabled: disabled,
            placeholder: placeholder,
            className: 'inputNumeric',
            decimalScale: decimalScale ? decimalScale : 0,
            decimalSeparator: decimalSeparator,
            fixedDecimalScale: fixedDecimalScale,
            thousandSeparator: thousandSeparator && thousandSeparator,
            min: 0,
        }
    }
    if (control) {
        return (
            <Controller
                control={control}
                name={name}
                render={({field: {ref, ...newField}}) => {
                    return (
                        <InputLayout
                            symbol={symbol}
                            label={label}
                            errors={errors}
                            isSmall={isSmall}
                            icon={icon}
                            iconPosition={iconPosition}
                            isBorder={isBorder}
                        >
                            <NumericFormat
                                {...newField}
                                {...numericFormatProps(newField)}
                                getInputRef={ref}
                                onChange={isDebounce ? debounce((e) => onChangeType(newField, e), 300) : (e) => onChangeType(newField, e)}
                            />
                        </InputLayout>
                    )
                }}
            />
        )
    }
    return (
        <InputLayout
            symbol={symbol}
            label={label}
            errors={errors}
            isSmall={isSmall}
            icon={icon}
            iconPosition={iconPosition}
            isBorder={isBorder}
        >
            <NumericFormat
                {...numericFormatProps()}
                value={value}
                onChange={isDebounce ? debounce(onChange, 300) : onChange}
            />
        </InputLayout>
    )
}

export default InputNumeric;
