import React, {useEffect} from 'react';
import Button from "@components/Form/Button";
import GridLayout from "@components/Layout/GridLayout";
import ItemTextile from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/ItemTextile";
import {useFieldArray} from "react-hook-form";
import {useSelector} from "react-redux";

const GroupTextile = ({itemSizeIndex, itemColor, control, register, watch, isBorder}) => {
    const activeColor = useSelector(state => state.model.modelActiveColor)

    const {
        fields: textileFields,
        append: appendTextile,
        remove: removeTextile,
        update: updateTextile
    } = useFieldArray({
        control,
        name: `articul.modelDesignTasks.${itemSizeIndex}.colors.${activeColor}.materialCalculations.textile`,
        keyName: 'idField'
    });

    const {
        fields: accessoriesFields,
        append: appendAccessories,
        remove: removeAccessories,
        update: updateAccessories
    } = useFieldArray({
        control,
        name: `articul.modelDesignTasks.${itemSizeIndex}.colors.${activeColor}.materialCalculations.accessories`,
        keyName: 'idField'
    });

    return (
        <GridLayout gap="20px">

            <div>
                {textileFields.map((itemTextile, indexTextile) =>
                    <ItemTextile
                        register={register} watch={watch}
                        remove={removeTextile}
                        update={updateTextile}
                        itemColor={itemColor}
                        itemSizeIndex={itemSizeIndex}
                        itemTextile={itemTextile}
                        indexTextile={indexTextile}
                        isTextile
                    />
                )}
                <Button
                    buttonText="Добавить материал"
                    iconName='krista-plus'
                    ariaLabel='Добавить материал'
                    isSmall
                    onClick={
                        () => appendTextile({
                                isMain: textileFields.length <= 0,
                                isNew: true,
                                material: {price: {}},
                            }
                        )
                    }
                />
            </div>

            <div>
                {accessoriesFields.map((itemTextile, indexTextile) =>
                    <ItemTextile register={register} watch={watch} update={updateAccessories} itemTextile={itemTextile} indexTextile={indexTextile}/>
                )}
                <Button
                    buttonText="Добавить фурнитуру"
                    iconName='krista-plus'
                    ariaLabel='Добавить фурнитуру'
                    isSmall
                    onClick={() => appendAccessories({
                            material: {
                                type: {
                                    id: "accessories"
                                }
                            },
                            colors: null,
                            id: "",
                            name: "",
                            supplierId: null,
                            supplierName: ""
                        }
                    )}
                />
            </div>

        </GridLayout>
    );
};
export default GroupTextile;