import {COLORS} from "@/constans";
import styled from "styled-components";

function FooterRow({children, padding, margin, border, backgroundColor}) {
    return (
        <FooterRowContent padding={padding} margin={margin} border={border} backgroundColor={backgroundColor}>
            {children}
        </FooterRowContent>
    );
}

const FooterRowContent = styled.div`
  border: ${({border}) => border ? border : `1px solid ${COLORS.LIGHTGRAY1}`};
  padding: ${({padding}) => padding ? padding : '16px 8px'};
  overflow: hidden;
  margin: ${({margin}) => margin ? margin : '10px 0 0 0'};
  ${({backgroundColor}) => backgroundColor && `background-color: ${backgroundColor}`};
  border-radius: 10px;
`

export default FooterRow;