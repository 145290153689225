import {useDispatch, useSelector} from "react-redux";
import {SET_MODEL_ACTIVE_SIZE} from "@types/types";
import PropTypes from "prop-types";
import {getValue} from "@util/stringKeysToNestedKeys";
import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";
import TabSize from "@pages/ProductionPages/ModelDevelopmentPage/components/TabSize";
import {useSearchParams} from "react-router-dom";

const ItemSize = (props) => {

    const {register} = props
    const {sizeItem, subSizes, sizeItemIndex} = props

    const [searchParams, setSearchParams] = useSearchParams()

    return (
        <Item
            activeSize={searchParams?.get('activeSize') == sizeItem.id}
            itemIndex={sizeItemIndex}
            onClick={() => setSearchParams({activeSize: sizeItem.id})}
            aria-label={`Размер ${sizeItem.value}`}
        >
            <Row>
                <input
                    {...register(`models.${sizeItemIndex}.priority`, {valueAsNumber: true})}
                    hidden
                    type="number"
                    value={sizeItemIndex + 1}
                />
                {subSizes?.length > 0 && (
                    subSizes?.map(subSizeItem => (
                        <WrapperValue key={subSizeItem.id}>
                            <Value isActiveSize={subSizeItem.isTopPriority}>
                                {subSizeItem.value}
                            </Value>
                        </WrapperValue>
                    ))
                )}
                {sizeItem.value && subSizes.length < 1 && <Value isActiveSize>{sizeItem.value}</Value>}
            </Row>
            <Count>{sizeItemIndex + 1}</Count>
        </Item>
    );
};

ItemSize.defaultProps = {
    sizeItem: {},
};

ItemSize.propTypes = {
    sizeItem: PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
        subSizes: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            value: PropTypes.string,
            isTopPriority: PropTypes.bool
        }))
    }),

};

const Item = styled.div`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  user-select: none;
  align-self: stretch;

  &:hover {
    border-bottom-color: ${COLORS.MAIN};
  }

  ${({activeSize}) => activeSize && `
    border-bottom-color: ${COLORS.MAIN};
    background: linear-gradient(180deg, rgba(0, 115, 255, 0) 0%, rgba(0, 115, 255, 0.1) 100%);
  `}
`

const Row = styled.div`
  display: flex;
  background: #FFFFFF;
  border-radius: 16px;
  padding: 2px;
`

const Count = styled.div`
  color: ${COLORS.LIGHTGRAY};
  font: ${FONTS.REGULAR_12};
  margin-top: 4px;
  text-align: center;
`
const WrapperValue = styled.div`
  display: flex;

  &:after {
    content: '-';
    margin: 0 4px;
  }

  &:last-child {
    &:after {
      display: none;
      margin: 0;
    }
  }
`
const Value = styled.div`
  background: #F1F1F5;
  border-radius: 12px;
  color: ${COLORS.BLACK};
  font: ${FONTS.REGULAR_14};
  height: 22px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${({isActiveSize}) => isActiveSize && `
    color: ${COLORS.WHITE};
    background-color: ${COLORS.MAIN};
  `}
`

const Decor = styled.div`
  font: ${FONTS.REGULAR_14};
  color: ${COLORS.LIGHTGRAY};
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`


export default ItemSize;