import React, {useState} from 'react';
import Icon from "@components/Icon/Icon";
import {COLORS} from "@/constans";
import styled from "styled-components";
import {NumericFormat} from "react-number-format";
import {useForm} from "react-hook-form";
import {Controller} from 'react-hook-form'

function Counter({value, min, max, disabled, placeholder, control, name, watch}) {

    return (
        <CounterContent>
            <CounterButton>
                <Icon
                    iconName='krista-minus'
                    iconHeight={24}
                    iconWidth={24}
                />
            </CounterButton>
            {/*<Controller*/}
            {/*    control={control}*/}
            {/*    name={name}*/}
            {/*    render={({field}) => (*/}
                    <NumericFormat
                        // {...field}
                        type='input'
                        disabled={disabled}
                        placeholder={placeholder}
                        className='counter'
                        min={0}
                    />
                {/*)}*/}
            {/*/>*/}
            <CounterButton>
                <Icon
                    iconName='krista-plus-v2'
                    iconHeight={24}
                    iconWidth={24}
                />
            </CounterButton>
        </CounterContent>
    );
}

const CounterContent = styled.div`
  display: inline-flex;

  border-radius: 8px;
  background: #FAFAFB;
  border: 1px solid #E2E2EA;

  .counter {
    width: 40px;
    border: 0;
    border-right: 1px solid #E2E2EA;
    border-left: 1px solid #E2E2EA;
    text-align: center;
    color: ${COLORS.LIGHTGRAY};

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */

    &[type=number] {
      -moz-appearance: textfield;
    }
  }
`

const CounterCenter = styled.input`
  width: 40px;
  text-align: center;
  border: 0;
  border-right: 1px solid #E2E2EA;
  border-left: 1px solid #E2E2EA;
  color: ${COLORS.LIGHTGRAY};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  &[type=number] {
    -moz-appearance: textfield;
  }
`

const CounterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  cursor: pointer;
  padding: 4px 0;
  color: ${COLORS.LIGHTGRAY};
`

export default Counter;