import React from 'react';
import {COLORS, FONTS} from "@/constans";
import Row from "@components/Table/TableDefault/TableOrdersModel/Row/Row";
import Table from "@components/Table/components/Wrapper";
import GridLayout from "@components/Layout/GridLayout";
import HeadRow from "@components/Table/components/HeadRow";
import HeadRowItem from "@components/Table/components/HeadRowItem";
import Checkbox from "@components/Form/Checkbox";
import Text from "@components/Text";


function TableOrders() {
    const array = [1,2,3,4,5,6,7,8,9,10]

    return (
        <Table>
            <GridLayout gap='10px'>
                <HeadRow>
                    <GridLayout template='40px 84px 9% 11% 11% 17% 7% 7% 7% 8% auto 5%' align='center'>
                        <HeadRowItem>
                            <Checkbox/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Фото' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Артикул' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Размеры' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Цвет' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Каталог' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Клиентов' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Серий' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Ед.' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Цена' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Сумма' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem/>
                    </GridLayout>
                </HeadRow>

                <GridLayout gap='10px'>
                    {array.map(item => <Row
                        catalogIcon='krista-scissors'
                        key={item}/>
                    )}
                </GridLayout>
            </GridLayout>
        </Table>
    );
}

export default TableOrders;