import React, {useState} from "react";
import Icon from "@components/Icon/Icon";
import RowChild from "@components/Table/TableDefault/TableOrdersModel/RowChild/RowChild";
import Table from "@components/Table/components/Wrapper";
import GridLayout from "@components/Layout/GridLayout";
import BodyRow from "@components/Table/components/BodyRow";
import {COLORS, FONTS} from "@/constans";
import Checkbox from "@components/Form/Checkbox";
import BodyRowItem from "@components/Table/components/BodyRowItem";
import Image from "@components/Image/Image";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";
import Button from "@components/Form/Button";
import Color from "@components/Table/components/Color";


const Row = ({catalogIcon}) => {
    const array = [1, 2, 3, 4]

    const [isOpen, setIsOpen] = useState(false);

    return (
        <Table border={`1px solid ${COLORS.LIGHTGRAY1}`} borderRadius='10px'>
            <BodyRow
                backgroundColor={COLORS.WHITE}
                borderRadius={isOpen && '10px 10px 0 0'}
                border='0'
                onClick={() => setIsOpen(!isOpen)}
            >
                <GridLayout template='40px 84px 9% 11% 11% 17% 7% 7% 7% 8% auto 5%' align='center'>
                    <BodyRowItem>
                        <Checkbox/>
                    </BodyRowItem>
                    <BodyRowItem>
                        <Image src='https://picsum.photos/200/100'/>
                    </BodyRowItem>
                    <BodyRowItem>
                        <Text value='10-01 к/р' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                    </BodyRowItem>
                    <BodyRowItem>
                        <Text value='36-38-40' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                    </BodyRowItem>
                    <BodyRowItem>
                        <GridLayout template='repeat(6, 1fr)'>
                            <Color backgroundColor='#000'/>
                            <Color backgroundColor='#000'/>
                            <Color backgroundColor='#000'/>
                            <Color backgroundColor='#000'/>
                            <Color backgroundColor='#000'/>
                            <Color backgroundColor='#000'/>
                        </GridLayout>
                    </BodyRowItem>
                    <BodyRowItem>
                        <FlexLayout align='center'>
                            <Icon
                                iconName={catalogIcon}
                                iconWidth={20}
                                iconHeight={20}
                                iconColor={COLORS.LIGHTGRAY}
                            />
                            <Text
                                value='Предзаказ сериями'
                                font={FONTS.REGULAR_16}
                                color={COLORS.DARKGRAY}
                                margin='0 0 0 8px'
                            />
                        </FlexLayout>
                    </BodyRowItem>
                    <BodyRowItem>
                        <Text value='240' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                    </BodyRowItem>
                    <BodyRowItem>
                        <Text value='720' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                    </BodyRowItem>
                    <BodyRowItem>
                        <Text value='960' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                    </BodyRowItem>
                    <BodyRowItem>
                        <Text value='35.00 $' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                    </BodyRowItem>
                    <BodyRowItem>
                        <Text value='5 320.00 $' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                    </BodyRowItem>
                    <BodyRowItem>
                        <Button
                            variant='isChevron'
                            iconName={isOpen ? "krista-chevron-top" : "krista-chevron-bottom"}
                            isSmall
                            onClick={() => setIsOpen(!isOpen)}
                        />
                    </BodyRowItem>
                </GridLayout>
            </BodyRow>

            {isOpen &&
                <>
                    {array.map((item, index) => <RowChild catalogIcon='krista-scissors' key={index}/>)}
                </>
            }
        </Table>
    );
};

export default Row;
