import {useSelector} from "react-redux";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AppLayout from "@components/Layout/AppLayout/AppLayout";
import LoginLayout from "@components/Layout/LoginLayout/LoginLayout";
import loadable from '@loadable/component'

const Modals = loadable(() => import('@components/Modal/Modals/Modals'))

export default function App() {
    const token = useSelector(state => state.user.userState.token)

    const toastProps = {
        position: "top-center",
        autoClose: 2500,
        newestOnTop: false,
        icon: false,
        closeButton: false,
        pauseOnFocusLoss: false,
        draggable: false,
        pauseOnHover: true,
        closeOnClick: true
    }

    return (
        <>
            {token ? <AppLayout/> : <LoginLayout/>}
            <ToastContainer {...toastProps}/>
            <Modals/>
        </>
    )
}
