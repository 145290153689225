import {Link} from "react-router-dom";
import TaskRowBodyItem from "@components/Tasks/components/TaskRowBodyItem/TaskRowBodyItem";
import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";
import Card from "@components/Tasks/components/Card/Card";

function DesignTask({modelTask, dashboardDataItem, articulItem}) {
    const mergeSizes = (sizeTask, status) => {
        const isSmall = modelTask.sizeTasks[status].length > 3
        return (
            <FlexLayout align='center' justifyContent='center' margin={isSmall ? '0 3px' : '0 4px'} key={sizeTask}>
                <Text value='-' font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
            </FlexLayout>
        )
    }

    for (let status of dashboardDataItem.statuses) {
        if (!modelTask.sizeTasks[status]) {
            continue
        }

        return (
            <TaskRowBodyItem key={articulItem.articulId}>
                <TaskLink to={`${articulItem.articulTaskId}?activeSize=${modelTask.modelTaskId}`}/>
                <Card ariaLabel={dashboardDataItem.title} >
                    <Text
                        value={modelTask.sizeTasks[status].length > 1 ? 'Размерный ряд' : 'Размер'}
                        font={FONTS.REGULAR_10}
                        color={COLORS.LIGHTGRAY}
                        margin='0 0 6px 0'
                    />
                    <FlexLayout align='center'>
                        {
                            modelTask.sizeTasks[status].map((task, taskIndex) => {
                                    const isPriority = task.isTopPriority && task.statusCategory !== 'finished'
                                    const isFinished = task.statusCategory === 'finished'
                                    const isSmall = modelTask.sizeTasks[status].length > 3

                                    const sizeAriaLabel = () => {
                                        if (isPriority) {
                                            return 'Приоритетный Размер'
                                        }
                                        if (isFinished) {
                                            return 'Завершенный Размер'
                                        }
                                        return null
                                    }

                                    return (
                                        <Indicator
                                            isPriority={isPriority}
                                            isFinished={isFinished}
                                            isSmall={isSmall}
                                            key={task.sizeTaskId}
                                            aria-label={`Размер ${task.size.value}`}
                                        >
                                            <Text
                                                value={task.size.value}
                                                font='inherit'
                                                color='inherit'
                                                ariaLabel={sizeAriaLabel()}
                                            />
                                        </Indicator>
                                    )
                                }
                            ).reduce((prev, curr) => [prev, mergeSizes(curr, status), curr])
                        }
                    </FlexLayout>
                </Card>
            </TaskRowBodyItem>
        )
    }
    return <TaskRowBodyItem aria-label='Пустая ячейка'/>
}

const Indicator = styled.div`
  display: inline-flex;
  align-self: center;
  padding: 3px 6px;
  color: ${COLORS.BLACK};
  background-color: ${COLORS.LIGHTGRAY2};
  border-radius: 12px;
  font: ${FONTS.REGULAR_14};

  ${({isPriority}) => isPriority && `
    background: ${COLORS.MAIN};
    color: ${COLORS.WHITE};
  `}

  ${({isFinished}) => isFinished && `
    background: ${COLORS.SALAD};
    color: ${COLORS.WHITE};
  `}

  ${({isSmall}) => isSmall && `
     padding: 2px 3px;
     border-radius: 8px;
     font: ${FONTS.REGULAR_14};
  `}
`

const TaskLink = styled(Link)`
  width: 100%;
  height: 100%;
  position: absolute;
`

export default DesignTask;