import {useSelector} from "react-redux";
import DesignTask
    from "@components/Tasks/ModelDevelopment/DesignTask/DesignTask";
import TaskRow from "@components/Tasks/components/TaskRow";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import TaskRowHead from "@components/Tasks/components/TaskRowHead";
import FlexLayout from "@components/Layout/FlexLayout";
import GridLayout from "@components/Layout/GridLayout";
import TaskRowBodyItems from "@components/Tasks/components/TaskRowBodyItems";

function ModelDevelopment({dashboardData}) {
    const tasks = useSelector(state => state?.designTasks.designTasksState)

    return (
        <div>
            {tasks?.map(taskItem => {
                return (
                    <div key={taskItem.employee.employeeId}>
                        <TaskRow>
                            <Text value={taskItem.employee.name} font={FONTS.MEDIUM_14} color={COLORS.DARKGRAY}/>
                        </TaskRow>
                        {taskItem.articulTasks.map(articulItem => (
                            <div key={articulItem.articulTaskId} aria-label={articulItem.articulName}>
                                <TaskRowHead>
                                    <FlexLayout align='center'>
                                        <Text value='Артикул: ' font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
                                        <Text
                                            value={articulItem.articulName}
                                            font={FONTS.MEDIUM_14}
                                            color={COLORS.DARKGRAY}
                                            margin='0 0 0 4px'
                                        />
                                    </FlexLayout>
                                </TaskRowHead>
                                <GridLayout margin='0 0 8px 0'>
                                    {articulItem?.modelTasks.map(modelTask => {
                                            return (
                                                <TaskRowBodyItems
                                                    key={modelTask.modelTaskId}
                                                    ariaLabel={`Размерный ряд ${modelTask.size.value}`}
                                                >
                                                    {dashboardData?.map((dashboardDataItem, index) => {
                                                            return (
                                                                <DesignTask
                                                                    key={dashboardDataItem.statuses + dashboardDataItem.title + index}
                                                                    modelTask={modelTask}
                                                                    articulItem={articulItem}
                                                                    dashboardDataItem={dashboardDataItem}
                                                                    isPriority
                                                                />
                                                            )
                                                        }
                                                    )}
                                                </TaskRowBodyItems>
                                            )
                                        }
                                    )}
                                </GridLayout>
                            </div>
                        ))}
                    </div>
                )
            })}
        </div>
    );
}

export default ModelDevelopment;