import React from 'react';
import ItemColorLayout from "@pages/ProductionPages/ModelDevelopmentPage/OperatorPage/components/ItemColorLayout";
import Button from "@components/Form/Button";
import styled from "styled-components";
import GridLayout from "@components/Layout/GridLayout";

const TabsColorsLayout = (props) => {

    const {isOpen, setIsOpen} = props
    const {itemSize} = props

    const newItemSize = itemSize?.colors.map(({color, ...item}) => ({
        id: color.id, name: color.name, hex: color.hex, ...item
    }))

    return (
        <GridLayout template='1fr 38px'>
            <WrapperColumn>
                {newItemSize?.map((item, index) => (
                    <ItemColorLayout
                        key={item.id + index}
                        item={item}
                        index={index}
                    />
                ))}
            </WrapperColumn>
            <Button
                variant='isChevron'
                iconName={isOpen ? "krista-chevron-top" : "krista-chevron-bottom"}
                isSmall
                onClick={() => setIsOpen(!isOpen)}
            />
        </GridLayout>

    );
};
const WrapperColumn = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 10px;
`
export default TabsColorsLayout;