import {COLORS} from "@/constans";
import styled from "styled-components";

function ClientInfoColumn({children}) {
    return (
        <ClientInfoColumnContent>
            {children}
        </ClientInfoColumnContent>
    );
}

const ClientInfoColumnContent = styled.div`
  background-color: ${COLORS.LIGHTGRAY3};
  border-radius: 6px;
`
export default ClientInfoColumn;