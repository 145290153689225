import {combineReducers, legacy_createStore as createStore} from "redux";
import userReducer from "./reducers/userReducer";
import modalsReducer from "./reducers/modalsReducer";
import registrationRequestReducer from "./reducers/registrationRequestReducer";
import managersReducer from "@reducers/managerReducer";
import cityReducer from "@reducers/cityReducer";
import countryReducer from "@reducers/countryReducer";
import catalogReducer from "@reducers/catalogReducer";
import sidebarReducer from "@reducers/sidebarReducer";
import taxPayerDataReducer from "@reducers/taxPayerDataReducer";
import taxPayerTypeReducer from "@reducers/taxPayerTypeReducer";
import clientsReducer from "@reducers/clientsReducer";
import discountReducer from "@reducers/discountReducer";
import marginsReducer from "@reducers/marginsReducer";
import currentCustomersReducer from "@reducers/currentCustomersReducer";
import collectionReducer from "@reducers/collectionReducer";
import articulDesignTaskReducer from "@reducers/articulDesignTaskReducer";
import sizeReducer from "@reducers/sizeReducer";
import colorReducer from "@reducers/colorReducer";
import designTaskReducer from "@reducers/designTaskReducer";
import materialsReducer from "@reducers/materialReducer";
import warehouseProductsReducer from "@reducers/warehouseReducer";
import storeEmployeesReducer from "@reducers/storeEmployeesReducer";
import storeClientsReducer from "@reducers/StoreClientReducer";

const rootReducer = combineReducers({
    user: userReducer,
    modals: modalsReducer,
    registrationRequests: registrationRequestReducer,
    employee: managersReducer,
    cities: cityReducer,
    countries: countryReducer,
    catalogs: catalogReducer,
    sidebar: sidebarReducer,
    taxPayerData: taxPayerDataReducer,
    taxPayerType: taxPayerTypeReducer,
    clients: clientsReducer,
    discount: discountReducer,
    margins: marginsReducer,
    currentCustomers: currentCustomersReducer,
    collection: collectionReducer,
    model: articulDesignTaskReducer,
    size: sizeReducer,
    color: colorReducer,
    designTasks: designTaskReducer,
    materials: materialsReducer,
    warehouse: warehouseProductsReducer,
    storeEmployees: storeEmployeesReducer,
    storeClients: storeClientsReducer,
})

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

export default store