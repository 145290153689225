import React, {useState} from 'react';
import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";
import Icon from "@components/Icon/Icon";
import Button from "@components/Form/Button";
import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";

function CardOrders({typeButton}) {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <Card>
            <Top isOpen={isOpen}>
                <GridLayout gap='12px'>
                    <GridLayout
                        template="113px 111px 111px"
                        align='center'
                        gap='18px'
                        spaceBetween
                    >
                        <Text
                            value='Заказы из новой коллекции'
                            font={FONTS.SEMI_BOLD_14}
                            color={COLORS.BLACK}
                        />
                        <FlexLayout align='center'>
                            <Text
                                value='123'
                                font={FONTS.REGULAR_36}
                                color={COLORS.BLACK}
                            />
                            <ValuesHighlight>
                                <Text
                                    value='12'
                                    font={FONTS.REGULAR_14}
                                    color={COLORS.WHITE}
                                />
                            </ValuesHighlight>
                        </FlexLayout>
                        <FlexLayout margin='0 0 0 auto' gap='8px'>
                            {typeButton === 'isPreorder' &&
                                <>
                                    <Button
                                        variant='isGray'
                                        iconName='krista-external-link'
                                        isSmall
                                    />
                                    <Button
                                        variant='isWarningV2'
                                        iconName='krista-scissors'
                                        isSmall
                                    />
                                </>
                            }
                            {typeButton === 'isStock' &&
                                <>
                                    <Button
                                        variant='isSuccessV2'
                                        iconName='krista-warehouse'
                                        isSmall
                                    />
                                </>
                            }
                            {typeButton === 'isTotal' &&
                                <Button
                                    variant='isMainV2'
                                    iconName='krista-cart'
                                    isSmall
                                />
                            }
                        </FlexLayout>
                    </GridLayout>
                    <GridLayout
                        template="113px 111px 111px"
                        align='center'
                        gap='18px'
                        spaceBetween
                    >
                        <div>
                            <Text
                                value='12'
                                font={FONTS.REGULAR_16}
                                color={COLORS.RED}/>
                            <Text
                                value='Единиц'
                                font={FONTS.REGULAR_12}
                                color={COLORS.LIGHTGRAY}
                            />
                        </div>
                        <div>
                            <Text
                                value='123'
                                font={FONTS.REGULAR_16}
                                color={COLORS.DARKGRAY}
                            />
                            <Text
                                value='Сумма заказов'
                                font={FONTS.REGULAR_12}
                                color={COLORS.LIGHTGRAY}
                            />
                        </div>
                        <div>
                            <Text
                                value='12'
                                font={FONTS.REGULAR_16}
                                color={COLORS.RED}
                            />
                            <Text
                                value='Сумма предоплат'
                                font={FONTS.REGULAR_12}
                                color={COLORS.LIGHTGRAY}
                            />
                        </div>
                    </GridLayout>
                    <GridLayout
                        template="113px 111px 111px"
                        align='center'
                        gap='18px'
                        spaceBetween
                    >
                        <div>
                            <Text
                                value='123456'
                                font={FONTS.REGULAR_16}
                                color={COLORS.DARKGRAY}
                            />
                            <Text
                                value='Клиентов'
                                font={FONTS.REGULAR_12}
                                color={COLORS.LIGHTGRAY}
                            />
                        </div>
                        <div>
                            <Text
                                value='123456'
                                font={FONTS.REGULAR_16}
                                color={COLORS.DARKGRAY}
                            />
                            <Text
                                value='Всего оплачено'
                                font={FONTS.REGULAR_12}
                                color={COLORS.LIGHTGRAY}
                            />
                        </div>
                        <div>
                            <Text
                                value='123456'
                                font={FONTS.REGULAR_16}
                                color={COLORS.MAIN}
                            />
                            <Text
                                value='Внесено предоплат'
                                font={FONTS.REGULAR_12}
                                color={COLORS.LIGHTGRAY}
                            />
                        </div>
                    </GridLayout>
                </GridLayout>
            </Top>
            {
                <Bottom isOpen={isOpen}>
                    {isOpen &&
                        <div>
                            <InCart>
                                <InCartIcon>
                                    <Icon
                                        iconName='krista-cart-v2'
                                        iconWidth={24}
                                        iconHeight={24}
                                    />
                                </InCartIcon>
                                <FlexLayout margin='0 0 0 8px'>
                                    <Text
                                        value='В корзинах:'
                                        font={FONTS.MEDIUM_14}
                                        color={COLORS.DARKGRAY}
                                    />
                                    <Text
                                        value='1 200 ед. (31 344.00 $)'
                                        font={FONTS.REGULAR_14}
                                        color={COLORS.DARKGRAY}
                                        margin='0 0 0 4px'
                                    />
                                </FlexLayout>
                            </InCart>
                            <FlexLayout spaceBetween margin='0 0 20px 0'>
                                <div>
                                    <Text
                                        value='Ожидает'
                                        font={FONTS.SEMI_BOLD_12}
                                        color={COLORS.DARKGRAY}
                                    />
                                    <Text
                                        value='1 290 ед.'
                                        font={FONTS.REGULAR_16}
                                        color={COLORS.DARKGRAY}
                                    />
                                    <Text
                                        value='110 000.00 $'
                                        font={FONTS.REGULAR_12}
                                        color={COLORS.LIGHTGRAY}
                                    />
                                </div>
                                <div>
                                    <Text
                                        value='В пошиве'
                                        font={FONTS.SEMI_BOLD_12}
                                        color={COLORS.DARKGRAY}
                                    />
                                    <Text
                                        value='860 ед.'
                                        font={FONTS.REGULAR_16}
                                        color={COLORS.DARKGRAY}
                                    />
                                    <Text
                                        value='80 000.00 $'
                                        font={FONTS.REGULAR_12}
                                        color={COLORS.LIGHTGRAY}
                                    />
                                </div>
                                <div>
                                    <Text
                                        value='В резерве'
                                        font={FONTS.SEMI_BOLD_12}
                                        color={COLORS.DARKGRAY}
                                    />
                                    <Text
                                        value='1 600 ед.'
                                        font={FONTS.REGULAR_16}
                                        color={COLORS.DARKGRAY}
                                    />
                                    <Text
                                        value='100 000.00 $'
                                        font={FONTS.REGULAR_12}
                                        color={COLORS.LIGHTGRAY}
                                    />
                                </div>
                                <div>
                                    <Text
                                        value='Отправлен'
                                        font={FONTS.SEMI_BOLD_12}
                                        color={COLORS.DARKGRAY}
                                    />
                                    <Text
                                        value='900 ед.'
                                        font={FONTS.REGULAR_16}
                                        color={COLORS.DARKGRAY}
                                    />
                                    <Text
                                        value='90 000.00 $'
                                        font={FONTS.REGULAR_12}
                                        color={COLORS.LIGHTGRAY}
                                    />
                                </div>
                            </FlexLayout>
                            <GridLayout gap='8px'>
                                {/*//TODO: временный массив, переделать когда придут с апи*/}
                                {[1,2,3,4,5,6].map(item => (
                                    <OrderItem>
                                        <OrderItemCircle/>
                                        <Text
                                            value='Новый заказ'
                                            font={FONTS.MEDIUM_14}
                                            color={COLORS.MAIN}
                                        />
                                        <Text
                                            value='100 заказов'
                                            font={FONTS.MEDIUM_14}
                                            color={COLORS.DARKGRAY}
                                            margin='0 0 0 4px'
                                        />
                                        <Text
                                            value='(500 ед., 1 344.00 $)'
                                            font={FONTS.REGULAR_14}
                                            color={COLORS.DARKGRAY}
                                            margin='0 0 0 4px'
                                        />
                                    </OrderItem>
                                ))}
                            </GridLayout>
                        </div>
                    }
                    <Status onClick={() => setIsOpen(!isOpen)}>
                        <FlexLayout align='center' margin='0 auto'>
                            <Text
                                value='Статусы заказов'
                                font={FONTS.REGULAR_14}
                                color={COLORS.LIGHTGRAY}
                            />
                            <StatusIcon>
                                <Icon
                                    iconName={isOpen ? "krista-arrow-top" : 'krista-arrow-bottom'}
                                    iconWidth={24}
                                    iconHeight={24}
                                />
                            </StatusIcon>
                        </FlexLayout>
                    </Status>
                </Bottom>
            }
        </Card>
    );
}

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: start;
  position: relative;
  margin-bottom: 54px;
  z-index: 4;
`

const Top = styled.div`
  border: 1px solid ${COLORS.LIGHTGRAY2};
  border-bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: 20px;
  padding: 20px 20px 17px 20px;
  ${({isOpen}) => isOpen && `
    border-bottom: 0;
  `}
`

const Bottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% - 15px);
  z-index: 3;
  border-radius: 0 0 10px 20px;
  padding: 0 20px 0 20px;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.LIGHTGRAY2};
  border-top: 0;
  ${({isOpen}) => isOpen && `
    border-top: 0;
  `}
`

const ValuesHighlight = styled.div`
  padding: 3px 10px;
  background-color: ${COLORS.RED};
  border-radius: 12px;
  margin-left: 12px;
`

const OrderItem = styled.div`
  display: flex;
  position: relative;
  padding-left: 16px;
`

const OrderItemCircle = styled.div`
  position: absolute;
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  //TODO: Определиться с дефолтным цветом когда придет с API
  background-color: ${({color}) => color ? color : COLORS.RED};
`

const Status = styled.div`
  display: flex;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid ${COLORS.LIGHTGRAY1};
  text-align: center;
  padding-bottom: 17px;
  cursor: pointer;
`

const StatusIcon = styled.div`
  color: ${COLORS.LIGHTGRAY};
`

const InCart = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.LIGHTGRAY3};
  border-radius: 10px;
  padding: 8px;
  margin: 20px 0;
  cursor: pointer;
`

const InCartIcon = styled.div`
  color: ${COLORS.LIGHTGRAY};
`

export default CardOrders;