import React, {useEffect} from 'react';
import Input from "@components/Form/Inputs/Input";
import {useForm} from "react-hook-form";
import {
    defaultSelectParamsValue,
    setOrDeleteSearchParams, setOrDeleteSearchParamsArr
} from "@util/defaultSelectParamsValue";
import GridLayout from "@components/Layout/GridLayout";
import SelectParams from "@components/Form/Selects/SelectParams";
import {useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";

const NavFiltersMovementHistory = ({documentDirections, documentTypes}) => {
    const employees = useSelector(state => state.storeEmployees.managersState)

    const [searchParams, setSearchParams] = useSearchParams();

    const {control, setValue, reset, register, watch} = useForm({
        defaultValues: {
            serialNumber: searchParams.get('serialNumber'),
            articulName: searchParams.get('articulName'),
            employeeIds: defaultSelectParamsValue(searchParams, 'employeeIds', employees, 'employeeId'),
            documentTypeIds: defaultSelectParamsValue(searchParams, 'documentTypeIds', documentTypes, 'documentTypeId'),
        }
    })

    const watchSerialNumber = watch("serialNumber");
    const watchArticulName = watch("articulName");
    const watchEmployee = watch("employeeIds");
    const watchDocumentsTypes = watch("documentTypeIds");


    useEffect(() => {
        setOrDeleteSearchParams(searchParams, watchSerialNumber, 'serialNumber')
        setOrDeleteSearchParams(searchParams, watchArticulName, 'articulName')
        setOrDeleteSearchParamsArr(searchParams, watchEmployee, 'employeeIds', 'employeeId')
        setOrDeleteSearchParamsArr(searchParams, watchDocumentsTypes, 'documentTypeIds', 'documentTypeId')
        setSearchParams(searchParams)
    }, [
        watchArticulName,
        watchSerialNumber,
       watchEmployee, watchDocumentsTypes
    ])

    return (
        <GridLayout template="1fr 1fr 1fr" gap="20px">
            <Input
                register={register}
                name='serialNumber'
                isDebounce
                isSmall
                isBorder={false}
                icon='krista-search'
                placeholder='№ перемещения'
            />
            <Input
                register={register}
                name='articulName'
                isDebounce
                isSmall
                isBorder={false}
                icon='krista-search'
                placeholder='Артикул'
            />
            <SelectParams
                register={register}
                control={control}
                values={employees}
                setValue={setValue}
                isSmall
                isBorder={false}
                isMulti
                hasNotDropdownIndicator

                name='employeeIds'
                chosenValues={watch('employeeIds')}
                url="employeeIds"
                placeholderText='Менеджер'
                placeholder='Менеджер'
                placeholderInner='Поиск'
                getOptionLabel='employeeName'
                getOptionValue='employeeId'
                watch={watch}
            />
            <SelectParams
                register={register}
                control={control}
                values={documentTypes}
                setValue={setValue}
                isSmall
                isBorder={false}
                isMulti
                hasNotDropdownIndicator

                name='documentTypeIds'
                chosenValues={watch('documentTypeIds')}
                url="documentTypeIds"
                placeholderText='Документ'
                placeholder='Документ'
                placeholderInner='Поиск'
                getOptionLabel='documentTypeName'
                getOptionValue='documentTypeId'
                watch={watch}
            />



        </GridLayout>
    );
};

export default NavFiltersMovementHistory;