import ItemTextile from "@pages/ProductionPages/ModelDevelopmentPage/OperatorPage/components/ItemTextile/ItemTextile";
import GridLayout from "@components/Layout/GridLayout";
import {useFieldArray, useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import Button from "@components/Form/Button";
import {useSearchParams} from "react-router-dom";

const Textile = ({control, errors, clearErrors, itemSizeIndex, itemColorIndex}) => {
    const activeColor = useSelector(state => state.model.modelActiveColor)

    const {
        fields: textileFields,
        append: appendTextile,
        remove: removeTextile,
        update: updateTextile
    } = useFieldArray({control, name: `models.${itemSizeIndex}.colors.${activeColor}.textileFields`, keyName: 'idField'});

    const {
        fields: accessoriesFields,
        append: appendAccessories,
        remove: removeAccessories,
        update: updateAccessories
    } = useFieldArray({control, name: `models.${itemSizeIndex}.colors.${activeColor}.accessoriesFields`, keyName: 'idField'});

    return (
        <GridLayout gap="20px">
            {textileFields.map((item, index) => (
                <ItemTextile
                    key={item.id}
                    labels={{
                        selectTextileLabel: `${index === 0 ? "Основная ткань" : ''} ${index === 1 ? "Дополнительные ткани" : ''}`,
                        inputPriceLabel: index <= 1 && "Цена за м",
                        selectWarehouseLabel: index <= 1 && "Склад"
                    }}
                    isMain={index === 0}
                    isTextile
                    update={updateTextile}
                    remove={removeTextile}
                    item={item}
                    index={index}
                    errors={errors}
                    clearErrors={clearErrors}
                    itemSizeIndex={itemSizeIndex}
                    itemColorIndex={itemColorIndex}
                    // searchControl={searchControl}
                    // searchWatch={searchWatch}
                    // searchSetValue={searchSetValue}
                />
            ))}
            <div>
                <Button
                    buttonText="Добавить материал"
                    iconName='krista-plus'
                    ariaLabel='Добавить материал'
                    isSmall
                    onClick={() => appendTextile({
                        colors: null,
                        id: "",
                        name: "",
                        supplierId: null,
                        supplierName: "" }
                    )}
                />
            </div>
            {accessoriesFields.map((item, index) => (
                <ItemTextile
                    key={item.id}
                    labels={{
                        selectTextileLabel: index === 0 && "Фурнитура",
                        inputPriceLabel: index === 0 && "Цена за м",
                        selectWarehouseLabel: index === 0 && "Склад"
                    }}
                    isTextile={false}
                    update={updateAccessories}
                    remove={removeAccessories}
                    item={item}
                    index={index}
                    errors={errors}
                    clearErrors={clearErrors}
                    itemSizeIndex={itemSizeIndex}
                    itemColorIndex={itemColorIndex}
                    // searchControl={searchControl}
                    // searchWatch={searchWatch}
                    // searchSetValue={searchSetValue}
                />
            ))}
            <div>
                <Button
                    buttonText="Добавить фурнитуру"
                    iconName='krista-plus'
                    ariaLabel='Добавить фурнитуру'
                    isSmall
                    onClick={() => appendAccessories({
                        colorSelected: {
                            color: {id: null, name: ''},
                            id: null,
                            materialPhoto: '',
                            price: {
                                currencyCode: '',
                                id: null,
                                price: '',
                            }
                        },
                        colors: null,
                        id: "",
                        name: "",
                        supplierId: null,
                        supplierName: ""
                    })}
                />
            </div>
        </GridLayout>
    );
};


export default Textile;