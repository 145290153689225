import {COLORS, FONTS} from "@/constans";
import {css} from "styled-components";

export const SelectContentStyles = (isInvalid, isBorder, isDisabled) => {
    return css`
      .controlSelectPrefix__ {
        ${({isInvalid}) => isInvalid && `
            &control {
              border: 1px solid ${COLORS.RED} !important;
            }
        `}
        &multi-value {
          display: flex;
          align-items: center;
          padding: 8px;
          background-color: ${COLORS.LIGHTGRAY2};
          border-radius: 16px;
        }

        &multi-value__label {
          padding: 0;
        }

        &value-container {
          padding: 0 30px 0 16px;

          &--is-multi {
            padding: 8px 30px 8px 8px;
          }
        }

        &control--menu-is-open {
          border: 1px solid ${COLORS.LIGHTGRAY};
        }

        &single-value, &placeholder {
          font-weight: 400;
          font-size: 14px;
        }

        &menu {
          box-shadow: 0 5px 15px rgba(68, 68, 79, 0.1);
          border-radius: 10px;
          border: 1px solid ${COLORS.LIGHTGRAY};
          margin-top: 4px;
          overflow: hidden;
        }

        &option {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: ${COLORS.DARKGRAY};
          background-color: ${COLORS.WHITE};
          padding: 8px 16px;
        }

        &option--is-focused {
          background-color: ${COLORS.LIGHTGRAY2};
        }
      }


      .customOption {
        &:hover {
          background-color: ${COLORS.LIGHTGRAY2};
          color: ${COLORS.DARKGRAY};
          cursor: pointer;
        }
      }

      display: block;
      font: ${FONTS.REGULAR_14};

      [class$="-control"] {
        min-height: 48px;
        background: ${COLORS.WHITE};
        border: 1px solid ${({isBorder}) => isBorder ? `${COLORS.LIGHTGRAY1}` : 'transparent'};

        ${({isDisabled}) => isDisabled && css`
          border: 1px solid ${ isDisabled ? `${COLORS.LIGHTGRAY2}` : 'transparent'};
            background: ${COLORS.LIGHTGRAY3};
        `}
        border-radius: 10px;
        outline: none;
        box-shadow: none;
      }

      ${({isSmall}) => isSmall && css`
        [class$="-control"] {
          min-height: 38px;
          border: 0;
        }

        .controlSelectDropdown {
          color: ${COLORS.LIGHTGRAY};
          margin-right: 8px;
        }
      `}
      .controlSelectDropdown {
        color: ${COLORS.LIGHTGRAY};
        margin-right: 12px;
      }
    `
}

