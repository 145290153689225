import Box from "@components/Box";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";
import dayjs from "dayjs";
import FlexLayout from "@components/Layout/FlexLayout";

const Totals = ({auditDocuments}) => {
    return (
        <GridLayout gap="20px" template="1fr 1fr 1fr" align="stretch">
            <Box>
                <Text value="Время ревизии" font={FONTS.SEMI_BOLD_16} color={COLORS.BLACK}/>
                <GridLayout gap="16px" template="1fr 1fr" margin="16px 0">
                    <div>
                        <Text value="Начало" font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY}/>
                        <Text value={dayjs(auditDocuments?.createDate).format('DD.MM.YYYY, HH:mm')}
                              font={FONTS.REGULAR_14} color={COLORS.BLACK}/>
                    </div>
                    <div>
                        <Text value="Конец" font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY}/>
                        {auditDocuments?.executionDate ? <Text
                            value={dayjs(auditDocuments?.executionDate).format('DD.MM.YYYY, HH:mm')}
                            font={FONTS.REGULAR_14}
                            color={COLORS.BLACK}
                        /> : <Text
                            value={auditDocuments?.statusName}
                            font={FONTS.REGULAR_14}
                            color={auditDocuments?.statusHexColor}
                        />}
                    </div>
                </GridLayout>
                <FlexLayout gap="4px">
                    <Text value="Ревизор:" font={FONTS.SEMI_BOLD_14} color={COLORS.BLACK}/>
                    <Text value={auditDocuments?.employeeName} font={FONTS.REGULAR_14} color={COLORS.BLACK}/>
                </FlexLayout>
            </Box>
            <Box>
                <Text value="Итоги ревизии" font={FONTS.SEMI_BOLD_16} color={COLORS.BLACK}/>
                <GridLayout gap="8px" template="1fr 0.5fr 1fr" margin="16px 0">
                    <div>
                        <Text value="Итого по ревизии" font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY}/>
                    </div>
                    <div>
                        <Text value="Единиц" font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY}/>
                    </div>
                    <div>
                        <Text
                            value={`На сумму ${auditDocuments?.totals?.curencySymbol}`}
                            font={FONTS.REGULAR_12}
                            color={COLORS.LIGHTGRAY}
                        />
                    </div>
                    <div>
                        <Text
                            value="Заявлено"
                            font={FONTS.SEMI_BOLD_14}
                            color={COLORS.BLACK}
                        />
                    </div>
                    <div>
                        <Text
                            value={auditDocuments?.totals?.expectedTotalQuantity}
                            font={FONTS.REGULAR_14}
                            color={COLORS.BLACK}
                        />
                    </div>
                    <div>
                        <Text
                            value={auditDocuments?.totals?.expectedTotalSum}
                            font={FONTS.REGULAR_14}
                            color={COLORS.BLACK}
                        />
                    </div>
                    <div>
                        <Text value="Подтверждено" font={FONTS.SEMI_BOLD_14} color={COLORS.BLACK}/>
                    </div>
                    <div>
                        <Text
                            value={auditDocuments?.totals?.actualTotalQuantity}
                            font={FONTS.REGULAR_14}
                            color={COLORS.BLACK}
                        />
                    </div>
                    <div>
                        <Text
                            value={auditDocuments?.totals?.actualTotalSum}
                            font={FONTS.REGULAR_14}
                            color={COLORS.BLACK}
                        />
                    </div>
                </GridLayout>
            </Box>
            <Box>
                <Text value="Разница" font={FONTS.SEMI_BOLD_16} color={COLORS.BLACK}/>
                <GridLayout gap="8px" template="1fr 0.5fr 1fr" margin="16px 0">
                    <div>
                        <Text value="Тип разницы" font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY}/>
                    </div>
                    <div>
                        <Text value="Единиц" font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY}/>
                    </div>
                    <div>
                        <Text
                            value={`На сумму ${auditDocuments?.totals?.curencySymbol}`}
                            font={FONTS.REGULAR_12}
                            color={COLORS.LIGHTGRAY}
                        />
                    </div>
                    <div>
                        <Text value="Недостача" font={FONTS.SEMI_BOLD_14} color={COLORS.RED}/>
                    </div>
                    <div>
                        <Text
                            value={auditDocuments?.totals?.deficitTotalQuantity}
                            font={FONTS.REGULAR_14}
                            color={COLORS.RED}
                        />
                    </div>
                    <div>
                        <Text
                            value={auditDocuments?.totals?.deficitTotalSum}
                            font={FONTS.REGULAR_14}
                            color={COLORS.RED}
                        />
                    </div>
                    <div>
                        <Text value="Избыток" font={FONTS.SEMI_BOLD_14} color={COLORS.BLACK}/>
                    </div>
                    <div>
                        <Text
                            value={auditDocuments?.totals?.excessTotalQuantity}
                            font={FONTS.REGULAR_14}
                            color={COLORS.BLACK}
                        />
                    </div>
                    <div>
                        <Text
                            value={auditDocuments?.totals?.excessTotalSum}
                            font={FONTS.REGULAR_14}
                            color={COLORS.BLACK}
                        />
                    </div>
                </GridLayout>
            </Box>
        </GridLayout>
    );
};

export default Totals;