import {SET_ALL_COUNTRY, SET_NEW_COUNTRY_NAME, SET_NEW_COUNTRY} from "@types/types";

const initState = {
    countriesState: [],
    newCountryState: null,
    newCountryIdState: null,
}

const countriesReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_ALL_COUNTRY:
            return {...state, countriesState: action.payload}
        case SET_NEW_COUNTRY_NAME:
            return {...state, newCountryState: action.payload}
        case SET_NEW_COUNTRY:
            return {...state, newCountryIdState: action.payload}


        default:
            return state
    }
}
export default countriesReducer