import styled from "styled-components";
import {COLORS} from "@/constans";

const Line = ({margin}) => {
    return (
        <Content margin={margin}/>
    );
};

const Content = styled.div`
  border-bottom: 1px solid ${COLORS.LIGHTGRAY1};
  ${({margin}) => margin && `margin: ${margin}`}
`
export default Line;