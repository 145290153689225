import React from 'react';
import Icon from "@components/Icon/Icon";
import {toast} from "react-toastify";
import ToastContent from "@components/ToastContent/ToastContent";
import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";
import Text from "@components/Text/Text";

function IconCard({item}) {
    const copyIconText = (text) => {
        navigator.clipboard.writeText(text).then(()=> {
            toast.success(<ToastContent title="Скопировано иконка" response={item} ok/>);
        })
    }
    return (
        <IconWrapper onClick={()=> {copyIconText(item)}}>
            <Icon
                iconName={item}
                iconWidth={40}
                iconHeight={40}
            />
            <Text
                font={FONTS.REGULAR_12}
                value={item}
                textAlign='center'
                margin='10px 0 0 0'
            />
        </IconWrapper>
    );
}

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.WHITE};
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${COLORS.LIGHTGRAY1};
  cursor: pointer;
`

export default IconCard;