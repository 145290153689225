import SelectRadio
    from "@pages/ProductionPages/ModelDevelopmentPage/OperatorPage/components/SelectRadio/SelectRadio";
import SelectTextile from "@components/Form/SelectsCustom/SelectTextile/SelectTextile";
import SelectTextileColor from "@components/Form/SelectsCustom/SelectTextileColor";
import GridLayout from "@components/Layout/GridLayout";
import Input from "@components/Form/Inputs/Input";
import Button from "@components/Form/Button";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";

const ItemTextile = (props) => {

    const {labels} = props
    const {itemSizeIndex, itemColorIndex} = props
    const {isMain, isTextile} = props
    const {remove, update} = props
    const {errors, clearErrors} = props
    const {item, index} = props

    const textileErrorMessage = () => {
        if(!isTextile) {
            return  errors?.models?.[itemSizeIndex]?.colors?.[itemColorIndex]?.accessoriesFields?.[index]?.colors?.message
        }
        return errors?.models?.[itemSizeIndex]?.colors?.[itemColorIndex]?.textileFields?.[index]?.colors?.message
    }

    const textileColorErrorMessage = () => {
        if(!isTextile) {
            return  errors?.models?.[itemSizeIndex]?.colors?.[itemColorIndex]?.accessoriesFields?.[index]?.colorSelected.id?.message
        }
        return errors?.models?.[itemSizeIndex]?.colors?.[itemColorIndex]?.textileFields?.[index]?.colorSelected.id?.message
    }

    const textileClearErrorMessage = () => {
        if(isTextile) {
            clearErrors(`models.[${itemSizeIndex}].colors.[${itemColorIndex}].textileFields.[${index}].colors`)
            clearErrors(`models.[${itemSizeIndex}].colors.[${itemColorIndex}].textileFields.[${index}].colorSelected.price.message`)
        }
        if(!isTextile) {
            clearErrors(`models.[${itemSizeIndex}].colors.[${itemColorIndex}].accessoriesFields.[${index}].colors`)
            clearErrors(`models.[${itemSizeIndex}].colors.[${itemColorIndex}].accessoriesFields.[${index}].colorSelected.price.message`)
        }
    }

    const textileColorClearErrorMessage = () => {
        if(isTextile) {
            clearErrors(`models.[${itemSizeIndex}].colors.[${itemColorIndex}].textileFields.[${index}].colorSelected.id`)
        }
        if(isTextile && item?.colorSelected?.price) {
            clearErrors(`models.[${itemSizeIndex}].colors.[${itemColorIndex}].textileFields.[${index}].colorSelected.price.message`)
        }
        if(!isTextile) {
            clearErrors(`models.[${itemSizeIndex}].colors.[${itemColorIndex}].accessoriesFields.[${index}].colorSelected.id`)
        }
        if(!isTextile && item?.colorSelected?.price) {
            clearErrors(`models.[${itemSizeIndex}].colors.[${itemColorIndex}].accessoriesFields.[${index}].colorSelected.price.message`)
        }
    }
    
    const priceErrorMessage = () => {
        if(isTextile) {
            return errors?.models?.[itemSizeIndex]?.colors?.[itemColorIndex]?.textileFields?.[index]?.colorSelected?.price?.message
        }
        return errors?.models?.[itemSizeIndex]?.colors?.[itemColorIndex]?.accessoriesFields?.[index]?.colorSelected?.price?.message
    }

    const priceValue = () => {
        if(item?.colorSelected?.price?.price === null || item?.colorSelected?.price === null) {
            return 'null'
        }
        return item?.colorSelected?.price?.price
    }



    return (
        <GridLayout
            template="1fr 1fr 120px 200px 48px"
            gap="16px"
            align='flex-end'
            ariaLabel={`Текстиль ${index}`}
        >
            <SelectTextile
                label={labels?.selectTextileLabel}
                isTextile={isTextile}
                update={update}
                item={item}
                index={index}
                clearErrors={textileClearErrorMessage}
                errorMessage={textileErrorMessage()}
            />
            <SelectTextileColor
                updateTextile={update}
                indexTextile={index}
                itemTextile={item}
                clearErrors={textileColorClearErrorMessage}
                errorMessage={textileColorErrorMessage()}
            />
            <Input
                label={labels?.inputPriceLabel}
                value={priceValue()}
                symbol={item?.colorSelected?.price?.currencyCode}
                errors={priceErrorMessage()}
                disabled
            />

            <SelectRadio
                label={labels?.selectWarehouseLabel}
            />
            {!isMain && <Button iconName='krista-delete' ariaLabel='Удалить текстиль' onClick={() => remove(index)}/>}
        </GridLayout>
    );
};

export default ItemTextile;