import Box from "@components/Box";
import GridLayout from "@components/Layout/GridLayout";
import Icon from "@components/Icon/Icon";
import ConfirmIndicator from "@components/ConfirmIndicator";
import Text from "@components/Text/Text";
import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";

function PhotoSet() {
    return (
        <Box>
            <GridLayout gap='16px'>
                <GridLayout gap='4px'>
                    <Text value='Фотосет' as='h3' font={FONTS.SEMI_BOLD_14}/>
                    <Text value='Проверьте наличие и содержание файлов' as='h4' isGray/>
                </GridLayout>
                <GridLayout gap='16px' template='148px 1fr'>
                    <ImgWrapper>
                        <Img src="https://picsum.photos/600/1440" alt=""/>
                        <Count>
                            <GridLayout gap='8px' template='16px auto' align='center'>
                                <div>
                                    <Icon
                                        iconName='krista-photo'
                                        iconWidth={16}
                                        iconHeight={16}
                                    />
                                </div>
                                <Text value='12' font={FONTS.REGULAR_12} color={COLORS.WHITE}/>
                            </GridLayout>
                        </Count>
                    </ImgWrapper>
                    <GridLayout gap='8px'>
                        <ConfirmIndicator text='Графис' confirm/>
                        <ConfirmIndicator text='Калькуляции'/>
                        <ConfirmIndicator text='Раскладка'/>
                    </GridLayout>
                </GridLayout>
            </GridLayout>
        </Box>
    );
}

const ImgWrapper = styled.div`
  height: 160px;
  position: relative;
`

const Img = styled.img`{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
`

const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  border-radius: 6px;
  color: ${COLORS.WHITE};
  padding: 8px 10px 8px 8px;
`


export default PhotoSet;