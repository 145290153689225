import BodyRow from "@components/Table/components/BodyRow";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import React from "react";
import GridLayout from "@components/Layout/GridLayout";
import BodyRowItem from "@components/Table/components/BodyRowItem";
import FlexLayout from "@components/Layout/FlexLayout";
import {NumericFormat} from "react-number-format";
import Color from "@components/Table/components/Color";

function RowDropdown({size, template}) {
    return (
        size?.colors?.map((color, index) => {
            return (
                <BodyRow padding="0px" borderRadius='0'>
                    <GridLayout template={template} align='stretch' padding="0 8px">
                        <BodyRowItem isCentered borderTop={index === 0}>
                            <FlexLayout padding={"16px 8px"}>
                                <FlexLayout align="center">
                                    {index === 0 && (
                                        <Text value={size?.sizeLineName} font={FONTS.SEMI_BOLD_14} color={COLORS.DARKGRAY}/>
                                    )}
                                </FlexLayout>
                            </FlexLayout>
                        </BodyRowItem>
                        <BodyRowItem isCentered borderTop padding="16px 8px">
                            <FlexLayout gap='8px' align='center'>
                                <Color backgroundColor={color?.hex}/>
                                <Text value={color?.colorName} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                            </FlexLayout>
                        </BodyRowItem>
                        <BodyRowItem isCentered borderTop padding="16px 8px">
                            <NumericFormat
                                value={color?.product?.price}
                                thousandSeparator=" "
                                decimalScale={2}
                                displayType="text"
                                renderText={(value) => (
                                    <Text value={value} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                                )}
                                suffix={` ${color?.product?.currencySymbol}`}
                            />
                        </BodyRowItem>
                        <BodyRowItem isCentered borderTop padding="16px 8px">
                            <Text value={color?.product?.linesQuantity} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                        </BodyRowItem>
                        <BodyRowItem isCentered borderTop padding="16px 8px">
                            <Text value={color?.product?.quantity} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                        </BodyRowItem>
                        <BodyRowItem isCentered borderTop padding="16px 8px">
                            <NumericFormat
                                value={color?.product?.totalSum}
                                thousandSeparator=" "
                                decimalScale={2}
                                displayType="text"
                                renderText={(value) => (
                                    <Text value={value} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                                )}
                                fixedDecimalScale
                                suffix={` ${color?.product?.currencySymbol}`}
                            />
                        </BodyRowItem>
                        <BodyRowItem isCentered borderTop padding="16px 8px"/>
                    </GridLayout>
                </BodyRow>
            )
        })
    );
}

export default RowDropdown;