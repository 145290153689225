import GridLayout from "@components/Layout/GridLayout";
import HeadRow from "@components/Table/components/HeadRow";
import HeadRowItem from "@components/Table/components/HeadRowItem";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import Table from "@components/Table/components/Wrapper";
import Row from "@components/Table/TableDefault/TableAudit/Row";
import Footer from "@components/Table/TableDefault/TableAudit/Footer";

function TableAudit({auditDocuments}) {
    const templateHead = '1.6fr 80px 1.8fr 130px 250px 250px 250px'
    const template = '1.6fr 80px 1.8fr 130px 100px 150px 100px 150px 100px 150px'
    const templateFoot = '200px 80px 1.8fr 130px 100px 150px 100px 150px 100px 150px'

    return (
        <Table overflow='visible'>
            <GridLayout gap='10px' position='relative'>
                <HeadRow padding="0">
                    <GridLayout template={templateHead} align='center'>
                        <HeadRowItem padding="12px 16px">
                            <Text value='Товар' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Размер' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Цвет' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Цена' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem bg="#EEF8FF">
                            <div style={{width: '100%'}}>
                                <Text
                                    value="Заявлено"
                                    font={FONTS.SEMI_BOLD_14}
                                    color={COLORS.LIGHTGRAY}
                                    margin="0 0 8px 0"
                                />
                                <GridLayout template="100px 150px" align='center'>
                                    <Text value="Eдиниц" font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
                                    <Text value="На сумму" font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
                                </GridLayout>
                            </div>
                        </HeadRowItem>
                        <HeadRowItem bg="#FFF8E8">
                            <div style={{width: '100%'}}>
                                <Text
                                    value="Подтверждено"
                                    font={FONTS.SEMI_BOLD_14}
                                    color={COLORS.LIGHTGRAY}
                                    margin="0 0 8px 0"
                                />
                                <GridLayout template="100px 150px" align='center'>
                                    <Text value='Единиц' font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
                                    <Text value='На сумму' font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
                                </GridLayout>
                            </div>
                        </HeadRowItem>
                        <HeadRowItem bg="#FFEFEF">
                            <div style={{width: '100%'}}>
                                <Text
                                    value="Разница"
                                    font={FONTS.SEMI_BOLD_14}
                                    color={COLORS.LIGHTGRAY}
                                    margin="0 0 8px 0"
                                />
                                <GridLayout template="100px 150px" align='center'>
                                    <Text value='Eдиниц' font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
                                    <Text value='На сумму' font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
                                </GridLayout>
                            </div>
                        </HeadRowItem>

                    </GridLayout>
                </HeadRow>
                {auditDocuments?.products?.map(item => (
                    <Row template={template} item={item} key={item.productId}/>
                ))}
                <Footer template={templateFoot} totals={auditDocuments?.totals}/>
            </GridLayout>
        </Table>
    );
}

export default TableAudit;