import styled from 'styled-components'

const ControlError = ({children}) => <Content>{children}</Content>

const Content = styled.div`
  position: absolute;
  top: calc(100% + 2px);
`

export default ControlError;


