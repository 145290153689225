import TableDocuments from "@components/Table/TableDefault/TableDocuments";
import {useDispatch} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {SET_MODAL_CONFIRM_ACTION} from "@types/types";
import {documentsProductDelete, getDocuments} from "@actions/storeActions/movementDocumentsAction";

const TableLayout = ({documents, setDocuments}) => {
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()

    const deleteProduct = (item) => {
        dispatch({
            type: SET_MODAL_CONFIRM_ACTION, payload: {
                isShow: true,
                title: 'Вы уверены, что хотите удалить ?',
                desc: `
                    Артикул: ${item.articulName} <br>
                    Цвет: ${item.colorName} <br>
                    Количество: ${item.quantity}
                `,
                accept: () => {
                    documentsProductDelete(searchParams.get('movementDocumentId'), item.productId).then(() => {
                        getDocuments(searchParams.get('movementDocumentId')).then(({data}) => {
                            setDocuments(data)
                            dispatch({
                                type: SET_MODAL_CONFIRM_ACTION, payload: {
                                    isShow: false,
                                    title: '',
                                    desc: '',
                                    accept: null,
                                    icon: ''
                                }
                            })
                        })
                    })

                },
                icon: 'krista-delete'
            }
        })

    }
    return (
        <TableDocuments
            documents={documents}
            setDocuments={setDocuments}
            deleteProduct={deleteProduct}
        />
    );
};

export default TableLayout;