import UploadFileIndicator from "@components/Upload/UploadFileIndicator/UploadFileIndicator";
import UploadFile from "@components/Upload/UploadFile/UploadFile";
import GridLayout from "@components/Layout/GridLayout";

const UploadFileLayouts = (props) => {
    const {item, index, fieldName} = props
    const {update, setValue, fields, watchValue} = props
    const {readOnly, isMultiple} = props

    const uploadFile = (e) => {
        const file = e.target.files[0]
        if(isMultiple) {
            update(index, {...fields[index], name: file.name})
            return
        }
        setValue(fieldName, file.name)
    }

    const clearFile = () => {
        if(isMultiple) {
            update(index, {...fields[index], name: ''})
            return
        }
        setValue(fieldName, '')
    }

    return (
        <GridLayout template={isMultiple ? `${readOnly ? '1fr' : '1fr 48px'}` : '1fr'} gap='16px'>
            <UploadFileIndicator
                title={item ? item?.name : watchValue}
                label='Шифон SlaveTex дордой'
                onClearFile={clearFile}
                readOnly={readOnly}
            />
            {!readOnly &&
                <UploadFile
                    isSmall={isMultiple}
                    onFileChange={(e) => uploadFile(e)}
                />
            }
        </GridLayout>
    );
}

export default UploadFileLayouts;