import Select, {components, createFilter} from "react-select";
import SelectCreatable from "react-select/creatable";
import styled from "styled-components";
import Icon from "@components/Icon/Icon";
import {Controller} from "react-hook-form";
import ControlBox from "@components/Form/Inputs/components/ControlBox";
import Label from "@components/Form/Label/Label";
import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";

const SelectLayout = (props) => {
    const {values, name, control, setValue, errors} = props
    const {onClear, whenChanged} = props
    const {label, isSmall, isBorder, placeholder, onCreate} = props
    const {closeMenuOnSelect, formatOptionLabel, formatCreateLabel} = props
    const { isMulti, hasClearIndicator = true, hasDropdownIndicator = true, customIndicator} = props
    const {getOptionLabel, getOptionValue, getOptionObjectKey} = props
    const {hideSelectedOptions, controlShouldRenderValue, menuIsOpen, isDisabled} = props

    const onClearType = () => {
        if(onClear) {
            onClear()
        }
        setValue(name, null)
    }

    const CustomClearIndicator = () => {
        return (
            <ClearSelect onClick={onClearType} aria-label='Очистить поле'>
                <ClearIcon>
                    <Icon
                        iconName='krista-close-v2'
                        iconWidth={24}
                        iconHeight={24}
                        iconColor={COLORS.LIGHTGRAY}
                    />
                </ClearIcon>
            </ClearSelect>
        )
    }

    const CustomOption = ({children, ...props}) => {
        const {onMouseMove, onMouseOver, ...rest} = props.innerProps;
        const newProps = {...props, innerProps: rest};
        return (
            <components.Option {...newProps} className='customOption'>
                {children}
            </components.Option>
        );
    };

    const getComponents = () => {
        return {
            Option: CustomOption,
            IndicatorSeparator: () => null,
            ClearIndicator: hasClearIndicator && CustomClearIndicator,
            DropdownIndicator: () => {
                if (customIndicator) {
                    return (
                        <GridLayout padding='0 8px'>
                            <Icon
                                iconName={customIndicator}
                                iconWidth={24}
                                iconHeight={24}
                                iconColor={COLORS.LIGHTGRAY}
                            />
                        </GridLayout>
                    )
                }
                if (hasDropdownIndicator) {
                    return (
                        <GridLayout padding='0 8px'>
                            <Icon
                                iconName='krista-arrow-bottom'
                                iconWidth={24}
                                iconHeight={24}
                                iconColor={COLORS.LIGHTGRAY}
                            />
                        </GridLayout>
                    )
                }
            }
        }
    }

    const onChangeType = (fieldOnChange, option) => {
        if (getOptionObjectKey) {
            fieldOnChange(option[getOptionObjectKey])
            if (whenChanged) {
                whenChanged(option[getOptionObjectKey])
            }
        } else {
            fieldOnChange(option)
            if (whenChanged) {
                whenChanged(option)
            }
        }
    }

    const selectOptions = (value, onChange) => {
        return {
            placeholder: placeholder ? placeholder : 'Выбрать',
            classNamePrefix: "controlSelectPrefix",
            className: 'controlSelect',
            options: values,
            isMulti: isMulti,
            formatOptionLabel: formatOptionLabel,
            defaultValue: value ? (getOptionObjectKey ? value[getOptionObjectKey] : value) : null,
            value: value ? (getOptionObjectKey ? value[getOptionObjectKey] : value) : null,
            onChange: (option) => onChangeType(onChange, option),
            hideSelectedOptions: hideSelectedOptions,
            closeMenuOnSelect: closeMenuOnSelect,
            controlShouldRenderValue: controlShouldRenderValue,
            filterOption: createFilter({ignoreAccents: false}),
            getOptionLabel: (option) => getOptionLabel ? option[getOptionLabel] : option.name,
            getOptionValue: (option) => getOptionValue ? option[getOptionValue] : option.id,
            menuIsOpen: menuIsOpen,
            components: getComponents(),
            isClearable: true,
            getNewOptionData: (inputValue, optionLabel) => {
                return (
                    {
                        [getOptionValue ? getOptionValue : 'id']: inputValue,
                        [getOptionLabel ? getOptionLabel : 'name']: optionLabel,
                    }
                )
            },
            isDisabled: isDisabled,
        }
    }

    return (
        <ControlBox
            isInvalid={errors}
            ariaLabel={`Поле ${label}`}
            isSmall={isSmall}
            isBorder={isBorder}
            isDisabled={isDisabled}
        >
            {label && <Label label={label} ariaLabel={`Поле ${label}`}/>}
            {control &&
                <Controller
                    name={name}
                    control={control}
                    render={({field: {value, onChange, onBlur}}) => {
                        if (onCreate) {
                            return (
                                <SelectCreatable
                                    {...selectOptions(value, onChange, onBlur)}
                                    onCreateOption={value => onCreate(value)}
                                    formatCreateLabel={value => `${formatCreateLabel} “${value}”`}
                                />
                            )
                        }
                        return (
                            <Select {...selectOptions(value, onChange, onBlur)}/>
                        )
                    }}
                />
            }
            {errors && <Errors aria-label={`Ошибка в поле ${label}`}>{errors}</Errors>}
        </ControlBox>
    )

};

const Errors = styled.div`
  color: ${COLORS.RED};
  position: absolute;
  top: calc(100% + 2px);
  font: ${FONTS.REGULAR_12};
`

const ClearSelect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 34px;
  cursor: pointer;
  background-color: ${COLORS.LIGHTGRAY2};
  color: ${COLORS.LIGHTGRAY};
  width: 20px;
  height: 20px;
  border-radius: 50%;
`

const ClearIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`

export default SelectLayout;