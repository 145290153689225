import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import TableAllModelConstructor from "@components/Table/TableDefault/TableAllModelConstructor";
import Select from "@components/Form/Selects/Select";
import Input from "@components/Form/Inputs/Input";
import {getArticleList} from "@actions/articulDesignTaskAction";
import {getCollection} from "@actions/collectionAction";
import {getAllConstructor} from "@actions/employeeAction";
import Text from "@components/Text/Text";
import {FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";

const BasicInformationOnTheModel = ({control, errors, register, watch, setValue, getValues}) => {
    const sizes = useSelector(state => state.model.modelAllSizeState)
    const sizesCount = sizes.length
    const initialColorsValue = 0
    const colorsCount = sizes.map(item => item.colors.length).reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        initialColorsValue
    )

    const collection = useSelector(state => state.collection.collectionState)
    const constructors = useSelector(state => state.employee.constructorsState)

    const [isLoadTable, setIsLoadTable] = useState(false)
    const constructorId = watch('constructorId')
    const articulName = watch('articulName')

    useEffect(() => {
        getCollection()
        getAllConstructor()
    }, [])

    useEffect(() => {
        if(!constructorId) {
            setValue('articulTaskPriorities', [])
        }
        if (constructorId) {
            setIsLoadTable(false)
            getArticleList(constructorId)
                .then(
                    (response) => {
                        setValue('articulTaskPriorities', response.data)
                        if(articulName) {
                            setValue('articulTaskPriorities', [...watch('articulTaskPriorities'), {
                                articulName: articulName, isNew: true, sizeLinesCount: sizesCount, colorsCount: colorsCount
                            }])
                        }
                        setIsLoadTable(true)
                    }
                )
        }
    }, [constructorId])

    return (
        <GridLayout gap="20px" ariaLabel='Основная информация по модели'>
            <Text
                as="h3"
                bold
                value="Основная информация по модели"
                font={FONTS.SEMI_BOLD_16}
            />
            <Select
                name="collectionId"
                label="Коллекция"
                control={control}
                errors={errors?.collectionId?.message}
                values={collection}
                setValue={setValue}
                getOptionLabel='name'
                getOptionValue='id'
                getOptionObjectKey='id'
            />
            <Input
                name="articulName"
                label="Артикул"
                placeholder="Введите артикул"
                register={register}
                errors={errors?.articulName?.message}
                icon="krista-info"
                iconPosition="right"
            />
            <Select
                name="constructorId"
                label="Конструктор"
                control={control}
                errors={errors?.constructorId?.message}
                values={constructors}
                setValue={setValue}
                getOptionLabel='value'
                getOptionValue='key'
                getOptionObjectKey='key'
            />
            {(watch('articulTaskPriorities').length > 0 || watch('articulName')) &&
                <TableAllModelConstructor
                    isLoadTable={isLoadTable}
                    articulName={articulName}
                    getValues={getValues}
                    setValue={setValue}
                    watch={watch}
                    sizesCount={sizesCount}
                    colorsCount={colorsCount}
                />
            }
        </GridLayout>
    );
};

export default BasicInformationOnTheModel;