import React from 'react';
import Select from "@components/Form/Selects/Select";
import Input from "@components/Form/Inputs/Input";
import GridLayout from "@components/Layout/GridLayout";
import Button from "@components/Form/Button";
import InputNumeric from "@components/Form/Inputs/InputNumeric";
import {useSelector} from "react-redux";
import {
    changeDiscount,
    changeDiscountByDiscountCard,
    changeEmployee,
    getAcceptedPayment,
    getInvoice,
    setClient
} from "@actions/invoiceAction";

function StoreHead(props) {
    const {register, control, setValue, errors, isDirty} = props
    const {setInvoice, setAcceptedPayment, invoiceId} = props
    const managers = useSelector(state => state.storeEmployees.managersState)
    const clients = useSelector(state => state.storeClients.clientsState)

    const refreshInvoice = (onGetInvoice) => {
        getInvoice(invoiceId).then(({data}) => {
            setInvoice(data)
            onGetInvoice(data)
        })
        getAcceptedPayment(invoiceId).then(({data}) => {
            setAcceptedPayment(data)
        })
    }

    const changeDiscountCardCode = (e) => {
        if (e.target.value.length === 16) {
            if (invoiceId && isDirty) {
                changeDiscountByDiscountCard({
                    invoiceId: invoiceId,
                    discountCardCode: e.target.value
                }).then(() => {
                    refreshInvoice((invoice) => {
                        setValue('discountPercent', invoice?.discountPercent)
                        setValue('client', invoice?.clientId ? {
                                fullName: invoice?.clientFullName,
                                clientId: invoice?.clientId
                            } : null
                        )
                    })
                })
            }
        }
    }

    const changeClient = (clientId) => {
        setClient({
            invoiceId: invoiceId,
            clientId: clientId
        }).then(() => {
            refreshInvoice((invoice) => {
                setValue('discountPercent', invoice?.discountPercent)
                setValue('discountCardCode', invoice?.discountCardCode)
            })
        })
    }

    const changeDiscountPercent = (e) => {
        if (e.target.value <= 100) {
            changeDiscount({
                invoiceId: invoiceId,
                discountPercent: +e.target.value
            }).then(() => {
                refreshInvoice(
                    (invoice) => {
                        setValue('discountCardCode', invoice?.discountCardCode)
                        setValue('client', invoice?.clientId ? {
                                fullName: invoice?.clientFullName,
                                clientId: invoice?.clientId
                            } : null
                        )
                    }
                )
            })
        }
    }

    return (
        <GridLayout template='1fr 1fr 1fr 1fr' gap='20px'>
            <Select
                whenChanged={(option) => {
                    invoiceId && changeEmployee(invoiceId, option.employeeId)
                }}
                label='Менеджер'
                register={register}
                name='employee'
                placeholderText='Выберите менеджера'
                control={control}
                getOptionLabel='employeeName'
                getOptionValue='employeeId'
                values={managers}
                setValue={setValue}
                isBorder={false}
                hasClearIndicator={false}
                errors={errors?.employee?.message}
            />

            <Input
                register={register}
                whenChanged={changeDiscountCardCode}
                isDebounce
                name='discountCardCode'
                label='Скидочная карта'
                placeholder='Введите данные'
                isBorder={false}
                errors={errors?.discountCardCode?.message}
                disabled={!invoiceId}
            />

            <GridLayout align='end' template='1fr' gap='8px'>
                <Select
                    register={register}
                    whenChanged={(option) => changeClient(option.clientId)}
                    onClear={() => changeClient(null)}
                    label='Клиент'
                    name='client'
                    placeholderText='Выберите клиента'
                    control={control}
                    getOptionLabel='fullName'
                    getOptionValue='clientId'
                    values={clients}
                    setValue={setValue}
                    isBorder={false}
                    isDisabled={!invoiceId}
                />

                {/*<Button*/}
                {/*    variant='isMain'*/}
                {/*    iconName='krista-add-user'*/}
                {/*/>*/}
            </GridLayout>

            <InputNumeric
                decimalScale={2}
                isDebounce
                control={control}
                isBorder={false}
                name='discountPercent'
                label='Размер скидки'
                placeholder='Введите данные'
                symbol='%'
                errors={errors?.discountPercent?.message}
                whenChanged={changeDiscountPercent}
                disabled={!invoiceId}
            />
        </GridLayout>
    );
}

export default StoreHead;