import React from "react";
import Icon from "@components/Icon/Icon";
import {Link} from "react-router-dom";
import Button from "@components/Form/Button";
import BodyRow from "@components/Table/components/BodyRow";
import GridLayout from "@components/Layout/GridLayout";
import Checkbox from "@components/Form/Checkbox";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import BodyRowOnline from "@components/Table/components/BodyRowOnline";
import BodyRowItem from "@components/Table/components/BodyRowItem";
import FlexLayout from "@components/Layout/FlexLayout";
import BadgeStatus from "@components/Badges/BadgeStatus";
import BadgeStatusSecondary from "@components/Badges/BadgeStatusSecondary/BadgeStatusSecondary";

const Row = ({isOnline, catalogIcon}) => {
    return (
        <BodyRow padding='12px 8px' backgroundColor={COLORS.WHITE} border='0'>
            <GridLayout template='24px 8% 20px auto 12% 12% 4% 4% 9% 9% 9% 50px' align='center'>
                <Checkbox/>
                <BodyRowItem>
                    <div>
                        <Text value='6587' font={FONTS.MEDIUM_14} color={COLORS.DARKGRAY}/>
                        <Text value='23.10.22, 14:58' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                    </div>
                </BodyRowItem>
                <BodyRowItem>
                    <BodyRowOnline isActive={isOnline}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text
                        value='Чичваркина Александра Александровна'
                        font={FONTS.REGULAR_14}
                        color={COLORS.MAIN}
                        cursor='pointer'
                    />
                </BodyRowItem>
                <BodyRowItem>
                    <FlexLayout align='center'>
                        <Icon
                            iconName={catalogIcon}
                            iconWidth={20}
                            iconHeight={20}
                            iconColor={COLORS.LIGHTGRAY}
                        />
                        <Text value='В наличии сериями' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}
                              margin='0 0 0 8px'/>
                    </FlexLayout>
                </BodyRowItem>
                <BodyRowItem>
                    <BadgeStatus backgroundColor={COLORS.STATUS_GREEN} value='Ожидает предоплаты'/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='50' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='150' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='3 540.00 $' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <FlexLayout>
                        <BadgeStatusSecondary color={COLORS.STATUS_GREEN} value='1 540.00 $'/>
                    </FlexLayout>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='1 600.00 $' font={FONTS.MEDIUM_14} color={COLORS.DARKGRAY}/>
                    <Text value='1 940.00 $' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Button
                        variant='isMain'
                        iconName='krista-cart'
                        isSmall
                        as={Link}
                        to="/orders/order"
                    />
                </BodyRowItem>
            </GridLayout>
        </BodyRow>
    );
};

export default Row;
