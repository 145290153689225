export function isWhite(str) {
    if (str) {
        let strWithOutHash = str;
        strWithOutHash = strWithOutHash.substring(1);
        let whiteLimit = 200,
            r, g, b;
        r = parseInt("0x" + strWithOutHash.substring(0, 2));
        g = parseInt("0x" + strWithOutHash.substring(2, 4));
        b = parseInt("0x" + strWithOutHash.substring(4));
        return !(r < whiteLimit || b < whiteLimit || g < whiteLimit);
    }

}