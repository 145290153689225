import GridLayout from "@components/Layout/GridLayout";
import SizeInWork from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/SizeInWork";
import Graphis from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/Graphis";
import Layouts from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/Layouts";
import Comment from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/Comment";

//TODO: придумать название
function FileMaterial(props) {
    const {
        readOnly,
        sizeInWorkData,
        sizePrioritySelected,
        register,
        watch,
        setValue,
        fields,
        update,
        isPriortySizeEditable
    } = props
    return (
        <GridLayout gap='20px'>
            {isPriortySizeEditable &&
                <SizeInWork
                    sizeInWorkData={sizeInWorkData}
                    sizePrioritySelected={sizePrioritySelected}
                    register={register}
                />
            }
            <Graphis readOnly={readOnly} watch={watch} setValue={setValue}/>
            <Layouts readOnly={readOnly} fields={fields} update={update}/>
            <Comment/>
        </GridLayout>
    );
}

export default FileMaterial;