import {toast} from "react-toastify";
import ToastContent from "@components/ToastContent/ToastContent";
import {SET_USER_DETAIL} from "../types";
import axios from "axios";
import ApiConfig from "@/ApiConfig";
import {getLocalStorage} from "@util/getLocalStorage";

export const getAuthorization = (dispatch, data, setError,setIsDisabled, navigate) => {
    const unInterceptedAxiosInstance = axios.create();
    unInterceptedAxiosInstance.post(`${process.env.REACT_APP_API_PATH}Employees/Authorization/SignIn`, data).then((response) => {
        toast.success(<ToastContent title="Вы авторизовались под:" response={response.data.name} ok/>);
        localStorage.setItem('adminToken', response.data.token);
        localStorage.setItem('adminRefreshToken', response.data.refreshToken);
        localStorage.setItem('adminName', response.data.name);
        localStorage.setItem('adminRoleName', response.data.roleName);
        localStorage.setItem('adminId', response.data.managerId);
        dispatch({type: SET_USER_DETAIL, payload: response.data})
        setIsDisabled(false)
        if (response.status === 200) {
            navigate("/", {replace: true});
        }

    }).catch(function (error) {
        setError(error.response.data.message || 'Что-то пошло не так');
        setIsDisabled(false)
    })
};

//TODO УДАЛИТЬ !!!! после продакшена
export const getAuthorizationTemp = ({data, dispatch}) => {
    const unInterceptedAxiosInstance = axios.create();
    unInterceptedAxiosInstance.post(`${process.env.REACT_APP_API_PATH}Employees/Authorization/SignIn`, data).then((response) => {
        toast.success(<ToastContent title="Вы авторизовались под:" response={response.data.name} ok/>);
        localStorage.setItem('adminToken', response.data.token);
        localStorage.setItem('adminRefreshToken', response.data.refreshToken);
        localStorage.setItem('adminName', response.data.name);
        localStorage.setItem('adminRoleName', response.data.roleName);
        localStorage.setItem('adminId', response.data.managerId);
        dispatch({type: SET_USER_DETAIL, payload: response.data})
    })
}

export const getLogout = (dispatch) => {
    ApiConfig.post(`Employees/Authorization/SignOut`, {token: getLocalStorage('adminRefreshToken')})
        .then((response) => {
        toast.success(<ToastContent response={response.data.message} ok/>);
        localStorage.removeItem('adminToken');
        localStorage.removeItem('adminRefreshToken');
        localStorage.removeItem('adminName');
        localStorage.removeItem('adminRoleName');
        localStorage.removeItem('adminId');
        dispatch({
            type: SET_USER_DETAIL, payload: {
                name: null,
                managerId: 0,
                rolaName: null,
                token: null,
                refreshToken: null,
            }
        })
    })
}
