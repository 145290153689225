import React from 'react';
import Row from "@components/Table/TableDefault/TableOrder/Row/Row";
import Footer from "@components/Table/TableDefault/TableOrder/Footer/Footer";
import Table from "@components/Table/components/Wrapper";
import HeadRow from "@components/Table/components/HeadRow";
import GridLayout from "@components/Layout/GridLayout";
import HeadRowItem from "@components/Table/components/HeadRowItem";
import Checkbox from "@components/Form/Checkbox";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";

function TableOrder() {
    const array = [1,2,3,4,5,6]

    return (
        <Table overflow='visible'>
            <GridLayout gap='10px'>
                <HeadRow isTopOffset>
                    <GridLayout align='center' template='42px 6% 7% 7% 11% auto 9% 6% 6% 8% 8% 54px'>
                        <HeadRowItem>
                            <Checkbox/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Фото' font={FONTS.SEMI_BOLD_16} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Артикул' font={FONTS.SEMI_BOLD_16} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Размер' font={FONTS.SEMI_BOLD_16} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Цвет' font={FONTS.SEMI_BOLD_16} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Каталог' font={FONTS.SEMI_BOLD_16} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Серий' font={FONTS.SEMI_BOLD_16} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Ед.' font={FONTS.SEMI_BOLD_16} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Цена' font={FONTS.SEMI_BOLD_16} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Сумма' font={FONTS.SEMI_BOLD_16} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Предоплата' font={FONTS.SEMI_BOLD_16} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem></HeadRowItem>
                    </GridLayout>
                </HeadRow>
                <GridLayout gap='10px'>
                    {array.map(item =>
                        <Row
                            catalogIcon='krista-scissors'
                            key={item}
                        />
                    )}
                </GridLayout>
            </GridLayout>
            <Footer/>
        </Table>
    );
}

export default TableOrder;