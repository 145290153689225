import BodyRow from "@components/Table/components/BodyRow";
import GridLayout from "@components/Layout/GridLayout";
import BodyRowItem from "@components/Table/components/BodyRowItem";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";
import Color from "@components/Table/components/Color";
import Button from "@components/Form/Button";
import {NumericFormat} from "react-number-format";
import Image from "@components/Image/Image";
import {getAcceptance, getAcceptanceProductDelete} from "@actions/acceptanceDocumentsAction";
import {useSearchParams} from "react-router-dom";
import {SET_MODAL_CONFIRM_ACTION} from "@types/types";
import {useDispatch} from "react-redux";

function Row({template, item}) {

    return (
        <BodyRow backgroundColor={COLORS.WHITE} padding="0">
            <GridLayout align='center' template={template}>
                <BodyRowItem padding="0 8px 0 16px">
                    <FlexLayout gap='8px' align='center'>
                        <Image src={item?.photo} width='36px' height='36px'/>
                        <Text value={item?.articulName} font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY}/>
                    </FlexLayout>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value={item?.sizeLineName} font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <FlexLayout gap='8px'>
                        <Color backgroundColor={item?.hex}/>
                        <Text value={item?.colorName} font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY}/>
                    </FlexLayout>
                </BodyRowItem>
                <BodyRowItem>
                    <NumericFormat
                        value={item?.price}
                        thousandSeparator=" "
                        decimalScale={2}
                        displayType="text"
                        renderText={(value) => (
                            <Text value={value} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                        )}
                        fixedDecimalScale
                        suffix={` ${item?.currencySymbol}`}
                    />
                </BodyRowItem>
                <BodyRowItem bg="#EEF8FF" isFillRow>
                    <Text value={item?.expectedQuantity} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem bg="#EEF8FF" isFillRow>
                    <NumericFormat
                        value={item?.expectedTotalSum}
                        thousandSeparator=" "
                        decimalScale={2}
                        displayType="text"
                        renderText={(value) => (
                            <Text value={value} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                        )}
                        fixedDecimalScale
                        suffix={` ${item?.currencySymbol}`}
                    />
                </BodyRowItem>
                <BodyRowItem bg="#FFF8E8" isFillRow>
                    <Text value={item?.actualQuantity} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem bg="#FFF8E8" isFillRow>
                    <NumericFormat
                        value={item?.actualTotalSum}
                        thousandSeparator=" "
                        decimalScale={2}
                        displayType="text"
                        renderText={(value) => (
                            <Text value={value} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                        )}
                        fixedDecimalScale
                        suffix={` ${item?.currencySymbol}`}
                    />
                </BodyRowItem>
                <BodyRowItem  bg="#FFEFEF" isFillRow>
                    <Text value={item?.quantityDiff} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem bg="#FFEFEF" isFillRow>
                    <NumericFormat
                        value={item?.totalSumDiff}
                        thousandSeparator=" "
                        decimalScale={2}
                        displayType="text"
                        renderText={(value) => (
                            <Text value={value} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                        )}
                        fixedDecimalScale
                        suffix={` ${item?.currencySymbol}`}
                    />
                </BodyRowItem>
            </GridLayout>
        </BodyRow>
    );
}


export default Row;