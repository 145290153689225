import Icon from "@components/Icon/Icon";
import Label from "@components/Form/Label/Label";
import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";
import Text from "@components/Text";
import hexToRgba from "hex-to-rgba";

function UploadFileIndicator({title, label, onClearFile, readOnly}) {
    return (
        <div>
            {label && <Label label={label}/>}
            <Content isActive={title}>
                <IconWrapper>
                    <Icon
                        iconName='krista-file-v2'
                        iconHeight={24}
                        iconWidth={24}
                    />
                </IconWrapper>
                <div>
                    {title ?
                        <Text value={title} font={FONTS.REGULAR_14} color={title ? COLORS.BLACK : COLORS.GRAY}/>
                        :
                        <Text value='Не загружен' font={FONTS.REGULAR_14} color={title ? COLORS.BLACK : COLORS.GRAY}/>
                    }
                </div>
                {
                    title && !readOnly && (
                        <Close onClick={onClearFile}>
                            <Icon
                                iconName='krista-close-circle'
                                iconHeight={24}
                                iconWidth={24}
                            />
                        </Close>
                    )
                }
            </Content>
        </div>
    );
}

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  min-height: 48px;
  border-radius: 10px;  
  background: ${COLORS.LIGHTGRAY2};
  color: ${COLORS.LIGHTGRAY};
  margin-right: 12px;
`
const Content = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.LIGHTGRAY3};
  border-radius: 10px;
  position: relative;
  padding-right: 40px;
  ${({isActive}) => isActive && `
      background-color: ${hexToRgba(COLORS.MAIN_BLUE, 0.08)};
      
       ${IconWrapper} {
        background: ${hexToRgba(COLORS.MAIN_BLUE, 0.08)};
        color: ${COLORS.MAIN_BLUE};
       }
  `}
`

const Close = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${COLORS.RED};
  cursor: pointer;
`

export default UploadFileIndicator;