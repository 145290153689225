import React, {useState} from 'react';
import SelectTextile from "@components/Form/SelectsCustom/SelectTextile";
import useOutside from "@hooks/useOutside";
import {getMaterials} from "@actions/materialsAction";
import {useSelector} from "react-redux";

const SelectTextileLayout = (props) => {
    const {label, errorMessage} = props
    const {clearErrors} = props

    const {isTextile} = props
    const {update} = props

    const {item, itemTextile, index} = props

    const [materials, setMaterials] = useState([])


    const {ref, isShow, setIsShow} = useOutside(false)

    const [isLoaded, setIsLoaded] = useState(false)

    const openDropdown = () => {
        if (!isShow) {
            setIsLoaded(true)
            getMaterials({}, isTextile).then(({data}) => {
                setIsShow(true)
                setMaterials(data)
                setTimeout(() => {
                    setIsLoaded(false)
                }, 300)
            })
        }
        return setIsShow(false)
    }

    const setItem = (item) => {
        update(index, {
            quantity: 0,
            sum: 0,
            ...itemTextile.hasOwnProperty('isNew') && {...itemTextile},
            material: {
                ...item,
            }
        })
        setIsShow(false)
        // clearErrors()
    }

    return (
        <SelectTextile
            label={label}
            errorMessage={errorMessage}
            name={item?.name}
            subName={item?.supplierName}
            items={materials?.items}
            isTextile={isTextile}
            ref={ref}
            isShow={isShow}
            isLoaded={isLoaded}
            setItem={setItem}
            openDropdown={openDropdown}
        />
    );
};

export default SelectTextileLayout;