import BodyRow from "@components/Table/components/BodyRow";
import GridLayout from "@components/Layout/GridLayout";
import BodyRowItem from "@components/Table/components/BodyRowItem";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";
import Color from "@components/Table/components/Color";
import Button from "@components/Form/Button";
import {NumericFormat} from "react-number-format";
import Image from "@components/Image/Image";
import {getAcceptance, getAcceptanceProductDelete} from "@actions/acceptanceDocumentsAction";
import {useSearchParams} from "react-router-dom";
import {SET_MODAL_CONFIRM_ACTION} from "@types/types";
import {useDispatch} from "react-redux";
import {getWriteOffDocuments, getWriteOffDocumentsProductDelete} from "@actions/writeOffDocumentsAction";

function Row({template, writeOffDocuments, item, setWriteOffDocuments}) {
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()

    const deleteProduct = () => {
        dispatch({
            type: SET_MODAL_CONFIRM_ACTION, payload: {
                isShow: true,
                title: 'Вы уверены, что хотите удалить ?',
                desc: `
                Артикул: ${item.articulName} <br>
                Цвет: ${item.colorName} <br>
                Количество: ${item.quantity}
                `,
                accept: () => {
                    getWriteOffDocumentsProductDelete(searchParams.get('writeOffDocumentId'), item.productId).then(() => {
                        getWriteOffDocuments(searchParams.get('writeOffDocumentId')).then(({data}) => {
                            setWriteOffDocuments(data)
                            dispatch({
                                type: SET_MODAL_CONFIRM_ACTION, payload: {
                                    isShow: false,
                                    title: '',
                                    desc: '',
                                    accept: null,
                                    icon: ''
                                }
                            })
                        })
                    })

                },
                icon: 'krista-delete'
            }
        })

    }

    return (
        <BodyRow backgroundColor={COLORS.WHITE}>
            <GridLayout align='center' template={template}>
                <BodyRowItem>
                    <FlexLayout gap='8px' align='center'>
                        <Image src={item?.photo} width='36px' height='36px'/>
                        <Text value={item?.articulName} font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY}/>
                    </FlexLayout>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value={item?.sizeLineName} font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <FlexLayout gap='8px'>
                        <Color backgroundColor={item?.hex}/>
                        <Text value={item?.colorName} font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY}/>
                    </FlexLayout>
                </BodyRowItem>
                <BodyRowItem>
                    <NumericFormat
                        value={item?.price}
                        thousandSeparator=" "
                        decimalScale={2}
                        displayType="text"
                        renderText={(value) => (
                            <Text value={value} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                        )}
                        fixedDecimalScale
                        suffix={` ${item?.currencySymbol}`}
                    />
                </BodyRowItem>
                <BodyRowItem>
                    <Text value={item?.quantity} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <NumericFormat
                        value={item?.totalSum}
                        thousandSeparator=" "
                        decimalScale={2}
                        displayType="text"
                        renderText={(value) => (
                            <Text value={value} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                        )}
                        fixedDecimalScale
                        suffix={` ${item?.currencySymbol}`}
                    />
                </BodyRowItem>
                <BodyRowItem>
                    {!writeOffDocuments?.isComleted &&
                        <Button
                            variant='isDangerBorder'
                            isSmall
                            iconName='krista-delete'
                            onClick={deleteProduct}
                        />
                    }

                </BodyRowItem>
            </GridLayout>
        </BodyRow>
    );
}


export default Row;