import React from 'react';
import Icon from "@components/Icon";
import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";
import useOutside from "@hooks/useOutside";
import {orderBy as _orderBy} from "lodash/collection";
import {SET_MODAL_CONFIRM_ACTION, SET_MODEL_ALL_SIZE} from "@types/types";
import {useDispatch, useSelector} from "react-redux";

const More = (props) => {
    const dispatch = useDispatch()

    const {sizes} = props
    const {itemSize} = props
    const {item} = props
    const {ref, isShow, setIsShow} = useOutside(false)

    const showDropdown = (e) => {
        e.stopPropagation()
        setIsShow(!isShow)
    }

    const setBasicColor = () => {
        const colors = itemSize.colors.map((color) => {
            if (color.id === item.id) {
                return {...color, isMain: true}
            }
            return {...color, isMain: false}
        })

        itemSize.colors = _orderBy(colors, ['isMain', 'name'], ['desc', 'asc'])

        dispatch({type: SET_MODEL_ALL_SIZE, payload: sizes})
    }

    const openModal = () => {
        dispatch({
            type: SET_MODAL_CONFIRM_ACTION, payload: {
                isShow: true,
                title: 'Сделать цвет базовым?',
                desc: `Вы уверены, что хотите сделать <b>${item.name}</b> цвет базовым для размера <b>${itemSize.size.value} ?</b>`,
                accept: () => {
                    setBasicColor()
                    dispatch({
                        type: SET_MODAL_CONFIRM_ACTION, payload: {
                            isShow: false,
                            title: '',
                            desc: '',
                            accept: null
                        }
                    })
                }
            }
        })
    }

    return (
        <Content
            isActive={isShow}
            onClick={showDropdown}
            ref={ref}
            aria-label='Действие с цветом'
        >
            <IconWrapper>
                <Icon iconName="krista-more"/>
            </IconWrapper>
            {isShow &&
                <Dropdown>
                    <DropdownItem onClick={openModal} aria-label='Сделать базовым цветом'>
                        <DropdownIcon>
                            <Icon iconName="krista-star"/>
                        </DropdownIcon>
                        <DropdownValue>Сделать базовым цветом</DropdownValue>
                    </DropdownItem>
                    <DropdownItem>
                        <DropdownIcon>
                            <Icon iconName="krista-cancel"/>
                        </DropdownIcon>
                        <DropdownValue>Удалить цвет</DropdownValue>
                    </DropdownItem>
                </Dropdown>
            }
        </Content>
    );
};

const Content = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: ${COLORS.LIGHTGRAY2};
  }
  ${({isActive})=> isActive && `
       background: ${COLORS.LIGHTGRAY2};
  `}
`

const Dropdown = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  right: -10px;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.LIGHTGRAY};
  box-shadow: 0 5px 15px rgba(68, 68, 79, 0.1);
  border-radius: 10px;
  z-index: 1;
  padding: 10px 0;
  white-space: nowrap;
`

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  cursor: pointer;
  color: ${COLORS.LIGHTGRAY};
  &:hover{
    background-color: ${COLORS.LIGHTGRAY2};
  }
`

const DropdownValue = styled.div`
  font: ${FONTS.REGULAR_14};
  color: ${COLORS.DARKGRAY};
`

const DropdownIcon = styled.div`
  margin-right: 8px;
`

const IconWrapper = styled.div`
  color: ${COLORS.DARKGRAY};
`

export default More;