import ApiConfig from "@/ApiConfigStore";

const path = 'documents/writeOff/'

export const getWriteOffDocumentsCreate = async () => {
    return await ApiConfig.post(`${path}create`)
};

export const getWriteOffDocumentsScan = async (data) => {
    return await ApiConfig.post(`${path}product/scan`, data)
};

export const getWriteOffDocumentsScanCancel = async (data) => {
    return await ApiConfig.post(`${path}product/scan/cancel`, data)
};

export const getWriteOffDocuments = async (writeOffId) => {
    return await ApiConfig.get(`${path}${writeOffId}`)
};

export const getWriteOffDocumentsDelete = async (writeOffId) => {
    return await ApiConfig.delete(`${path}${writeOffId}/delete`)
};

export const getWriteOffDocumentsFinish = async (writeOffId) => {
    return await ApiConfig.post(`${path}${writeOffId}/finish`)
};

export const getWriteOffDocumentsProductDelete = async (writeOffId, productId) => {
    return await ApiConfig.delete(`${path}${writeOffId}/product/delete/${productId}`)
};


