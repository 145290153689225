import React from 'react';
import GridLayout from "@components/Layout/GridLayout";
import FooterRow from "@components/Table/components/FooterRow/FooterRow";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import FooterRowItem from "@components/Table/components/FooterRowItem";

function Footer() {
    return (
        <FooterRow>
            <GridLayout template='42px 6% 7% 7% 11% auto 9% 6% 6% 8% 8% 54px' align='center'>
                <FooterRowItem/>
                <Text value='Итого: 12 моделей' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                <FooterRowItem/>
                <FooterRowItem/>
                <FooterRowItem/>
                <FooterRowItem/>
                <FooterRowItem>
                    <Text value='148' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                </FooterRowItem>
                <FooterRowItem>
                    <Text value='296' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                </FooterRowItem>
                <FooterRowItem/>
                <FooterRowItem>
                    <Text value='5 320.00 $' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                </FooterRowItem>
                <FooterRowItem>
                    <Text value='2 520.00 $' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                </FooterRowItem>
                <FooterRowItem/>
            </GridLayout>
        </FooterRow>
    );
}

export default Footer;