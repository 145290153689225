import React from 'react';
import styled from "styled-components";
import {FONTS} from "@/constans";
import hexToRgba from "hex-to-rgba";
import Text from "@components/Text";

function BadgeStatusSecondary({color, value}) {
    return (
        <BadgeStatusSecondaryContent color={color} value={value}>
            <Text value={value} font={FONTS.MEDIUM_14} color='inherit'/>
        </BadgeStatusSecondaryContent>
    );
}

const BadgeStatusSecondaryContent = styled.div`
  padding: 10px 8px;
  border-radius: 8px;
  text-align: center;
  color: ${({color}) => color && color};
  background-color: ${({color}) => color && hexToRgba(color, 0.1)};
`

export default BadgeStatusSecondary;