import Icon from "@components/Icon/Icon";
import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";
import Text from "@components/Text";
import hexToRgba from "hex-to-rgba";

const OpenModalCurrentCustomers = ({onClick, isDisabled, item}) => {
    return (
        <BodyRowBadge
            isDisabled={isDisabled}
            onClick={onClick}
        >
            {isDisabled
                ?
                <IconWrapper>
                    <Icon
                        iconName={'krista-loading'}
                        iconWidth={18}
                        iconHeight={18}
                    />
                </IconWrapper>
                :
                <Text value={`Уже есть: ${item.activeClientsCount}`} font={FONTS.MEDIUM_12} color={COLORS.RED}/>
            }
        </BodyRowBadge>
    );
};

const BodyRowBadge = styled.span`
  background-color: ${hexToRgba(COLORS.DARKRED, 0.1)};
  padding: 0 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 20px;
  margin-left: 8px;
  cursor: pointer;
  &:hover{
    background-color: ${hexToRgba(COLORS.DARKRED, 0.2)};
    color: ${COLORS.WHITE}
  }
  ${({isDisabled}) => isDisabled && 'padding: 14px 6px'};
  ${({isDisabled}) => isDisabled && 'pointer-events: none'};
`

const IconWrapper = styled.div`
  color: ${COLORS.RED};
  animation: rotateIcon infinite 4s linear;
`

export default OpenModalCurrentCustomers;
