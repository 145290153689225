import {COLORS} from "@/constans";
import {css} from "styled-components";

export const InputContentStyles = (isInvalid, isSmall, iconPosition, icon, isBorder) => {
    return css`
      height: 48px;
      width: 100%;
      display: block;
      background-color: ${COLORS.WHITE};
      border: 1px solid ${({isBorder}) => isBorder ? `${COLORS.LIGHTGRAY1}` : 'transparent'};
      border-radius: 10px;
      padding: 0 20px;
      outline: none;
      font-weight: 400;
      font-size: 14px;

      &:disabled{
        border-color: ${COLORS.LIGHTGRAY2};
        background-color: ${COLORS.LIGHTGRAY3};
        cursor: not-allowed;
      }
      
      &:focus {
        border: 1px solid ${COLORS.LIGHTGRAY};
      }

      ${isInvalid && `
        border-color: ${COLORS.RED};
        &:focus {
            border: 1px solid ${COLORS.RED};
        }
      `};
    
          ${isSmall && `
        height: 38px;
        padding: 0 16px;
      `}

      ${() => {
        if (iconPosition === 'right') {
          return 'padding: 0 40px 0 16px;'
        }
        if (icon) {
          return 'padding: 0 16px 0 40px;'
        }
      }}
    `
}

