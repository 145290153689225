import {
    SET_CLIENTS,
} from "@types/types";

const initState = {
    clientsState: null,
}

const clientsReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_CLIENTS :
            return {...state, clientsState: action.payload}
        default:
            return state
    }
}

export default clientsReducer