import GridLayout from "@components/Layout/GridLayout";
import Documents from "@pages/ProductionPages/ModelDevelopmentPage/ChiefPage/components/Documents";
import PhotoSet from "@pages/ProductionPages/ModelDevelopmentPage/ChiefPage/components/PhotoSet";

function ChiefPage() {
    return (
        <div>
            <GridLayout template='412px 1fr'>
                <GridLayout gap='20px'>
                    <Documents/>
                    <PhotoSet/>
                </GridLayout>
            </GridLayout>
        </div>
    );
}

export default ChiefPage;