import FooterRow from "@components/Table/components/FooterRow/FooterRow";
import GridLayout from "@components/Layout/GridLayout";
import FooterRowItem from "@components/Table/components/FooterRowItem";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";
import React from "react";
import FlexLayout from "@components/Layout/FlexLayout";
import {NumericFormat} from "react-number-format";
import {getCurrencyScaleLength} from "@util/decimalHelper";

function Footer({template, totals}) {
    return (
        <FooterRow padding='24px 8px' border='0' backgroundColor={COLORS.WHITE}>
            <GridLayout template={template} align='center'>
                <FooterRowItem>
                    <FlexLayout gap='4px' align='center'>
                        <Text value='Всего товаров:' font={FONTS.MEDIUM_14} color={COLORS.DARKGRAY}/>
                        <Text
                            value={totals?.totalProducts ? totals.totalProducts : 0}
                            font={FONTS.REGULAR_14}
                            color={COLORS.DARKGRAY}
                        />
                    </FlexLayout>
                </FooterRowItem>
                <FooterRowItem/>
                <FooterRowItem/>
                <FooterRowItem/>
                <FooterRowItem/>
                <FooterRowItem/>
                <FooterRowItem>
                    <Text
                        value={totals?.totalQuantity ? totals.totalQuantity : 0}
                        font={FONTS.SEMI_BOLD_14}
                        color={COLORS.DARKGRAY}
                    />
                </FooterRowItem>
                <FooterRowItem>
                    {
                        totals.totalDiscount > 0 &&
                        <NumericFormat
                            value={totals.totalSumWithoutDiscount}
                            thousandSeparator=" "
                            decimalScale={getCurrencyScaleLength(totals.totalSumWithoutDiscount)}
                            fixedDecimalScale
                            displayType="text"
                            renderText={(value) => (
                                <Text
                                    value={value}
                                    font={FONTS.REGULAR_12}
                                    color={COLORS.LIGHTGRAY}
                                    textDecoration='line-through'
                                />
                            )}
                            suffix={` ${totals?.currencySymbol}`}
                        />
                    }

                    <NumericFormat
                        value={totals.totalSum}
                        thousandSeparator=" "
                        decimalScale={getCurrencyScaleLength(totals.totalSum)}
                        fixedDecimalScale
                        displayType="text"
                        renderText={(value) => (
                            <Text value={value} font={FONTS.SEMI_BOLD_14} color={COLORS.DARKGRAY}/>
                        )}
                        suffix={` ${totals?.currencySymbol}`}
                    />
                </FooterRowItem>
                <FooterRowItem/>
            </GridLayout>
        </FooterRow>
    );
}

export default Footer;