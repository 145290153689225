import React, {useEffect, useState} from 'react';
import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";
import Button from "@components/Form/Button";
import {
    getAudit,
    getAuditCreate,
    getAuditDelete,
    getAuditFinish,
    getAuditProductScan, getAuditProductScanCancel
} from "@actions/auditDocumentsAction.js";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import TableRevision from "src/components/Table/TableDefault/TableAudit";
import {toast} from "react-toastify";
import ToastContent from "@components/ToastContent";
import EmptyBox from "@components/EmptyBox/EmptyBox";
import {SET_MODAL_CONFIRM_ACTION} from "@types/types";
import {useDispatch} from "react-redux";
import LastScan from "@pages/StorePages/components/LastScan";
import ProductBarcode from "@pages/StorePages/components/ProductBarcode";
import BadgeStatus from "@components/Badges/BadgeStatus";
import Totals from "@pages/StorePages/AuditDocumentsPage/components/Totals";
import BreadCrumbs from "@pages/StorePages/components/BreadCrumbs";

const AuditDocumentsPage = () => {
    const dispatch = useDispatch()

    const [auditDocuments, setAuditDocuments] = useState(null)
    const [isAddProduct, setIsAddProduct] = useState(true)
    const [lastProduct, setLastProduct] = useState(null)

    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate();

    const isCompleted = auditDocuments?.isCompleted

    const {register, handleSubmit, watch, setValue, formState: {errors}} = useForm({
        resolver: yupResolver(yup.object().shape({
            productBarcode: yup.string()
                .test('value must be 16 numbers', 'Штрих код должен быть 16 символов', (value) => {
                    return !value || value.length === 16;
                }),
        })),
        defaultValues: {productBarcode: ""}
    })

    const acceptanceDelete = () => {
        dispatch({
            type: SET_MODAL_CONFIRM_ACTION, payload: {
                isShow: true,
                title: 'Вы уверены, что хотите удалить все ?',
                desc: '',
                accept: () => {
                    getAuditDelete(searchParams.get('auditDocumentId'))
                        .then(() => [
                            navigate('/store/warehouse?page=1&pageSize=25'),
                            toast.success(<ToastContent title="Документ принятие на склад, удален" ok/>),
                            dispatch({
                                type: SET_MODAL_CONFIRM_ACTION, payload: {
                                    isShow: false,
                                    title: '',
                                    desc: '',
                                    accept: null,
                                    icon: ''
                                }
                            })
                        ])
                },
                icon: 'krista-delete'
            }
        })

    }

    const acceptanceFinish = () => {
        getAuditFinish(searchParams.get('auditDocumentId'))
            .then(() => [
                navigate('/store/warehouse?page=1&pageSize=25'),
                toast.success(<ToastContent title="Ревизия завершена" ok/>)
            ])
    }

    const submit = (data) => {
        if (!isAddProduct) {
            getAuditProductScanCancel(
                {
                    ...data,
                    auditDocumentId: searchParams.get('auditDocumentId')
                }
            ).then(({data}) => [
                setLastProduct(data),
                getAudit(searchParams.get('auditDocumentId'))
                    .then(({data}) => setAuditDocuments(data))
            ])
        }
        if (isAddProduct) {
            getAuditProductScan({...data, auditDocumentId: searchParams.get('auditDocumentId')}).then(({data}) => [
                setLastProduct(data),
                getAudit(searchParams.get('auditDocumentId'))
                    .then(({data}) => [
                        setAuditDocuments(data),
                        setValue('productBarcode', '')
                    ])
            ])
        }

    }

    useEffect(() => {
        if (!searchParams.get('auditDocumentId')) {
            getAuditCreate().then(({data}) => setSearchParams(data))
        } else {
            getAudit(searchParams.get('auditDocumentId')).then(({data}) => setAuditDocuments(data))
        }
    }, [searchParams])

    useEffect(() => {
        if (watch('productBarcode').length === 16) {
            handleSubmit(submit)()
        }
    }, [watch('productBarcode')]);

    return (
        <GridLayout gap='24px'>
            <div>
                <BreadCrumbs
                    documentTypeName={auditDocuments?.documentTypeName}
                    serialNumber={auditDocuments?.serialNumber}
                    statusHexColor={auditDocuments?.statusHexColor}
                    statusName={auditDocuments?.statusName}
                />
            </div>

            <Totals auditDocuments={auditDocuments}/>

            {!isCompleted &&
                <GridLayout gap='20px' template='1fr 1fr' align='center'>
                    <div>
                        <ProductBarcode
                            register={register}
                            errors={errors}
                            isAddProduct={isAddProduct}
                            setIsAddProduct={setIsAddProduct}
                        />
                    </div>
                    <div><LastScan item={lastProduct}/></div>
                </GridLayout>
            }

            {!auditDocuments?.products?.length ?
                <EmptyBox icon='krista-box' title='Нет товаров' isRefresh={false}/>
                :
                <TableRevision auditDocuments={auditDocuments} setAuditDocuments={setAuditDocuments}/>
            }

            {!isCompleted
                &&
                <GridLayout template='14% 30%' spaceBetween>
                    <Button
                        variant='isDangerBorder'
                        buttonText='Удалить документ'
                        onClick={acceptanceDelete}
                    />
                    <Button
                        isDisabled={!auditDocuments?.products?.length}
                        variant={`${!auditDocuments?.products?.length ? 'isDisabled' : 'isSuccess'}`}
                        buttonText='Завершить ревизию'
                        onClick={acceptanceFinish}
                    />
                </GridLayout>
            }

        </GridLayout>
    );
};

export default AuditDocumentsPage;