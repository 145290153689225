import {SET_MARGINS} from "@types/types"

const initState = {
    marginsState: null
}

const marginsReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_MARGINS :
            return {...state, marginsState: action.payload}
        default:
            return state
    }
}

export default marginsReducer