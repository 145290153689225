import React from 'react';
import {getSizes} from "@actions/sizeAction";
import {SET_MODAL_CREATE_SIZES, SET_MODEL_ACTIVE_SIZE, SET_MODEL_ALL_SIZE} from "@types/types";
import {useDispatch} from "react-redux";
import TabSize from "@pages/ProductionPages/ModelDevelopmentPage/components/TabSize";

const TabsSizeLayout = (props) => {

    const {sizes} = props
    const {register, getValues} = props

    const dispatch = useDispatch()

    const openModalCreateSize = () => {
        getSizes().then((response) => {
            if (response.status === 200) {
                dispatch({type: SET_MODEL_ALL_SIZE, payload: getValues('models')})
                dispatch({type: SET_MODAL_CREATE_SIZES, payload: true})
            }
        })
    }

    const newSizes = sizes.map(item => ({
        id: item.size.id,
        value: item.size.value,
        subSizes: item.size.subSizes
    }))
    return (
        <TabSize
            callBackTab={openModalCreateSize}
            sizes={newSizes}
            register={register}
        />
    );

};

export default TabsSizeLayout;