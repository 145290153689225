import {SET_ALL_CITY, SET_NEW_CITY, SET_NEW_CITY_ID} from "@types/types";

const initState = {
    citiesState: [],
    newCityState: null,
    newCityIdState: null
}

const citiesReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_ALL_CITY :
            return {...state, citiesState: action.payload}
        case SET_NEW_CITY :
            return {...state, newCityState: action.payload}
        case SET_NEW_CITY_ID :
            return {...state, newCityIdState: action.payload}

        default:
            return state
    }
}
export default citiesReducer