import Row from "@components/Table/TableDefault/TableSecondaryOrdersModel/Row/Row";
import HeadRow from "@components/Table/components/HeadRow";
import GridLayout from "@components/Layout/GridLayout";
import {COLORS, FONTS} from "@/constans";
import HeadRowItem from "@components/Table/components/HeadRowItem";
import Text from "@components/Text";

function TableSecondaryOrdersModel() {
    const array = [1, 2, 3, 4]
    return (
        <>
            <HeadRow borderRadius='10px 10px 0 0'>
                <GridLayout template='40px auto 14% 8% 10% 10% 10% 100px'>
                    <HeadRowItem>
                        <Text value='№' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                    </HeadRowItem>
                    <HeadRowItem>
                        <Text value='Имя' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                    </HeadRowItem>
                    <HeadRowItem>
                        <Text value='Город' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                    </HeadRowItem>
                    <HeadRowItem>
                        <Text value='Менеджер' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                    </HeadRowItem>
                    <HeadRowItem>
                        <Text value='Серий' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                    </HeadRowItem>
                    <HeadRowItem>
                        <Text value='Едениц' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                    </HeadRowItem>
                    <HeadRowItem>
                        <Text value='Сумма' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                    </HeadRowItem>
                    <HeadRowItem></HeadRowItem>
                </GridLayout>
            </HeadRow>
            {array.map(item => <Row key={item}/>)}
        </>
    );
}

export default TableSecondaryOrdersModel;