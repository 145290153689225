import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import Box from "@components/Box";
import GridLayout from "@components/Layout/GridLayout";
import Input from "@components/Form/Inputs/Input";
import React from "react";
import Switch from "@components/Switch";

function ProductBarcode(props) {
    const {register, errors, isAddProduct, setIsAddProduct} = props
    const {isDisabledBarCode, placeholderBarCode} = props

    const switchArrayText = [
        {
            id: true,
            render: <div onClick={() => setIsAddProduct(true)}><Text value="Добавление" color="inherit"/></div>
        },
        {
            id: false,
            render: <div onClick={() => setIsAddProduct(false)}><Text value="Удаление" color="inherit"/></div>
        },
    ]

    return (
        <>
            <Text value='Штрих-код товара' font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY} margin='0 0 8px 0'/>
            <Box padding='16px'>
                <GridLayout template='3fr 5fr' gap='20px' align='center'>
                    <Switch array={switchArrayText} selected={isAddProduct}/>
                    <GridLayout gap='16px'>
                        <Input
                            disabled={isDisabledBarCode}
                            register={register}
                            required={false}
                            name='productBarcode'
                            placeholder={placeholderBarCode ? placeholderBarCode : 'Отсканируйте или введите номер'}
                            errors={errors?.productBarcode?.message}
                        />
                    </GridLayout>
                </GridLayout>
            </Box>
        </>
    );
}

export default ProductBarcode;