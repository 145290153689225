import TableOrder from "@components/Table/TableDefault/TableOrder/TableOrder";
import OrderPanelActions from "@components/Order/OrderPanelActions/OrderPanelActions";

const OrderPage = () => {
    return (
        <>
            <OrderPanelActions/>
            <TableOrder/>
        </>
    );
};

export default OrderPage;
