import React, {useEffect, useState} from 'react';
import GridLayout from "@components/Layout/GridLayout";
import Button from "@components/Form/Button";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import {toast} from "react-toastify";
import ToastContent from "@components/ToastContent";
import EmptyBox from "@components/EmptyBox/EmptyBox";
import {SET_MODAL_CONFIRM_ACTION} from "@types/types";
import {useDispatch} from "react-redux";
import LastScan from "@pages/StorePages/components/LastScan";
import ProductBarcode from "@pages/StorePages/components/ProductBarcode";
import {
    getDocuments,
    documentsCreate, documentsDelete,
    documentsFinish, documentsScan, documentsScanCancel
} from "@actions/storeActions/movementDocumentsAction";
import BreadCrumbs from "@pages/StorePages/components/BreadCrumbs";
import TableDocuments from "@components/Table/TableDefault/TableDocuments";
import TableLayout from "@pages/StorePages/MovementDocumentsPage/TableLayout";

const MovementDocumentsPage = () => {
    const dispatch = useDispatch()

    const [documents, setDocuments] = useState(null)
    const [isAddProduct, setIsAddProduct] = useState(true)
    const [lastProduct, setLastProduct] = useState(null)

    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate();

    const isCompleted = documents?.isCompleted

    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        resolver: yupResolver(yup.object().shape({
            productBarcode: yup.string().required('Обязательное поле')
        })),
        defaultValues: {productBarcode: ""}
    })

    const documentsDeleteHandle = () => {
        dispatch({
            type: SET_MODAL_CONFIRM_ACTION, payload: {
                isShow: true,
                title: 'Вы уверены, что хотите удалить все ?',
                desc: '',
                accept: () => {
                    documentsDelete(searchParams.get('movementDocumentId'))
                        .then(() => [
                            navigate('/store/warehouse?page=1&pageSize=25'),
                            toast.success(<ToastContent title="Документ принятие на склад, удален" ok/>),
                            dispatch({
                                type: SET_MODAL_CONFIRM_ACTION, payload: {
                                    isShow: false,
                                    title: '',
                                    desc: '',
                                    accept: null,
                                    icon: ''
                                }
                            })
                        ])
                },
                icon: 'krista-delete'
            }
        })

    }

    const documentsFinishHandle = () => {
        documentsFinish(searchParams.get('movementDocumentId'))
            .then(() => [
                navigate('/store/warehouse?page=1&pageSize=25'),
                toast.success(<ToastContent title="Документ принятие на склад, добавлен" ok/>)
            ])
    }

    const submit = (data) => {
        if (isAddProduct) {
            documentsScan(
                {
                    ...data,
                    movementDocumentId: searchParams.get('movementDocumentId')
                }
            ).then(({data}) => [
                setLastProduct(data),
                getDocuments(searchParams.get('movementDocumentId'))
                    .then(({data}) => setDocuments(data))
            ])
        } else {
            documentsScanCancel(
                {
                    ...data,
                    movementDocumentId: searchParams.get('movementDocumentId')
                }
            ).then(({data}) => [
                setLastProduct(data),
                getDocuments(searchParams.get('movementDocumentId'))
                    .then(({data}) => setDocuments(data))
            ])
        }

    }


    useEffect(() => {
        if (!searchParams.get('movementDocumentId')) {
            documentsCreate().then(({data}) => setSearchParams(data))
        } else {
            getDocuments(searchParams.get('movementDocumentId')).then(({data}) => setDocuments(data))
        }
    }, [searchParams])

    useEffect(() => {
        if (watch('productBarcode').length === 16) {
            handleSubmit(submit)()
        }
    }, [watch('productBarcode')]);

    return (
        <GridLayout gap='24px'>
            <div>
                <BreadCrumbs
                    documentTypeName={documents?.documentTypeName}
                    serialNumber={documents?.serialNumber}
                    statusHexColor={documents?.statusHexColor}
                    statusName={documents?.statusName}
                />
            </div>

            {!isCompleted &&
                <GridLayout gap='20px' template='1fr 1fr'>
                    <div>
                        <ProductBarcode
                            register={register}
                            errors={errors}
                            isAddProduct={isAddProduct}
                            setIsAddProduct={setIsAddProduct}
                        />
                    </div>
                    <div><LastScan item={lastProduct}/></div>
                </GridLayout>
            }
            {!documents?.products?.length ?
                <EmptyBox icon='krista-box' title='Нет товаров' isRefresh={false}/>
                :
                <TableLayout
                    documents={documents}
                    setDocuments={setDocuments}
                />
            }
            {!documents?.isComleted
                &&
                <GridLayout template='14% 30%' spaceBetween>
                    <Button
                        variant='isDangerBorder'
                        buttonText='Удалить документ'
                        onClick={documentsDeleteHandle}
                    />
                    <Button
                        isDisabled={!documents?.products?.length}
                        variant={`${!documents?.products?.length ? 'isDisabled' : 'isDanger'}`}
                        buttonText='Списать со склада'
                        onClick={documentsFinishHandle}
                    />
                </GridLayout>
            }

        </GridLayout>
    );
};

export default MovementDocumentsPage;