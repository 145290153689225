import axios from 'axios'
import {toast} from "react-toastify";
import ToastContent from "@components/ToastContent/ToastContent";
import store from './store'
import {SET_USER_DETAIL} from "@types/types";

const customId = "custom-id-yes";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })
    failedQueue = [];
}


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8'
    },
});

axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('adminToken');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    response => response,
    error => {

        const originalRequest = error.config;

        if (error.request.status === 0) {
            toast.error('Ошибка сети, проверьте интернет', {
                toastId: customId
            });
            return Promise.reject(error);
        }

        if (error.response.status === 401 && !originalRequest._retry) {

            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({resolve, reject})
                }).then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return axios(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                })
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const refreshToken = localStorage.getItem('adminRefreshToken');
            return new Promise(function (resolve, reject) {
                axiosInstance.post('Employees/Authorization/Refresh', {token: refreshToken})
                    .then(({data}) => {
                        localStorage.setItem('adminToken', data.token);
                        localStorage.setItem('adminRefreshToken', data.refreshToken);
                        store.dispatch({type: SET_USER_DETAIL, payload: data})
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
                        originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
                        processQueue(null, data.token);
                        resolve(axios(originalRequest));
                    })
                    .catch((err) => {
                        localStorage.removeItem('adminToken');
                        localStorage.removeItem('adminRefreshToken');
                        localStorage.removeItem('adminId');
                        localStorage.removeItem('adminName');
                        localStorage.removeItem('adminRoleName');
                        store.dispatch({
                            type: SET_USER_DETAIL, payload: {
                                name: null,
                                id: 0,
                                rolaName: null,
                                token: null,
                                refreshToken: null,
                            }
                        })
                        processQueue(err, null);
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false
                    })
            })
        } else if (error.response.status === 404) {
            toast.error(
                <ToastContent
                    title="Ошибка!"
                    response={`${error.response.status} ${error.response.statusText}`}
                    responseURL={error.response.request.responseURL}
                    error
                />, {toastId: customId});
        } else {
            toast.error(<ToastContent title="Ошибка!" response={error.response.data.message} error/>, {
                toastId: customId
            });
        }
        return Promise.reject(error);
    });

export default axiosInstance;