import Text from "@components/Text/Text";
import {FONTS} from "@/constans";
import Box from "@components/Box";

function Comment() {
    return (
        <Box>
            <Text value='Комментарий' as='h3' font={FONTS.SEMI_BOLD_16} margin='0 0 16px 0'/>
            <Text value='Съешь ещё этих мягких французских булок, да выпей же чаю' as='p' font={FONTS.REGULAR_14}/>
        </Box>
    );
}

export default Comment;