import SidebarCollection from "@components/Sidebars/SidebarCollection";
import styled from "styled-components";

function ModelsPage() {
    return (
        <Wrapper>
            <SidebarCollection/>
            <Content>

            </Content>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: grid;
  overflow: hidden;
  margin: -32px -40px;
  grid-template-columns: 252px 1fr;
  height: calc(100vh - 71px);
`

const Content = styled.div`
  padding: 32px 40px;
  height: 100%;
  overflow: scroll;
`

export default ModelsPage;