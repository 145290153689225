import react from 'react'
import {NavLink, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import AnimateHeight from "react-animate-height";
import Icon from "@components/Icon/Icon";
import PropTypes from 'prop-types'
import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";

const Nav = () => {
    const sidebar = useSelector(state => state.sidebar.sidebarState)
    const location = useLocation();

    return (
        <nav>
            {sidebar?.map(item =>
                <react.Fragment key={item.id}>
                    <NavLinkContent to={item.link} end data-testid='openLink'>
                        <IconWrapper>
                            <Icon iconName={item.icon}/>
                        </IconWrapper>
                        <Text value={item.title} font={FONTS.MEDIUM_14} color='inherit'/>
                    </NavLinkContent>
                    <AnimateHeight
                        height={location.pathname.startsWith(item.link) ? 'auto' : 0}
                        duration={200}
                        data-testid='openLinkChildrenAnimate'
                    >
                        {item.childItems.map(itemChild => (
                                <NavLinkContent
                                    to={itemChild.link}
                                    key={itemChild.id + itemChild.link}
                                    data-testid='openLinkChildren'
                                >
                                    <IconWrapper>
                                        <Icon iconName='krista-children'/>
                                    </IconWrapper>
                                    <Text
                                        value={itemChild.title}
                                        font={FONTS.REGULAR_14}
                                        color='inherit'
                                    />
                                </NavLinkContent>
                            )
                        )}
                    </AnimateHeight>
                </react.Fragment>
            )}
            <NavLinkContent to="/task-models">
                <Text value='Разработка моделей' font={FONTS.MEDIUM_14} color='inherit'/>
            </NavLinkContent>
            <NavLinkContent to="/lists/models">
                <Text value='Модели' font={FONTS.MEDIUM_14} color='inherit'/>
            </NavLinkContent>

            <react.Fragment>
                <NavLinkContent to='/store' end data-testid='openLink'>
                    <IconWrapper>
                        <Icon iconName='krista-shop'/>
                    </IconWrapper>
                    <Text value='Магазин' font={FONTS.MEDIUM_14} color='inherit'/>
                </NavLinkContent>
                <AnimateHeight
                    height={location.pathname.startsWith('/store') ? 'auto' : 0}
                    duration={200}
                    data-testid='openLinkChildrenAnimate'
                >
                    {[
                        {link: '/store/sales', title: 'Продажи'},
                        {link: '/store/warehouse', title: 'Склад наличия'},
                        {link: '/store/acceptance-documents', title: 'Добавление товара'},
                        {link: '/store/sales-history', title: 'История продаж'},
                    ].map(itemChild => {
                            return (
                                <NavLinkContent
                                    to={itemChild.link}
                                    key={itemChild.id + itemChild.link}
                                    data-testid='openLinkChildren'
                                >
                                    <IconWrapper>
                                        <Icon iconName='krista-children'/>
                                    </IconWrapper>
                                    <Text
                                        value={itemChild.title}
                                        font={FONTS.REGULAR_14}
                                        color='inherit'
                                    />
                                </NavLinkContent>
                            )
                        }
                    )}
                </AnimateHeight>
            </react.Fragment>

            <NavLinkContent to="/icons">
                <Text value='Иконки' font={FONTS.MEDIUM_14} color='inherit'/>
            </NavLinkContent>
        </nav>
    );
};

const IconWrapper = styled.div`
  color: ${COLORS.LIGHTGRAY};
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 20px;
`

const NavLinkContent = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 14px 20px 14px 64px;
  text-decoration: none;
  position: relative;
  color: ${COLORS.BLACK};

  &.active {
    color: #0073FF;

    ${IconWrapper} {
      color: ${COLORS.MAIN};
    }

    &:before {
      background-color: ${COLORS.MAIN};
      left: 0;
    }
  }

  &:before {
    position: absolute;
    left: -3px;
    top: 50%;
    transform: translateY(-50%);
    height: 32px;
    width: 3px;
    content: '';
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    transition: 0.3s;
  }

  &:hover {
    background-color: ${COLORS.LIGHTGRAY3};
  }
`

Nav.propTypes = {
    sidebar: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        link: PropTypes.string,
        position: PropTypes.number,
        icon: PropTypes.string,
        parentId: PropTypes.number,
        notificationKey: PropTypes.string,
        childItems: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            link: PropTypes.string,
            position: PropTypes.number,
            icon: PropTypes.string,
            parentId: PropTypes.number,
            notificationKey: PropTypes.string,
        }),
    }),
}
export default Nav;