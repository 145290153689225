import React from 'react';
import Add from "@components/Buttons/Add";
import {SET_MODAL_CREATE_COLOR, SET_MODEL_ALL_SIZE} from "@types/types";
import {getColors} from "@actions/colorAction";
import styled from "styled-components";
import {useDispatch} from "react-redux";
import {useSearchParams} from "react-router-dom";
import ItemColorLayout from "@pages/ProductionPages/ModelDevelopmentPage/OperatorPage/components/ItemColorLayout";
import More from "@pages/ProductionPages/ModelDevelopmentPage/components/ItemColor/components/More";

const TabsColorsLayout = (props) => {
    const {getValues} = props
    const {sizes, itemSize} = props

    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()

    const activeSize = searchParams?.get('activeSize')
    const activeSizeColorsLength = sizes.find(item => item.size.id == activeSize)?.colors?.length < 1

    const openModalCreateColor = () => {
        dispatch({type: SET_MODEL_ALL_SIZE, payload: getValues('models')})

        getColors().then((response) => {
            if (response.status === 200) {
                dispatch({type: SET_MODAL_CREATE_COLOR, payload: true})
            }
        })
    }

    return (
        <WrapperColumn>
            <Add
                value={activeSizeColorsLength && 'Добавить цвет'}
                onClick={openModalCreateColor}
                ariaLabel='Кнопка Добавить цвет'
            />
            {itemSize.colors.map((item, index) => (
                <ItemColorLayout
                    key={item.id + index}
                    item={item}
                    index={index}
                >
                    <More sizes={sizes} itemSize={itemSize} item={item}/>
                </ItemColorLayout>
            ))}
        </WrapperColumn>
    );
};

const WrapperColumn = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 10px;
`

export default TabsColorsLayout;