import ProfileInfo from "@components/ProfileInfo/ProfileInfo";
import Nav from "@components/Sidebars/Sidebar/components/Nav/Nav";
import ErrorBoundary from "@components/ErrorBoundary/ErrorBoundary";
import styled from "styled-components";

const Sidebar = () => {
    return (
        <Content>
            <Wrapper>
                <ProfileInfo/>
                <ErrorBoundary>
                    <Nav/>
                </ErrorBoundary>
            </Wrapper>
        </Content>
    );
};

const Content = styled.aside`
  background-color: #FFFFFF;
  z-index: 1;
  position: relative;
  box-shadow: 0 0 8px rgba(138, 138, 153, 0.15);
`

const Wrapper = styled.div`
  position: sticky;
  top: 91px;
`

export default Sidebar;