import React, {useState} from 'react';
import Icon from "@components/Icon/Icon";
import {COLORS, FONTS} from "@/constans";

import ClientProfileStore from "./ClientProfileStore/ClientProfileStore";
import ClientProfilePersonal from "./ClientProfilePersonal/ClientProfilePersonal";
import ClientProfileNav from "./ClientProfileNav/ClientProfileNav";

import Wrapper from "@components/Table/TableDefault/TableClient/RowDropdown/components/Wrapper/Wrapper";
import ClientHeader from "@components/Table/TableDefault/TableClient/RowDropdown/components/ClientHeader/ClientHeader";
import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";
import GridLayout from "@components/Layout/GridLayout";

function ClientProfile({clientInfo}) {
    const [activeNav, setActiveNav] = useState(clientInfo.taxPayers[0]?.id)

    return (
        <Wrapper>
            <ClientHeader backgroundColor={COLORS.YELLOW}>
                <FlexLayout align='center' spaceBetween>
                    <Text
                        value='Профиль'
                        font={FONTS.SEMI_BOLD_16}
                        color={COLORS.WHITE}
                    />
                    <Icon
                        iconName='krista-pen'
                        iconHeight={24}
                        iconWidth={24}
                    />
                </FlexLayout>
            </ClientHeader>
            <ClientProfileNav activeNav={activeNav} setActiveNav={setActiveNav} clientInfo={clientInfo}/>
            {clientInfo.taxPayers.filter(item => item.id === activeNav).map(itemFiltered => (
                <GridLayout template='1fr 308px' gap='16px' padding='16px 12px 0' key={itemFiltered}>
                    <ClientProfilePersonal clientInfo={clientInfo} itemFiltered={itemFiltered}/>
                    <ClientProfileStore clientInfo={clientInfo}/>
                </GridLayout>
            ))}
        </Wrapper>
    );
}

export default ClientProfile;