import {COLORS, FONTS} from "@/constans";
import hexToRgba from "hex-to-rgba";
import styled from "styled-components";

import Button from "@components/Form/Button";
import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";

function OrderHeader({statusTitle}) {
    return (
        <FlexLayout align='center' spaceBetween margin='0 0 24px 0'>
            <div>
                <FlexLayout align='center' margin='0 0 4px 0'>
                    <Text value='Заказ № 6592' font={FONTS.SEMI_BOLD_24} color={COLORS.BLACK} margin='0 16px 0 0'/>
                    <FlexLayout gap='8px'>
                        <Status color={COLORS.RED}>
                            <Text value={statusTitle} font={FONTS.MEDIUM_16} color='inherit'/>
                        </Status>
                        <Status color={COLORS.YELLOW}>
                            <Text value={statusTitle} font={FONTS.MEDIUM_16} color='inherit'/>
                        </Status>
                        <Status color={COLORS.STATUS_GREEN}>
                            <Text value={statusTitle} font={FONTS.MEDIUM_16} color='inherit'/>
                        </Status>
                        <Status color={COLORS.MAIN_BLUE}>
                            <Text value={statusTitle} font={FONTS.MEDIUM_16} color='inherit'/>
                        </Status>
                    </FlexLayout>
                </FlexLayout>
                <Text value="22.06.2022, 08:34" font={FONTS.REGULAR_16} color={COLORS.LIGHTGRAY}/>
            </div>
            <FlexLayout align='center' gap='16px'>
                <Button
                    variant='isGray'
                    iconName='krista-document'
                    isSmall
                />
                <Button
                    variant='isGray'
                    iconName='krista-download-file'
                    isSmall
                />
                <Button
                    variant='isGray'
                    iconName='krista-share'
                    isSmall
                />
            </FlexLayout>
        </FlexLayout>
    );
}

const Status = styled.div`
  padding: 2px 10px;
  border-radius: 16px;
  ${({color}) => color && `
    color: ${color};
    background-color: ${hexToRgba(color, 0.1)}; 
  `}
`

export default OrderHeader;