const decimalHelper = (value) => {
    return (value.toString().split('.')[1] || '').length
}

export const getCurrencyScaleLength = (value) => {
    if(value) {
        const decimalLength = decimalHelper(value);
        if(decimalLength <= 1) {
            return 2
        } else if (decimalLength <= 4) {
            return decimalLength
        } else {
            return 4
        }
    }

    return 2
}