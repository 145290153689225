import GridLayout from "@components/Layout/GridLayout";
import HeadRow from "@components/Table/components/HeadRow";
import HeadRowItem from "@components/Table/components/HeadRowItem";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import HeadRowSort from "@components/Table/components/HeadRowSort";
import Table from "@components/Table/components/Wrapper";
import Row from "@components/Table/TableDefault/TableMovementHistory/Row";

function TableMovementHistory({documents}) {
    const template = '80px 1.2fr 1.8fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 80px'

    return (
        <Table overflow='visible'>
            <GridLayout gap='10px' position='relative'>
                <HeadRow>
                    <GridLayout template={template} align='center'>
                        <HeadRowSort title="№" sortValue='serialNumber'/>
                        <HeadRowSort title="Документ" sortValue='documentTypeName'/>
                        <HeadRowSort title="Перемещение" sortValue='direction'/>
                        <HeadRowSort title="Менеджер" sortValue='execitedByEmployee'/>
                        <HeadRowSort title="Дата и время" sortValue='executionDate'/>
                        <HeadRowSort title="Статус" sortValue='statusName'/>
                        <HeadRowSort title="Кол-во единиц" sortValue='quantity'/>
                        <HeadRowSort title="На сумму" sortValue='totalSum'/>
                        <HeadRow/>
                    </GridLayout>
                </HeadRow>
                {documents?.items.map((document) => (
                    <Row
                        template={template}
                        document={document}
                        key={document?.documentId}
                    />
                ))}
            </GridLayout>
        </Table>
    );
}

export default TableMovementHistory;