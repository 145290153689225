import styled from "styled-components";
import {COLORS} from "@/constans";
import hexToRgba from "hex-to-rgba";

function HeadRowItem({children, onClick, isAction, bg, padding}) {
    return (
        <HeadRowItemContent onClick={onClick} isAction={isAction} bg={bg} padding={padding}>
            {children}
        </HeadRowItemContent>
    );
}

const HeadRowItemContent = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  text-align: left;
  ${({isAction}) => isAction && `
      cursor: pointer;
      &:hover {
        background-color: ${hexToRgba(COLORS.LIGHTGRAY1)};
      }
  `};
  ${({bg}) => bg && `background-color: ${bg}`};
  padding: ${({padding}) => padding ? padding : '12px 8px'};
  min-height: 100%;
`

export default HeadRowItem;