import Row from "@components/Table/TableDefault/TableOrderChanges/Row/Row"
import Table from "@components/Table/components/Wrapper";
import styled from "styled-components";
import GridLayout from "@components/Layout/GridLayout";
import HeadRow from "@components/Table/components/HeadRow";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text";
import HeadRowItem from "@components/Table/components/HeadRowItem";

function TableOrderChanges() {
    const array = [1,2,3]

    return (
        <Table overflow='visible'>
            <WrapperInner>
                <GridLayout gap='10px'>
                    <HeadRow isTopOffset>
                        <GridLayout template='100px 10% 11% 12% auto 8% 8% 10% 10% 36px' align='center'>
                            <HeadRowItem>
                                <Text value='Фото' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                            </HeadRowItem>
                            <HeadRowItem>
                                <Text value='Артикул' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                            </HeadRowItem>
                            <HeadRowItem>
                                <Text value='Размер' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                            </HeadRowItem>
                            <HeadRowItem>
                                <Text value='Цвет' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                            </HeadRowItem>
                            <HeadRowItem>
                                <Text value='Каталог' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                            </HeadRowItem>
                            <HeadRowItem>
                                <Text value='Серий' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                            </HeadRowItem>
                            <HeadRowItem>
                                <Text value='Единиц' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                            </HeadRowItem>
                            <HeadRowItem>
                                <Text value='Цена' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                            </HeadRowItem>
                            <HeadRowItem>
                                <Text value='Сумма' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                            </HeadRowItem>
                            <HeadRowItem></HeadRowItem>
                        </GridLayout>
                    </HeadRow>
                    <GridLayout gap='10px'>
                        {array.map(item =>
                            <Row
                                catalogIcon='krista-scissors'
                                key={item}
                            />
                        )}
                    </GridLayout>
                </GridLayout>
            </WrapperInner>
        </Table>
    );
}

const WrapperInner = styled.div`
  padding: 0 15px 15px 15px;
  border: 1px solid ${COLORS.LIGHTGRAY1};
  border-top: 0;
  border-radius: 0 0 14px 14px;
`

export default TableOrderChanges;