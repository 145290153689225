import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";
import {COLORS, FONTS} from "@/constans";
import Button from "@components/Form/Button";
import {useForm} from "react-hook-form";
import TableSales from "@components/Table/TableDefault/TableSales";
import {
    createInvoiceProduct,
    deleteInvoice,
    getInvoice,
    paymentInvoice,
    addInvoiceProduct,
    cancelInvoiceProduct,
    getAcceptedPayment,
} from "@actions/invoiceAction";
import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import EmptyBox from "@components/EmptyBox";
import {getAllManagers} from "@actions/storeEmployeeAction";
import {useDispatch} from "react-redux";
import {SET_MODAL_CONFIRM_ACTION} from "@types/types";
import ProductBarcode from "@pages/StorePages/components/ProductBarcode";
import LastScan from "@pages/StorePages/components/LastScan";
import AvailablePayments from "@pages/StorePages/SalesPage/components/AvailablePayments";
import AcceptedPayments from "@pages/StorePages/SalesPage/components/AcceptedPayments";
import BadgeStatus from "@components/Badges/BadgeStatus";
import StoreHeadCompleted from "@pages/StorePages/SalesPage/components/StoreHeadCompleted";
import StoreHead from "@pages/StorePages/SalesPage/components/StoreHead";
import {schema} from "@pages/StorePages/SalesPage/schema";
import Box from "@components/Box";
import {getAllClients} from "@actions/storeClientAction";

function SalesPage() {
    const dispatch = useDispatch()

    const [searchParams, setSearchParams] = useSearchParams()

    const [invoice, setInvoice] = useState(null)
    const [acceptedPayment, setAcceptedPayment] = useState(null);
    const [isAddProduct, setIsAddProduct] = useState(true)
    const [lastScanProduct, setLastScanProduct] = useState(null)

    const {
        register, control, watch, setValue, clearErrors, handleSubmit, reset, formState: {errors, isDirty}
    } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        defaultValues: {
            productBarcode: '',
            discountPercent: 0,
            discountCardCode: '',
            client: null,
            employee: null,
            payMethod: 0,
        }
    })

    const employeeId = watch('employee.employeeId')
    const isCompleted = invoice?.isCompleted
    const invoiceId = searchParams.get('invoiceId')

    const scanBarcode = () => {
        if (!isAddProduct) {
            cancelInvoiceProduct({
                invoiceId: invoiceId,
                productBarcode: watch('productBarcode')
            }).then(({data}) => {
                setLastScanProduct(data)

                getInvoice(searchParams.get('invoiceId')).then(({data}) => {
                    setInvoice(data)
                    setValue('productBarcode', '')
                })
                getAcceptedPayment(searchParams.get('invoiceId')).then(({data}) => {
                    setAcceptedPayment(data)
                })
            })
            return
        }
        if (isAddProduct) {
            if (searchParams.get('invoiceId')) {
                addInvoiceProduct({
                    invoiceId: invoiceId,
                    productBarcode: watch('productBarcode')
                }).then(({data}) => {
                    setLastScanProduct(data)

                    getInvoice(searchParams.get('invoiceId')).then(({data}) => {
                        setInvoice(data)
                    })
                    getAcceptedPayment(searchParams.get('invoiceId')).then(({data}) => {
                        setAcceptedPayment(data)
                    })
                    setValue('productBarcode', '')
                })
            } else {
                createInvoiceProduct({
                    productBarcode: watch('productBarcode'),
                    employeeId: employeeId
                }).then(({data}) => {
                    searchParams.set('invoiceId', data.invoiceId)
                    setSearchParams(searchParams)
                    setLastScanProduct(data)

                    getInvoice(data.invoiceId).then(({data}) => {
                        setInvoice(data)
                    })
                    getAcceptedPayment(searchParams.get('invoiceId')).then(({data}) => {
                        setAcceptedPayment(data)
                    })
                    setValue('productBarcode', '')
                })
            }
        }
    }

    const clearPrevInvoice = () => {
        searchParams.delete('invoiceId')
        setSearchParams(searchParams)
        setInvoice(null)
        setLastScanProduct(null)
        setAcceptedPayment(null)
        reset()
    }

    const deleteInvoiceAction = () => {
        dispatch({
            type: SET_MODAL_CONFIRM_ACTION, payload: {
                isShow: true,
                title: 'Вы уверены, что хотите удалить все товары?',
                desc: '',
                accept: () => {
                    deleteInvoice(invoice?.invoiceId).then(() => {
                        clearPrevInvoice()
                        dispatch({
                            type: SET_MODAL_CONFIRM_ACTION, payload: {
                                title: '',
                                desc: '',
                                isShow: false,
                                icon: ''
                            }
                        })
                    })
                },
                icon: 'krista-delete'
            }
        })
    }

    const onPay = () => {
        paymentInvoice({
            invoiceId: invoice?.invoiceId,
            isCashless: watch('payMethod') === 1,
            employeeId: employeeId,
        }).then(() => {
            clearPrevInvoice()
        })
    }

    useEffect(() => {
        if (!isCompleted) {
            getAllManagers()
            getAllClients()
        }
        if (searchParams.get('invoiceId')) {
            getInvoice(searchParams.get('invoiceId')).then(({data}) => {
                setInvoice(data)
                setValue('employee', {employeeId: data?.employeeId, employeeName: data?.employeeName})
                if (data?.clientFullName && data?.clientId) {
                    setValue('client', {clientId: data?.clientId, fullName: data?.clientFullName})
                }
                setValue('discountCardCode', data?.discountCardCode ? data?.discountCardCode : '')
                setValue('discountPercent', data?.discountPercent)
                clearErrors('employee')
            })
            getAcceptedPayment(searchParams.get('invoiceId')).then(({data}) => {
                setAcceptedPayment(data)
            })
        }
        clearErrors('productBarcode')
    }, [])

    useEffect(() => {
        if (watch('productBarcode').length === 16) {
            handleSubmit(scanBarcode)()
        }
    }, [watch('productBarcode')]);

    useEffect(() => {
        if (!searchParams.get('invoiceId')) {
            setInvoice(null)
            setLastScanProduct(null)
        }
    }, [invoiceId]);

    return (
        <GridLayout gap='24px' align='start'>
            <FlexLayout gap='16px' align='center'>
                <Text
                    value={invoice?.documentTypeName ? `${invoice?.documentTypeName} №${invoice?.serialNumber}` : 'Продажа'}
                    font={FONTS.MEDIUM_24}
                    color={COLORS.BLACK}
                />
                <BadgeStatus backgroundColor={invoice?.statusHexColor} value={invoice?.statusName}/>
            </FlexLayout>

            {isCompleted ?
                <StoreHeadCompleted invoice={invoice}/>
                :
                <>
                    {(invoice || !invoiceId) && (
                        <StoreHead
                            register={register}
                            control={control}
                            setValue={setValue}
                            errors={errors}
                            isCompleted={isCompleted}
                            isDirty={isDirty}
                            setInvoice={setInvoice}
                            setAcceptedPayment={setAcceptedPayment}
                            invoiceId={invoiceId}
                        />
                    )}
                    <GridLayout gap='20px' template='3fr 2.4fr' align='center'>
                        <div>
                            <ProductBarcode
                                register={register}
                                errors={errors}
                                isAddProduct={isAddProduct}
                                setIsAddProduct={setIsAddProduct}
                                isDisabledBarCode={!watch('employee')}
                                placeholderBarCode={!watch('employee') && 'Выберите менеджера'}
                            />
                        </div>

                        <div>
                            <LastScan item={lastScanProduct}/>
                        </div>
                    </GridLayout>
                </>
            }

            {!invoice?.products.length ?
                <EmptyBox
                    title="Список товаров на продажу пуст"
                    subtitle="Отсканируйте штрих-код или введите номер товара для добавления в список новой продажи"
                    icon="krista-cart-add"
                    iconColor={COLORS.LIGHTGRAY}
                    isRefresh={false}
                />
                :
                <TableSales
                    invoice={invoice}
                    setInvoice={setInvoice}
                    setLastScanProduct={setLastScanProduct}
                    setAcceptedPayment={setAcceptedPayment}
                    isCompleted={isCompleted}
                />
            }

            <GridLayout template='14% 50%' spaceBetween>
                <div>
                    {invoice && !isCompleted && (
                        <Button
                            variant={!invoiceId ? 'isDisabled' : 'isDangerBorder'}
                            buttonText='Удалить все'
                            isDisabled={!invoiceId}
                            onClick={deleteInvoiceAction}
                            isResponsive
                        />
                    )}
                </div>

                {invoice?.products.length > 0 && !isCompleted && (
                    <GridLayout gap='16px'>
                        <Box background={COLORS.WHITE} borderRadius='10px'>
                            <GridLayout gap='16px'>
                                <Text value='Оплата' font={FONTS.MEDIUM_20} color={COLORS.BLACK}/>
                                <AvailablePayments
                                    isCompleted={isCompleted}
                                    invoice={invoice}
                                    register={register}
                                    setValue={setValue}
                                    watch={watch}
                                />
                            </GridLayout>
                        </Box>

                        <Box background={COLORS.WHITE} borderRadius='10px'>
                            <GridLayout gap='16px'>
                                <Text value='Приход средств' font={FONTS.MEDIUM_20} color={COLORS.BLACK}/>

                                <AcceptedPayments
                                    isCompleted={invoice?.isCompleted}
                                    invoice={invoice}
                                    register={register}
                                    setValue={setValue}
                                    acceptedPayment={acceptedPayment}
                                    setAcceptedPayment={setAcceptedPayment}
                                    watch={watch}
                                />

                                <Button
                                    variant='isSuccess'
                                    buttonText='Оформить продажу'
                                    isResponsive
                                    onClick={onPay}
                                />
                            </GridLayout>
                        </Box>
                    </GridLayout>
                )
                }

                {invoice?.products.length > 0 && isCompleted && (
                    <Box background={COLORS.WHITE} borderRadius='10px'>
                        <GridLayout gap='16px'>
                            <Text value='Итого' font={FONTS.MEDIUM_20} color={COLORS.BLACK}/>
                            <AvailablePayments
                                isCompleted={isCompleted}
                                invoice={invoice}
                                register={register}
                                setValue={setValue}
                                watch={watch}
                            />

                            <AcceptedPayments
                                isCompleted={invoice?.isCompleted}
                                invoice={invoice}
                                register={register}
                                setValue={setValue}
                                acceptedPayment={acceptedPayment}
                                setAcceptedPayment={setAcceptedPayment}
                                watch={watch}
                            />

                            {/*<Button*/}
                            {/*    variant='isDanger'*/}
                            {/*    buttonText='Оформить возврат'*/}
                            {/*    isResponsive*/}
                            {/*    // onClick={null}*/}
                            {/*/>*/}
                        </GridLayout>

                    </Box>
                )}
            </GridLayout>
        </GridLayout>
    );
}

export default SalesPage;