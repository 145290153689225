import ApiConfig from "@/ApiConfigStore";

const path = 'documents/acceptance/'

export const getAcceptanceCreate = async () => {
    return await ApiConfig.post(`${path}create`)
};

export const getAcceptanceProductScan = async (data) => {
    return await ApiConfig.post(`${path}product/scan`, data)
};

export const getAcceptanceProductScanCancel = async (data) => {
    return await ApiConfig.post(`${path}product/scan/cancel`, data)
};

export const getAcceptance = async (acceptanceId) => {
    return await ApiConfig.get(`${path}${acceptanceId}`)
};

export const getAcceptanceDelete = async (acceptanceId) => {
    return await ApiConfig.delete(`${path}${acceptanceId}/delete`)
};

export const getAcceptanceFinish = async (acceptanceId) => {
    return await ApiConfig.post(`${path}${acceptanceId}/finish`)
};

export const getAcceptanceProductDelete = async (acceptanceId, productId) => {
    return await ApiConfig.delete(`${path}${acceptanceId}/product/delete/${productId}`)
};


