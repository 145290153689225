import Add from "@components/Buttons/Add";
import ItemSize from "@pages/ProductionPages/ModelDevelopmentPage/components/ItemSize";
import PropTypes from "prop-types";
import styled from "styled-components";

const TabSize = (props) => {

    const {register} = props
    const {sizes} = props
    const {callBackTab} = props

    return (
        <WrapperColumn>
            {callBackTab &&
                <Add
                    value={sizes?.length < 1 && 'Добавить размер'}
                    onClick={callBackTab && (() => callBackTab())}
                    ariaLabel='Кнопка Добавить размер'
                />
            }
            {sizes?.map((sizeItem, sizeItemIndex) => {
                return (
                    <ItemSize
                        register={register}
                        key={sizeItem.id}
                        sizeItem={sizeItem}
                        subSizes={sizeItem.subSizes}
                        sizeItemIndex={sizeItemIndex}
                    />
                )
            })}
        </WrapperColumn>
    );
};

const WrapperColumn = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 10px;
`


TabSize.defaultProps = {
    sizes: [],
};

TabSize.propTypes = {
    sizes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        value: PropTypes.string,
        subSizes: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            value: PropTypes.string,
            isTopPriority: PropTypes.bool
        }))
    })),

};

export default TabSize;