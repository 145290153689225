import Icon, {Content as IconContent} from "@components/Icon/Icon";
import {COLORS, FONTS} from "@/constans";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {COLORS_BUTTON} from "@/constans/buttonVariants";
import GridLayout from "@components/Layout/GridLayout";

const Button = (props) => {
    const {variant, buttonText, iconName, as, to, margin, ariaLabel, height} = props
    const {isSmall, isMinimal, isResponsive, isBorder, isDisabled} = props
    const {onClick} = props

    return (
        <Content
            $isResponsive={isResponsive}
            $isBorder={isBorder}
            $isDisabled={isDisabled}
            $variant={variant}
            $buttonText={buttonText}
            $iconName={iconName}
            as={as}
            to={to}
            disabled={isDisabled}
            onClick={onClick}
            $isSmall={isSmall}
            $isMinimal={isMinimal}
            aria-label={`Кнопка ${ariaLabel}`}
            $margin={margin}
            type={!to ? 'button' : null}
            $height={height}
        >
            <GridLayout gap={buttonText && iconName ? '8px' : '0'} template='auto auto' align='center'>
                {iconName && <Icon iconName={iconName}/>}
                {buttonText}
            </GridLayout>
        </Content>
    )
};

const Content = styled.button`
  min-height: ${({$height, $isSmall}) => {
      if($height) {
          return $height
      }
      return $isSmall ? '38px' : '48px';
  }};
  display: ${({$isResponsive}) => $isResponsive ? 'flex' : 'inline-flex'};
  align-items: center;
  justify-content: center;
  color: ${({$variant}) => COLORS_BUTTON[$variant ? $variant : 'isDefault']?.color};
  background-color: ${({$variant}) => COLORS_BUTTON[$variant ? $variant : 'isDefault']?.bgColor};
  border: 1px solid ${({$variant}) => COLORS_BUTTON[$variant ? $variant : 'isDefault']?.borderColor};
  cursor: ${({isDisabled}) => isDisabled ? 'not-allowed' : 'pointer'};
  border-radius: 10px;
  outline: none;
  font: ${FONTS.SEMI_BOLD_14};
  padding: ${({$buttonText}) => $buttonText ? '0 16px' : '0 6px'};
  width: ${({$isResponsive}) => $isResponsive ? '100%' : 'inherit'};
  text-decoration: none;
  margin: ${({margin}) => margin};

  ${IconContent} {
    @keyframes rotateIcon {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
    ${({$iconName}) => $iconName === 'krista-loading' && css`animation: rotateIcon infinite 4s linear`}
  }

  ${({$iconName}) => $iconName === 'krista-cycle' && css`
    ${IconContent} {
      transition: transform .2s linear;
    }

    &:hover {
      ${IconContent} {
        transform: rotate(180deg);
      }
    }
  `}
  &:hover {
    color: ${({$variant}) => COLORS_BUTTON[$variant ? $variant : 'isDefault']?.colorHover};
    background-color: ${({$variant}) => COLORS_BUTTON[$variant ? $variant : 'isDefault']?.bgColorHover};
    border: 1px solid ${({$variant}) => COLORS_BUTTON[$variant ? $variant : 'isDefault']?.borderColorHover};
  }
  
  //Используется для иконок и текста без отступов и границ
  ${({$isMinimal}) => $isMinimal  && css`
    padding: 0;
    min-height: auto;
    border: 0;
    &:hover {
      border: 0;
    }
  `}
`

Button.propTypes = {
    buttonText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    isSmall: PropTypes.bool,
    iconName: PropTypes.string,
    ariaLabel: PropTypes.string,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf(Object.keys(COLORS_BUTTON)),
    isResponsive: PropTypes.bool,
    isBorder: PropTypes.bool,
    to: PropTypes.string,
}

export default Button;
