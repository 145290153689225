import PropTypes from "prop-types";
import Icon from "@components/Icon/Icon";
import CardStatusLayout from "@components/Cards/CardStatus/CardStatusLayout/CardStatusLayout";
import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";

const CardStatusClient = ({linkPath, linkTitle, title,subtitle, quantity, iconName, color, leftTotal, rightTotal, isLinkIcon, form, isActive, isInactiveClients}) => {
    return (
        <CardStatusLayout
            linkTitle={linkTitle}
            linkPath={linkPath}
            title={title}
            subtitle={subtitle}
            quantity={quantity}
            iconName={iconName}
            color={color}
            isInactiveClients={isInactiveClients}
            isLinkIcon={isLinkIcon}
            form={form}
            isActive={isActive}
        >
            {isInactiveClients ?
                <div>
                    <Text
                        value="Без заказов за период"
                        font={FONTS.REGULAR_14}
                        color={COLORS.LIGHTGRAY}
                        margin='0 0 18px 0'
                    />
                    <FlexLayout align='center'>
                        <IconWrapper>
                            <Icon
                                iconName='krista-delete-user'
                                iconWidth={20}
                                iconHeight={20}
                            />
                        </IconWrapper>
                        <Text
                            value='Черный список'
                            font={FONTS.REGULAR_14}
                            color={COLORS.MAIN}
                        />
                    </FlexLayout>
                </div>
                :
                <FlexLayout gap='20px' align='center' margin='auto 0 0 0'>
                    <div>
                        <Text
                            value={`${leftTotal.value} ${leftTotal.suffix}`}
                            font={FONTS.REGULAR_18}
                            color={COLORS.BLACK}
                        />
                        <Text
                            value={leftTotal.label}
                            font={FONTS.REGULAR_12}
                            color={COLORS.GRAY}
                            margin='0 0 4px 0'
                        />
                    </div>
                    <div>
                        <Text
                            value={`${rightTotal.value} ${rightTotal.suffix}`}
                            font={FONTS.REGULAR_18}
                            color={COLORS.BLACK}
                        />
                        <Text
                            value={rightTotal.label}
                            font={FONTS.REGULAR_12}
                            color={COLORS.GRAY}
                            margin='0 0 4px 0'
                        />
                    </div>
                </FlexLayout>
            }
        </CardStatusLayout>
    );
};

CardStatusClient.defaultProps = {
    linkPath: '',
    linkTitle: '',
    title: '',
    subtitle: '',
    quantity: '',
    iconName: '',
    leftTotal: {
        value: '',
        label: '',
        suffix: ''
    },
    rightTotal: {
        value: '',
        label: '',
        suffix: ''
    },
    isInactive: false,
    isLinkIcon: false,
};

const IconWrapper = styled.div`
  color: ${COLORS.LIGHTGRAY};
  margin-right: 5px;
`

CardStatusClient.propTypes = {
    linkPath: PropTypes.string,
    linkTitle: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    quantity: PropTypes.string,
    iconName: PropTypes.string,
    leftTotal: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        suffix: PropTypes.string,
    }),
    rightTotal: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        suffix: PropTypes.string,
    }),
    isInactive: PropTypes.bool,
    isLinkIcon: PropTypes.bool,
};
export default CardStatusClient;
