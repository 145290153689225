import Input from "@components/Form/Inputs/Input";
import Button from "@components/Form/Button";
import React from "react";
import GridLayout from "@components/Layout/GridLayout";

function NavFiltersOrders() {
    return (
        <GridLayout template='repeat(4, 1fr)' gap='20px' margin='0 0 14px 0' zIndex='3'>
            <Input
                placeholder='Искать по имени'
                icon='krista-search'
                isSmall
                isBorder={false}
            />
            <Input
                placeholder='Искать по № заказа'
                icon='krista-search'
                isSmall
                isBorder={false}
            />
            <Input
                placeholder='Выбрать город'
                isSmall
                isBorder={false}
            />
            <Input
                placeholder='Дата создания'
                isSmall
                isBorder={false}
            />
            <Input
                placeholder='Каталоги'
                isSmall
                isBorder={false}
            />
            <Input
                placeholder='Статус'
                isSmall
                isBorder={false}
            />
            <Input
                placeholder='Коллекция'
                isSmall
                isBorder={false}
            />
            <Input
                placeholder='Кем создан'
                isSmall
                isBorder={false}
            />
            <Input
                placeholder='Артикул'
                icon='krista-search'
                isSmall
                isBorder={false}
            />
            <Input
                placeholder='Цвет'
                isSmall
                isBorder={false}
            />
            <Input
                placeholder='Размер'
                isSmall
                isBorder={false}
            />
                <Button
                    buttonText='Сбросить все'
                    variant='isMainBorder'
                    ariaLabel='Сбросить все'
                    isSmall
                />
        </GridLayout>
    );
}

export default NavFiltersOrders;