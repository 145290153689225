import React from 'react';
import Box from "@components/Box";
import {useSelector} from "react-redux";
import styled from "styled-components";
import Text from "@components/Text/Text";
import {COLORS, FONTS} from "@/constans";

const Comments = ({register}) => {

    return (
        <Box>
            <Text
                value='Комментарий'
                font={FONTS.SEMI_BOLD_16}
                color={COLORS.BLACK}
                margin='0 0 16px 0'
            />
            <Text
                value='Сообщение (опционально)'
                font={FONTS.REGULAR_12}
                color={COLORS.LIGHTGRAY}
                margin='0 0 6px 0'
            />
            <MessageArea
                {...register}
                rows="10"
                placeholder="Введите текст"
                aria-label='Комментарий'
            />
        </Box>
    );
};

const MessageArea = styled.textarea`
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.LIGHTGRAY1};
  border-radius: 10px;
  width: 100%;
  max-width: 100%;
  padding: 16px;
  font: ${FONTS.REGULAR_14};
  color: ${COLORS.BLACK};
  outline: none;

  &:focus {
    border: 1px solid #8A8A99;
  }

  &::placeholder {
    color: ${COLORS.LIGHTGRAY};
  }
`

export default Comments;