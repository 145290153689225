import {useEffect, useState} from "react";
import clsx from "clsx";
import useOutside from "@hooks/useOutside";
import Icon from "@components/Icon/Icon";
import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";
import Text from "@components/Text/Text";
import Button from "@components/Form/Button";

const SelectRadio = () => {
    const {ref, isShow, setIsShow} = useOutside(false)
    const [value, setValue] = useState('')
    const [valueRadio, setValueRadio] = useState(value)

    const openDropdown = () => {
        setIsShow(!isShow)
        setValueRadio(value)
    }

    return (
        <SelectWrapper ref={ref}>
            <Select
                onClick={() => openDropdown()}
                isShow={isShow}
            >
                <SelectValue>
                    {value ? value : <span>Не указан</span>}
                </SelectValue>
                <SelectDecor>
                    <Icon iconName="krista-arrow-bottom"/>
                </SelectDecor>
            </Select>
            {isShow &&
                <Dropdown>
                    <div>
                        <ListItem>
                            <Input
                                checked={valueRadio === ''}
                                value=""
                                type="radio"
                                name="radio"
                                onChange={(e)=> setValueRadio(e.currentTarget.value)}
                            />
                            <Radio>
                                <Icon
                                    iconName={clsx(valueRadio === "" ? 'krista-around-check': 'krista-around-uncheck' )}
                                />
                            </Radio>
                            <Info>
                                <Text
                                    value='Не указан'
                                    font={FONTS.SEMI_BOLD_14}
                                    color={COLORS.DARKGRAY}
                                    margin='0 0 0 4px'
                                />
                            </Info>
                        </ListItem>
                        <ListItem>
                            <Input
                                checked={'Склад 1:' === valueRadio}
                                value="Склад 1:"
                                type="radio"
                                name="radio"
                                onChange={(e)=> setValueRadio(e.currentTarget.value)}
                            />
                            <Radio>
                                <Icon
                                    iconName={clsx('Склад 1:' === valueRadio ? 'krista-around-check': 'krista-around-uncheck' )}
                                />
                            </Radio>
                            <Info>
                                <Text
                                    value='Склад 1:'
                                    font={FONTS.SEMI_BOLD_14}
                                    color={COLORS.DARKGRAY}
                                    margin='0 0 0 4px'
                                />
                                <Text
                                    value='1 326.55 м'
                                    font={FONTS.REGULAR_14}
                                    color={COLORS.DARKGRAY}
                                    margin='0 0 0 4px'
                                />
                                <Text
                                    value='(13 рулонов)'
                                    font={FONTS.REGULAR_14}
                                    color={COLORS.LIGHTGRAY}
                                    margin='0 0 0 4px'
                                />
                            </Info>
                        </ListItem>
                        <ListItem>
                            <Input
                                value="Склад 2:"
                                type="radio"
                                checked={'Склад 2:' === valueRadio}
                                name="radio"
                                onChange={(e)=> setValueRadio(e.currentTarget.value)}
                            />
                            <Radio>
                                <Icon
                                    iconName={clsx('Склад 2:' === valueRadio ? 'krista-around-check': 'krista-around-uncheck' )}
                                />
                            </Radio>
                            <Info>
                                <Text
                                    value='Склад 2:'
                                    font={FONTS.SEMI_BOLD_14}
                                    color={COLORS.DARKGRAY}
                                    margin='0 0 0 4px'
                                />
                                <Text
                                    value='1 326.55 м'
                                    font={FONTS.REGULAR_14}
                                    color={COLORS.DARKGRAY}
                                    margin='0 0 0 4px'
                                />
                                <Text
                                    value='(2 рулонов)'
                                    font={FONTS.REGULAR_14}
                                    color={COLORS.LIGHTGRAY}
                                    margin='0 0 0 4px'
                                />
                            </Info>
                        </ListItem>
                        <ListItem>
                            <Input
                                value="Основной склад очень длинный:"
                                checked={'Основной склад очень длинный:' === valueRadio}
                                type="radio"
                                name="radio"
                                onChange={(e)=> setValueRadio(e.currentTarget.value)}
                            />
                            <Radio>
                                <Icon
                                    iconName={clsx('Основной склад очень длинный:' === valueRadio ? 'krista-around-check': 'krista-around-uncheck' )}
                                />
                            </Radio>
                            <Info>
                                <Text
                                    value='Основной склад очень длинный:'
                                    font={FONTS.SEMI_BOLD_14}
                                    color={COLORS.DARKGRAY}
                                    margin='0 0 0 4px'
                                />
                                <Text
                                    value='1 326.55 м'
                                    font={FONTS.REGULAR_14}
                                    color={COLORS.DARKGRAY}
                                    margin='0 0 0 4px'
                                />
                                <Text
                                    value='(2 рулонов)'
                                    font={FONTS.REGULAR_14}
                                    color={COLORS.LIGHTGRAY}
                                    margin='0 0 0 4px'
                                />
                            </Info>
                        </ListItem>
                        <Button
                            buttonText='Выбрать'
                            variant='isMain'
                            isResponsive
                            isSmall
                            onClick={()=> setValue(valueRadio)}
                        />
                    </div>
                </Dropdown>
            }
        </SelectWrapper>
    );
};

const SelectWrapper = styled.div`
  position: relative;
`

const Select = styled.div`
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.LIGHTGRAY2};
  border-radius: 10px;
  padding: 2px 12px 2px 2px;
  height: 48px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  border: ${({isShow})=> isShow && `1px solid ${COLORS.LIGHTGRAY}`};
`

const SelectValue = styled.div`
  font: ${FONTS.REGULAR_14};
  color: ${COLORS.BLACK};
  white-space: nowrap;
  padding: 0 20px 0 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  span{
    color: ${COLORS.LIGHTGRAY};
  }
`

const SelectDecor = styled.div`
  color: ${COLORS.LIGHTGRAY};
  position: absolute;
  top: 0;
  right: 12px;
  bottom: 0;
  display: flex;
  align-items: center;
`
const Dropdown = styled.div`
  z-index: 2;
  position: absolute;
  top: calc(100% + 4px);
  padding: 16px;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.LIGHTGRAY};
  box-shadow: 0 5px 15px rgba(68, 68, 79, 0.1);
  border-radius: 10px;
  right: 0;
  min-width: 308px;
  overflow: auto;
`
const ListItem = styled.label`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
`
const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 0;
  height: 0;
  visibility: hidden;
`
const Radio = styled.div`
  color: ${COLORS.STATUS_GREEN};
`
const Info = styled.div`
  display: flex;
`
const Select4Decor1 = styled.div`
  
`




export default SelectRadio;