import {useFieldArray, useForm} from "react-hook-form";
import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text/Text";
import SizeInWork from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/SizeInWork";
import Graphis from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/Graphis";
import Layouts from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/Layouts";
import Comment from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/Comment";
import Specification
    from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/Specification/Specification";
import {FONTS} from "@/constans";
import {useParams, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {getArticleDesignTaskDetail, postArticleDesignTaskDetail} from "@actions/articulDesignTaskAction";
import {useDispatch, useSelector} from "react-redux";
import {SET_MODAL_CONFIRM_ACTION} from "@types/types";
import TaskDetails from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/TaskDetails";

function ConstructorPage() {
    const dispatch = useDispatch()

    const {id} = useParams()
    const [searchParams] = useSearchParams()
    const taskId = searchParams.get('activeSize')

    const {register, control, watch, setValue} = useForm({
        mode: 'onTouched',
        defaultValues: {}
    })


    const task = watch('articul')
    // const articleIndex = task?.modelDesignTasks?.findIndex((item) => item.id === taskId)
    // const activeSize = task?.modelDesignTasks[articleIndex]
    // const sizeInWorkData = activeSize?.sizeDesignTasks.map(item => item.size)
    // const activeColor = useSelector(state => state.model.modelActiveColor)
    // const topPrioritySize = task?.modelDesignTasks[articleIndex].sizeDesignTasks.find(item => item.isTopPriority)

    const {fields: sizes, replace} = useFieldArray({control, name: `articul.modelDesignTasks`, keyName: 'idField'});
    // const q = watch('articul.modelDesignTasks.0.colors.0.materialCalculations.textile.0')
    // useEffect(()=> {
    //           if(q) {
    //               console.log('blabalabla')
    //           }
    // },[q])
    // const openModalTakeTask = () => {
    //     dispatch({
    //         type: SET_MODAL_CONFIRM_ACTION, payload: {
    //             isShow: true,
    //             title: 'Взять задачу в работу',
    //             desc: `Вы уверены, что хотите взять задачу в работу?`,
    //             icon: 'krista-error',
    //             accept: () => {
    //                 postArticleDesignTaskDetail(topPrioritySize?.id)
    //                 dispatch({
    //                     type: SET_MODAL_CONFIRM_ACTION, payload: {
    //                         isShow: false,
    //                         title: '',
    //                         desc: '',
    //                         accept: null,
    //                         icon: ''
    //                     }
    //                 })
    //             }
    //         }
    //     })
    // }

    useEffect(() => {
        (getArticleDesignTaskDetail(id, taskId)).then(response => {

            setValue('articul', response.data);
            replace(response.data.modelDesignTasks);
        })
    }, [])

    return (
        <>
            <GridLayout template='413px 1fr' gap='20px' align='flex-start'>
                <GridLayout gap='20px'>
                    {/*<SizeInWork*/}
                    {/*    sizeInWorkData={sizeInWorkData}*/}
                    {/*    sizePrioritySelected={topPrioritySize?.size}*/}
                    {/*    register={register}*/}
                    {/*    isAvailableTake={activeSize?.availableTake}*/}
                    {/*    availableTake={openModalTakeTask}*/}
                    {/*    setValue={setValue}*/}
                    {/*/>*/}
                    {/*{*/}
                    {/*    activeSize?.hasDocuments.hasGraphis &&*/}
                    {/*    <Graphis*/}
                    {/*        watch={watch}*/}
                    {/*        setValue={setValue}*/}
                    {/*    />*/}
                    {/*}*/}

                    {/*<Layouts*/}
                    {/*    update={update}*/}
                    {/*    fields={fields}*/}
                    {/*    watch={watch}*/}
                    {/*/>*/}
                    <Comment/>
                </GridLayout>
                <TaskDetails sizes={sizes} register={register} control={control} watch={watch}/>
                {/*<Specification/>*/}


            </GridLayout>
        </>
    );
}

export default ConstructorPage;