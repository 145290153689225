import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";
import TableMovementHistory from "@components/Table/TableDefault/TableMovementHistory";
import React, {useEffect, useState} from "react";
import {getWarehouse} from "@actions/warehouseAction";
import {useSearchParams} from "react-router-dom";
import NavFiltersMovementHistory from "@components/NavFilters/NavFiltersMovementHistory";
import {getAllManagers} from "@actions/storeEmployeeAction";
import Box from "@components/Box";
import TableTotals from "@pages/StorePages/MovementHistory/components/TableTotals";
import {getDocumentDirections, getDocumentTypes} from "@actions/storeActions/documentsAction";
import ErrorBoundary from "@components/ErrorBoundary";
import Pagination from "@components/Pagination";
import EmptyBox from "@components/EmptyBox";

const MovementHistory = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const [documents, setDocuments] = useState(null)
    const [documentDirections, setDocumentDirections] = useState([])
    const [documentTypes, setDocumentTypes] = useState([])

    useEffect(() => {
        getAllManagers()
        getDocumentDirections().then(({data}) => setDocumentDirections(data))
        getDocumentTypes().then(({data}) => setDocumentTypes(data))
    }, [])

    useEffect(() => {
        if (!searchParams.get('page') || !searchParams.get('pageSize')) {
            setSearchParams({
                page: '1',
                pageSize: '10'
            })
        } else {
            getWarehouse(`${searchParams}`).then(({data}) => setDocuments(data))
        }
    }, [searchParams])

    useEffect(() => {
    }, [searchParams])

    return (
        <GridLayout gap='24px'>
            <div>
                <FlexLayout gap='4px' align='center' margin="0 0 16px 0">
                    <Text value='Магазин' font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
                    <Text value='/' font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY1}/>
                    <Text value='Склад наличия' font={FONTS.REGULAR_14} color={COLORS.LIGHTGRAY}/>
                </FlexLayout>
                <FlexLayout gap='4px' align='center'>
                    <Text value='История перемещений' font={FONTS.MEDIUM_24} color={COLORS.BLACK}/>
                </FlexLayout>
            </div>
            <Box>
                <Text
                    color={COLORS.BLACK}
                    font={FONTS.SEMI_BOLD_16}
                    margin=" 0 0 16px 0"
                    value="По документам за период"
                />
                <GridLayout template="1fr 1fr" gap="20px" align="stretch">
                    <TableTotals
                        documents={documents?.totals?.totalsByAccepted?.totalsByDocumentTypes}
                        documentsType="Документы прихода"
                        documentsTotals={documents?.totals?.totalsByAccepted}
                        currencySymbol={documents?.totals.currencySymbol}

                    />
                    <TableTotals
                        documents={documents?.totals?.totalsByWrittenOff?.totalsByDocumentTypes}
                        documentsType="Документы списания"
                        documentsTotals={documents?.totals?.totalsByWrittenOff}
                        currencySymbol={documents?.totals.currencySymbol}
                    />
                    <TableTotals
                        documents={documents?.totals?.totalsByOtherDocumets?.totalsByDocumentTypes}
                        documentsType="Другие документы"
                        documentsTotals={documents?.totals?.totalsByOtherDocumets}
                        currencySymbol={documents?.totals.currencySymbol}
                    />
                    <Box
                        border={COLORS.LIGHTGRAY1}
                        otherStyle={`
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 4px;
                        `}
                    >
                        <Text
                            font={FONTS.SEMI_BOLD_14}
                            color={COLORS.DARKGRAY}
                            value="Всего документов:"
                        />
                        <Text
                            font={FONTS.REGULAR_14}
                            color={COLORS.DARKGRAY}
                            value={documents?.totals?.totalDocumentsCount}
                        />
                    </Box>
                </GridLayout>
            </Box>
            <NavFiltersMovementHistory documentDirections={documentDirections} documentTypes={documentTypes}/>
            {documents?.items?.length !== 0 &&
                <ErrorBoundary>
                    <TableMovementHistory documents={documents}/>

                    <Pagination
                        total={documents?.page?.totalItemCount}
                        totalSuffix="документов"
                        active={documents?.page?.currentPageIndex}
                        perPage={documents?.page?.pageSize}
                        startItem={documents?.page?.startItemIndex}
                        endItem={documents?.page?.endItemIndex}
                    />
                </ErrorBoundary>
            }
            {documents?.items?.length === 0 &&
                <EmptyBox
                    title="Список клиентов пуст"
                    icon="krista-user"
                    isRefresh={false}
                />
            }

        </GridLayout>
    );
};

export default MovementHistory;