import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";

const Text = (props) => {
    return (
        <Content
            {...props}
            aria-label={props.ariaLabel}
        >
            {props.value ? props.value : ""}
        </Content>
    )
}

const Content = styled.div`
  color: ${({color}) => color ? color : COLORS.BLACK};
  font: ${({font}) => font ? font : FONTS.REGULAR_14};
  margin: ${({margin}) => margin ? margin : 0};
  text-align: ${({textAlign}) => textAlign};
  text-decoration: ${({textDecoration}) => textDecoration};
  cursor: ${({cursor}) => cursor && cursor};
  white-space: ${({whiteSpace}) => whiteSpace && whiteSpace};
  user-select: ${({userSelect}) => userSelect && userSelect};
  ${({otherStyle}) => otherStyle}
`

export default Text;

