import {SET_COLOR} from "@types/types"

const initState = {
    colorState: null
}

const colorsReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_COLOR :
            return {...state, colorState: action.payload}
        default:
            return state
    }
}

export default colorsReducer