import Icon from "@components/Icon/Icon";
import BodyRow from "@components/Table/components/BodyRow";
import GridLayout from "@components/Layout/GridLayout";
import {COLORS, FONTS} from "@/constans";
import BodyRowItem from "@components/Table/components/BodyRowItem";
import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";

const Row = ({item, index, ariaLabel}) => {
    return (
        <BodyRow borderTop borderRadius='0' ariaLabel={ariaLabel + ' ' + index}>
            <GridLayout gap='8px' template='25% 25% 25% 25%' align='center'>
                <BodyRowItem>
                    <FlexLayout align='center'>
                        <Text
                            value={index + 1}
                            font={FONTS.REGULAR_16}
                            color={COLORS.DARKGRAY}
                            margin='0 16px 0 0'
                        />
                        <Icon
                            iconName='krista-drop-indicator'
                            iconColor={COLORS.LIGHTGRAY}
                        />
                    </FlexLayout>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value={item.articulName} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value={item.sizeLinesCount} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value={item.colorsCount} font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
            </GridLayout>
        </BodyRow>
    );
};

export default Row;