import {useSelector} from "react-redux";

import TableClient from "@components/Table/TableDefault/TableClient/TableClient";
import NavFiltersClients from "@components/NavFilters/NavFiltersClients/NavFiltersClients";
import ErrorBoundary from "@components/ErrorBoundary/ErrorBoundary";

import EmptyBox from "@components/EmptyBox/EmptyBox";
import Pagination from "@components/Pagination/Pagination";

const ClientPage = () => {
    const clientsData = useSelector(state => state.clients.clientsState)
    const clients = useSelector(state => state.clients.clientsState?.items)
    const citiesState = useSelector(state => state.cities.citiesState)

    const clientsPagination = useSelector(state => state.clients.clientsState?.page)

    return (
        <>
            {citiesState.length > 0 &&
                <NavFiltersClients/>
            }

            {!clientsData &&
                <EmptyBox
                    title="Загружаем таблицу с клиентами"
                    icon="krista-user"
                />
            }

            {clientsData && clients.length !== 0 &&
                <ErrorBoundary>
                    <TableClient/>
                    <Pagination
                        total={clientsPagination.totalItemCount}
                        totalSuffix="клиентов"
                        active={clientsPagination.currentPageIndex}
                        perPage={clientsPagination.pageSize}
                        startItem={clientsPagination.startItemIndex}
                        endItem={clientsPagination.endItemIndex}
                    />
                </ErrorBoundary>
            }

            {clientsData && clients.length === 0 &&
                <EmptyBox
                    title="Список клиентов пуст"
                    icon="krista-user"
                />
            }
        </>
    );
};

export default ClientPage;