import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {getLogout} from "@actions/authorizationAction";
import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";

import Icon from "@components/Icon/Icon";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";
import Button from "@components/Form/Button";

function ProfileInfo() {
    const dispatch = useDispatch()

    const [isOpen, setIsOpen] = useState(false);

    const exit = () => {
        getLogout(dispatch)
    }

    return (
        <Profile>
            <ProfileTop onClick={() => setIsOpen(!isOpen)}>
                <div>
                    <Text
                        value='Ажара'
                        font={FONTS.SEMI_BOLD_16}
                        color={COLORS.BLACK}
                    />
                    <Text
                        value='Менеджер'
                        font={FONTS.REGULAR_14}
                        color={COLORS.LIGHTGRAY}
                    />
                </div>
                <IconArrow isOpen={isOpen}>
                    <Icon
                        iconName='krista-arrow-top'
                        iconHeight={24}
                        iconWidth={24}
                    />
                </IconArrow>
            </ProfileTop>
            {isOpen && (
                <ProfileInner>
                    <Earning>
                        <FlexLayout spaceBetween align='center' margin='0 0 12px 0'>
                            <EarningName>
                                <IconWrapper>
                                    <Icon
                                        iconName='krista-dollar'
                                        iconWidth={16}
                                        iconHeight={16}
                                    />
                                </IconWrapper>
                                <Text
                                    value='Заработано'
                                    font={FONTS.REGULAR_14}
                                    color={COLORS.GRAY}
                                    margin='0 0 0 10px'
                                />
                            </EarningName>
                            <Text
                                value='1052.50 $'
                                font={FONTS.MEDIUM_14}
                                color={COLORS.GRAY}
                                margin='0 0 0 12px'
                            />
                        </FlexLayout>
                        <Button variant='isMain' isSmall buttonText='История выплат'/>
                    </Earning>
                    <div onClick={exit}>
                        <Text
                            value='Выйти из профиля'
                            font={FONTS.MEDIUM_14}
                            color={COLORS.MAIN}
                            textAlign='center'
                            cursor='pointer'
                        />
                    </div>
                </ProfileInner>
            )}
        </Profile>
    );
}

const Profile = styled.div`
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
`

const ProfileTop = styled.div`
  background: ${COLORS.LIGHTGRAY3};
  border: 1px solid ${COLORS.LIGHTGRAY1};
  border-radius: 15px;
  display: flex;
  padding: 12px 12px 12px 16px;
  cursor: pointer;
  user-select: none;
  justify-content: space-between;
  align-items: center;
`

const ProfileInner = styled.div`
  border-radius: 0 0 15px 15px;
  background: ${COLORS.LIGHTGRAY3};
  display: flex;
  flex-direction: column;
  padding: 15px 16px 16px 16px;
  position: absolute;
  top: calc(100% - 14px);
  left: 0;
  right: 0;
  border: 1px solid ${COLORS.LIGHTGRAY1};
  border-top: 0;
`

const IconWrapper = styled.div`
  color: ${COLORS.LIGHTGRAY};
`

const IconArrow = styled.div`
  color: ${COLORS.LIGHTGRAY};
  transform: ${({isOpen}) => isOpen ? 'rotate(0)' : 'rotate(-180deg)'};
`

const Earning = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  border-bottom: 1px solid ${COLORS.LIGHTGRAY1};
  margin-bottom: 12px;

  padding-top: 12px;
  border-top: 1px solid ${COLORS.LIGHTGRAY1};
`

const EarningName = styled.div`
  color: ${COLORS.GRAY};
  display: flex;
  align-items: center;
`

export default ProfileInfo;