import React from "react";
import {COLORS} from "@/constans";
import styled from "styled-components";

import ClientBalance from '@components/Table/TableDefault/TableClient/RowDropdown/ClientBalance/ClientBalance'
import ClientProfile from "@components/Table/TableDefault/TableClient/RowDropdown/ClientProfile/ClientProfile";
import ClientCatalogs from "@components/Table/TableDefault/TableClient/RowDropdown/ClientCatalogs/ClientCatalogs";
import ClientLinks from "@components/Table/TableDefault/TableClient/RowDropdown/ClientLinks/ClientLinks";
import Line from "@components/Line";
import GridLayout from "@components/Layout/GridLayout";

function RowDropdown({clientInfo}) {
    return (
        //TODO: разобраться с высотой у всех компонентов
        <Background>
            <Line/>
            <GridLayout template='240px 240px auto' gap='15px' padding='16px 0'>
                <ClientBalance clientInfo={clientInfo}/>
                <GridLayout gap='16px'>
                    <ClientCatalogs/>
                    <ClientLinks/>
                </GridLayout>
                <ClientProfile clientInfo={clientInfo}/>
            </GridLayout>
        </Background>
    );
}

const Background = styled.div`
  background-color: ${COLORS.WHITE};
  width: 100%;
  padding: 0 16px;
  border-radius: 0 0 10px 10px;
`

export default RowDropdown;