import React from 'react';
import {COLORS} from "@/constans";
import styled from "styled-components";

function Card({children, ariaLabel}) {
    return (
        <CardContent aria-label={ariaLabel}>
            {children}
        </CardContent>
    );
}

const CardContent = styled.div`
  background: ${COLORS.WHITE};
  border-radius: 8px;
  padding: 10px 12px 12px 12px;
`

export default Card;