import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";
import NavFiltersSalesHistory from "@components/NavFilters/NavFiltersSalesHistory";
import TableSalesHistory from "@components/Table/TableDefault/TableSalesHistory";
import Pagination from "@components/Pagination";
import EmptyBox from "@components/EmptyBox";
import {useEffect, useState} from "react";
import {getSalesHistory} from "@actions/salesHistoryAction";
import {useSearchParams} from "react-router-dom";
import {getAllManagers} from "@actions/storeEmployeeAction";
import {useSelector} from "react-redux";

function SalesHistoryPage() {
    let history = true
    const employees = useSelector(state => state.storeEmployees.managersState)

    const [searchParams, setSearchParams] = useSearchParams()

    const [salesHistory, setSalesHistory] = useState(null)

    useEffect(() => {
        if (!searchParams.get('page') || !searchParams.get('pageSize')) {
            setSearchParams({
                page: '1',
                pageSize: '25',
            })
        } else {
            getSalesHistory(searchParams).then(({data}) => {
                setSalesHistory(data)
            })
        }
    }, [searchParams])


    useEffect(() => {
        getAllManagers()
    }, []);

    return (
        <GridLayout gap='24px'>
            <FlexLayout gap='4px'>
                <Text value='Магазин' font={FONTS.REGULAR_24} color={COLORS.BLACK}/>
                <Text value='/' font={FONTS.REGULAR_24} color={COLORS.LIGHTGRAY1}/>
                <Text value='История продаж' font={FONTS.MEDIUM_24} color={COLORS.BLACK}/>
            </FlexLayout>

            {employees.length > 0 &&
                <GridLayout template='repeat(4, 1fr)' gap='20px'>
                    <NavFiltersSalesHistory searchParams={searchParams} employees={employees}/>
                </GridLayout>
            }

            {
                history ?
                    <div>
                        <TableSalesHistory salesHistory={salesHistory}/>
                        <Pagination
                            total={salesHistory?.page?.totalItemCount}
                            totalSuffix='продаж'
                            active={salesHistory?.page?.currentPageIndex}
                            perPage={salesHistory?.page?.pageSize}
                            startItem={salesHistory?.page?.startItemIndex}
                            endItem={salesHistory?.page?.endItemIndex}
                        />
                    </div>
                    :
                    <EmptyBox icon='krista-search' iconColor={COLORS.LIGHTGRAY} title='Нет истории продаж'/>
            }

        </GridLayout>
    );
}

export default SalesHistoryPage;