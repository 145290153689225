import React from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";

import {FONTS} from "@/constans";

import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text/Text";
import Box from "@components/Box";
import Line from "@components/Line";
import Button from "@components/Form/Button";
import Comments from "@pages/ProductionPages/ModelDevelopmentPage/OperatorPage/components/Comments";
import Textile from "@pages/ProductionPages/ModelDevelopmentPage/OperatorPage/components/Textile";
import {getDesignTasksCreate} from "@actions/designTaskAction";
import TabsSizeLayout from "src/pages/ProductionPages/ModelDevelopmentPage/OperatorPage/components/TabsSizeLayout";
import TabsColorsLayout from "@pages/ProductionPages/ModelDevelopmentPage/OperatorPage/components/TabsColorsLayout";

const TaskDetails = (props) => {

    const {sizes} = props
    const {register, getValues, errors, control, clearErrors, handleSubmit} = props

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams()

    const activeSize = searchParams?.get('activeSize')
    const activeColor = useSelector(state => state.model.modelActiveColor)

    const submitForm = (data) => {
        const checkIsSubSize = (size) => {
            if (size.subSizes.length > 0) {
                return size.subSizes.find(item => item.isTopPriority).id
            }
            return size.id
        }
        const concatMaterialAndAccessories = (colorItem) => {
            return (
                [...colorItem.accessoriesFields.map(y => ({
                    materialPriceId: y.colorSelected.price?.id, isMain: false
                })),
                    ...colorItem.textileFields.map((y, index) => ({
                        materialPriceId: y.colorSelected.price?.id, isMain: index === 0
                    }))]
            )
        }
        const newArticulTaskPriorities = data.articulTaskPriorities.filter(item => !item.hasOwnProperty('isNew'))
            .map((item, index) => (
                {id: item.id, priority: index + 1}
            ))

        const newModel = data.models.map(({size, ...item}, index) => {
            return ({
                ...item,
                sizeId: size.id,
                priority: index + 1,
                prioritySizeId: checkIsSubSize(size),
                colors: item.colors.map(colorItem => ({
                    colorId: colorItem.id, isMain: colorItem.isMain,
                    materialPrices: concatMaterialAndAccessories(colorItem)
                }))
            });
        })

        const copyData = {...data, articulTaskPriorities: newArticulTaskPriorities, models: newModel}
        getDesignTasksCreate(copyData, navigate)
    }

    return (
        <GridLayout gap="20px">
            <Text value="Детали задания" font={FONTS.SEMI_BOLD_20} as="h2"/>
            <Box>
                <TabsSizeLayout
                    sizes={sizes}
                    getValues={getValues}
                    register={register}
                />
                {sizes.length > 0 && <Line margin="-1px -16px 20px -16px"/>}
                {sizes.map((itemSize, itemSizeIndex) => {
                    return (
                        itemSize.size.id == activeSize &&
                        <div key={itemSize.id}>
                            <TabsColorsLayout sizes={sizes} getValues={getValues} itemSize={itemSize}/>
                            {sizes.find(item => item.size.id == activeSize)?.colors?.length > 0 && (
                                <Line margin="20px -16px 20px -16px"/>
                            )}
                            {itemSize.colors.map((itemColor, itemColorIndex) => (
                                itemColorIndex === activeColor && (
                                    <Textile
                                        control={control}
                                        errors={errors}
                                        clearErrors={clearErrors}
                                        key={itemColor.id + itemColorIndex}
                                        itemSizeIndex={itemSizeIndex}
                                        itemColorIndex={itemColorIndex}
                                    />
                                )))
                            }
                        </div>
                    );
                })}
            </Box>

            {sizes.length > 0 && <Line/>}

            {sizes.map((itemSize, itemSizeIndex) => itemSize.size.id == activeSize && (
                <Comments register={register(`models.${itemSizeIndex}.remark`)} key={itemSize.id}/>
            ))}

            {errors?.models?.map((item, index) => {
                if (item.remark) {
                    return (
                        `${item?.remark?.message} ${index + 1} remark`
                    )
                }
                if (item?.colors[index]?.textileFields?.message) {
                    return `${item.colors[index].textileFields?.message}`
                }
                if (item.colors) {
                    return (
                        `${item?.colors?.message} ${index + 1} colors`
                    )
                }
            })}

            {sizes.length > 0 &&
                <Button
                    variant="isMain"
                    buttonText="Создать задание"
                    ariaLabel="Создать задание"
                    onClick={handleSubmit(submitForm)}
                />}
        </GridLayout>
    );
};

export default TaskDetails;