import GridLayout from "@components/Layout/GridLayout";
import HeadRow from "@components/Table/components/HeadRow";
import HeadRowItem from "@components/Table/components/HeadRowItem";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import Table from "@components/Table/components/Wrapper";
import Row from "@components/Table/TableDefault/TableDocuments/Row";
import Footer from "@components/Table/TableDefault/TableDocuments/Footer";

function TableDocuments({documents, deleteProduct}) {
    const template = '1.4fr 1.2fr 1.8fr 1.5fr 1.5fr 1.5fr 52px'

    return (
        <Table overflow='visible'>
            <GridLayout gap='10px' position='relative'>
                <HeadRow>
                    <GridLayout template={template} align='center'>
                        <HeadRowItem>
                            <Text value='Товар' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Размер' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Цвет' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Цена' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='Единиц' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                        <HeadRowItem>
                            <Text value='На сумму' font={FONTS.SEMI_BOLD_14} color={COLORS.LIGHTGRAY}/>
                        </HeadRowItem>
                    </GridLayout>
                </HeadRow>
                {documents?.products?.map(item => (
                    <Row
                        item={item}
                        key={item.productId}
                        template={template}
                        documents={documents}
                        deleteProduct={deleteProduct}
                    />
                ))}
                <Footer template={template} totals={documents?.totals}/>
            </GridLayout>
        </Table>
    );
}

export default TableDocuments;