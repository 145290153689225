import {NavLink} from "react-router-dom";
import {useState} from "react";
import {COLORS, FONTS} from "@/constans";
import useOutside from "@hooks/useOutside";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import dayjs from "dayjs";
import styled from "styled-components";

import Icon from "@components/Icon/Icon";
import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";

const dataTabs = [
    {
        title: 'Показать всё',
        desc: 'Заказы за всё время',
        chosen: 'За всё время',
    },
    {
        title: 'День',
        desc: 'За последние 24 часа',
        chosen: 'Последний день',
    },
    {
        title: 'Неделя',
        desc: 'За последние 7 дней',
        chosen: 'Последнюю неделю',
    },
    {
        title: 'Месяц',
        desc: 'За последние 4 недели',
        chosen: 'Последний месяц',
    },
    {
        title: 'Год',
        desc: 'За последние 12 месяцев',
        chosen: 'Последний год',
    },
]

function OrdersHeader() {
    const {ref, isShow, setIsShow} = useOutside(false);

    const [dateChosen, setDateChosen] = useState(dataTabs[0].desc);
    const [valueInitial, setValueInitial] = useState();

    const toggleActive = (title) => {
        setDateChosen(title)
        setIsShow(false)
    }
    const setDate = (value) => {
        setDateChosen(dayjs(value[0]).format('DD.MM.YYYY') + ' - ' + dayjs(value[1]).format('DD.MM.YYYY'))
        setIsShow(false)
        setValueInitial([value[0], value[1]])
    }

    return (
        <FlexLayout margin='0 0 24px 0' spaceBetween>
            <FlexLayout align='flex-end'>
                <Text value='Заказы' font={FONTS.SEMI_BOLD_24} color={COLORS.BLACK} margin='0 30px 0 0'/>
                <DropdownWrapper>
                    <Dropdown onClick={() => setIsShow(!isShow)}>
                        <Text value='Показать:' font={FONTS.REGULAR_18} color={COLORS.LIGHTGRAY}/>
                        <Text value={dateChosen} font={FONTS.MEDIUM_18} color={COLORS.DARKGRAY} margin='0 8px 0 4px'/>
                        <Icon
                            iconName={isShow ? 'krista-arrow-bottom' : 'krista-arrow-top'}
                            iconWidth={24}
                            iconHeight={24}
                        />
                    </Dropdown>
                    {isShow &&
                        <DropdownInner ref={ref}>
                            {dataTabs.map((item, index) => {
                                return (
                                    <DropdownInnerItem key={index} onClick={() => toggleActive(item.chosen)}>
                                        <Text value={item.title} font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                                        <Text
                                            value={item.desc}
                                            font={FONTS.REGULAR_14}
                                            color={COLORS.LIGHTGRAY}
                                            margin='0 0 0 8px'
                                        />
                                    </DropdownInnerItem>
                                )
                            })}
                            <DropdownInnerItem>
                                <Text value='Период' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY}/>
                                {/*//TODO: допилить стили на календарь, когда будет готов бэк*/}
                                <div className="data-range-custom">
                                    <DateRangePicker
                                        format="dd-MM-yyyy"
                                        onChange={(value) => setDate(value)}
                                        locale="ru-RU"
                                        value={valueInitial}
                                        dayPlaceholder=''
                                        maxDate={new Date()}
                                        minDetail={"decade"}
                                        nextLabel={<Icon
                                            iconName='krista-chevron-bottom'
                                            iconClass='react-calendar-button react-calendar-button-next'
                                            iconWidth={24}
                                            iconHeight={24}
                                        />}
                                        prevLabel={<Icon
                                            iconName='krista-chevron-bottom'
                                            iconClass='react-calendar-button react-calendar-button-prev'
                                            iconWidth={24}
                                            iconHeight={24}
                                        />}
                                        next2Label={null}
                                        prev2Label={null}
                                    />
                                </div>
                            </DropdownInnerItem>
                        </DropdownInner>
                    }
                </DropdownWrapper>
            </FlexLayout>
            <LinkButtons>
                <LinkButton to='/orders' end>
                    <Text value='Показать по заказам' font='inherit' color='inherit'/>
                </LinkButton>
                <LinkButton to='/orders/models' end>
                    <Text value='Расшифровать по моделям' font='inherit' color='inherit'/>
                </LinkButton>
            </LinkButtons>
        </FlexLayout>
    );
}

const DropdownWrapper = styled.div`
  position: relative;
`
const Dropdown = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`

const DropdownInner = styled.div`
  width: 276px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  z-index: 5;

  border: 1px solid #F1F1F5;
  box-shadow: 0 5px 15px rgba(68, 68, 79, 0.1);
  border-radius: 8px;
  cursor: default;
`

const DropdownInnerItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: 8px 14px;
  background-color: ${COLORS.WHITE};

  &:hover {
    background-color: ${COLORS.LIGHTGRAY2};
  }

  &:first-child {
    border-radius: 8px 8px 0 0;
    padding-top: 15px;
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
    padding-bottom: 15px;
  }
`

const LinkButtons = styled.div`
  display: flex;
  align-items: center;
  padding: 1px;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.LIGHTGRAY2};
  border-radius: 8px;
`

const LinkButton = styled(NavLink)`
  padding: 5px 15px;
  background-color: transparent;
  border-radius: 8px;
  color: ${COLORS.GRAY};
  cursor: pointer;
  text-decoration: none;
  font: ${FONTS.REGULAR_14};
  &:hover {
    color: ${COLORS.WHITE};
    background-color: ${COLORS.MAIN};
  }

  &:not(:last-child) {
    margin-right: 2px;
  }

  &.active {
    color: ${COLORS.WHITE};
    background-color: ${COLORS.MAIN};
  }
`

export default OrdersHeader;