import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";
import styled, {css} from "styled-components";
import Icon from "@components/Icon";
import FlexLayout from "@components/Layout/FlexLayout";

const RadioButtons = (props) => {
    const {title, name, value, label} = props
    const {data, selected, priority, objectKey} = props
    const {setValue} = props

    const optionalItem = (item) => {
        if (value) {
            return item[value]
        }
        return item.id
    }

    const optionalSelected = (selected) => {
        if (objectKey) {
            return selected?.id
        }
        return selected
    }


    return (
        <div aria-label={`Поле ${title}`}>
            <Text value={title} font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY} margin='0 0 6px 0'/>
            <Radio>
                <GridLayout gap='4px' template={`repeat(${data?.length}, 1fr)`}>
                    {data?.map(item => (
                            <RadioLabel
                                key={optionalItem(item)}
                                id={item.id}
                                isActive={optionalItem(item) === optionalSelected(selected)}
                                aria-label={`Кнопка ${item.id}`}
                                onClick={() => setValue(name, objectKey ? item : optionalItem(item))}
                            >
                                <FlexLayout gap='4px' align='center'>
                                    <Icon
                                        iconWidth={24}
                                        iconHeight={24}
                                        iconName={optionalItem(item) === optionalSelected(selected) ? 'krista-around-check' : 'krista-around-uncheck'}
                                        iconColor={COLORS.STATUS_GREEN}
                                    />
                                    <Text
                                        value={item[label]}
                                        font={FONTS.REGULAR_14}
                                        color={COLORS.DARKGRAY}
                                        ariaLabel={optionalItem(item) === optionalSelected(selected) && 'Активная кнопка'}
                                    />
                                </FlexLayout>
                                {priority && item.id === +selected &&
                                    <Text value='Приоритет' font={FONTS.REGULAR_10} color={COLORS.WHITE}/>
                                }
                            </RadioLabel>
                        )
                    )}
                </GridLayout>
            </Radio>
        </div>
    );
};

const Radio = styled.div`

`

const RadioLabel = styled.div`
  cursor: pointer;
`


export default RadioButtons;
