import GridLayout from "@components/Layout/GridLayout";
import SelectTextile from "@components/Form/SelectsCustom/SelectTextile";
import SelectTextileColor from "@components/Form/SelectsCustom/SelectTextileColor";
import Input from "@components/Form/Inputs/Input";
import Button from "@components/Form/Button";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {
    getDesignTasksAddMaterials,
    getDesignTasksDeleteMaterials, getDesignTasksUpdateMaterialConsumption,
    getDesignTasksUpdateMaterials
} from "@actions/designTaskAction";
import {toast} from "react-toastify";
import ToastContent from "@components/ToastContent";
import SelectTextileLayout
    from "@pages/ProductionPages/ModelDevelopmentPage/ConstructorPage/components/ItemTextile/components/SelectTextileLayout";
import {useForm} from "react-hook-form";
import InputNumeric from "@components/Form/Inputs/InputNumeric";
import {useSelector} from "react-redux";

function ItemTextile({isTextile, itemTextile, itemColor, indexTextile,itemSizeIndex, register, watch, update, remove}) {
    console.log(itemTextile.quantity)
    const {id} = useParams()
    const nomenclatureId = itemColor?.nomenclatureId
    const isMain = itemTextile.isMain
    const colorMaterialPriceId = itemTextile?.material?.price?.colorMaterialPriceId
    const materialCalculationId = itemTextile?.materialCalculationId
    const activeColor = useSelector(state => state.model.modelActiveColor)

    const watchExpenses = watch(`articul.modelDesignTasks.${itemSizeIndex}.colors.${activeColor}.materialCalculations.textile.${indexTextile}.quantity`)

    useEffect(()=> {
        if(watchExpenses) {
            getDesignTasksUpdateMaterialConsumption(id, nomenclatureId, materialCalculationId, watchExpenses)
        }
    },[watchExpenses])
    console.log(watchExpenses)

    const removeItem = () => {
        if (itemTextile.hasOwnProperty('isNew')) {
            remove(indexTextile)
        } else {
            getDesignTasksDeleteMaterials(id, itemColor.nomenclatureId, itemTextile.materialCalculationId)
                .then((response) => {
                    remove(indexTextile)
                    toast.success(<ToastContent title="Успешная операция" response={response.data.message} ok/>);
                })
        }

    }
    return (
        <GridLayout gap='8px' template='1.3fr 1fr 110px 110px 100px 48px' align='flex-end'>
            <SelectTextileLayout
                update={update}
                index={indexTextile}
                isTextile={isTextile}
                label="Матерьалы"
                item={itemTextile?.material}
                itemTextile={itemTextile}
            />
            <SelectTextileColor
                updateTextile={update}
                // isDisabled={!itemTextile?.material?.colors?.length}
                itemTextile={itemTextile}
                itemColor={itemColor}
                indexTextile={indexTextile}
                materialId={itemTextile?.material?.materialId}
                supplierId={itemTextile?.material?.supplierId}
                photo={itemTextile?.material?.imagePath}
                name={itemTextile?.material?.color?.name}
                priceId={itemTextile?.material?.price?.materialPriceId}
                price={itemTextile?.material?.price?.price}
                isConstructor
                taskId={id}
            />
            <Input
                label="Цена за м"
                symbol='USD'
                disabled
                value={itemTextile?.material?.price?.price}
            />
            <Input
                register={register}
                label="Расход"
                symbol='м'
                name={`articul.modelDesignTasks.${itemSizeIndex}.colors.${activeColor}.materialCalculations.textile.${indexTextile}.quantity`}
                disabled={!itemTextile?.material?.color}
            />
            {/*<InputNumeric*/}
            {/*    */}
            {/*/>*/}
            <Input
                label="Сумма"
                register={register}
                symbol='USD'
                disabled
                name={`articul.modelDesignTasks.${itemSizeIndex}.colors.${activeColor}.materialCalculations.textile.${indexTextile}.sum`}

            />
            {!itemTextile.isMain && <Button variant='isGray' iconName='krista-delete' onClick={removeItem}/>}

        </GridLayout>
    );
}


export default ItemTextile;