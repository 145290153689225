import {COLORS} from "@/constans";
import styled from "styled-components";

function BodyRowOnline({isActive, children}) {
    return (
        <BodyRowOnlineContent isActive={isActive}>
            {children}
        </BodyRowOnlineContent>
    );
}

const BodyRowOnlineContent = styled.div`
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${({isActive}) => isActive ? COLORS.SALAD : COLORS.RED};
    border-radius: 50%;
`

export default BodyRowOnline;


