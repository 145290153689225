import Text from "@components/Text/Text";
import {FONTS} from "@/constans";
import RadioButtons from "@components/Form/RadioButtons";
import Box from "@components/Box";
import Button from "@components/Form/Button";

function SizeInWork({sizeInWorkData, sizePrioritySelected, register, isAvailableTake, availableTake, setValue}) {
    return (
        <Box>
            <Text value='Размер в работе' as='h3' font={FONTS.SEMI_BOLD_16} margin='0 0 16px 0'/>
            <RadioButtons
                data={sizeInWorkData}
                register={register}
                title="Выбранный размер"
                name="sizeInWork.prioritySize"
                selected={sizePrioritySelected}
                priority
                label='value'
                value='id'
                objectKey='id'
                setValue={setValue}
            />
            {isAvailableTake &&
                <Button
                    variant='isSuccess'
                    buttonText='Взять в работу'
                    margin='20px 0 0 0'
                    ariaLabel='Взять в работу'
                    isResponsive
                    onClick={availableTake}
                />
            }
        </Box>
    );
}

export default SizeInWork;