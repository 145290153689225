import {Link} from "react-router-dom";
import Counter from "@components/Form/Counter";
import Button from "@components/Form/Button";
import BodyRow from "@components/Table/components/BodyRow";
import GridLayout from "@components/Layout/GridLayout";
import {COLORS, FONTS} from "@/constans";
import BodyRowItem from "@components/Table/components/BodyRowItem";
import Text from "@components/Text";
import FlexLayout from "@components/Layout/FlexLayout";

const Row = () => {
    return (
        <BodyRow padding='6px 8px' backgroundColor={COLORS.WHITE} borderRadius='0' borderTop>
            <GridLayout template='40px auto 14% 8% 10% 10% 10% 100px' align='center'>
                <BodyRowItem>
                    <Text value='1' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text
                        value='Чичваркина Александра Николаевна'
                        font={FONTS.REGULAR_16}
                        color={COLORS.MAIN}
                        cursor='pointer'
                    />
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='Санкт-Петербург' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='Алина' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Counter value={18}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='36' font={FONTS.REGULAR_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <Text value='1 020.00 $' font={FONTS.MEDIUM_16} color={COLORS.DARKGRAY}/>
                </BodyRowItem>
                <BodyRowItem>
                    <FlexLayout margin='0 0 0 auto' gap='8px' align='end'>
                        <Button
                            variant='isMain'
                            iconName='krista-cart'
                            isSmall
                            as={Link}
                            to="/orders/order"
                        />
                        <Button
                            variant='isWhite'
                            iconName='krista-delete'
                            isSmall
                        />
                    </FlexLayout>
                </BodyRowItem>
            </GridLayout>
        </BodyRow>
    );
};

export default Row;