import styled from "styled-components";

function BodyRowAnimateIndicator({children, isLoading}) {
    return (
        <HeadRowContent isLoading={isLoading}>
            {children}
        </HeadRowContent>
    );
}

const HeadRowContent = styled.div`
  position: absolute;
  display: block;
  content: '';
  bottom: 0;
  left: 0;
  height: 4px;
  opacity: 0;
  right: 0;
  transition: 0.2s;
  background: linear-gradient(90deg, #50B5FF 0%, #0073FF 20%, #9D61D8 40%, #FAB314 60%, #3DD598 80%, #50B5FF 100%), linear-gradient(90deg, #0073FF 0%, #D9EAFF 25%, #5252CC 50%, #CCE3FF 75%, #0073FF 100%);
  background-size: 200% 200%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  animation: gradientAnimation infinite ease 2s;
  visibility: hidden;
  ${({isLoading}) => isLoading && `
    opacity: 1;
    visibility: visible;
  `}
  @keyframes gradientAnimation {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 200% 0;
    }
  }
`

export default BodyRowAnimateIndicator;