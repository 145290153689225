import React from 'react';
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import Box from "@components/Box";
import GridLayout from "@components/Layout/GridLayout";
import Image from "@components/Image/Image";
import FlexLayout from "@components/Layout/FlexLayout";
import hexToRgba from "hex-to-rgba";
import {NumericFormat} from "react-number-format";
import {getCurrencyScaleLength} from "@util/decimalHelper";

function LastScan(props) {
    const {item} = props

    return (
        <>
            <Text
                value='Последнее добавление'
                font={FONTS.REGULAR_12}
                color={COLORS.LIGHTGRAY}
                margin='0 0 8px 0'
            />
            <Box padding="12px 16px">
                <GridLayout template='50px 1fr' gap='14px' align='center'>
                    <Image isEmptyImage={!item} src={item?.photo} width='50px' height='50px'/>
                    {item ?
                        <GridLayout template='1fr 1fr 1fr' align='center' gap='20px'>
                            <div>
                                <FlexLayout align='center' gap='4px'>
                                    <Text value='Артикул:' font={FONTS.MEDIUM_14} color={COLORS.BLACK}/>
                                    <Text
                                        value={item.articulName}
                                        font={FONTS.REGULAR_14}
                                        color={COLORS.BLACK}
                                    />
                                </FlexLayout>
                                <FlexLayout align='center' gap='4px'>
                                    <Text value='Размер:' font={FONTS.MEDIUM_14} color={COLORS.BLACK}/>
                                    <Text
                                        value={item.sizeName}
                                        font={FONTS.REGULAR_14}
                                        color={COLORS.BLACK}
                                    />
                                </FlexLayout>
                                <FlexLayout align='center' gap='4px'>
                                    <Text value='Цвет:' font={FONTS.MEDIUM_14} color={COLORS.BLACK}/>
                                    <Text
                                        value={item.colorName}
                                        font={FONTS.REGULAR_14}
                                        color={COLORS.BLACK}
                                    />
                                </FlexLayout>
                            </div>
                            <Box
                                padding='8px 16px'
                                background={item.discountPercent ? hexToRgba(COLORS.YELLOW, 0.1) : COLORS.LIGHTGRAY3}
                                height='100%'
                            >
                                <GridLayout align='center' height='100%' gap='2px'>
                                    {item.discountPercent && (
                                        <FlexLayout justifyContent='center' align='center' gap='12px'>
                                            <NumericFormat
                                                value={item.originalPrice}
                                                decimalScale={getCurrencyScaleLength(item.originalPrice)}
                                                fixedDecimalScale
                                                displayType="text"
                                                renderText={(value) => (
                                                    <Text
                                                        value={value}
                                                        font={FONTS.REGULAR_12}
                                                        color={COLORS.LIGHTGRAY}
                                                        textDecoration='line-through'
                                                    />
                                                )}
                                                suffix={` ${item.currencySymbol}`}
                                            />

                                            <NumericFormat
                                                value={item.discountPercent}
                                                decimalScale={2}
                                                displayType="text"
                                                renderText={(value) => (
                                                    <Text
                                                        value={value}
                                                        font={FONTS.REGULAR_12}
                                                        color={COLORS.RED}
                                                    />
                                                )}
                                                suffix={`%`}
                                                prefix='-'
                                            />
                                        </FlexLayout>
                                    )}

                                    <NumericFormat
                                        value={item.price}
                                        decimalScale={getCurrencyScaleLength(item.price)}
                                        fixedDecimalScale
                                        displayType="text"
                                        renderText={(value) => (
                                            <Text
                                                value={value}
                                                font={FONTS.REGULAR_14}
                                                color={COLORS.BLACK}
                                                textAlign='center'
                                            />
                                        )}
                                        suffix={` ${item.currencySymbol}`}
                                    />
                                </GridLayout>
                            </Box>

                            <Box
                                padding='8px 16px'
                                background={hexToRgba(item.isAdded ? COLORS.STATUS_GREEN : COLORS.RED, 0.1)}
                            >
                                <GridLayout gap='2px'>
                                    <Text value='Действие' font={FONTS.REGULAR_12} color={COLORS.GRAY}/>
                                    <Text
                                        value={item.isAdded ? 'Добавлен' : 'Удален'}
                                        font={FONTS.REGULAR_14}
                                        color={COLORS.BLACK}
                                    />
                                </GridLayout>
                            </Box>
                        </GridLayout>
                        :
                        <Box background={COLORS.LIGHTGRAY3}>
                            <Text
                                value='Сканирований не было'
                                font={FONTS.REGULAR_14}
                                color={COLORS.LIGHTGRAY}
                                textAlign='center'
                            />
                        </Box>
                    }
                </GridLayout>
            </Box>
        </>
    );
}

export default LastScan;