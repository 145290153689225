import Header from "@components/Header/Header";
import RouterApp from "@/RouterApp";
import styled from "styled-components";

const LoginLayout = () => {
    return (
        <Wrapper>
            <Header/>
            <Content>
                <RouterApp/>
            </Content>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
const Content = styled.main`
  background-color: #FAFAFB;
  padding: 32px 40px;
  flex-grow: 3;
  display: flex;
  flex-direction: column;
  @media (max-width: 640px) {
    padding: 20px 16px;
  }
`

export default LoginLayout;
