import React from 'react';
import ItemColor from "@pages/ProductionPages/ModelDevelopmentPage/components/ItemColor";
import More from "@pages/ProductionPages/ModelDevelopmentPage/components/ItemColor/components/More";

const ItemColorLayout = (props) => {

    const {item, index} = props
    const {children} = props

    return (
        <ItemColor item={item} index={index}>
            {children}
        </ItemColor>
    );
};



export default ItemColorLayout;