import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import store from './store'
import App from './App';
import '@style/_app.scss'
import '@style/_fonts.scss'
import '@style/_colors.scss'
import '@style/_toast.scss'
import '@style/_calendar.scss'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?
        <BrowserRouter basename="new-admin">
            <Provider store={store}>
                <App/>
            </Provider>
        </BrowserRouter>
        :
        <React.StrictMode>
            <BrowserRouter basename="new-admin">
                <Provider store={store}>
                    <App/>
                </Provider>
            </BrowserRouter>
        </React.StrictMode>
);