import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";
import GridLayout from "@components/Layout/GridLayout";
import Text from "@components/Text";

function OrderInfoPanel() {
    return (
        <Wrapper>
            <GridLayout gap='72px' template='repeat(5,1fr)'>
                <OrderItem>
                    <Text value='Ожидает запуска' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY} margin='0 0 8px 0'/>
                    <Text value='1 290 ед.' font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY} margin='0 0 2px 0'/>
                    <Text value='110 000.00 $' font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY} margin='0 0 2px 0'/>
                </OrderItem>
                <OrderItem>
                    <Text value='В пошиве' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY} margin='0 0 8px 0'/>
                    <Text value='860 ед.' font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY} margin='0 0 2px 0'/>
                    <Text value='80 000.00 $' font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY} margin='0 0 2px 0'/>
                </OrderItem>
                <OrderItem>
                    <Text value='В резерве' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY} margin='0 0 8px 0'/>
                    <Text value='1 600 ед.' font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY} margin='0 0 2px 0'/>
                    <Text value='100 000.00 $' font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY} margin='0 0 2px 0'/>
                </OrderItem>
                <OrderItem>
                    <Text value='Оплачено' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY} margin='0 0 8px 0'/>
                    <Text value='1 600 ед.' font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY} margin='0 0 2px 0'/>
                    <Text value='100 000.00 $' font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY} margin='0 0 2px 0'/>
                </OrderItem>
                <OrderItem>
                    <Text value='Отправлен' font={FONTS.REGULAR_14} color={COLORS.DARKGRAY} margin='0 0 8px 0'/>
                    <Text value='900 ед.' font={FONTS.SEMI_BOLD_16} color={COLORS.DARKGRAY} margin='0 0 2px 0'/>
                    <Text value='90 000.00 $' font={FONTS.REGULAR_12} color={COLORS.LIGHTGRAY} margin='0 0 2px 0'/>
                </OrderItem>
            </GridLayout>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  background: ${COLORS.WHITE};
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 24px;
`

const OrderItem = styled.div`
  text-align: center;
  position: relative;
  &:not(:last-child)::before {
    position: absolute;
    content: '';
    right: -36px;
    top: 0;
    bottom: 0;
    border-right: 1px solid ${COLORS.LIGHTGRAY2};
  }
`


export default OrderInfoPanel;