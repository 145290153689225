import ApiConfig from "@/ApiConfig";
import {
    SET_CLIENTS, SET_MODAL_CONFIRM_ACTION,
    SET_MODAL_CONFIRM_NEW_CLIENT,
    SET_NOT_APPROVED_CLIENT_BY_ID,
    SET_NOT_APPROVED_CLIENTS, SET_TAX_PAYER_DATA
} from "@types/types";
import store from "@store";
import {toast} from "react-toastify";
import ToastContent from "@components/ToastContent/ToastContent";

export const getClients = (searchParams) => {
    ApiConfig.get(`Client/Client?${searchParams}`)
        .then((response) => {
            store.dispatch({type: SET_CLIENTS, payload: response.data})
        })
};

export const getNotApprovedClients = (searchParams) => {
    ApiConfig.get(`Client/RegistrationRequest?${searchParams}`)
        .then((response) => {
            store.dispatch({type: SET_NOT_APPROVED_CLIENTS, payload: response.data})
        })
};

export const getNotApprovedClient = async (id) => {
    return await ApiConfig.get(`Client/RegistrationRequest/${id}`)
};

export const getApproveClient = async (data, client) => {
    const response = await ApiConfig.post(`Client/RegistrationRequest/Approve/${client.id}`, data)
    toast.success(<ToastContent title={`Клиент: ${client.fullName}`} response={response.data.message} ok/>);
    store.dispatch({type: SET_MODAL_CONFIRM_NEW_CLIENT, payload: false})
    store.dispatch({type: SET_NOT_APPROVED_CLIENT_BY_ID, payload: null})
    store.dispatch({type: SET_TAX_PAYER_DATA, payload: null})
};

export const getCreateClient = async (data, client) => {
    const response = await ApiConfig.post(`Client/Client/Create`, data)
    toast.success(<ToastContent title={`Клиент: ${client}`} response={response.data.message} ok/>);
    store.dispatch({type: SET_MODAL_CONFIRM_NEW_CLIENT, payload: false})
    store.dispatch({type: SET_NOT_APPROVED_CLIENT_BY_ID, payload: null})
    store.dispatch({type: SET_TAX_PAYER_DATA, payload: null})
};

export const getRemoveClient = async (client) => {
    const response = await ApiConfig.delete(`Client/RegistrationRequest/Delete/${client.id}`)
    toast.success(<ToastContent title={`Клиент: ${client.fullName}`} response={response.data.message} ok/>);
    store.dispatch({
        type: SET_MODAL_CONFIRM_ACTION, payload: {
            isShow: false,
            title: '',
            desc: '',
            accept: null
        }
    })
    store.dispatch({type: SET_NOT_APPROVED_CLIENT_BY_ID, payload: null})
};

export const getClient = async (id) => {
    return await ApiConfig.get(`Client/Client/${id}`)
};