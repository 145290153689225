import React, {useEffect, useState} from 'react';
import FlexLayout from "@components/Layout/FlexLayout";
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import GridLayout from "@components/Layout/GridLayout";
import Button from "@components/Form/Button";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import {toast} from "react-toastify";
import ToastContent from "@components/ToastContent";
import EmptyBox from "@components/EmptyBox/EmptyBox";
import {SET_MODAL_CONFIRM_ACTION} from "@types/types";
import {useDispatch} from "react-redux";
import LastScan from "@pages/StorePages/components/LastScan";
import ProductBarcode from "@pages/StorePages/components/ProductBarcode";
import TableWriteOffDocuments from "@components/Table/TableDefault/TableWriteOffDocuments";
import {
    getWriteOffDocuments,
    getWriteOffDocumentsCreate, getWriteOffDocumentsDelete,
    getWriteOffDocumentsFinish, getWriteOffDocumentsScan, getWriteOffDocumentsScanCancel
} from "@actions/writeOffDocumentsAction";

const WriteOffDocumentsPage = () => {
    const dispatch = useDispatch()

    const [writeOffDocuments, setWriteOffDocuments] = useState(null)
    const [isAddProduct, setIsAddProduct] = useState(true)
    const [lastProduct, setLastProduct] = useState(null)

    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate();

    const {register, handleSubmit, formState: {errors}} = useForm({
        resolver: yupResolver(yup.object().shape({
            productBarcode: yup.string().required('Обязательное поле')
        })),
        defaultValues: {productBarcode: "0000000001"}
    })

    const writeOffDocumentsDelete = () => {
        dispatch({
            type: SET_MODAL_CONFIRM_ACTION, payload: {
                isShow: true,
                title: 'Вы уверены, что хотите удалить все ?',
                desc: '',
                accept: () => {
                    getWriteOffDocumentsDelete(searchParams.get('writeOffDocumentId'))
                        .then(() => [
                            navigate('/store/warehouse?page=1&pageSize=25'),
                            toast.success(<ToastContent title="Документ принятие на склад, удален" ok/>),
                            dispatch({
                                type: SET_MODAL_CONFIRM_ACTION, payload: {
                                    isShow: false,
                                    title: '',
                                    desc: '',
                                    accept: null,
                                    icon: ''
                                }
                            })
                        ])
                },
                icon: 'krista-delete'
            }
        })

    }

    const writeOffDocumentsFinish = () => {
        getWriteOffDocumentsFinish(searchParams.get('writeOffDocumentId'))
            .then(() => [
                navigate('/store/warehouse?page=1&pageSize=25'),
                toast.success(<ToastContent title="Документ принятие на склад, добавлен" ok/>)
            ])
    }

    const submit = (data) => {
        if (isAddProduct) {
            getWriteOffDocumentsScan(
                {
                    ...data,
                    writeOffDocumentId: searchParams.get('writeOffDocumentId')
                }
            ).then(({data}) => [
                setLastProduct(data),
                getWriteOffDocuments(searchParams.get('writeOffDocumentId'))
                    .then(({data}) => setWriteOffDocuments(data))
            ])
        } else {
            getWriteOffDocumentsScanCancel(
                {
                    ...data,
                    writeOffDocumentId: searchParams.get('writeOffDocumentId')
                }
            ).then(({data}) => [
                setLastProduct(data),
                getWriteOffDocuments(searchParams.get('writeOffDocumentId'))
                    .then(({data}) => setWriteOffDocuments(data))
            ])
        }

    }


    useEffect(() => {
        if (!searchParams.get('writeOffDocumentId')) {
            getWriteOffDocumentsCreate().then(({data}) => setSearchParams(data))
        } else {
            getWriteOffDocuments(searchParams.get('writeOffDocumentId')).then(({data}) => setWriteOffDocuments(data))
        }
    }, [searchParams])

    return (
        <GridLayout gap='24px'>
            <FlexLayout gap='4px'>
                <Text value='Магазин' font={FONTS.REGULAR_24} color={COLORS.BLACK}/>
                <Text value='/' font={FONTS.REGULAR_24} color={COLORS.LIGHTGRAY}/>
                <Text value='Склад наличия' font={FONTS.REGULAR_24} color={COLORS.BLACK}/>
                <Text value='/' font={FONTS.REGULAR_24} color={COLORS.LIGHTGRAY}/>
                {writeOffDocuments &&
                    <Text
                        value={`${writeOffDocuments?.documentTypeName} №${writeOffDocuments?.serialNumber}`}
                        font={FONTS.MEDIUM_24}
                        color={COLORS.BLACK}
                    />
                }
            </FlexLayout>
            {writeOffDocuments?.isCompleted && (
                <FlexLayout gap='8px' align='center'>
                    <Text
                        value={`Номер документа: ${writeOffDocuments.serialNumber}`}
                        font={FONTS.MEDIUM_18}
                    />
                    <Text
                        value={writeOffDocuments.statusName}
                        color={COLORS.STATUS_GREEN}
                        font={FONTS.REGULAR_24}
                    />
                </FlexLayout>
            )}
            {!writeOffDocuments?.isCompleted &&
                <GridLayout gap='20px' template='1fr 1fr'>
                    <div>
                        <ProductBarcode
                            register={register}
                            errors={errors}
                            onClick={handleSubmit(submit)}
                            isAddProduct={isAddProduct}
                            setIsAddProduct={setIsAddProduct}
                        />
                    </div>
                    {lastProduct && <div><LastScan item={lastProduct}/></div>}
                </GridLayout>
            }
            {!writeOffDocuments?.products?.length ?
                <EmptyBox icon='krista-box' title='Нет товаров' isRefresh={false}/>
                :
                <TableWriteOffDocuments
                    writeOffDocuments={writeOffDocuments}
                    setWriteOffDocuments={setWriteOffDocuments}
                />
            }
            {!writeOffDocuments?.isComleted
                &&
                <GridLayout template='14% 30%' spaceBetween>
                    <Button
                        variant='isDangerBorder'
                        buttonText='Удалить все'
                        onClick={writeOffDocumentsDelete}
                    />
                    <Button
                        isDisabled={!writeOffDocuments?.products?.length}
                        variant={`${!writeOffDocuments?.products?.length ? 'isDisabled' : 'isDanger'}`}
                        buttonText='Списать со склада'
                        onClick={writeOffDocumentsFinish}
                    />
                </GridLayout>
            }

        </GridLayout>
    );
};

export default WriteOffDocumentsPage;