import React, {useEffect} from 'react';
import Text from "@components/Text";
import {COLORS, FONTS} from "@/constans";
import Button from "@components/Form/Button";
import {SET_MODAL_CREATE_COLLECTION} from "@types/types";
import Input from "@components/Form/Inputs/Input";
import styled from "styled-components";
import {useDispatch} from "react-redux";
import GridLayout from "@components/Layout/GridLayout";
import FlexLayout from "@components/Layout/FlexLayout";
import {useForm} from "react-hook-form";
import InputDebounce from "@components/Form/Inputs/InputDebounce";

function SidebarCollection() {
    const {control, watch} = useForm()

    const dispatch = useDispatch()

    useEffect(()=> {
        console.log(watch('collections'), 'Тестовая проверка дебануса в сайдбаре коллекции')
    }, [watch('collections')])

    return (
        <Wrapper id="sidebar-collection">
            <Sticky>
                <Text value='Коллекции' font={FONTS.SEMI_BOLD_16} color={COLORS.BLACK} margin='0 0 10px 0'/>
                <Button
                    buttonText={(<Text value='Создать новую коллекцию' font={FONTS.SEMI_BOLD_12} color={COLORS.GRAY}/>)}
                    variant='isDefault'
                    isSmall
                    height='28px'
                    onClick={() => {dispatch({type: SET_MODAL_CREATE_COLLECTION, payload: true})}}
                    margin='0 0 18px 0'
                    isResponsive
                />
                <InputDebounce
                    control={control}
                    name='collections'
                    icon='krista-search'
                    placeholder='Артикул в коллекциях'
                    isSmall
                    debounceTimeout={1500}
                />
            </Sticky>
            <GridLayout gap='16px'>
                <div>
                    <Text font={FONTS.SEMI_BOLD_12} color={COLORS.LIGHTGRAY} value='2023' margin='0 0 8px 0'/>
                    <CollectionItem>
                        <FlexLayout align='center' spaceBetween padding='12px 0'>
                            <Text font={FONTS.REGULAR_16} color={COLORS.MAIN} value='Коллекция 32'/>
                            <Text font={FONTS.MEDIUM_14} color={COLORS.LIGHTGRAY} value='50'/>
                        </FlexLayout>
                    </CollectionItem>
                </div>
                <div>
                    <Text font={FONTS.SEMI_BOLD_12} color={COLORS.LIGHTGRAY} value='2022' margin='0 0 8px 0'/>
                    <CollectionItem>
                        <FlexLayout align='center' spaceBetween padding='12px 0'>
                            <Text font={FONTS.REGULAR_16} color={COLORS.BLACK} value='Коллекция 32'/>
                            <Text font={FONTS.MEDIUM_14} color={COLORS.LIGHTGRAY} value='50'/>
                        </FlexLayout>
                    </CollectionItem>
                    <CollectionItem>
                        <FlexLayout align='center' spaceBetween padding='12px 0'>
                            <Text font={FONTS.REGULAR_16} color={COLORS.BLACK} value='Коллекция 32'/>
                            <Text font={FONTS.MEDIUM_14} color={COLORS.LIGHTGRAY} value='50'/>
                        </FlexLayout>
                    </CollectionItem>
                </div>
                <div>
                    <Text font={FONTS.SEMI_BOLD_12} color={COLORS.LIGHTGRAY} value='2021' margin='0 0 8px 0'/>
                    <CollectionItem>
                        <FlexLayout align='center' spaceBetween padding='12px 0'>
                            <Text font={FONTS.REGULAR_16} color={COLORS.BLACK} value='Коллекция 32'/>
                            <Text font={FONTS.MEDIUM_14} color={COLORS.LIGHTGRAY} value='50'/>
                        </FlexLayout>
                    </CollectionItem>
                </div>
            </GridLayout>
        </Wrapper>
    );
}

const Sticky = styled.div`
  position: sticky;
  top: -20px;
  margin: -20px 0 0 0;
  padding: 20px 0;
  background-color: ${COLORS.WHITE};
`

const CollectionItem = styled.div`
    cursor: pointer;
`

const Wrapper = styled.div`
  overflow: scroll;
  padding: 20px 24px;
  max-width: 252px;
  height: 100%;
  background-color: ${COLORS.WHITE};
  border-left: 1px solid ${COLORS.LIGHTGRAY1};
  z-index: 2;
  box-shadow: 0 0 8px 0 rgba(138, 138, 153, 0.15);
`

export default SidebarCollection;