import Icon from "@components/Icon/Icon";
import {css} from "styled-components";
import {COLORS, FONTS} from "@/constans";
import styled from "styled-components";
import Text from "@components/Text";

const Checkbox = ({register, name, value,onChange, title, hasBorder, checked}) => {
    const hasOnChange = ()=> {
        if(onChange) {
            return {onChange: onChange}
        }
    }

    return (
        <Label hasBorder={hasBorder}>
            <CheckboxInput
                value={value}
                name={name}
                type="checkbox"
                {...hasOnChange()}
                {...register && {...register(`${name}`)}}
            />
            <IndicatorCheckbox title={title}>
                <CheckboxIconChecked>
                    <Icon iconName="krista-square-check"/>
                </CheckboxIconChecked>
                <CheckboxIcon>
                    <Icon iconName="krista-square-uncheck"/>
                </CheckboxIcon>
            </IndicatorCheckbox>
            {title && <Text value={title} font={FONTS.REGULAR_14} color={COLORS.BLACK}/>}
        </Label>
    );
};

const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  ${({hasBorder}) => hasBorder && css`
    border: 1px solid ${COLORS.LIGHTGRAY1};
    border-radius: 10px;
    padding: 0 8px;
    height: 38px;
  `}
`

const IndicatorCheckbox = styled.div`
  ${({title}) => title && 'margin-right: 4px;'}
  width: 24px;
  height: 24px;
  position: relative;
`
const CheckboxIcon = styled.div`
  color: ${COLORS.LIGHTGRAY};
  position: absolute;
  top: 0;
  left: 0;
`
const CheckboxIconChecked = styled.div`
  color: ${COLORS.SALAD};
  display: none;
  position: absolute;
  top: 0;
  left: 0;

`
const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;

  &:checked + ${IndicatorCheckbox} {
    ${CheckboxIcon} {
      display: none;
    }

    ${CheckboxIconChecked} {
      display: flex;
    }
  }
`


export default Checkbox;