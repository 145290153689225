import React, {useEffect, useMemo,} from "react";
import {useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {getClients} from "@actions/clientsAction";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'

import NavFiltersForms from "@components/NavFilters/NavFiltersClients/NavFiltersForms/NavFilterForms";
import ClientsDashboard from "@components/Dashboards/ClientsDashboard/ClientsDashboard";

import "react-datepicker/dist/react-datepicker.css";
import {useSelector} from "react-redux";

dayjs.extend(customParseFormat)

function NavFiltersClients() {
    const [searchParams, setSearchParams] = useSearchParams();

    const calendarDefaultValue = (dateName) => {
        if (!searchParams.get(dateName)) {
            return [null, null]
        }
        const [startDate, endDate] = searchParams.get(dateName).split(' - ')
        const startStringDateParsed = dayjs(startDate, 'DD.MM.YYYY').toDate();
        const endStringDateParsed = dayjs(endDate, 'DD.MM.YYYY').toDate();
        return [startStringDateParsed, endStringDateParsed]
    }

    const citiesState = useSelector(state => state.cities.citiesState).map(item => {
        return {...item, checked: false}
    })

    const citiesDefaultValue = useMemo(() => {
        const cityIds = searchParams.get('cityIds')
        if (!cityIds) return []
        return citiesState.filter(item => cityIds.includes(String(item.id)));
    }, [citiesState])

    const {register, watch, control, setValue, reset, getValues, resetField} = useForm({
        mode: "onChange",
        defaultValues: {
            fullname: searchParams.get('fullname'),
            phone: searchParams.get('phoneNumber'),
            cities: citiesDefaultValue,
            isOnline: searchParams.get('isOnline'),
            hasCart: searchParams.get('hasCart'),
            catalogTypes: searchParams.get('catalogTypes') ? searchParams.get('catalogTypes') : [],
            hasReserve: searchParams.get('hasReserve'),
            hasDebt: searchParams.get('hasDebt'),
            registrationDates: calendarDefaultValue('registrationDates'),
            onlineDates: calendarDefaultValue('onlineDates'),
        }
    });

    const watchFullname = watch('fullname');
    const watchPhone = watch('phone');
    const watchCities = watch('cities')
    const watchHasCart = watch('hasCart')
    const watchCatalogTypes = watch('catalogTypes')
    const watchIsOnline = watch('isOnline')
    const watchHasReserve = watch('hasReserve')
    const watchHasDebt = watch('hasDebt')
    const watchRegistrationDates = watch('registrationDates')
    const watchOnlineDates = watch('onlineDates')

    useEffect(() => {
        watchHasReserve ? searchParams.set('hasReserve', watchHasReserve) : searchParams.delete('hasReserve')
        watchHasDebt ? searchParams.set('hasDebt', watchHasDebt) : searchParams.delete('hasDebt')

        watchFullname ? searchParams.set('fullname', watchFullname) : searchParams.delete('fullname')
        watchPhone ? searchParams.set('phoneNumber', watchPhone) : searchParams.delete('phoneNumber')

        watchCities.length > 0 ? searchParams.set('cityIds', watchCities.map(item => item.id).join(',')) : searchParams.delete('cityIds')

        //TODO: добавить backspace
        if (watchRegistrationDates[0] && watchRegistrationDates[1] !== null) {
            let startDate = dayjs(watchRegistrationDates[0]).format('DD.MM.YYYY')
            let endDate = dayjs(watchRegistrationDates[1]).format('DD.MM.YYYY')
            searchParams.set('registrationDates', startDate + ' ' + '-' + ' ' + endDate)
        } else {
            searchParams.delete('registrationDates')
        }
        if (watchOnlineDates[0] && watchOnlineDates[1] !== null) {
            let startDate = dayjs(watchOnlineDates[0]).format('DD.MM.YYYY')
            let endDate = dayjs(watchOnlineDates[1]).format('DD.MM.YYYY')
            searchParams.set('onlineDates', startDate + ' ' + '-' + ' ' + endDate)
        } else {
            searchParams.delete('onlineDates')
        }

        watchHasCart ? searchParams.set('hasCart', watchHasCart) : searchParams.delete('hasCart')
        watchCatalogTypes.length > 0 ? searchParams.set('catalogTypes', watchCatalogTypes) : searchParams.delete('catalogTypes')
        watchIsOnline ? searchParams.set('isOnline', watchIsOnline) : searchParams.delete('isOnline')

        setSearchParams(searchParams)
    }, [watchFullname, watchPhone, watchIsOnline, watchCatalogTypes, watchHasCart,
        watchHasReserve, watchHasDebt, watchCities, watchRegistrationDates, watchOnlineDates])

    useEffect(() => {
        if (!searchParams.get('sort') || !searchParams.get('page') || !searchParams.get('pageSize')) {
            setSearchParams({
                sort: 'asc.fullname',
                page: '1',
                pageSize: '25'
            })
        } else {
            getClients(searchParams)
        }
    }, [searchParams])

    return (
        <>
            <ClientsDashboard register={register} watch={watch}/>
            <NavFiltersForms
                register={register}
                watch={watch}
                control={control}
                reset={reset}
                setValue={setValue}
                getValues={getValues}
                resetField={resetField}
            />
        </>
    );
}

export default NavFiltersClients;