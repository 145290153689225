import styled from "styled-components";
import {COLORS, FONTS} from "@/constans";
import Icon from "@components/Icon/Icon";

const Add = ({value, onClick, ariaLabel}) => {
    return (
        <Content onClick={onClick} aria-label={ariaLabel}>
            <Icon iconName="krista-plus"/>
            {value && <div className="text">{value}</div>}
        </Content>
    );
};

const Content = styled.div`
  height: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.LIGHTGRAY2};
  border-radius: 10px;
  color: ${COLORS.LIGHTGRAY};
  cursor: pointer;
  padding: 0 7px;
  align-self: flex-start;

  &:hover {
    background-color: ${COLORS.LIGHTGRAY1};
  }

  .text {
    font: ${FONTS.SEMI_BOLD_14};
    color: ${COLORS.DARKGRAY};
    margin: 0 8px;
  }
`
export default Add;