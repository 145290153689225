import React from 'react'
import {DebounceInput} from "react-debounce-input";
import {Controller} from "react-hook-form";
import InputLayout from "@components/Form/Inputs/InputLayout";

function InputDebounce(props) {
    const {name, control, errors} = props
    const {placeholder, icon, iconPosition, ariaLabel} = props
    const {minLength, debounceTimeout, isSmall, isBorder} = props
    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState, formState,}) => {
                return (
                    <InputLayout
                        icon={icon}
                        iconPosition={iconPosition}
                        errors={errors}
                        isSmall={isSmall}
                        ariaLabel={ariaLabel}
                        isBorder={isBorder}
                    >
                        <DebounceInput
                            {...field}
                            className="controlInputDebounce"
                            minLength={minLength}
                            debounceTimeout={debounceTimeout}
                            placeholder={placeholder}
                            icon={icon}
                            aria-label={ariaLabel}
                        />
                    </InputLayout>
                )
            }}
        />
    );
}

export default InputDebounce;