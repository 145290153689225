import {COLORS} from "@/constans";
import styled from "styled-components";

function Wrapper({children}) {
    return (
        <WrapperContent>
            {children}
        </WrapperContent>
    );
}

const WrapperContent = styled.div`
  height: 100%;
  padding: 4px 4px 16px 4px;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.LIGHTGRAY1};
  border-radius: 8px;
  align-self: start;
`
export default Wrapper;