import {SET_STORE_ALL_MANAGER} from "@types/types";

const initState = {
    managersState: [],
}

const storeEmployeesReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_STORE_ALL_MANAGER :
            return {...state, managersState: action.payload}

        default:
            return state
    }
}

export default storeEmployeesReducer